import { BookFilled, BugFilled, QuestionCircleOutlined, WarningFilled } from '@ant-design/icons';
import { Dropdown, Space, theme, Typography } from 'antd';
import { isNil } from 'lodash';
import styles from './header.module.less';

const { useToken } = theme;

type SupportDropdownPropsType = {
	/**
	 * Callback for handling open help portal
	 */
	handleOpenHelpPortal?: () => void;
	/**
	 * Callback for handling report an issue
	 */
	handleReportAnIssue?: () => void;
	/**
	 * Callback for handling open site status
	 */
	handleOpenSiteStatus?: () => void;
	/**
	 * Whether the support dropdown should be rendered in light or dark mode
	 */
	isDark?: boolean;
};

/**
 * Initialize the Support Dropdown menu.
 *
 * @constructor
 */
export function SupportDropdown(props: SupportDropdownPropsType) {
	const { handleOpenHelpPortal, handleReportAnIssue, handleOpenSiteStatus, isDark } = props;
	const { token } = useToken();

	const menuItems = [];

	if (!isNil(handleOpenHelpPortal)) {
		menuItems.push({
			icon: <BookFilled />,
			label: 'Help Portal',
			key: 'helpPortal',
			onClick: handleOpenHelpPortal,
		});
	}

	if (!isNil(handleReportAnIssue)) {
		menuItems.push({
			icon: <BugFilled />,
			label: 'Report an Issue',
			key: 'reportBugs',
			onClick: handleReportAnIssue,
		});
	}

	if (!isNil(handleOpenSiteStatus)) {
		menuItems.push({
			icon: <WarningFilled />,
			label: 'Site Status',
			key: 'siteStatus',
			onClick: handleOpenSiteStatus,
		});
	}

	return (
		<Dropdown
			trigger={['click']}
			menu={{ items: menuItems }}
			overlayStyle={{ userSelect: 'none' }}
		>
			<Space
				className={isDark ? styles.supportButtonDark : styles.supportButtonLight}
				tabIndex={2}
			>
				<QuestionCircleOutlined
					style={{ verticalAlign: 'middle', fontSize: token.fontSizeLG }}
				/>
				<Typography.Text>Support</Typography.Text>
			</Space>
		</Dropdown>
	);
}
