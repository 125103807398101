import BasicContainer from '@copilot/common/components/containers/basic';
import { PageContainer } from '@copilot/common/components/containers/page';
import { TenantPanel } from '@copilot/common/pages/adminHub/TenantPanel';
import { useParams } from 'react-router';
import { useAccountOwners, useTenant } from '@copilot/common/pages/adminHub/useTenant';
import { useSelector } from 'react-redux';
import { RootState } from '@copilot/common/store/reducers';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';
import { useImpersonationWithNewTab } from '@copilot/common/pages/adminHub/useImpersonation';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { RoleTitles } from '@copilot/common/config/roles';
import { useEffect, useState } from 'react';

/**
 * The Admin Hub Page for the customer layer feature
 */
function AdminHub(): JSX.Element {
	const { tenantId } = useParams<{ tenantId: string }>();
	const handleImpersonation = useImpersonationWithNewTab();
	const currentUserTenantId = useSelector((state: RootState) => state.app.tenant);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const {
		data,
		loading,
		onOrganizationNameSearchTermChanged,
		onTenantNameSearchTermChanged,
		onAccountOwnerSelected,
	} = useTenant(tenantId ?? currentUserTenantId ?? '');
	const { data: accountOwners, loading: isAccountOwnersLoading } = useAccountOwners();
	const [selectedAccountOwnerId, setSelectedAccountOwnerId] =
		useState<string | undefined>(undefined);

	const updateSelectedAccountOwnerId = (ownerId: string | undefined) => {
		setSelectedAccountOwnerId(ownerId);
		onAccountOwnerSelected(ownerId);
	};

	useEffect(() => {
		const currentAccountOwnerId = activeMember?.userRoles.includes(RoleTitles.CustomerSuccess)
			? activeMember?.userId
			: undefined;
		updateSelectedAccountOwnerId(currentAccountOwnerId);
	}, [activeMember?.userId]);

	return (
		<PageContainer
			header="All Accounts"
			content={
				<ErrorBoundary>
					<BasicContainer bordered={false}>
						<BasicContainer.Content>
							<TenantPanel
								tenant={data}
								isLoading={loading || isAccountOwnersLoading}
								onImpersonate={handleImpersonation}
								onTenantNameSearchTermChanged={onTenantNameSearchTermChanged}
								onOrganizationNameSearchTermChanged={
									onOrganizationNameSearchTermChanged
								}
								onAccountOwnerSelected={updateSelectedAccountOwnerId}
								accountOwners={accountOwners ?? []}
								selectedAccountOwnerId={selectedAccountOwnerId}
							/>
						</BasicContainer.Content>
					</BasicContainer>
				</ErrorBoundary>
			}
		/>
	);
}

export default AdminHub;
