import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { RootState } from '@copilot/common/store/reducers';

interface LogRocketProps {
	// LogRocket App/Project Id
	appId: string;
	isEnabled: boolean;
}

/**
 * LogRocket provider. It must be included before the main application.
 * @param props LogRocketProps
 * @returns
 */
const LogRocketProvider: React.FC<LogRocketProps> = (props) => {
	const { appId, isEnabled } = props;
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const activeImpersonatorId = useSelector((state: RootState) => state.app.impersonatorId);
	const canEnable = isEnabled && appId;

	useEffect(() => {
		if (canEnable) {
			LogRocket.init(appId, {
				release: '0.0.1',
				network: {
					requestSanitizer: (request) => {
						// remove authorization token in network log
						if (request.headers.Authorization) request.headers.Authorization = '';
						return request;
					},
				},
				ingestServer: `${window.origin}/LogRocket`,
				rootHostname: window.origin,
			});
			setupLogRocketReact(LogRocket);
		}
	}, []);

	useEffect(() => {
		if (canEnable && activeMember) {
			LogRocket.identify(activeImpersonatorId ?? activeMember.userId);
			if (isEmpty(activeImpersonatorId)) {
				LogRocket.identify(activeMember.userId, {
					email: activeMember.email,
					organizationId: activeMember.organizationId,
					orgMemberId: activeMember.id,
					orgType: activeMember.orgType,
					orgRole: head(activeMember.orgRoles) ?? '',
					isFreeTrial: activeOrganization?.isFreeTrial ?? false,
				});
			}
		}
	}, [activeMember?.userId, activeImpersonatorId]);

	return <>{props.children}</>;
};

export default LogRocketProvider;
