import { Moment } from 'moment';

/**
 * Dropdown option representing a custom date range
 * Note: included in type file to avoid circular dependency
 */
export const CUSTOM_OPTION = {
	key: 'custom',
	label: 'Custom date',
	value: -1,
} as const;
/**
 * Value type for the DateRangeDropdown
 */
export type DateRangeDropdownValue = Readonly<{
	value: number;
	start?: Moment;
	end?: Moment;
}>;

/**
 * Option type for the DateRangeDropdown
 */
export type DateRangeDropdownOption = Readonly<{
	key: string;
	label: string;
	value: number;
}>;

/**
 * Custom dropdown option for DateRangeDropdown, allowing user specified date range
 */
export type CustomDropdownOption = typeof CUSTOM_OPTION;

/**
 * Range backing CustomDropdownOption, allowing user specified date range
 */
export type CustomRange = {
	option: CustomDropdownOption;
	startDate: Moment | undefined;
	endDate: Moment | undefined;
};

/**
 * Preset date range for quick access (eg last 30 days)
 */
export type PresetRange = {
	option: DateRangeDropdownOption;
	startDate: Moment;
	endDate: Moment;
};

/**
 * DateRange type for DateRangeDropdown
 */
export type DateRange = CustomRange | PresetRange;

/**
 * OnChangeCallback type for DateRangeDropdown
 */
export type OnChangeCallback = (range: DateRange) => void;
