import { Avatar, Space, Typography, Image } from 'antd';
import ProductSwitcher, {
	ProductKeys,
} from '@copilot/common/pages/layouts/headerSider/header/productSwitcher';
import { SupportDropdown } from '@copilot/common/pages/layouts/headerSider/header/supportDropdown';
import { UserDropdown } from '@copilot/common/pages/layouts/headerSider/header/userDropdown';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { isNil } from 'lodash';
import { Config } from '@copilot/common/config';
import { handleUserLogout } from '@copilot/common/utils';
import { useOrganizationLogo } from '@copilot/common/hooks/organization';
import isEmpty from 'lodash/isEmpty';
import SearchAutoComplete, {
	SearchOptionType,
} from '@copilot/common/components/search/autocomplete/searchAutoComplete';
import styles from './accountDashboardHeader.module.less';
import { ShopOutlined } from '@ant-design/icons';
import {
	IAccountSummaryFragment,
	IAccountType,
	useSearchAccountsQuery,
} from '@copilot/data/graphql/_generated';
import { useHistory } from 'react-router';
import { SEARCH_INPUT_PLACEHOLDER } from '@copilot/common/pages/accountDashboard/const';
import { useState } from 'react';
import { useThrottle } from '@copilot/common/hooks/common';

type AccountDashboardHeaderPropsType = {
	/**
	 * User's name
	 */
	name: string;
	search: string;
	setSearch: (v: string) => void;

	/**
	 * Callback for handling open help portal
	 */
	handleOpenHelpPortal?: () => void;

	/**
	 * Callback for handling open site status
	 */
	handleOpenSiteStatus?: () => void;

	/**
	 * Callback for handling report an issue
	 */
	handleReportAnIssue?: () => void;

	/**
	 * Callback for handling user logout
	 */
	handleLogout: () => void;
	/**
	 * If the accounts are loading
	 */
	loading: boolean;
	/**
	 * List of accounts to display
	 */
	accounts?: IAccountSummaryFragment[];
	/**
	 * When to navigate to a specific url after clicking on an account
	 */
	handleNavigate: (url: string) => void;
};

/**
 * Represents the header component of the account dashboard.
 * @constructor
 * @returns {JSX.Element} The AccountDashboardHeader component.
 */
export function AccountDashboardHeader({
	handleOpenHelpPortal,
	handleReportAnIssue,
	handleOpenSiteStatus,
	handleLogout,
	handleNavigate,
	name,
	loading,
	accounts,
	search,
	setSearch,
}: AccountDashboardHeaderPropsType): JSX.Element {
	const logoPath = useOrganizationLogo({ useDark: true });
	const throttleSearch = useThrottle(handleNavigate, 500, false);

	const searchOptions: SearchOptionType[] | undefined = isNil(accounts)
		? undefined
		: accounts.map((value) => {
				// if the master parent account is not mine
				// dashboard/${isUndefined(accountId) ? '' : `${accountId}/`}${id}/details
				const key =
					value.type === IAccountType.Master
						? `/dashboard/${value.id}`
						: `/dashboard/${value.id}/details`;

				return {
					key,
					label: (
						<div
							onClick={() => {
								//HACK: COPILOT-8960: To deal with AutoComplete's inability to
								// difference btw items with the same value
								throttleSearch(key);
							}}
						>
							<Typography.Text ellipsis key={value.id}>
								<Avatar shape="square" size={32} src={value.logoUrl}>
									{value.type === IAccountType.Master ? (
										<ShopOutlined />
									) : (
										value.name.substring(0, 1).toUpperCase()
									)}
								</Avatar>{' '}
								{value.name}
								<span
									className={
										value.isActive ? styles.activeStatus : styles.inactiveStatus
									}
								>
									{value.isActive ? 'Active' : 'Inactive'}
								</span>
							</Typography.Text>
						</div>
					),
					value: value.name,
				};
		  });
	return (
		<div className={styles.accountDashboardHeader}>
			<div className={styles.leftSide}>
				{!isEmpty(logoPath) && (
					<Image
						style={{ maxHeight: 40, width: 'auto' }}
						src={logoPath}
						preview={false}
					/>
				)}
				<ProductSwitcher currentProductKey={ProductKeys.accountDashboard} />
			</div>
			<div className={styles.searchBox}>
				<SearchAutoComplete
					options={searchOptions}
					placeholder={SEARCH_INPUT_PLACEHOLDER}
					onSearch={(_val, o) => {
						throttleSearch(o.key);
					}}
					onChange={setSearch}
					value={search}
					isLoadingOptions={loading}
				/>
			</div>
			<Space size="small" className={styles.rightSide}>
				<SupportDropdown
					handleOpenSiteStatus={handleOpenSiteStatus}
					handleOpenHelpPortal={handleOpenHelpPortal}
					handleReportAnIssue={handleReportAnIssue}
				/>
				<UserDropdown name={name} handleLogout={handleLogout} />
			</Space>
		</div>
	);
}

/**
 * [Smart] Component to handle logic for the account dashboard header
 *
 * @returns {JSX.Element | null} The rendered React component or null if there is no active member.
 */
export function AccountDashboardHeaderContainer(): JSX.Element | null {
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	if (isNil(activeMember)) return null;
	const [search, setSearch] = useState<string>('');
	const { loading } = useSearchAccountsQuery({
		variables: {
			accountsFilter: {
				accountName: search,
			},
			pagination: {
				pageSize: 100,
				pageIndex: 0,
			},
		},
		returnPartialData: true,
		onCompleted: (d) => setAccounts(d.admin_accounts),
	});
	const [accounts, setAccounts] = useState<IAccountSummaryFragment[]>([]);
	const history = useHistory();

	return (
		<AccountDashboardHeader
			name={activeMember.name}
			handleOpenSiteStatus={
				Config.siteStatusURL ? () => window.open(Config.siteStatusURL) : undefined
			}
			handleOpenHelpPortal={() => window.open(Config.helpPortalURL)}
			handleReportAnIssue={() => window.open(Config.reportBugURL)}
			handleLogout={() => handleUserLogout(Config.isB2CEnabled)}
			accounts={accounts}
			loading={loading}
			search={search}
			setSearch={setSearch}
			handleNavigate={(url) => history.push(url)}
		/>
	);
}
