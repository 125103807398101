import { useState, useEffect, useRef } from 'react';
import { NotificationManager, OrganizationMemberManager } from '../../../../core/data/managers';
import {
	INotification,
	INotificationAction,
	OrganizationMember,
} from '@copilot/common/store/models/redux';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '@copilot/common/store/actions/notification';
import { NotificationType, NotificationActionType } from '@copilot/common/store/models/const/enum';
import { SessionBoundModel } from 'redux-orm';
import { useFetch } from '../common';
import { LinkedInMetaActions } from '@copilot/common/store/actions/linkedInMeta';
import {
	loadActionsMessageCount,
	loadMemberActionsMessageCount,
} from '@copilot/common/pages/inbox/data/actionCreators';
import isNil from 'lodash/isNil';

/**
 * Determines if the document is visible or not.  True if hidden; false otherwise.
 */
function isDocumentHidden(): boolean {
	return document.visibilityState === 'hidden';
}

/**
 * Uses the page visibility state.
 */
export function usePageVisibility(): boolean {
	const [isVisible, setIsVisible] = useState(!isDocumentHidden());
	const onVisibilityChange = () => setIsVisible(!isDocumentHidden());
	useEffect(() => {
		window.addEventListener('visibilitychange', onVisibilityChange, false);
		return () => {
			window.removeEventListener('visibilitychange', onVisibilityChange);
		};
	});
	return isVisible;
}

const NOTIFICATION_POLL_RATE = 1000 * 60 * 2 + 1000 * 30;
export function useNotifications(
	activeMember: SessionBoundModel<OrganizationMember, {}> | null
): INotification[] {
	const id = activeMember ? activeMember.id : '';
	const isLinkedInLoggedIn = activeMember ? activeMember.isLinkedInLoggedIn : false;
	const isLinkedInSessionInRetryState = !isNil(activeMember)
		? activeMember.isLiSessionInRetryState
		: false;
	//TODO may want to add campaign paused notification here
	const storeDispatch = useDispatch();
	const [notifications, setNotifications] = useState<INotification[]>([]);

	const removeNotification = (key: string) => {
		const notificationObject = {
			id: key,
		} as INotification;
		storeDispatch(NotificationActions.removeNotifications([notificationObject]));
	};

	// page visibility state
	const isPageVisible = usePageVisibility();

	// the interval id (from setInterval) of the notification pull.
	const notificationPullIntervalId = useRef<NodeJS.Timeout | undefined>();

	/**
	 * Retrieves notifications.
	 * @param orgMemberId the org member id to get the notifications for.
	 */
	const retrieveNotifications = (orgMemberId: string) => {
		NotificationManager.getNotifications(orgMemberId)
			.then((result: any) => {
				const notificationKeys = Object.keys(result as object);
				const notificationList = notificationKeys.map(
					(n: string) =>
						({
							id: n,
							title: n,
							body: `${result[n]}`,
							actions: [],
						} as INotification)
				);
				setNotifications(notificationList);
				storeDispatch(NotificationActions.loadNotifications(notificationList));
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		if (!isLinkedInLoggedIn && !isLinkedInSessionInRetryState) {
			const title = 'Logged out of LinkedIn.';
			const body =
				"You're currently logged out of linkedin. Click here to reauthenticate with linkedin.";
			const actions = [
				{
					type: NotificationActionType.Button,
					meta: '/settings/linkedin',
					text: 'Reauthenticate',
				} as INotificationAction,
			];

			const notificationObject = {
				id: NotificationType.LoggedOut,
				title,
				body,
				actions,
			} as INotification;

			setNotifications([notificationObject]);
			storeDispatch(NotificationActions.loadNotifications([notificationObject]));
		} else {
			removeNotification(NotificationType.LoggedOut);
		}
	}, [isLinkedInLoggedIn]);

	useEffect(() => {
		if (id) {
			storeDispatch(loadMemberActionsMessageCount());
			if (activeMember?.isOrgAdmin) storeDispatch(loadActionsMessageCount());
		}
	}, [id]);

	useEffect(() => {
		// either state changes, we need to clearInterval if set.
		if (notificationPullIntervalId.current !== undefined) {
			clearInterval(notificationPullIntervalId.current);
			notificationPullIntervalId.current = undefined;
		}

		if (isPageVisible && id) {
			retrieveNotifications(id);
			notificationPullIntervalId.current = setInterval(
				() => retrieveNotifications(id),
				NOTIFICATION_POLL_RATE
			);
		}

		return () => {
			if (notificationPullIntervalId.current !== undefined) {
				clearInterval(notificationPullIntervalId.current);
			}
		};
	}, [isPageVisible, id]);

	return notifications;
}

export function useLoginCheck(
	activeMember: SessionBoundModel<OrganizationMember, {}> | null
): void {
	const [, fetchIsLoggedIn] = useFetch(
		(id: string) =>
			OrganizationMemberManager.checkIsLoggedIn(id).then((r) => ({
				orgMemberId: id,
				isLoggedIn: r,
			})),
		LinkedInMetaActions.loadLinkedInMeta
	);

	useEffect(() => {
		if (!activeMember) return undefined;
		fetchIsLoggedIn(activeMember.id);
		const intervalId = setInterval(() => {
			fetchIsLoggedIn(activeMember.id);
		}, NOTIFICATION_POLL_RATE);

		return () => clearInterval(intervalId);
	}, [activeMember?.id]);
}
