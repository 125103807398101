import { CSSProperties, FC, ReactElement } from 'react';
import { personalizedInsightsSubheadingFontSize } from './personalityInsightsPage';
import { PointedNotes } from './pointedNotes';

type GeneralSuggestionBoxProps = {
	suggestions: string[];
	icon?: ReactElement;
	title: string;
	titleStyles: CSSProperties;
	boxStyles: CSSProperties;
};

/**
 * [Presentational]
 * Container for PointedNotes below a subtitle and optional icon
 * @param props
 * @returns
 */
export const GeneralSuggestionBox: FC<GeneralSuggestionBoxProps> = (props) => (
	<div style={{ borderRadius: '5px', padding: '35px 35px 20px 35px', ...props.boxStyles }}>
		<div style={personalizedInsightsSubheadingFontSize}>
			<span>{props.icon}</span>
			<span style={{ fontWeight: 'bold', ...props.titleStyles }}> {props.title}</span>
		</div>
		<PointedNotes notes={props.suggestions} />
	</div>
);
