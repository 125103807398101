import { useEffect } from 'react';
import { OAuthConstants } from './constants';
import { TMessageData } from './types';

export interface OAuthPopupProps {
	Component?: React.ReactElement;
}

/**
 * Checks the session storage if the state key is equivalent with what was received
 * @param storage
 * @param receivedState
 * @returns
 */
function checkState(storage: Storage, receivedState: string) {
	const state = storage.getItem(OAuthConstants.OAuthStateKey);
	return state === receivedState;
}

/**
 * Helper that turns a query string to an object
 * @param query
 * @returns
 */
function queryToObject(query: string) {
	const parameters = new URLSearchParams(query);
	return Object.fromEntries(parameters.entries());
}

/**
 * Posts message to the opener
 * @param opener
 * @param message
 * @returns
 */
function openerPostMessage(opener: Window, message: TMessageData) {
	opener.postMessage(message);
}

/**
 * check if the opener is available
 * @param opener
 * @returns
 */
function isWindowOpener(opener: Window | null): opener is Window {
	return opener !== null && opener !== undefined;
}

let didInit = false;

/**
 * Component that handles the OAuth popup flow
 * @returns
 */
export const OAuthPopup: React.FC<OAuthPopupProps> = () => {
	useEffect(() => {
		if (didInit) return;

		didInit = true;
		const payload = {
			...queryToObject(window.location.search.split('?')[1]),
			...queryToObject(window.location.hash.split('#')[1]),
		};
		const state = payload?.state;
		const error = payload?.error;
		const opener: Window | null = window?.opener;

		if (isWindowOpener(opener)) {
			const stateOk = state && checkState(opener.sessionStorage, state);

			if (!error && stateOk) {
				openerPostMessage(opener, {
					type: OAuthConstants.OAuthResponse,
					payload,
				});
			} else {
				const errorMessage = error
					? decodeURI(error)
					: !stateOk
					? 'OAuth error: State mismatch.'
					: 'OAuth error: An error has occured.';
				openerPostMessage(opener, {
					type: OAuthConstants.OAuthResponse,
					error: errorMessage,
				});
			}
		} else {
			throw new Error('No window opener');
		}
	}, []);

	return <div style={{ margin: '12px' }}>Loading...</div>;
};

export default OAuthPopup;
