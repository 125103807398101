import useScheduleMessageTabV2 from './hooks/useScheduleMessageTabV2';
import ScheduleMessageTab from './scheduleMessageTab';

/**
 * Routes the data from the hook to the presentational component for the schedule message tab
 * @returns
 */
export default function ScheduleMessageTabSmart() {
	const data = useScheduleMessageTabV2();
	return <ScheduleMessageTab {...data} />;
}
