import { useState } from 'react';
import { Button, Dropdown, Typography } from 'antd';
import styles from './header.module.less';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ItemType, MenuItemType } from 'antd/lib/menu/hooks/useItems';
import isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';

export type ProductSwitcherPropsType = {
	/**
	 * Selected key
	 */
	currentProductKey: ValueOf<typeof ProductKeys>;
	/**
	 * Whether the product switcher should be styled for dark or light mode
	 */
	isDark?: boolean;
};

/**
 * The Product Keys available
 */
export const ProductKeys = {
	accountDashboard: 'accountDashboard',
	teamsAccount: 'teamsAccount',
} as const;

/**
 * Labels associated with each of those product keys
 */
const ProductLabel = {
	[ProductKeys.accountDashboard]: 'Accounts Dashboard',
	[ProductKeys.teamsAccount]: 'My Dashboard',
};

/**
 * Renders a product switcher dropdown menu.
 *
 * @constructor
 * @returns {JSX.Element} The product switcher dropdown menu.
 */
export default function ProductSwitcher({
	currentProductKey,
	isDark,
}: ProductSwitcherPropsType): JSX.Element {
	const [focus, setFocus] = useState<boolean>(false);
	const menuItems: ItemType<MenuItemType>[] = [
		{
			key: ProductKeys.accountDashboard,
			label: (
				<Link to={'/dashboard'}>
					<Typography.Text strong>
						{ProductLabel[ProductKeys.accountDashboard]}
					</Typography.Text>
					<Typography.Paragraph>
						Monitor and manage customer campaigns
					</Typography.Paragraph>
				</Link>
			),
		},
		{
			key: ProductKeys.teamsAccount,
			label: (
				<Link to={'/'}>
					<Typography.Text strong>
						{ProductLabel[ProductKeys.teamsAccount]}
					</Typography.Text>
					<Typography.Paragraph>
						Create my own campaigns and generate leads for my company
					</Typography.Paragraph>
				</Link>
			),
		},
	]
		// Sort this list so the selected product is at top
		.sort((a, _b) => (isNil(a) ? 0 : a.key === currentProductKey ? -1 : 1));
	return (
		<Dropdown
			trigger={['click']}
			menu={{
				items: menuItems,
				selectable: true,
				selectedKeys: [currentProductKey],
				onClick: () => {
					setFocus(false);
				},
			}}
			placement="bottomLeft"
			onOpenChange={setFocus}
			overlayClassName={styles.productSwitcherOverlay}
		>
			<Button
				className={isDark ? styles.productSwitcherDark : styles.productSwitcherLight}
				size={'large'}
			>
				<Typography.Text strong>{ProductLabel[currentProductKey]}</Typography.Text>{' '}
				{focus ? <UpOutlined /> : <DownOutlined />}
			</Button>
		</Dropdown>
	);
}
