import { FC, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Spin, Skeleton } from 'antd';
import {
	TagManager,
	CampaignManager,
	OrganizationManager,
	ConstantManager,
	TemplateManager,
	InterestsManager,
} from '@copilot/data';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useOrganization } from '@copilot/common/hooks/organization';
import {
	loadActiveOrganizationMember,
	useOrgSiteCheck,
	useShowMemberLiLoginModal,
} from '@copilot/common/hooks/organizationMember';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { useNotifications, useLoginCheck } from '@copilot/common/hooks/notification';
import { useFetch } from '@copilot/common/hooks/common';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import { ProxyActions } from '@copilot/common/store/actions/proxy';
import BaseDataManager from '@copilot/data/managers/base';
import { LoadMessageTemplatesAction } from '@copilot/common/pages/settings/template/data/saga';
import NotificationManager from '@copilot/common/utils/notificationManager';
import { Config as CommonConfig, Config } from '@copilot/common/config';
import { OrganizationType } from '@copilot/common/store/models/const/enum';
import { useShowPreOnboardScreen } from '@copilot/common/pages/campaignDashboard/newUserOnboard/welcome';
import { handleUserLogout } from '@copilot/common/utils';
import { useFeatureToggle } from '../../hooks/feature';
import { Features } from '@copilot/data/responses/interface';
import { fetchUserInterests } from '../userInterests/saga';
import { loadOrgTagsAction } from '@copilot/common/pages/organizationDashboard/tags/data/saga';
import { SmartReplyProvider } from '@copilot/common/hooks/smartReply/prompts';
import { MessageTemplateActions } from '@copilot/common/store/actions';

interface DataInitializerProps {
	isTeamUser: boolean;
}

let isShowingNotification = false;
BaseDataManager.RequestManager.applyResponseMiddleware(
	(response) => response,
	(error) => {
		const { response } = error;
		if (response?.status === 401 || response?.status === 403) {
			const url = response.url ?? response.config.url;
			if (!isShowingNotification && !url.includes('/auth/login')) {
				isShowingNotification = true;
				NotificationManager.showWarningNotification({
					description: 'You are not currently logged in.',
					message: 'Please log in again.',
					onClose: () => handleUserLogout(Config.isB2CEnabled),
				});
			}
		}
		return Promise.reject(response);
	}
);

const DataInitializer: FC<DataInitializerProps> = (props) => {
	const { isTeamUser } = props;
	const storeDispatch = useDispatch();
	const history = useHistory();
	const isOnboardSideNavRemovalFeature = useFeatureToggle(Features.OnboardSideBarRemovalFeature);
	const isAdvancedModeToggleFeature = useFeatureToggle(Features.IndividualAdvancedToggleFeature);
	const organizationId = useSelector(OrganizationSelectors.getActiveOrganizationId);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const incompleteCampaign = useSelector(CampaignSelectors.getFirstIncompleteCampaign);
	const [_, fetchTemplatesByOrganization] = useFetch(
		TemplateManager.getTemplateMessagesByOrg,
		MessageTemplateActions.load
	);

	const [, fetchCampaigns] = useFetch(
		CampaignManager.getCampaignsByOrganization,
		CampaignActions.loadCampaigns,
		(r) => r.results
	);
	const [, fetchOrganizationMembers] = useFetch(
		OrganizationManager.getMembers,
		OrganizationMemberActions.loadOrganizationMember
	);
	const [, getProxyMapping] = useFetch(
		ConstantManager.loadProxies,
		ProxyActions.loadProxies,
		(proxies) =>
			Object.keys(proxies).map((proxy) => {
				const proxyPort = parseInt(proxy);
				return {
					id: proxyPort,
					name: proxies[proxy],
					value: proxyPort,
				};
			})
	);

	useEffect(() => {
		if (!organizationId) return;
		// Load Organization
		useOrganization(organizationId);
		fetchOrganizationMembers(organizationId);
		// Load all tags for the organization
		storeDispatch(loadOrgTagsAction(TagManager.getTags, organizationId));
		if (organizationId) fetchCampaigns(organizationId, 0, 2000); // [TODO] THREE8-97 Investigate ways to not have to pull all campaigns on initial load. May require changes to design.
		storeDispatch(LoadMessageTemplatesAction(fetchTemplatesByOrganization, organizationId));
	}, [organizationId]);

	useEffect(() => {
		if (!activeMember) return;
		// Get info about active organization member
		loadActiveOrganizationMember(isTeamUser, !isAdvancedModeToggleFeature);
		getProxyMapping();
		storeDispatch(fetchUserInterests(InterestsManager.getUserInterests, {}));
	}, [activeMember?.id, isTeamUser, isAdvancedModeToggleFeature]);

	const canOnboard = useMemo(() => {
		if (!CommonConfig.isAgency && activeMember?.orgType === OrganizationType.Individual) {
			// activeMember.isOnboarded will be undefined if the OrganizationMember fetch hasn't happened yet
			if (activeMember.isOnboarded !== undefined) {
				return !activeMember.isOnboarded;
			} else {
				return undefined;
			}
		} else {
			return false;
		}
	}, [activeMember?.orgType, activeMember?.isOnboarded]);

	// Load notifications
	useNotifications(activeMember);
	useOrgSiteCheck(activeMember);
	useLoginCheck(activeMember);
	useShowMemberLiLoginModal(activeMember);
	useShowPreOnboardScreen(canOnboard ? incompleteCampaign?.id : undefined);

	useEffect(() => {
		if (activeMember && canOnboard && incompleteCampaign?.id) {
			if (isOnboardSideNavRemovalFeature) {
				history.push('/onboard');
			} else {
				history.push(`/campaign/${incompleteCampaign.id}`);
			}
		}
	}, [activeMember, canOnboard, incompleteCampaign?.id, isOnboardSideNavRemovalFeature]);

	if (activeMember && canOnboard === undefined)
		return (
			<Spin size="large">
				{' '}
				<Skeleton paragraph={{ rows: 15 }} />{' '}
			</Spin>
		);
	return <SmartReplyProvider isTeamUser={isTeamUser}>{props.children}</SmartReplyProvider>;
};

export default DataInitializer;
