import ConversationListV2 from './conversationListV2';
import MessageForm from '@copilot/common/components/forms/messageForm';
import styles from './conversationTab.module.less';
import { Empty } from 'antd';
import { ConversationTabProps } from './types';
import {
	CONVERSATION_TAB_MESSAGE_SECTION,
	INVALID_CONVERSATION_DESCRIPTION_TEXT,
} from './constants';
import isNil from 'lodash/isNil';

/**
 * Component responsible for rendering the conversation tab in the contact drawer and coordinating its state, CRUD flows, etc
 * @returns
 */
export default function ConversationTab({
	//message thread rendering data
	messages,
	threadLink,
	isConversationRestricted,
	sourceName,
	targetName,
	tempOutgoing,
	isConversationInvalid,
	//message form data
	isMessageFormDisabled,
	campaignId,
	messageFormPlaceholder,
	smartReplyPromptCategory,
	onSubmitMessage,
	onSetReminderOnNoResponse,
	smartReplyResult,
	writeSmartReply,
	regenerateSmartReply,
	editSmartReply,
	nextAutomatedStep,
	messageTemplates,
	createQuickReplyModal,
	//tracking
	handleConversationTabTracking,
	//feature flags
	videoInAppValidationEnabled,
	hasAcceptedAITermsOfUse,
}: ConversationTabProps) {
	return (
		<div className={styles.conversationContentWrapper}>
			{isConversationInvalid ? (
				<div className={styles.invalidConversationPlaceholder}>
					<Empty description={INVALID_CONVERSATION_DESCRIPTION_TEXT} />
				</div>
			) : (
				<>
					<div className={styles.conversationListWrapper}>
						<ConversationListV2
							sourceName={sourceName}
							targetName={targetName}
							messages={messages}
							threadLink={threadLink}
							onTracking={handleConversationTabTracking}
							tempOutgoing={tempOutgoing}
						/>
					</div>
					<div
						className={styles.messageFormWrapper}
						data-testid={CONVERSATION_TAB_MESSAGE_SECTION}
					>
						<MessageForm
							messageTemplates={messageTemplates}
							videoInAppValidationEnabled={videoInAppValidationEnabled}
							hasAcceptedAITermsOfUse={hasAcceptedAITermsOfUse}
							isThreadRestricted={isConversationRestricted}
							isDisabled={isMessageFormDisabled}
							isUsingTemplateDropdown
							onSetupTemplate={(formMessage: string) => {
								createQuickReplyModal([campaignId], formMessage);
							}}
							onSubmit={onSubmitMessage}
							onSetReminder={onSetReminderOnNoResponse}
							placeholder={messageFormPlaceholder}
							chatGPTWriteRetryCallback={regenerateSmartReply}
							chatGPTWriteCallback={writeSmartReply}
							chatGPTEditCallback={editSmartReply}
							chatGPTQueryResult={smartReplyResult}
							automatedSteps={isNil(nextAutomatedStep) ? [] : [nextAutomatedStep]}
							smartReplyPromptCategory={smartReplyPromptCategory}
						/>
					</div>
				</>
			)}
		</div>
	);
}
