// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fBy2XOfU8o2y4Ssx2tmq {\n  min-height: 0;\n  max-height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  flex-shrink: 1;\n  box-sizing: border-box;\n  padding-right: 16px;\n}\n.TM1C9htd2fzcGv_ImC6I {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-between;\n  padding: 16px;\n  padding-bottom: 16px;\n  padding-right: 0;\n  max-height: 100%;\n  overflow: hidden;\n}\n.eVJ3dgJJVUeMWp06Wjgo {\n  padding-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/contactDrawerV2/tabs/notesTab/notesTab.module.less"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,sBAAA;EAEA,mBAAA;AAAD;AAGA;EACC,aAAA;EACA,sBAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,oBAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AADD;AAIA;EACC,mBAAA;AAFD","sourcesContent":[".notesListWrapper {\n\tmin-height: 0;\n\tmax-height: 100%;\n\toverflow-y: auto;\n\toverflow-x: hidden;\n\tflex-shrink: 1;\n\tbox-sizing: border-box;\n\n\tpadding-right: 16px;\n}\n\n.notesWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100%;\n\tjustify-content: space-between;\n\tpadding: 16px;\n\tpadding-bottom: 16px;\n\tpadding-right: 0;\n\tmax-height: 100%;\n\toverflow: hidden;\n}\n\n.submitFormWrapper {\n\tpadding-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesListWrapper": "fBy2XOfU8o2y4Ssx2tmq",
	"notesWrapper": "TM1C9htd2fzcGv_ImC6I",
	"submitFormWrapper": "eVJ3dgJJVUeMWp06Wjgo"
};
export default ___CSS_LOADER_EXPORT___;
