import { OrganizationMemberManager } from '@copilot/data';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import { useStoreLoader, useFetch } from '../common';
import { AppActions } from '@copilot/common/store/actions/app';
import Store from '@copilot/common/store';
import { Ref, SessionBoundModel } from 'redux-orm';
import { IOrganizationMember, OrganizationMember } from '@copilot/common/store/models/redux';
import { OrganizationType, OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import { Config } from '@copilot/common/config';
import { useEffect } from 'react';

import { loadProfile } from '../permission';
import PermissionProfiles from '../permission/profiles';
import { LinkedInMetaActions } from '@copilot/common/store/actions/linkedInMeta';
import ModalManager from '@copilot/common/utils/modalManager/index';
import { useMsal } from '@azure/msal-react';
import { appInsights } from '@copilot/common/components/snippets/applicationInsights';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '../../store/selectors/organizationMember';

/**
 * Facade for the Redux ORM Organization Member type
 */
type OrgMember = Ref<OrganizationMember>;

/**
 * Load the active user the platform
 * @param isTeamUser Whether the user should be a teams user
 * @param isDefaultAdvancedIndividual Whether we want to default an individual customer to advanced
 */
export const loadActiveOrganizationMember = (
	isTeamUser: boolean,
	isDefaultAdvancedIndividual = false
): void => {
	OrganizationMemberManager.getMember().then((r) => {
		Store.Dispatch(OrganizationMemberActions.loadOrganizationMember(r));

		let profile: keyof typeof PermissionProfiles;
		if (isTeamUser) {
			if (
				r.orgRoles.includes(OrgRoleTitles.Admin) ||
				r.orgRoles.includes(OrgRoleTitles.Owner)
			) {
				profile = 'AdminTeamUser';
			} else if (r.orgRoles.includes(OrgRoleTitles.Advanced)) {
				profile = 'AdvancedTeamUser';
			} else {
				profile = 'BaseTeamUser';
			}
		} else if (isDefaultAdvancedIndividual || r.feSettings?.isAdvanced) {
			profile = 'AdvancedIndividualUser';
		} else {
			profile = 'IndividualUser';
		}
		loadProfile(profile);

		if (r.feSettings) {
			Store.Dispatch(AppActions.loadSettings(r.feSettings));
		}
	});
};

export const useOrganizationMember = (id: string) => {
	useStoreLoader(OrganizationMemberActions.loadOrganizationMember, () =>
		OrganizationMemberManager.getMember(id)
	);
};

/**
 * Hook to fetch the current Organization Member
 */
export function useActiveMember(): IOrganizationMember | null {
	return useSelector(OrganizationMemberSelectors.getActiveMember);
}

/**
 * Fetches all the active organization's members
 */
export function useAllOrganizationMembers(): readonly OrgMember[] | null {
	return useSelector(OrganizationMemberSelectors.getAllOrgMembers);
}

export const useOrgSiteCheck = (activeMember: SessionBoundModel<OrganizationMember, {}> | null) => {
	const url = window.location.href;
	// Config should not change so we should never have incorrect # of hooks
	const msal = Config.isB2CEnabled ? useMsal() : null;

	const logout = () => {
		const cookieStr = 'token=; Expires= Thu, 01 Jan 1970 00:00:01 GMT;';
		document.cookie = cookieStr;
		document.cookie = 'crm_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		if (msal?.instance) msal.instance.logout(); // Log out the user through b2c if it is enabled
		if (appInsights?.ApplicationInsights)
			appInsights.ApplicationInsights.clearAuthenticatedUserContext();
	};

	const redirectSite = (siteUrl: string | undefined) => {
		const sites = siteUrl?.split(',');
		if (sites?.length) {
			let isProperUrl = false;
			sites.forEach((u) => {
				if (url.includes(u.trim())) {
					isProperUrl = true;
				}
			});
			if (!isProperUrl) {
				logout();
				// TODO: we don't know which site url to use at this point, so default to the first one
				window.location.replace(`//${sites[0]}`);
			}
		}
	};

	if (process.env.NODE_ENV == 'development') {
		return;
	}
	useEffect(() => {
		if (activeMember && activeMember.orgType === OrganizationType.Enterprise) {
			redirectSite(Config.teamURL);
		} else if (activeMember && activeMember.orgType === OrganizationType.Individual) {
			redirectSite(Config.individualURL);
		}
	}, [activeMember]);
};

export function useShowMemberLiLoginModal(
	activeMember: SessionBoundModel<OrganizationMember, {}> | null
): void {
	const [, fetchIsLoggedIn] = useFetch(
		(id: string) =>
			OrganizationMemberManager.checkIsLoggedIn(id).then((r) => ({
				orgMemberId: id,
				isLoggedIn: r,
			})),
		LinkedInMetaActions.loadLinkedInMeta
	);

	const showLiLoginModal = (isLoggedIn: boolean, isIndividual: boolean): void => {
		const isLoginModalEligible = !Config.isAgency && isIndividual;
		if (!isLoggedIn && isLoginModalEligible) {
			ModalManager.openReconnectLinkedInAlert({ width: 500, centered: true });
		}
	};

	useEffect(() => {
		if (activeMember?.isOnboarded) {
			fetchIsLoggedIn(activeMember.id).then((r) =>
				showLiLoginModal(r.isLoggedIn, activeMember.orgType === OrganizationType.Individual)
			);
		}
	}, [activeMember?.id, activeMember?.isOnboarded]);
}
