import { EMPTY_PAGINATION_RESPONSE } from '@copilot/common/types/pagination';
import { MeetingsBookedContextData, MeetingsBookedGlobalFilter } from './types';
import { getDefaultActiveInactiveOptions } from '@copilot/common/components/filters/organizationFilterSection/constants';
import { DEFAULT_MEETING_BOOKED_PAGE_SIZE } from '../tables/constants';

export const DEFAULT_MEETINGS_BOOKED_GLOBAL_FILTER: MeetingsBookedGlobalFilter = {
	campaigns: [],
	teamMembers: [],
	dateRange: {},
};

export const DEFAULT_MEETINGS_BOOKED_CONTEXT: MeetingsBookedContextData = {
	//OPTIONS
	campaignOptions: getDefaultActiveInactiveOptions(),
	teamMemberOptions: [],
	//STATE
	activeFilter: DEFAULT_MEETINGS_BOOKED_GLOBAL_FILTER,
	isLoadingConfirmed: false,
	isLoadingDetected: false,
	isInitializingData: true,
	isClearFiltersEnabled: false,
	confirmedPageSize: DEFAULT_MEETING_BOOKED_PAGE_SIZE,
	detectedPageSize: DEFAULT_MEETING_BOOKED_PAGE_SIZE,
	teamMemberFilterEnabled: false,
	//HANDLERS
	onCampaignFilterChange: () => {},
	onTeamMemberFilterChange: () => {},
	onUpdateDateRange: () => {},
	onConfirmMeeting: () => {},
	onDenyMeeting: () => {},

	onClearFilters: () => {},
	onLoadPage: () => {},
	onViewConversation: () => {
		return Promise.resolve();
	},
	onViewPersonalizedInsights: () => {
		return Promise.resolve();
	},
	onUpdatePageSize: () => {},
	//DATA
	confirmedMeetings: EMPTY_PAGINATION_RESPONSE,
	aiDetectedMeetings: EMPTY_PAGINATION_RESPONSE,
};
