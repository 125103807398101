import { ActiveInactiveFilterOptions } from './types';

export const TEAM_MEMBER_FILTER_TEST_ID = 'team-member-filter';
export const CAMPAIGN_FILTER_TEST_ID = 'campaign-filter';
export const FILTER_SECTION_TEST_ID = 'filter-section';

export const DEFAULT_ACTIVE_ORGANIZATION_FILTER = {
	teamMembers: [],
	campaigns: [],
};

export function getDefaultActiveInactiveOptions(): ActiveInactiveFilterOptions {
	return { active: [], inactive: [] };
}
