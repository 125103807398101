import { Card, Col, Row, Typography } from 'antd';
import { ScheduleOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { PolarityCard } from '../polarityCard';
import { SectionCard } from '../sectionCard';
import { CommunicationStyle } from '../../../services/types';
import styles from './meetStrategicallySection.module.less';
import { getPointCardSection } from '../util';
import { ContentWithTitle, MeetingSectionData } from '../types';

function getDosAndDontsCardSection(item: ContentWithTitle, idx: number) {
	return (
		<Card.Grid key={item.title} className={styles.gridItem} hoverable={false}>
			<Typography.Title level={5} className={styles.cardTitle}>
				{idx + 1}. {item.title}
			</Typography.Title>
			<Typography.Paragraph>{item.content}</Typography.Paragraph>
		</Card.Grid>
	);
}

type MeetStrategicallySectionProps = {
	data: MeetingSectionData;
	communicationStyle: CommunicationStyle;
};

/**
 * Section for effective meeting content
 * @param param0
 * @returns
 */
export function MeetingStrategicallySection({
	data: { dos, donts, objectionHandling, callToAction },
	communicationStyle,
}: MeetStrategicallySectionProps) {
	return (
		<div id="#meet-strategically" className={styles.contentBody}>
			<SectionCard
				title={
					<>
						<ScheduleOutlined /> Meet Strategically
					</>
				}
				communicationStyle={communicationStyle}
			>
				<Row gutter={[24, 24]}>
					<Col span={24} sm={12}>
						<PolarityCard
							title={'Communication Dos'}
							isPositive
							content={dos.map((doItem, idx) => {
								return getDosAndDontsCardSection(doItem, idx);
							})}
						/>
					</Col>

					<Col span={24} sm={12}>
						<PolarityCard
							title={"Communication Don'ts"}
							content={donts.map((dontItem, idx) => {
								return getDosAndDontsCardSection(dontItem, idx);
							})}
						/>
					</Col>
				</Row>
				<SectionCard
					title={
						<>
							<DoubleRightOutlined /> Objection Handling
						</>
					}
					communicationStyle={communicationStyle}
					isSubSection
					usesSection
				>
					{objectionHandling.map((point, idx) => getPointCardSection(point, idx))}
				</SectionCard>
				<SectionCard
					title={
						<>
							<DoubleRightOutlined /> Clear Call-to-Action
						</>
					}
					communicationStyle={communicationStyle}
					isSubSection
					usesSection
				>
					{callToAction.map((point, idx) => getPointCardSection(point, idx))}
				</SectionCard>
			</SectionCard>
		</div>
	);
}
