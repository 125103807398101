import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * This functional can be used to maintain scroll postion on the top
 * of the page as the user navigates between routes.
 *
 * This is a preferred method as we prefer to reload our cached model
 * rather than show stale data.
 *
 * @returns a react functional component
 */
const ScrollToTop: React.FC = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		// Scroll the user to the top
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};
export default ScrollToTop;
