import { OrganizationManager } from '@copilot/data';
import { OrganizationActions } from '@copilot/common/store/actions/organization';
import { useStoreLoader } from '../common';
import { useSelector } from 'react-redux';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { Config } from '@copilot/common/config';
import { AgencyCode } from '@copilot/common/config/constant';

export const useOrganization = (id: string) => {
	if (!id) return;
	useStoreLoader(OrganizationActions.loadOrganization, () =>
		OrganizationManager.getOrganization(id)
	);
};

type OrganizationLogoProps = {
	/**
	 * Should try to use the dark logo if available
	 * Defaults to use light logo
	 */
	useDark?: boolean;
};

/**
 * Hook to get the current logo of the active organization
 */
export function useOrganizationLogo(props?: OrganizationLogoProps): string | undefined {
	// If the current organization has a logo, use it.
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	// Return undefined when activeOrganization is nil because it is still loading
	// There should always be an activeOrganization once data is loaded
	if (isNil(activeOrganization)) return undefined;

	if (!isEmpty(activeOrganization.logoUrl)) {
		// Extensive check added above due to type inference issues with lodash methods
		return activeOrganization.logoUrl;
	}

	// If an agency is logged in, use their logo.
	if (Config.isAgency) {
		switch (Config.agencyCode) {
			case AgencyCode.cleverly:
				return require('../../assets/images/cleverly_white.jpg');
			case AgencyCode.prosocial:
			default:
				return require('../../assets/images/socially_white.png');
		}
	}

	// Use CoPilot's logo
	return require(props?.useDark
		? '../../assets/images/copilotai_black.png'
		: '../../assets/images/copilotai_white.png');
}
