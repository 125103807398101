import { Insights } from '../../services/types';
import { PersonalizedInsightsData } from './types';

/**
 * Copy look up for each insights communication style
 * @returns
 */
export function getInsightsLookup(): { [key in Insights['commStyle']]: PersonalizedInsightsData } {
	return {
		director: {
			messaging: {
				approachCopy:
					'Use this approach when you are dealing with decision-makers or people who appreciate direct and fact-based communication. Ideal for initial outreach or follow-ups where specifics and action are required.',
				dos: ['Get straight to the point and mention specific milestones or achievements.'],
				donts: ['Use too many words or fluff. Keep it concise.'],
				templates: {
					postConnection: [
						"Hi {firstname}, thanks for connecting. I've been following [Their Company]'s growth, and it’s impressive how you’ve achieved [Their Specific Milestone]. Looking forward to learning more from you.",
						"Hey {firstname}, glad we're connected. I noticed you're deeply involved in [Industry]. Any insights you'd care to share on its future trends?",
						'Appreciate connecting, {firstname}. Your role at [Their Company] caught my attention. Are you interested in exploring [Opportunity Type] opportunities together?',
						"Hello {firstname}, I’m intrigued by your role in [Industry]. Let's discuss synergies between our goals and [Your Product].",
					],
					nurturing: [
						'Hi {firstname}, congratulations on [Their Specific Milestone].',
						'Hi {firstname}, just a quick check-in. How’s everything going with [Their Specific Milestone] at [Their Company]? ',
						'Hey {firstname}, what are you up to nowadays?',
						'Hey {firstname}, I came across this latest update in [Industry] and immediately thought of you and [Their Company]. There might be some interesting takeaways for your team.',
					],
					meetings: [
						'Hi {firstname}, I hope all is well. I’m reaching out because [Your Reason To Reach] and based on what you mentioned prior to this, I think diving into how [Your Product] can provide [Your Benefits]. Would [Date and Time] work for you to discuss this?',
						'Hey {firstname}, do you have some time this week for a quick catch-up?',
						'Interested in a brief meeting, {firstname}? Let’s discuss actionable strategies with [Your Product] for [Their Company].',
						'Would you be available for a quick strategy discussion, {firstname}? Keen to outline how [Your Product] meets [Their Specific Milestone].',
					],
					objections: [
						"I understand your concerns, {firstname}. Let's re-examine how [Your Product] aligns with your objectives.",
						"Noted your points, {firstname}. Let's address them directly to see how [Your Product] can still be a fit.",
						'Noted. I’ll pass this feedback to my team and see how we can address some of your concerns.',
						'Thanks for the feedback. Perhaps we can look into how [Your Product] might fit into your workflow later down the line.',
					],
				},
			},
			meeting: {
				dos: [
					{
						title: 'Get to the Point',
						content:
							"Quickly summarize what you're offering and how it solves their problem.",
					},
					{
						title: 'Present Facts',
						content: 'Use data and concrete examples to support your points.',
					},
					{
						title: 'Set an Agenda',
						content: 'Make the meeting structure clear from the beginning.',
					},
					{
						title: 'Decision Making',
						content:
							'Present the most important points and alternatives, then discuss how to move forward.',
					},
				],
				donts: [
					{
						title: 'Avoid Small Talk',
						content: 'Directors prefer getting straight to business.',
					},
					{
						title: "Don't Be Indirect",
						content: 'Avoid beating around the bush; be concise and clear.',
					},
					{
						title: 'No Fluff',
						content: "Don't use flowery language or unnecessary jargon.",
					},
					{
						title: "Don't Over-explain",
						content: 'Give just enough information to make a decision.',
					},
				],
				objectionHandling: [
					{
						title: 'Quick Rebuttal',
						content:
							'Immediately counter objections with a one-liner backed by a key statistic.',
					},
					{
						title: 'Close-Loop',
						content:
							'"Does that resolve your concern?" to ensure you\'ve addressed the issue.',
					},
				],
				callToAction: [
					{
						title: 'Immediate Action',
						content: "Let's finalize this deal by end of the day. Sound good?",
					},
					{
						title: 'Quick Validation',
						content:
							'Can I consider your objection addressed and move to contract discussion?',
					},
				],
			},
			following: [
				{
					title: 'Timely Followup',
					content: 'Always follow up promptly and as promised.',
				},
				{
					title: 'Bullet-Point Recap',
					content:
						"Use bullet points to summarize the meeting's key takeaways and next steps.",
				},
				{
					title: 'Timeline Attached',
					content: 'Include a brief timeline for project implementation.',
				},
				{
					title: 'Confirmation',
					content:
						'Seek confirmation on the timeline or next steps to ensure everyone is aligned.',
				},
			],
		},
		relator: {
			messaging: {
				approachCopy:
					'Perfect for those who prefer long-term relationships and deep connections. Use this approach when your prospect values loyalty and consistent communication.',
				dos: ['Show genuine interest in their career and their company culture.'],
				donts: ['Rush into the sales pitch; build a relationship first.'],
				templates: {
					postConnection: [
						'Hello {firstname}, I appreciate the connection. I see we both value [Common Professional Interest]. How did you get started in your role at [Their Company]?',
						"Hi {firstname}, thanks for connecting. I noticed you've been at [Their Company] for several years. That speaks volumes about the company culture. Would love to hear more about your experience there.",
						"Thanks for connecting, {firstname}! I saw you're also an alumnus at [Shared School] - small world.",
						'Thanks for connecting, {firstname}! I saw you know [Shared Connection] too - small world!',
					],
					nurturing: [
						'Hey {firstname}, I came across this article about [Industry Trend] and thought I would share it with you! Cheers.',
						"I'm interested in learning more about [Industry]/[Their Role] — could you share any insights about your experience?",
						'Hey {firstname}, just wanted to say I really appreciated our previous discussions. Your insights were incredibly valuable. Hope everything is going great with you.',
						"Hey {firstname}, I just wanted to check-in. Seems like you're up to great things! How's everything going at [Their Company]?",
					],
					meetings: [
						"Hi {firstname}, I remember you mentioning some challenges you're facing. I believe [Your Product] can be tailored to meet those specific needs. Do you have time for a call to explore this further?",
						'Hello {firstname}, our conversation got me thinking. You really value long-term strategies, and I think [Your Product] could align well with that. Would you be interested in discussing how we can support your goals in a call this week?',
						"Hey {firstname}, based on what's been said, I think there's a lot we can achieve together. Can we schedule a time to discuss this in detail?",
						'Hi {firstname}, your insights about [Industry/Topic] have been incredibly valuable. I’d love to have a more in-depth conversation. Are you available for a meeting soon?',
					],
					objections: [
						'I understand your concerns, {firstname}. It’s important to us that we address them fully. Could we discuss this further to find a solution that aligns with your values?',
						'Hey {firstname}, your feedback is invaluable. Let’s collaborate to tailor our strategy in a way that feels right for you and [Their Company]. When could we discuss this?',
						"Hi {firstname}, I appreciate your honesty and see where you're coming from. Let’s work together to ensure our approach meets your needs and expectations.",
						'Hi {firstname}, building a solution that you’re comfortable with is our priority. I’m here to listen and adapt our approach to better suit your long-term goals. Can we set up a call?',
					],
				},
			},
			meeting: {
				dos: [
					{
						title: 'Start With Context',
						content:
							'Relators value how your product fits into broader strategies or initiatives.',
					},
					{
						title: 'Be Genuine',
						content: 'Authenticity goes a long way; no hard-selling.',
					},
					{
						title: 'Patient Explanation',
						content: 'Take the time to answer questions in depth.',
					},
					{
						title: 'Future Plans',
						content: 'Discuss long-term relationship and support plans.',
					},
				],
				donts: [
					{
						title: "Don't Push",
						content: 'Avoid aggressive sales tactics.',
					},
					{
						title: 'Avoid Being Impersonal',
						content: "Show you've done your homework on them or their company.",
					},
					{
						title: "Don't Skip Steps",
						content: 'Walk through all points systematically.',
					},
					{
						title: 'Avoid Hype',
						content: 'Stick to realistic promises and expectations.',
					},
					// ... more donts
				],
				objectionHandling: [
					{
						title: 'Empathetic Acknowledgment',
						content:
							"I understand your concern; here's how we've successfully addressed this in the past.",
					},
					{
						title: 'Solution Discussion',
						content:
							'Invite them to discuss how the objection might be collaboratively resolved.',
					},
					// ... more objectionHandling
				],
				callToAction: [
					{
						title: 'Mutual Goals',
						content: 'I believe this aligns with your goals, shall we move forward?',
					},
					{
						title: 'Shared Plan',
						content: 'Would you like to join me in building something impactful here?',
					},
				],
			},
			following: [
				{
					title: 'Timely Followup',
					content: 'Always follow up promptly and as promised.',
				},
				{
					title: 'Thank You',
					content: 'A genuine thank-you note acknowledging their time and contribution.',
				},
				{
					title: 'Resource Sharing',
					content:
						'Share an article or tool that relates to what you discussed; shows you’re thinking about their needs.',
				},
				{
					title: 'Personal Note',
					content:
						'Mention something specific you discussed in a follow-up to personalize it.',
				},
				{
					title: 'Feedback Request',
					content: 'Ask for their thoughts or concerns to show you value their opinion.',
				},
			],
		},
		socializer: {
			messaging: {
				approachCopy:
					'This approach is great for those who thrive on personal relationships and social interaction. Use it when the prospect seems to be active on social media or has shown a preference for casual, friendly communication.',
				dos: [
					'Make the message personalized by mentioning their recent LinkedIn posts or common interests.',
				],
				donts: [
					"Ignore their social cues; if they're talking about certain topics online, tie that into your conversation.",
				],
				templates: {
					postConnection: [
						"Hey {firstname}, thrilled to be connected! Loved your recent post on [Topic]. What's the most exciting project you're working on right now?",
						"Hi {firstname}, I'm happy we connected. I see you're also a fan of [Shared Interest, e.g., a specific book, sports team, or hobby]. Have you caught the latest [Event/Game/Episode]?",
						"It's great we've connected, {firstname}! I see you're working at [Their Company]. Could you tell me more about what you do?",
						'Hi {firstname}, thanks for connecting! How are you finding your work at [Their Company]?',
					],
					nurturing: [
						'Thanks for connecting, {firstname}! I saw you know [Shared Connection] too - small world!',
						'Hey {firstname}, I came across this interesting piece about [Industry Trend] and immediately thought of you. Would love to hear your perspective!',
						"Hey {firstname}, just checking in! How's everything going at [Their Company]?",
						"I'm interested in learning more about [Industry]/[Their Role] — could you share any insights?",
					],
					meetings: [
						"Hey {firstname}, it's been awesome hearing about your dynamic team at [Their Company]. I can't help but think [Your Product] could bring your team to the next level. Are you up for a chat later this week?",
						'Hi {firstname}, I loved hearing about your recent project! It made me think about how [Your Product] could take things to a whole new level for you. How does your schedule look for a catch-up call?',
						"How about a coffee chat, {firstname}? I'd love to chat more with you.",
						"Are you open to a call, {firstname}? I'd love to discuss how [Your Product] can support [Their Company].",
					],
					objections: [
						"I hear you, {firstname}. Let's revisit this with a fresh perspective that might resonate more with you.",
						'Thanks for your honesty, {firstname}. What if we look at it from a different angle that aligns with [Their Company]?',
						"Thanks for the feedback, {firstname}. Let's revisit how [Your Product] aligns with your objectives.",
						"Thanks, {firstname}. Maybe there's another way we can look at this.",
					],
				},
			},
			meeting: {
				dos: [
					{
						title: 'Build Rapport',
						content: 'Start the meeting with a few minutes of friendly chit-chat.',
					},
					{
						title: 'Be Enthusiastic',
						content: 'Show energy and excitement about your product.',
					},
					{
						title: 'Visuals',
						content: 'Use images or videos that make your pitch more engaging.',
					},
					{
						title: 'Interactive',
						content: 'Encourage feedback and dialogue throughout the meeting.',
					},
				],
				donts: [
					{
						title: "Don't Be Monotone",
						content: 'Vary your tone to keep the socializer engaged.',
					},
					{
						title: 'Avoid Excessive Details',
						content: 'Stick to big-picture benefits and features.',
					},
					{
						title: 'No Long Lectures',
						content: 'Keep the energy high with shorter, more dynamic segments.',
					},
					{
						title: "Don't Rush",
						content: 'Allow time for discussion and relationship-building.',
					},
				],
				objectionHandling: [
					{
						title: 'Positive Spin',
						content:
							'Put a positive twist on the objection to demonstrate its benefit.',
					},
					{
						title: 'Social Proof',
						content: 'Use testimonials or recommendations to address concerns.',
					},
				],
				callToAction: [
					{
						title: 'Visual Imagery',
						content:
							'Picture us celebrating our first successful quarter together. Ready to start?',
					},
					{
						title: 'Immediate Engagement',
						content: "Why don't we get started on this exciting journey today?",
					},
				],
			},
			following: [
				{
					title: 'Timely Followup',
					content: 'Always follow up promptly and as promised.',
				},
				{
					title: 'Friendly Check-in',
					content: "Just wanted to see how you're feeling about our discussion?",
				},
				{
					title: 'Event Invite',
					content:
						'Invite them to an upcoming webinar or social event related to your product.',
				},
				{
					title: 'Success Stories',
					content:
						'Share a short anecdote or success story that relates to what you discussed.',
				},
			],
		},
		thinker: {
			messaging: {
				approachCopy:
					'This approach is designed for those who value data and thorough analysis. Use them when your prospect seems analytical and detail-oriented.',
				dos: ['Provide facts, statistics, or thought-provoking questions.'],
				donts: ['Make unsubstantiated claims or be overly emotive.'],
				templates: {
					postConnection: [
						"Hi {firstname}, thank you for the connection. I've read some of your articles about [Topic] and appreciate the depth of research. How do you see this trend evolving?",
						"Hello {firstname}, glad we could connect. I'm interested in your work - could you tell me more about what you do at [Their Company]?",
						'Hi {firstname}, thanks for connecting. I hope you are doing well.',
						"Thanks for connecting, {firstname}. I saw you're working on [Specific Project or Topic] - could you tell me more about it?",
					],
					nurturing: [
						'Hey {firstname}, I came across this article and thought you might find it interesting.',
						'Hi {firstname}, how is work going at [Their Company]?',
						"Hey {firstname}, I'm interested in learning more about [Industry]/[Their Role]. Do you have any insights?",
						'Hey {firstname}, how can I learn more about [Industry]?',
					],
					meetings: [
						"Hi {firstname}, in our previous discussions, you've emphasized the importance of data. Our product shows a [Your Statistical Benefit] within [Timeframe]. How about we schedule a call for a detailed walkthrough?",
						"Hi {firstname}, I recall you being interested in comprehensive solutions. We've got a range of case studies that show how our product tackles [Pain Point] from multiple angles. Are you free to dive into these details in a call soon?",
						'Hi {firstname}, considering your schedule, a short meeting might give us more room to discuss details. Let me know what your availability is like.',
						"Hey {firstname}, I think we could benefit from having a meeting. We can go over the details to better understand what we're both looking for.",
					],
					objections: [
						"I understand your concerns, {firstname}. Let's review the data together and address any specific points you're unsure about.",
						"Hi {firstname}, thanks for your insightful feedback. Let's break down your points and see how we can align our solution more closely with your needs.",
						'Hello {firstname}, I appreciate your attention to detail. Let me provide more specific information and case studies that might address your concerns more effectively.',
						"Thank you for your candid feedback, {firstname}. Based on your concerns, I'd like to share some additional insights and examples that may help in reassessing the situation.",
					],
				},
			},
			meeting: {
				dos: [
					{
						title: 'Data-Driven',
						content:
							"Present thorough data that validates your product's effectiveness.",
					},
					{
						title: 'Logical Structure',
						content: 'Your meeting should follow a logical flow.',
					},
					{
						title: 'Time for Questions',
						content: 'Allocate time for detailed queries.',
					},
					{
						title: 'Proof of Concept',
						content: 'Offer case studies, testimonials, or even a demo.',
					},
				],
				donts: [
					{
						title: "Don't Skip Details",
						content: 'Thinkers want to understand the finer points.',
					},
					{
						title: 'Avoid Emotional Appeals',
						content: 'Focus on logic and reason.',
					},
					{
						title: 'No Ambiguity',
						content: 'Be specific in all claims and proposals.',
					},
					{
						title: "Don't Rush",
						content: 'Allow time for thorough evaluation and consideration.',
					},
				],
				objectionHandling: [
					{
						title: 'Detailed Response',
						content: 'Offer a data-backed, comprehensive answer to objections.',
					},
					{
						title: 'Supplemental Information',
						content:
							'Share a case study or white paper that addresses their objection.',
					},
				],
				callToAction: [
					{
						title: 'Logical Step',
						content:
							'As per the data, the next logical step would be X. Would you agree?',
					},
					{
						title: 'Analytical Summary',
						content: 'Considering the ROI we discussed, shall we proceed?',
					},
				],
			},
			following: [
				{
					title: 'Timely Followup',
					content: 'Always follow up promptly and as promised.',
				},
				{
					title: 'Meeting Minutes',
					content:
						'Share detailed minutes of the meeting, including data points discussed.',
				},
				{
					title: 'Checklist',
					content:
						'Include a list of next steps and ask for their input to ensure everything is aligned.',
				},
				{
					title: 'References',
					content:
						'Include additional data or research that supports what you discussed.',
				},
				{
					title: 'Clarifications',
					content:
						'Offer to clarify any points or data that were discussed during the meeting.',
				},
			],
		},
	};
}
