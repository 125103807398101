import styled from 'styled-components';

interface ChipProps {
	/**
	 * Amount of border radius
	 */
	borderRadius?: number;
	/**
	 * Color to wrap it's children in
	 */
	color: string;
	/**
	 * Text color to use
	 */
	textColor?: string;
}

/**
 * [Presentational]
 * Chip component that wraps its inline children with a background color
 */
export const Chip = styled.span<ChipProps>`
	${({ borderRadius = 4, color, textColor = 'inherit' }) => {
		return `
            padding: 4px 8px;
            border-radius: ${borderRadius}px;
            background-color: ${color};
            color: ${textColor}
        `;
	}}
`;
