import StepsWizard from '@copilot/common/pages/wizard/steps/steps';
import { useState } from 'react';
import { Button } from 'antd';
import { BlocklistEntryUpload } from './blocklistEntryUpload';
import { useCSVFileUpload } from './context/useCSVFileUpload';
import { useHistory } from 'react-router';

/**
 * A wizard for uploading blocklists
 * @constructor
 */
export function BlocklistUploadWizard() {
	const [current, setCurrent] = useState(0);
	const history = useHistory();
	const { onUpload, isUploading } = useCSVFileUpload();
	const steps = [
		{
			title: 'Blocklist options',
			content: <Button onClick={() => setCurrent(current + 1)}>First content</Button>,
		},
		{
			title: 'Upload / Manual entry',
			content: <BlocklistEntryUpload onUpload={onUpload} isUploading={isUploading} />,
		},
		{
			title: 'Mapping & Details',
			content: <Button onClick={() => setCurrent(current + 1)}>Third content</Button>,
		},
		{
			title: 'Import',
			content: 'Fourth content',
		},
	];

	return (
		<StepsWizard
			title="Add to Blocklist"
			current={current}
			steps={steps}
			onExitCallback={() => {
				history.goBack();
			}}
		/>
	);
}
