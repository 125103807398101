import { createContext } from 'react';
import { TeamsPageSideBarContextData } from './types';
import { DEFAULT_TEAMS_PAGE_SIDE_BAR_CONTEXT_DATA } from './constants';

/**
 * Context that allows pages to communicate with the side nav and vice versa without the need to utilize redux
 */
export const TeamsPageSideBarContext = createContext<TeamsPageSideBarContextData>(
	DEFAULT_TEAMS_PAGE_SIDE_BAR_CONTEXT_DATA
);
