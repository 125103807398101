// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qTjQnadMukAK_whjHVmd {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-between;\n  padding: 16px;\n  padding-bottom: 16px;\n  padding-right: 0;\n  max-height: 100%;\n  overflow: hidden;\n  padding-bottom: 0;\n}\n.qTjQnadMukAK_whjHVmd .copilot-list {\n  min-height: 0;\n  overflow-y: auto;\n  margin-right: 16px;\n}\n.lzoiFYuQZPi1R3YTBVPA {\n  min-height: 0;\n  overflow-y: auto;\n}\n.jyql9hi147WVISEoIWJp {\n  padding-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/contactDrawerV2/tabs/scheduleMessageTab/scheduleMessageTab.module.less","webpack://./../../common/components/drawer/contactDrawerV2/tabs/contactDrawerTabs.module.less"],"names":[],"mappings":"AAEA;ECDC,aAAA;EACA,sBAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,oBAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EDLA,iBAAA;AAOD;AATA;EAIE,aAAA;EACA,gBAAA;EACA,kBAAA;AAQF;AAJA;EACC,aAAA;EACA,gBAAA;AAMD;AAHA;ECJC,mBAAA;ADUD","sourcesContent":["@import '../contactDrawerTabs.module.less';\n\n.scheduleMessageWrapper {\n\t.tabContentWrapper();\n\tpadding-bottom: 0;\n\t:global(.copilot-list) {\n\t\tmin-height: 0;\n\t\toverflow-y: auto;\n\t\tmargin-right: 16px;\n\t}\n}\n\n.scheduleMessageListWrapper {\n\tmin-height: 0;\n\toverflow-y: auto;\n}\n\n.scheduleMessageFormWrapper {\n\t.tabFormWrapper();\n}\n",".tabContentWrapper() {\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100%;\n\tjustify-content: space-between;\n\tpadding: 16px;\n\tpadding-bottom: 16px;\n\tpadding-right: 0;\n\tmax-height: 100%;\n\toverflow: hidden;\n}\n\n.tabFormWrapper() {\n\tpadding-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scheduleMessageWrapper": "qTjQnadMukAK_whjHVmd",
	"scheduleMessageListWrapper": "lzoiFYuQZPi1R3YTBVPA",
	"scheduleMessageFormWrapper": "jyql9hi147WVISEoIWJp"
};
export default ___CSS_LOADER_EXPORT___;
