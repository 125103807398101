import { useSelector } from 'react-redux';
import { NotificationType } from '@copilot/common/store/models/const/enum';
import { NotificationsSelectors } from '@copilot/common/store/selectors/notifications';
import reduce from 'lodash/reduce';

/**
 * Sidebar notification keys
 */
export const SidebarNotifications = NotificationType;

/**
 * All available sidebar notifications in the app
 */
export type AvailableSidebarNotifications =
	typeof SidebarNotifications[keyof typeof SidebarNotifications];
type SidebarNotificationMap = Record<AvailableSidebarNotifications, number>;

const DEFAULT_NOTIFICATIONS: SidebarNotificationMap = Object.values(SidebarNotifications).reduce(
	(acc, key) => ({ ...acc, [key]: 0 }),
	{} as SidebarNotificationMap
);

/**
 * Get the sidebar notifications
 * @returns A map of the sidebar notifications
 */
export function useSidebarNotifications(): SidebarNotificationMap {
	const notifications = useSelector(NotificationsSelectors.getNotifications);
	return reduce(
		notifications,
		(acc: SidebarNotificationMap, notification) => {
			if (notification.id in SidebarNotifications) {
				return { ...acc, [notification.id]: parseInt(notification.body) };
			}
			return acc;
		},
		DEFAULT_NOTIFICATIONS
	);
}
