import { Button, Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Validators } from '../common/validators';

interface PasswordResetFormProps {
	userId: string;
	code: string;
	onSubmit: (userId: string, code: string, password: string) => Promise<void>;
	isSubmitting?: boolean;
}

const StyledInput = styled(Input.Password)`
	&&& {
		padding: 8px 12px;
	}
`;

const StyledFormItem = styled(FormItem)`
	&&& {
		min-height: 68px;
		margin-bottom: 0;
		text-align: left;
		margin-top: 4px;

		& .${(props) => props.theme['@ant-prefix']}-form-item-explain {
			font-size: 14px;
		}
	}
`;

/**
 * Form to reset password
 * @param {string} userId userId to reset password for
 * @param {string} code unique code to submit to reset password
 * @param {function} onSubmit callback on submit
 * @param {boolean} [isSubmitting] whether submission is in progress
 */
const PasswordResetForm: React.FC<PasswordResetFormProps> = (props) => {
	const { userId, code, onSubmit, isSubmitting = false } = props;
	const [form] = Form.useForm();

	const handleFinish = useCallback(
		(values: { password: string }) => {
			onSubmit(userId, code, values.password);
		},
		[userId, code]
	);

	const confirmPasswordMatch = (rule: any, value: string) => {
		if (value === form.getFieldValue('password')) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('Your passwords must match'));
	};

	return (
		<Form form={form} onFinish={handleFinish}>
			<StyledFormItem
				name="password"
				rules={[{ required: true, validator: Validators.validateSecurePassword }]}
				hasFeedback
			>
				<StyledInput type="password" placeholder="New Password" />
			</StyledFormItem>
			<StyledFormItem
				name="confirmationPassword"
				dependencies={['password']}
				hasFeedback
				rules={[{ required: true, validator: confirmPasswordMatch }]}
			>
				<StyledInput placeholder="Confirm your new Password" />
			</StyledFormItem>
			<StyledFormItem>
				<Button
					disabled={isSubmitting}
					loading={isSubmitting}
					type="primary"
					htmlType="submit"
					style={{ width: '100%', padding: '8px 12px', height: 'auto' }}
				>
					Set Password
				</Button>
			</StyledFormItem>
		</Form>
	);
};

export default PasswordResetForm;
