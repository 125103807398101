import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useImpersonation } from '@copilot/common/pages/adminHub/useImpersonation';
import { RootState } from '@copilot/common/store/reducers';
import { Alert, Button } from 'antd';
import { isNil } from 'lodash';

/**
 * Component shows to the user that they are impersonating
 * @constructor
 */
function ImpersonatorInformationPanel(): JSX.Element | null {
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const [_, handleExitImpersonation] = useImpersonation();
	const activeImpersonator = useSelector((state: RootState) => state.app.impersonator);
	if (isNil(activeImpersonator)) return null;

	return (
		<Alert
			type="info"
			message={
				<>
					You are {activeImpersonator} impersonating as {activeMember?.name}.{' '}
					<Button onClick={handleExitImpersonation}>Stop impersonating</Button>
				</>
			}
			banner
		/>
	);
}
export default ImpersonatorInformationPanel;
