export const INCLUDE_EXCLUDE_FILTER_TEST_IDS = {
	INLINE_BOX: 'inline-box',
	INCLUDED_TAG: 'included-tag',
	EXCLUDED_TAG: 'excluded-tag',
	INCLUDED_BUTTON_PREFIX: 'include-button-',
	EXCLUDED_BUTTON_PREFIX: 'exclude-button-',
	DESELECT_BUTTON_PREFIX: 'deselect-button-',
	FILTER_MENU: 'filter-menu',
	COUNT_TAG: 'count-tag',
	REMOVE_TAG: 'remove-tag',
	DROP_DOWN_TAG: 'open-dropdown',
	SAVE_BUTTON: 'save-button',
	CLEAR_ALL_BUTTON: 'clear-all-button',
	SEARCH_INPUT: 'search-input',
	INCLUDED_SECTION: 'included-section',
	UNSELECTED_SECTION: 'unselected-section',
};

export const IncludeExcludeFilterOptionStates = {
	INCLUDED: 'included',
	UNSELECTED: 'unselected',
	EXCLUDED: 'excluded',
} as const;
export type IncludeExcludeFilterOptionState = ValueOf<typeof IncludeExcludeFilterOptionStates>;

export const INCLUDED_SECTION_HEADER = 'Included';
export const EXCLUDED_SECTION_HEADER = 'Excluded';
export const UNSELECTED_SECTION_HEADER = 'Unselected';

export const NO_FILTER_RESULTS_FOR_SEARCH_TERM = 'No results found for search term';
export const NO_FILTER_RESULTS = 'No results found';
