import { JSX } from 'react';
import { Avatar, Dropdown, Space, theme as antdTheme, Typography } from 'antd';
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import styles from './header.module.less';

const { useToken } = antdTheme;

type UserDropdownPropsType = {
	/**
	 * The name of a user
	 */
	name: string;
	/**
	 * Callback for handling user logout
	 */
	handleLogout: () => void;
	/**
	 * Determines if the dropdown should be styled for a dark backdrop
	 */
	isDark?: boolean;
};

/**
 * Renders a user dropdown component with a username and avatar.
 */
export function UserDropdown(props: UserDropdownPropsType): JSX.Element {
	const { name, handleLogout, isDark } = props;
	const { token } = useToken();

	return (
		<Dropdown
			trigger={['click']}
			menu={{
				items: [
					{
						key: '1',
						label: 'Logout',
						icon: <LogoutOutlined />,
						onClick: handleLogout,
					},
				],
			}}
			overlayStyle={{ userSelect: 'none' }}
		>
			<Space
				className={isDark ? styles.userDropdownDark : styles.userDropdownLight}
				tabIndex={5}
			>
				<Avatar
					size="small"
					className={styles.avatar}
					icon={<UserOutlined className={styles.avatarIcon} />}
				/>
				<Typography.Text style={{ fontSize: token.fontSize }}>{name}</Typography.Text>
				<DownOutlined style={{ verticalAlign: 'middle' }} width={10} height={10} />
			</Space>
		</Dropdown>
	);
}
