import { Insights } from '../../services/types';
import { getInsightsLookup } from './copy';
import { PersonalizedInsightsData, TemplateCategory } from './types';

/**
 * Getter for the insights body copy
 * @param insights
 * @returns
 */
export function getInsightsCopy(insights: Insights): PersonalizedInsightsData {
	return getInsightsLookup()[insights.commStyle];
}

/**
 * Drop down options for the template section
 * @returns
 */
export function getTemplateOptions(): { value: TemplateCategory; label: string }[] {
	return [
		{ value: 'postConnection', label: 'Post-Connection Engagement' },
		{ value: 'nurturing', label: 'Nurturing the Prospect' },
		{ value: 'meetings', label: 'Meeting Request' },
		{ value: 'objections', label: 'Objection Responses' },
	];
}
