import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { UseNotesTabV2Data } from './types';
import { useSelector } from 'react-redux';
import { Note } from '@copilot/common/store/models/redux';
import { ActivityManager, ContactManager } from '@copilot/data';
import ContactDrawerV2Context from '../../../context/contactDrawerV2Context';
import { useContext } from 'react';
import isNil from 'lodash/isNil';
import notificationManager from '@copilot/common/utils/notificationManager';

/**
 * Hook for the managing notes tab in the contact drawer
 * @returns
 */
export default function useNotesTabV2(): UseNotesTabV2Data {
	const activeOrgMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const { orgContact, onUpdateOrgContact, campaignConnection } =
		useContext(ContactDrawerV2Context);

	const orgMemberId = campaignConnection?.orgMemberId;

	async function onDeleteNote(note: Note) {
		if (isNil(orgContact)) return;

		try {
			await ActivityManager.deleteNotesFromContact(orgContact.id, note);
			const updatedOrgContact = await ContactManager.updateContact({
				...orgContact,
				notes: orgContact.notes.filter((n) => n.id != note.id),
			});
			onUpdateOrgContact(updatedOrgContact);
		} catch (e) {
			notificationManager.showErrorNotification({
				message:
					'There was an issue deleting your note. Please try again later or contact support.',
			});
		}
	}

	async function onSubmitNote(note: string) {
		if (isNil(orgContact) || isNil(campaignConnection)) return;

		try {
			const response = await ActivityManager.submitNoteToContact(
				orgContact.id,
				campaignConnection.orgMemberId,
				note
			);

			const updatedOrgContact = await ContactManager.updateContact({
				...orgContact,
				notes: [...orgContact.notes, response],
			});
			onUpdateOrgContact(updatedOrgContact);
		} catch (e) {
			notificationManager.showErrorNotification({
				message:
					'There was an issue creating your note. Please try again later or contact support.',
			});
		}
	}

	return {
		notes: orgContact?.notes.filter((note) => note.orgMemberId === orgMemberId) ?? [],
		onDeleteNote,
		activeMemberId: activeOrgMember?.id,
		isOrgAdmin: activeOrgMember?.isOrgAdmin ?? false,
		onSubmitNote,
	};
}
