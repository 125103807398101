import { InboxV2ListHandlerProps, InboxV2ListProps } from './types';
import InboxV2Card from '@copilot/common/pages/inboxV2/components/card/inboxV2Card';
import { InboxV2CardType } from '@copilot/common/pages/inboxV2/components/card/types';
import InboxV2List from '@copilot/common/pages/inboxV2/components/listing/inboxV2List';
import { InboxMessage } from '@copilot/common/pages/inbox/ui/types';
import useInboxV2 from '../../useInboxV2';

type InboxV2CardListProps = InboxV2ListProps & {
	cardType: InboxV2CardType;
};

//TODO implement me https://cassia.atlassian.net/browse/COPILOT-7753
/**
 * Lists the messages in the inbox in a card format, granting more inline actions
 */
export default function InboxV2CardList({
	onViewConversation: onClick,
	onRemove,
	...props
}: InboxV2CardListProps & InboxV2ListHandlerProps) {
	const { setReminder, setReminderClick, cancelReminder, onSubmitInboxMessage } = useInboxV2();
	const { cardType } = props;
	return (
		<InboxV2List
			render={(item: InboxMessage, idx: number) => (
				<InboxV2Card
					cardType={cardType}
					message={item}
					onViewConversation={() => onClick(item, idx)}
					setReminder={setReminder}
					setReminderClick={setReminderClick}
					cancelReminder={cancelReminder}
					onRemove={() => onRemove([item])}
					onSubmitInboxMessage={onSubmitInboxMessage}
					reminder={item.reminder}
				/>
			)}
			{...props}
		/>
	);
}
