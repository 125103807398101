import React, { MouseEvent } from 'react';
import {
	Badge,
	Table,
} from 'antd';
import { withErrorHandling, withLoading } from '@copilot/common/components/errorAndLoading/errorAndLoading';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import { blue, grey } from '@ant-design/colors';
import { ICampaignStatus, ICampaignType } from '@copilot/data/graphql/_generated';
import { Link } from 'react-router-dom';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { CampaignStatsType, getCampaignStatsColor } from '@copilot/common/utils/campaign';

export type AllCampaignsTableProps = {
	data: CampaignSummaryModel[]
	showActiveMembers?: boolean
	isLoading: boolean
	isError: boolean
};

export type CampaignSummaryModel = {
	id: string
	name: string
	type: ICampaignType
	status: ICampaignStatus
	numActiveMembers?: number
	stats: CampaignStats
	members: {
		id: string,
		orgMemberId: string
	}[]
};

type CampaignStats = {
	numUserReplies: number
	numUsersConnected: number
	numInvitesSent: number
	percentConnected: number | undefined
	percentReplied: number | undefined
};

const CampaignColorWhitelist = [
	ICampaignType.Prospecting,
];

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const TableWithLoader = withErrorHandling(withLoading(Table<CampaignSummaryModel>));

/**
 * Renderer for campaign percentage statistics
 * @param value 
 * @param hasWarning 
 * @returns 
 */
const RateRenderer = (value: number | undefined, statsType: CampaignStatsType, campaignType: ICampaignType) => {
	const isNA = value === undefined;
	
	return (
		<span style={{ 
			padding: '4px 8px', 
			borderRadius: '4px', 
			backgroundColor: `${(isNA || !CampaignColorWhitelist.includes(campaignType)) ? 'inherit' : getCampaignStatsColor(value, statsType)}`, 
			color: isNA ? 'rgba(0,0,0,0.25)' : 'inherit', 
		}}>
			{isNA ? 'N/A' : UtilityFunctions.convertDecimalToPercentage(value / 100)}
		</span>
	);
};

/**
 * Create table's Column Definition
 * @param title column title
 * @param dataIndex property name or path to the data in CampaignSummaryModel
 * @param fnRender renderer for rendering the content in the table cell
 * @returns 
 */
const getColumnDef = (
	title: string,
	dataIndex: string | string[],
	sortFn?: (a: CampaignSummaryModel, b: CampaignSummaryModel) => number,
	fnRender?: (value: any, record: CampaignSummaryModel, index: number) => React.ReactNode
) => {
	return {
		title,
		dataIndex,
		showSorterTooltip: false,
		sortDirections: sortFn ? ['ascend', 'descend'] as SortOrder[] : undefined,
		sorter: sortFn,
		render: fnRender,
	};
};

/**
 * Renderer for whether or not campaign is active
 * @param isActive 
 */
const StatusRenderer = (isActive: boolean) => {
	return (
		<>
			<Badge dot color={isActive ? blue[4] : grey[0]}/> 
			<span>{isActive ? 'Active' : 'Inactive'}</span>
		</>
	);
};
/**
 * Renderer for type of campaign
 * @param type 
 */
const TypeRenderer = (type: string) => {
	return (<span>{type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}</span>);
};

const CreateLinkToTeamMembersTab = (model: CampaignSummaryModel) => `/campaign/${model.id}?tab=teammembers`;
const MemberRenderer = (value: any, record: CampaignSummaryModel) => (
	<Link to={CreateLinkToTeamMembersTab(record)} onClick={(ev) => ev.stopPropagation()}>
		{record.numActiveMembers}
	</Link>
);

/**
 * Object describing mouse actions for rows in campaign table
 * @param campaign 
 * @returns 
 */
const RowMouseInteractions = (campaign: CampaignSummaryModel) => {
	const campaignUrlToOpen = `/campaign/${campaign.id}`;

	return {
		onClick: (event: MouseEvent) => {
			if (event.ctrlKey || event.metaKey) {
				window.open(campaignUrlToOpen);
			} else {
				window.location.assign(campaignUrlToOpen);
			}
		},
	};
};

/**
 * Campaign table
 * @param props 
 * @returns 
 */
const AllCampaignsTable: React.FC<AllCampaignsTableProps> = (props) => {
	const { data, showActiveMembers, isLoading, isError } = props;
	const NumActiveMembers = 'numActiveMembers';

	const columns: ColumnsType<CampaignSummaryModel> = [
		getColumnDef(
			'Campaign name', 
			'name',
			(a, b) => a.name.localeCompare(b.name)
		),
		getColumnDef(
			'Type', 
			'type',
			undefined,
			(value: string) => TypeRenderer(value)
		),
	];
	if (showActiveMembers) {
		columns.push(getColumnDef(
			'Active members', 
			NumActiveMembers,
			undefined,
			(value: any, record: CampaignSummaryModel) => MemberRenderer(value, record)
		));
	}
	columns.push(
		getColumnDef(
			'Total reach', 
			['stats', 'numUsersConnected'], 
			(a, b) => a.stats.numUsersConnected - b.stats.numUsersConnected
		),
		getColumnDef(
			'Connection rate', 
			['stats', 'percentConnected'], 
			(a, b) => (a.stats.percentConnected ?? 0) - (b.stats.percentConnected ?? 0),
			(value: number | undefined, record: CampaignSummaryModel) => RateRenderer(value, CampaignStatsType.ConnectionRate, record.type)
		),
		getColumnDef(
			'Reply rate', 
			['stats', 'percentReplied'], 
			(a, b) => (a.stats.percentReplied ?? 0) - (b.stats.percentReplied ?? 0),
			(value: number | undefined, record: CampaignSummaryModel) => RateRenderer(value, CampaignStatsType.ReplyRate, record.type)
		),
		getColumnDef(
			'Status', 
			'status', 
			undefined, 
			(value: string) => StatusRenderer(value === ICampaignStatus.Enabled)
		)
	);

	return (
		<TableWithLoader
			dataSource={ data }
			isLoading={isLoading}
			error={isError}
			columns={columns}
			pagination={{
            	showSizeChanger: true,
			}}
			rowClassName="clickable"
			rowKey={campaign => campaign.id}
			onRow={campaign => RowMouseInteractions(campaign)}
		>
		</TableWithLoader>
	);
};

export default AllCampaignsTable;