import { spacingLG, spacingXS } from '@copilot/common/constant/commonStyles';
import { Space, Typography } from 'antd';
import styled from 'styled-components';

/**
 * Legend Dot component
 */
const Dot = styled.span<{ color: string }>`
	& {
		background-color: ${(props) => props.color};
		border-radius: 20px;
		display: inline-block;
		height: 10px;
		margin-right: 3px;
		width: 10px;
	}
`;

/**
 * Legend item definition
 */
type DotLegendItem = Readonly<{
	/**
	 * Color for the legend dot
	 */
	color: string;
	/**
	 * Legend text
	 */
	text: string;
}>;

type DotLegendProps = Readonly<{
	/**
	 * Legend items to render
	 */
	items: DotLegendItem[];
}>;

/**
 * Legend that renders with a preceding colored dot
 */
const DotLegend = ({ items }: DotLegendProps): JSX.Element => {
	return (
		<Space size={30} style={{ paddingBottom: spacingLG, paddingTop: spacingXS }}>
			{items.map(({ color, text }) => {
				return (
					<Typography.Text>
						<Dot color={color} /> {text}
					</Typography.Text>
				);
			})}
		</Space>
	);
};

type DefaultAboveBelowAverageLegendProps = Readonly<{
	/**
	 * Color for above average
	 */
	aboveAverageColor: string;
	/**
	 * Color for below average
	 */
	belowAverageColor: string;
	/**
	 * Color for average range
	 */
	averageColor: string;
}>;
/**
 * Default above and below average legend for organization summary v2
 */
export const DefaultAboveBelowAverageLegend = ({
	aboveAverageColor,
	belowAverageColor,
	averageColor,
}: DefaultAboveBelowAverageLegendProps) => {
	const items: DotLegendItem[] = [
		{ color: aboveAverageColor, text: 'Above average' },
		{ color: averageColor, text: 'Average' },
		{ color: belowAverageColor, text: 'Below average' },
	];
	return <DotLegend items={items} />;
};

export default DotLegend;
