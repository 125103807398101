// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YzkC6asteYJfdOUp4FDE {\n  display: flex;\n  flex-direction: column;\n  row-gap: 16px;\n}\n.YzkC6asteYJfdOUp4FDE label {\n  width: 240px;\n  flex-shrink: 0;\n}\n.ojkEVImyG1vxe9FOIuGc {\n  margin-top: 6px;\n  color: #8c8c8c;\n  font-size: 12px;\n  max-width: 640px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/settings/user/userSettings.module.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,sBAAA;EACA,aAAA;AADD;AAFA;EAKE,YAAA;EACA,cAAA;AAAF;AAIA;EACC,eAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAFD","sourcesContent":["@import '../../../../styles/common-variables.less';\n\n.userSettingsWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\trow-gap: 16px;\n\tlabel {\n\t\twidth: 240px;\n\t\tflex-shrink: 0;\n\t}\n}\n\n.statusDescription {\n\tmargin-top: 6px;\n\tcolor: @gray7;\n\tfont-size: 12px;\n\tmax-width: 640px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userSettingsWrapper": "YzkC6asteYJfdOUp4FDE",
	"statusDescription": "ojkEVImyG1vxe9FOIuGc"
};
export default ___CSS_LOADER_EXPORT___;
