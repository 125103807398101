import { Config } from '@copilot/common/config';
import React from 'react';

interface MouseflowProps {
	appID: string;
}

declare global {
	interface Window {
		_mfq: any;
		[key: string]: any;
	}
}

export const MouseflowUpdateRoute = (pageName: string, pageRoute?: string) => {
	window._mfq = window._mfq || [];
	window._mfq.push(!pageRoute ? [pageName] : [pageName, pageRoute]);
};

// Migrate to data initializer?
const Mouseflow: React.FC<MouseflowProps> = (props) => {
	const { appID } = props;
	React.useEffect(() => {
		const canUseDOM = !!(
			typeof window !== 'undefined' &&
			window.document &&
			window.document.createElement
		);
		if (
			Config.isMouseflowDisabled ||
			!appID ||
			!canUseDOM ||
			window.document.getElementById('mouseflow')
		)
			return;

		(function () {
			const mf = document.createElement('script');
			mf.type = 'text/javascript';
			mf.async = true;
			mf.src = `//cdn.mouseflow.com/projects/${props.appID}.js`;
			document.getElementsByTagName('head')[0].appendChild(mf);
		})();
	}, []);
	return null;
};

export default Mouseflow;
