import OrganizationTagsTable from '@copilot/common/components/tables/tableTypes/tag';
import { TagManager } from '@copilot/data';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	createTagAction,
	deleteOrgTagAction,
	loadOrgTagsAction,
	updateTagAction,
	upsertOrgTagAction,
} from './data/saga';
import { getAllOrgTags } from './data/selectors';
import NotificationManager from '@copilot/common/utils/notificationManager';
import { Tag } from './data/models';
import modalManager from '@copilot/common/utils/modalManager';
import { validateNewName } from '@copilot/common/utils';

interface OrganizationDashboardAllTagsProps {
	organizationId: string;
}

export const OrganizationDashboardAllTags: FC<OrganizationDashboardAllTagsProps> = (props) => {
	const orgId = props.organizationId;

	const dispatch = useDispatch();

	const orgTags = useSelector(getAllOrgTags);

	// TODO: [COPILOT-5175] Implement more robust solution to handle this edge case
	useEffect(() => {
		// Load only custom tags for tag management page
		dispatch(loadOrgTagsAction(TagManager.getTags, orgId, true));

		// On unmount, reload all tags for use in other pages
		return () => {
			dispatch(loadOrgTagsAction(TagManager.getTags, orgId, false));
		};
	}, []);

	const handleDeleteTag = async (tag: Tag) => {
		try {
			// attempt to delete tag
			const response = await TagManager.deleteTag(orgId, tag.id);

			// show toast corresponding to result
			if (response?.status === 204) {
				NotificationManager.showInfoNotification({
					message: `${tag.name} has been deleted.`,
				});
			} else {
				throw new Error(`Tag ${tag.name} deletion did not occur as expected`);
			}
		} catch (err) {
			NotificationManager.showErrorNotification({
				message: `Unable to delete "${tag.name}". Please try again later.`,
			});
			throw err;
		}
	};

	/**
	 * Create a tag in both the backend and the store
	 * @param {Tag} newTag
	 */
	const sendCreateTag = (newTag: Tag) => {
		dispatch(upsertOrgTagAction(createTagAction, orgId, newTag));
	};

	/**
	 * Update a tag in both the backend and the store
	 * @param {Tag} newTag
	 */
	const sendUpdateTag = (newTag: Tag) => {
		dispatch(upsertOrgTagAction(updateTagAction, orgId, newTag));
	};

	/**
	 * Delete a tag in both the backend and the store
	 * @param {Tag} tag
	 */
	const sendDeleteTag = (tag: Tag) => {
		dispatch(deleteOrgTagAction(handleDeleteTag, tag.id, tag));
	};

	const handleOpenTagsModal = async (onContinue: (tag: Tag) => void, oldTag?: Tag) => {
		const validator = await getTagNameValidator(orgId);

		modalManager.openTagsModal({
			oldTag,
			nameEditorValidator: validator,
			onContinue: onContinue,
		});
	};

	const tagNameValidator = (tags: readonly { name: string }[]) => (nameToValidate: string) =>
		validateNewName(
			nameToValidate,
			tags.map((tag) => tag.name),
			50
		);

	const getTagNameValidator = async (orgIdInput: string) => {
		const allTags: { name: string }[] = await TagManager.getTags(orgIdInput, false);
		return tagNameValidator(allTags);
	};

	const handleUpdateTag = async (oldTag: Tag) => {
		await handleOpenTagsModal(sendUpdateTag, oldTag);
	};

	const handleCreateTag = async () => {
		await handleOpenTagsModal(sendCreateTag);
	};

	return (
		<OrganizationTagsTable
			loading={orgTags.loading}
			tags={orgTags.data}
			onCreateTag={handleCreateTag}
			onUpdateTag={handleUpdateTag}
			onDeleteTag={sendDeleteTag}
		/>
	);
};
