import { DateRangeDropdownOption } from './types';

/**
 * Dropdown option representing last 7 days
 */
export const LAST_7_DAYS_OPTION: DateRangeDropdownOption = {
	key: 'lastSevenDays',
	label: 'Last 7 days',
	value: 7,
} as const;

/**
 * Dropdown option representing last 14 days
 */
export const LAST_14_DAYS_OPTION: DateRangeDropdownOption = {
	key: 'lastFourteenDays',
	label: 'Last 14 days',
	value: 14,
} as const;

/**
 * Dropdown option representing last 30 days
 */
export const LAST_30_DAYS_OPTION: DateRangeDropdownOption = {
	key: 'lastThirtyDays',
	label: 'Last 30 days',
	value: 30,
} as const;

/**
 * Default label for property to render in select
 */
export const ACTIVE_LABEL = 'activeLabel';
