// Batch action copy
export const BATCH_ACTION_TITLE = 'Batch actions:';
export const APPLY_TAGS_BATCH = 'Apply tags';
export const MARK_AS_READ_BATCH = 'Mark as read';
export const MARK_AS_UNREAD_BATCH = 'Mark as unread';
export const DELETE_BATCH = 'Delete';
export const ADD_TO_CAMPAIGN_BATCH = 'Move to nurture campaign';
export const SELECTED_ITEMS_COUNT = 'Selected:';

// Pagination copy
export const LOAD_MORE_BUTTON = 'Load More';

// Checkbox test ids
export const SELECT_ALL_CHECKBOX_TEST_ID = 'select-all-checkbox';
export const SELECT_CHECKBOX_TEST_ID = 'select-checkbox';

// Load more button test id
export const LOAD_MORE_BUTTON_TEST_ID = 'load-more';
