import { List } from 'antd';
import styles from './notesTab.module.less';
import NoteItem from '@copilot/common/components/componentModels/activities/notes/item';
import { checkHasAccess } from '@copilot/common/utils/access';
import SubmitForm from '@copilot/common/components/componentModels/activities/submitForm';
import { UseNotesTabV2Data } from './hooks/types';
import isNil from 'lodash/isNil';

type NotesTabProps = UseNotesTabV2Data;

/**
 * Renders the notes tab for the contact drawer, including the list of notes and the submit form
 * @param param0
 * @returns
 */
export default function NotesTabComponent({
	notes,
	onDeleteNote,
	activeMemberId,
	isOrgAdmin,
	onSubmitNote,
}: NotesTabProps) {
	return (
		<div className={styles.notesWrapper}>
			<div className={styles.notesListWrapper}>
				<List
					dataSource={notes}
					itemLayout="vertical"
					className="notes-list"
					renderItem={(item) => (
						<NoteItem
							key={item.id}
							note={item}
							onRemoveNote={onDeleteNote}
							isDeletable={
								!isNil(activeMemberId) &&
								checkHasAccess(activeMemberId, item.metaData.AddedBy, isOrgAdmin)
							}
						/>
					)}
				/>
			</div>
			<div className={styles.submitFormWrapper}>
				<SubmitForm submitButtonText="Save" onSubmit={onSubmitNote} />
			</div>
		</div>
	);
}
