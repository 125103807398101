import {
	ContactResponse,
	ConversationResponse,
	MemberLinkedInProfileResponse,
} from '@copilot/data/responses/interface';
import ContactDrawerV2Context from './context/contactDrawerV2Context';
import { DEFAULT_CONTACT_DRAWER_CONTEXT } from './context/constants';
import useContactDrawerV2Data from './useContactDrawerV2Data';
import ContactDrawerV2 from './contactDrawerV2';

type ContactDrawerV2SmartProps = {
	contactId?: string;
	isOpen?: boolean;
	onClose: () => void;
	isLoading: boolean;
	conversation?: ConversationResponse;
	targetProfile?: MemberLinkedInProfileResponse;
	orgMemberId?: string;
	orgContact?: ContactResponse;
	onGoToThread(idx: number): void;
	currConversationIdx: number;
	totalConversations: number;
	onMeetingBookedUpdated?: (connectionId: string, isBooked: boolean) => void;
};

/**
 * Smart component for the ContactDrawerV2 that manages the data fetching and context to avoid prop drilling
 * @param param0
 * @returns
 */
export default function ContactDrawerV2Smart({
	conversation,
	targetProfile,
	orgMemberId,
	contactId = '',
	isLoading,
	orgContact,
	onMeetingBookedUpdated,
	...rest
}: ContactDrawerV2SmartProps) {
	const { isRefetching, ...data } = useContactDrawerV2Data({
		conversation,
		orgMemberId,
		targetProfile,
		contactId,
		orgContact,
		onMeetingBookedUpdated,
	});

	return (
		<ContactDrawerV2Context.Provider
			value={{
				...DEFAULT_CONTACT_DRAWER_CONTEXT,
				...data,
				isLoading: isLoading || isRefetching,
			}}
		>
			<ContactDrawerV2 {...rest} />
		</ContactDrawerV2Context.Provider>
	);
}
