import { CommunicationStyle, Insights } from '../../services/types';

/**
 * Type representing the header content contained in the lookup
 */
type HeaderContent = {
	commTypeTitle: string;
	commTypeSubtitle: string;
};

/**
 * Get the header content based on communication style
 * @param insights
 * @returns
 */
export function getHeaderContent(insights: Insights): HeaderContent {
	return getHeaderLookup()[insights.commStyle];
}

/**
 * Get the lookup for the header content
 * @returns
 */
function getHeaderLookup(): { [key in CommunicationStyle]: HeaderContent } {
	return {
		director: {
			commTypeTitle: 'Director',
			commTypeSubtitle:
				'A Director is goal-oriented and decisive, valuing efficiency and results above all else.',
		},
		relator: {
			commTypeTitle: 'Relator',
			commTypeSubtitle:
				'A Relator places high value on relationships and trust, seeking long-term connections and deep conversations.',
		},
		socializer: {
			commTypeTitle: 'Socializer',
			commTypeSubtitle:
				'A Socializer is outgoing and enthusiastic, thriving on interactions and often engaging in social dialogue before getting down to business.',
		},
		thinker: {
			commTypeTitle: 'Thinker',
			commTypeSubtitle:
				'A Thinker is analytical and detail-oriented, preferring data-driven conversations and logical reasoning.',
		},
	};
}
