// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uOiRZkk3l369M0iozDcz * {\n  -webkit-print-color-adjust: exact;\n  /* Chrome, Safari 6 – 15.3, Edge */\n  color-adjust: exact;\n  /* Firefox 48 – 96 */\n  print-color-adjust: exact;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/personalizedInsightsV2/personalizedInsights.module.less"],"names":[],"mappings":"AACA;EACC,iCAAA;EAAC,kCAAkC;EACnC,mBAAA;EACC,oBAAoB;EAArB,yBAAA;AAED","sourcesContent":["//below is necessary to print with graphics in color\n.insightsWrapper * {\n\t-webkit-print-color-adjust: exact; /* Chrome, Safari 6 – 15.3, Edge */\n\tcolor-adjust: exact; /* Firefox 48 – 96 */\n\tprint-color-adjust: exact;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"insightsWrapper": "uOiRZkk3l369M0iozDcz"
};
export default ___CSS_LOADER_EXPORT___;
