import {
	LinkedinFilled,
	SyncOutlined,
	CloseSquareOutlined,
	CheckSquareOutlined,
} from '@ant-design/icons';
import styles from './header.module.less';
import { Popover, Tag } from 'antd';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import isNil from 'lodash/isNil';

type LinkedInStatusIndicatorProps = { currentMember: IOrganizationMember | null };

/**
 * Indicator for the status of the LinkedIn connection (either connected, syncing, or disconnected)
 * @param param0
 * @returns
 */
export function LinkedInStatusIndicator({ currentMember }: LinkedInStatusIndicatorProps) {
	const isMemberLoading = isNil(currentMember);

	function getPopOverTitle(member: IOrganizationMember) {
		const { isLiSessionInRetryState, isLinkedInLoggedIn } = member;
		if (isLinkedInLoggedIn) {
			return 'Connected';
		} else if (isLinkedInLoggedIn === false && isLiSessionInRetryState) {
			return 'Syncing in-progress';
		} else {
			return 'Disconnected';
		}
	}

	function getPopOverContent(member: IOrganizationMember) {
		const { isLiSessionInRetryState, isLinkedInLoggedIn } = member;
		if (isLinkedInLoggedIn) {
			return 'Your LinkedIn account is connected and ready to use.';
		} else if (isLinkedInLoggedIn === false && isLiSessionInRetryState) {
			return 'We are attempting to sync your LinkedIn account. Outgoing messages will be paused and uploading search lists will be temporarily unavailable until the connection is established.';
		} else {
			return 'To reconnect your LinkedIn account and resume running automations and actions on our platform, navigate to Settings > LinkedIn Settings.';
		}
	}

	function getStatusTag(member: IOrganizationMember) {
		const { isLiSessionInRetryState, isLinkedInLoggedIn } = member;
		if (isLinkedInLoggedIn) {
			return (
				<Tag icon={<CheckSquareOutlined />} color="success">
					Connected
				</Tag>
			);
		} else if (isLinkedInLoggedIn === false && isLiSessionInRetryState) {
			return (
				<Tag icon={<SyncOutlined />} color="processing">
					Syncing
				</Tag>
			);
		} else {
			return (
				<Tag icon={<CloseSquareOutlined />} color="error">
					Disconnected
				</Tag>
			);
		}
	}

	if (
		isMemberLoading ||
		isNil(currentMember.isLiSessionInRetryState) ||
		isNil(currentMember.isLinkedInLoggedIn)
	) {
		return <></>;
	}

	return (
		<div className={styles.linkedInStatusIndicator}>
			<LinkedinFilled className={styles.headerIcon} />:
			<Popover
				placement="bottomLeft"
				title={getPopOverTitle(currentMember)}
				content={
					<div className={styles.linkedInStatusPopOverContent}>
						{getPopOverContent(currentMember)}
					</div>
				}
			>
				{getStatusTag(currentMember)}
			</Popover>
		</div>
	);
}
