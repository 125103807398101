import { FC } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { AppSelectors } from '@copilot/common/store/selectors';
import { useIntercom } from 'react-use-intercom';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { useEffectAsync } from '@copilot/common/hooks/common';
import { OrganizationMemberManager, StatsManager } from '@copilot/data';
import {
	LinkedInCalculatedStats,
	StatsModel,
} from '@copilot/common/components/componentModels/stat';
import { OrganizationType, OrgRoleTitles } from '../../../store/models/const/enum';
import { UtilityFunctions } from '../../../utils/common';

type IdentityAttributes = {
	name: string;
	email: string;
	userId: string;
	userHash: string;
};

type CustomAttributesType = {
	User_Type: string;
	Organization_Role: string;
	Reply_Invitation_Rate: number;
	Interested_Reply_Rate: number;
	Connection_Reply_Rate: number;
	Connection_Rate: number;
	CS_Owner: string;
	Is_FreeTrial: boolean;
} & IdentityAttributes;

/**
 * Get the user type label we use for Intercom
 * @param organizationType The type of organization we're looking at
 * @returns The user type label we want to post to Intercom
 */
const getUserTypeLabel = (organizationType: OrganizationType) => {
	switch (organizationType) {
		case OrganizationType.Enterprise:
			return 'Enterprise';
		case OrganizationType.Individual:
			return 'Individual';
		default:
			return UtilityFunctions.assertUnreachable(organizationType);
	}
};

/**
 * Get the label for the role for an org member
 * @param orgMember The org member we're evaluating
 * @returns The role label we want to post to Intercom
 */
const getOrganizationRoleLabel = (role: OrgRoleTitles) => {
	switch (role) {
		case OrgRoleTitles.Owner:
			return 'Owner';
		case OrgRoleTitles.Admin:
			return 'Admin';
		case OrgRoleTitles.Advanced:
			return 'Advanced';
		case OrgRoleTitles.User:
			return 'Base';
		default:
			return UtilityFunctions.assertUnreachable(role);
	}
};

const Intercom: FC = () => {
	const { update } = useIntercom();
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const userHash = useSelector(AppSelectors.getUserHash);

	useEffectAsync(async () => {
		const identityAttributes: Partial<IdentityAttributes> = {
			name: activeMember?.name,
			email: activeMember?.email,
			userId: activeMember?.userId,
			userHash,
		};

		const customAttributes: Partial<CustomAttributesType> = { ...identityAttributes };

		if (activeMember && activeMember.orgRoles.length > 0) {
			customAttributes.Organization_Role = getOrganizationRoleLabel(
				activeMember.orgRoles[0] as OrgRoleTitles
			); // orgRole property for both IOrganizationMember and OrganizationMemberResponse is typed as string but is associated with OrgRoleTitles
		}

		if (activeOrganization?.id) {
			const today = new Date();
			today.setHours(23, 59, 59, 999);
			const startDate = new Date(today);
			startDate.setDate(today.getDate() - 7);
			const stats = await StatsManager.getStatsForOrganization(
				activeOrganization.id,
				startDate,
				today
			);
			const { calculatedRatios } = StatsModel.fromResponse(stats);
			customAttributes.Reply_Invitation_Rate = Number.isNaN(
				calculatedRatios[LinkedInCalculatedStats.ReplyOverInvites]
			)
				? 0
				: calculatedRatios[LinkedInCalculatedStats.ReplyOverInvites];
			customAttributes.Interested_Reply_Rate = Number.isNaN(
				calculatedRatios[LinkedInCalculatedStats.InterestedOverReply]
			)
				? 0
				: calculatedRatios[LinkedInCalculatedStats.InterestedOverReply];
			customAttributes.Connection_Reply_Rate = Number.isNaN(
				calculatedRatios[LinkedInCalculatedStats.ReplyOverConnections]
			)
				? 0
				: calculatedRatios[LinkedInCalculatedStats.ReplyOverConnections];
			customAttributes.Connection_Rate = Number.isNaN(
				calculatedRatios[LinkedInCalculatedStats.ConnectionsOverInvites]
			)
				? 0
				: calculatedRatios[LinkedInCalculatedStats.ConnectionsOverInvites];

			try {
				// This returns a 404 when a user does not have a cs owner. Allow this to continue even on failure of this request
				// [TODO] Investigate pushing data from the BE instead of the FE - https://cassia.atlassian.net/browse/COPILOT-2850?atlOrigin=eyJpIjoiOTI3ZjdjOWVlODU4NDNiODgxOTY1OWYyM2M1OWVhNjQiLCJwIjoiaiJ9
				const { firstName, lastName } = await OrganizationMemberManager.getCSOwnerInfo(
					activeMember?.id
				);
				customAttributes.CS_Owner = `${firstName} ${lastName}`;
			} catch {
				customAttributes.CS_Owner = 'None';
			}

			customAttributes.User_Type = getUserTypeLabel(activeOrganization.orgType);
			customAttributes.Is_FreeTrial = activeOrganization.isFreeTrial === true;
		}
		update({
			...identityAttributes,
			customAttributes,
		});
	}, [
		activeMember?.name,
		activeMember?.email,
		userHash,
		activeMember?.userId,
		activeOrganization?.id,
	]);
	return null;
};

export default Intercom;
