// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RaYwAjPFLNHwDgpQMn0o {\n  max-width: 667px;\n  margin-left: auto;\n  margin-right: auto;\n}\n.RaYwAjPFLNHwDgpQMn0o .BnT9eOfK1kaQSD79Q8b4 {\n  max-width: 80%;\n  margin-bottom: 24px;\n}\n.RaYwAjPFLNHwDgpQMn0o .aGbcoDlnUnbgp1pKZ1Kj {\n  font-size: 3.5rem;\n  color: #1677ff;\n}\n.RaYwAjPFLNHwDgpQMn0o .XqLCYG5rzSXGjKRfi7au {\n  font-weight: normal;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/organizationDashboard/blocklist/blocklistEntryUpload.module.less"],"names":[],"mappings":"AAEA;EACC,gBAAA;EACA,iBAAA;EACA,kBAAA;AADD;AAFA;EAME,cAAA;EACA,mBAAA;AADF;AANA;EAWE,iBAAA;EACA,cAAA;AAFF;AAVA;EAgBE,mBAAA;AAHF","sourcesContent":["@dropZoneColor: #1677ff;\n\n.blocklistEntryUploadContainer {\n\tmax-width: 667px;\n\tmargin-left: auto;\n\tmargin-right: auto;\n\n\t.blocklistEntryUploadTitle {\n\t\tmax-width: 80%;\n\t\tmargin-bottom: 24px;\n\t}\n\n\t.dropzoneIcon {\n\t\tfont-size: 3.5rem;\n\t\tcolor: @dropZoneColor;\n\t}\n\n\t.dropzoneMainText {\n\t\tfont-weight: normal;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blocklistEntryUploadContainer": "RaYwAjPFLNHwDgpQMn0o",
	"blocklistEntryUploadTitle": "BnT9eOfK1kaQSD79Q8b4",
	"dropzoneIcon": "aGbcoDlnUnbgp1pKZ1Kj",
	"dropzoneMainText": "XqLCYG5rzSXGjKRfi7au"
};
export default ___CSS_LOADER_EXPORT___;
