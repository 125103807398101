import { ContactDrawerContextData } from './types';

export const DEFAULT_CONTACT_DRAWER_CONTEXT: ContactDrawerContextData = {
	//LOADING STATE
	isLoading: true,
	isRefetching: false,
	//HANDLERS
	onChangeSelectedOrgMember: () => {},
	onUpdateCurrentConversation: () => {},
	onUpdateOrgContact: () => {},
	//CONTACT DATA (non-nullable)
	contactData: {
		loading: true,
		campaignConnections: undefined,
		contact: undefined,
		isContactTitleUpdating: false,
		onContactTitleUpdate: undefined,
		isContactCompanyUpdating: false,
		onContactCompanyUpdate: undefined,
		isContactEmailUpdating: false,
		onContactEmailUpdate: undefined,
		isContactPhoneNumberUpdating: false,
		onContactPhoneNumberUpdate: undefined,
	},
	sourceName: '',
};
