import { CSSProperties } from 'react';
import { UserOutlined, BulbTwoTone } from '@ant-design/icons';

/**
 * [Presentational]
 * Custom icon combining a user icon with a lit light bulb
 * @returns
 */
export const UserBulbOutlined = () => {
	const commonStyles: CSSProperties = {
		position: 'absolute',
		fontSize: '20px',
		color: '#0062D9',
	};

	return (
		<span style={{ position: 'relative', transform: 'translate(-10px, -10px)' }}>
			<UserOutlined style={{ fontSize: '50px', color: '#0062D9' }} />
			<BulbTwoTone
				twoToneColor={commonStyles.color}
				style={{
					fontSize: '30px',
					position: 'absolute',
					top: '26px',
					left: '28px',
					borderRadius: '100%',
				}}
			/>
			<span style={{ ...commonStyles, transform: 'rotate(88deg)', left: '40px', top: '5px' }}>
				-
			</span>
			<span
				style={{ ...commonStyles, transform: 'rotate(-65deg)', left: '45px', top: '6px' }}
			>
				-
			</span>
			<span
				style={{ ...commonStyles, transform: 'rotate(-35deg)', left: '51px', top: '10px' }}
			>
				-
			</span>
			<span
				style={{ ...commonStyles, transform: 'rotate(-5deg)', left: '54px', top: '17px' }}
			>
				-
			</span>
		</span>
	);
};
