import { Button, StepProps, Steps, Typography } from 'antd';
import styles from './steps.module.less';
import { ReactNode } from 'react';

const { Text } = Typography;

type StepWithContent = { content: ReactNode } & StepProps;

type StepsWizardProps = Readonly<{
	/**
	 * The title of the wizard
	 */
	title: string;
	/**
	 * The current step index
	 */
	current: number;
	/**
	 * The steps to display
	 */
	steps: StepWithContent[];
	/**
	 * Callback to call when the exit button is clicked
	 */
	onExitCallback: () => void;
}>;

function StepsHeader({ title, onExit }: { title: string; onExit: () => void }) {
	return (
		<div className={styles.stepsHeaderSection}>
			<Button ghost className={styles.exitButton} onClick={onExit}>
				Exit
			</Button>
			<Text className={styles.stepsHeaderTitle}>{title}</Text>
			<div />
		</div>
	);
}

/**
 * A full screen wizard with configurable steps
 * @param title
 * @param current
 * @param steps
 * @param onExitCallback
 * @constructor
 */
export default function StepsWizard({ title, current, steps, onExitCallback }: StepsWizardProps) {
	function onExit() {
		onExitCallback();
	}

	return (
		<div>
			<StepsHeader title={title} onExit={onExit} />
			<Steps
				className={styles.progressWrapper}
				size="small"
				current={current}
				items={steps}
			/>
			<div className={styles.contentWrapper}>{steps[current].content}</div>
		</div>
	);
}
