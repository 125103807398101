import useNotesTabV2 from './hooks/useNotesTabV2';
import NotesTabComponent from './notesTabComponent';

/**
 * Smart component for NotesTab, handles data fetching and passing to the presentational component
 * @returns
 */
export default function NotesTabSmartComponent() {
	const data = useNotesTabV2();
	return <NotesTabComponent {...data} />;
}
