// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yYCnhP6outCaul8TfBfT {\n  color: #1677ff;\n}\n.efA7H_w2UyTXtHdDqIUa {\n  height: 100%;\n}\n.efA7H_w2UyTXtHdDqIUa .copilot-card-body {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/personalizedInsightsV2/components/body/templateCard/templateCard.module.less"],"names":[],"mappings":"AAAA;EACC,cAAA;AACD;AAEA;EACC,YAAA;AAAD;AADA;EAGE,aAAA;AACF","sourcesContent":[".highlight {\n\tcolor: #1677ff;\n}\n\n.templateCard {\n\theight: 100%;\n\t:global(.copilot-card-body) {\n\t\tdisplay: flex;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlight": "yYCnhP6outCaul8TfBfT",
	"templateCard": "efA7H_w2UyTXtHdDqIUa"
};
export default ___CSS_LOADER_EXPORT___;
