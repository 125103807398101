// Copy for card action tooltips
export const CARD_ACTION_TOOLTIPS = {
	VIEW_LINKEDIN: 'View LinkedIn',
	LINKED_IN_NOT_ACCESSIBLE: 'This LinkedIn profile is not accessible',
	SET_REMINDER: 'Set Reminder',
	READ: 'Mark as read',
	REMINDER_COMPLETE: 'Mark as complete',
	VIEW_CONVERSATION: 'View conversation',
} as const;

// Copy for the button to expand/collapse card message
export const EXPAND_MESSAGE_COPY = 'view entire message';
export const COLLAPSE_MESSAGE_COPY = 'show less';

// Prefix to add to a message when it is sent from the campaign member
export const SENT_MESSAGE_PREFIX = 'You replied: ';

// Card action buttons test ids
export const VIEW_LINKEDIN_TEST_ID = 'view-linkedin-button';
export const SET_REMINDER_TEST_ID = 'set-reminder-button';
export const MARK_READ_TEST_ID = 'mark-read-button';
