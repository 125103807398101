import React, { useEffect, useCallback, useState, useMemo } from 'react';
import BasicContainer from '@copilot/common/components/containers/basic';
import { LinkedInSentMessageError, ThreadStatus } from '@copilot/common/store/models/const/enum';
import { useFetch } from '@copilot/common/hooks/common';
import { OutboxManager } from '@copilot/data';
import { OutboxActions } from '@copilot/common/store/actions/outbox';
import { FailedOutboxColumns } from '@copilot/common/components/componentModels/columnTypeDefinition';
import { QueryObject } from '@copilot/data/managers/base';
import ErrorTable from '@copilot/common/components/tables/tableTypes/error';
import { OutboxMessageSelectors } from '@copilot/common/store/selectors/outbox';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { addSorterToQuery } from '@copilot/common/utils/tables';
import Store from '@copilot/common/store';
import { IOutboxMessage } from '@copilot/common/store/models/redux';
import { LinkedInMessageStatus } from '@copilot/data/responses/interface';
import FailedOutgoingPageBatchAction from './batchAction';
import { withNotification } from '@copilot/common/hoc/utils';
import { NotificationContent } from '@copilot/common/constant/notificationContent';
import { arrayToCustomMap } from '@copilot/common/utils';
//view types - campaign, sentiment, email

interface FailedOutgoingProps {
	orgId: string;
	orgMemberId: string;
	isOrgAdmin: boolean;
}

const Content = styled(BasicContainer.Content)`
	${(props) => {
		const backgroundColor = props.theme['@layout-body-background'];
		const padding = props.theme['@card-padding-base'];
		return `
			background-color: ${backgroundColor};
			padding: ${padding} ${padding} 0;
			`;
	}}
`;

const StyledErrorTable = styled(ErrorTable)`
	td.${(props) => props.theme['@ant-prefix']}-table-column-sort {
		background: unset;
	}
`;

const DismissWithNotification = withNotification(
	OutboxManager.updateOutboxMessageStatus,
	NotificationContent.DismissMessage.Success,
	NotificationContent.DismissMessage.Fail
);
const RetryWithNotification = withNotification(
	OutboxManager.updateOutboxMessageStatus,
	NotificationContent.RetryMessage.Success,
	NotificationContent.RetryMessage.Fail
);

const FailedOutgoing: React.FC<FailedOutgoingProps> = (props) => {
	const outgoingGetter = useCallback<typeof OutboxManager.getOutboxMessages>(
		(orgMemberId, query) => {
			if (props.isOrgAdmin) orgMemberId = null;
			return OutboxManager.getOutboxMessages(orgMemberId, query);
			//TODO if we want to have a separate route for ADMIN and CS to retrieve outgoing then add here
		},
		[props.orgMemberId, props.isOrgAdmin]
	);
	const [currentPage, setCurrentPage] = useState<number | undefined>(1);

	const [failedOutgoingFetch, fetchFailedOutgoingMessages] = useFetch(
		outgoingGetter,
		OutboxActions.loadOutboxMessages,
		(r) => r.results
	);

	useEffect(() => {
		const query = new QueryObject();
		query.addFilter(OutboxManager.KeyMap.status, '==', `${ThreadStatus.Failed}`);
		fetchFailedOutgoingMessages(props.orgMemberId, query);
	}, [props.orgMemberId]);

	const handleOutgoingTableChange = useCallback<
		NonNullable<React.ComponentProps<typeof ErrorTable>['onChange']>
	>(
		(pagination, filters, sorter) => {
			const query = new QueryObject({
				page: (pagination.current ?? 1) - 1,
				pageSize: pagination.pageSize,
			});
			query.addFilter(OutboxManager.KeyMap.status, '==', `${ThreadStatus.Failed}`);
			setCurrentPage(pagination.current);
			Object.keys(filters).forEach((k) => {
				if (filters[k]) {
					const key = OutboxManager.KeyMap[k as keyof typeof OutboxManager.KeyMap] || k;
					const evaluator = k === OutboxManager.KeyMap.error ? '==' : '^*=';
					query.addFilter(key, evaluator, filters[k]!.join('|'));
				}
			});
			addSorterToQuery(sorter, query);
			fetchFailedOutgoingMessages(props.orgMemberId, query);
		},
		[props.orgMemberId]
	);

	const [selectedRows, setSelectedRows] = useState<IOutboxMessage[]>([]);

	const handleRowChange = useCallback((rowKeys, rows: IOutboxMessage[]) => {
		setSelectedRows(rows);
	}, []);

	const rowSelection = {
		selectedRowKeys: selectedRows.map((r) => r.id),
		onChange: handleRowChange,
	};

	const resetSelectedRows = () => {
		setSelectedRows([]);
	};

	const failedPendingMessages = useSelector(
		OutboxMessageSelectors.getOutboxMessagesByStatus(ThreadStatus.Failed)
	);

	// Creates dictionary where id is the key for the whole message
	const failedPendingMessagesMap = useMemo(
		() =>
			arrayToCustomMap(
				failedPendingMessages ?? [],
				(message) => message.id,
				(message) => message
			),
		[failedPendingMessages]
	);

	// Crosscheck failedPendingMessagesMap and failedOutgoingFetch to populate the table in the correct order
	const displayedPendingMessages: IOutboxMessage[] = useMemo(
		() =>
			failedOutgoingFetch.data?.results
				.filter((result) => failedPendingMessagesMap[result.id])
				.map((result) => failedPendingMessagesMap[result.id]) ?? [],
		[failedPendingMessagesMap, failedOutgoingFetch.data]
	);

	const failedOutgoingTotal = useMemo(
		() => failedOutgoingFetch.data?.totalCount ?? 0,
		[failedOutgoingFetch.data?.totalCount]
	);

	const updateMessagePending = (ids: string[]) => {
		const messageItems = ids.map((id) => ({
			id,
			status: ThreadStatus.Pending,
			error: LinkedInSentMessageError.NoError,
		}));
		Store.Dispatch(OutboxActions.updateOutboxMessage(messageItems));
	};

	const handleRetry = useCallback(() => {
		const selectedThreadIds = selectedRows.map((r) => r.id);
		RetryWithNotification(selectedThreadIds, LinkedInMessageStatus.Pending).then(() => {
			updateMessagePending(selectedThreadIds);
			resetSelectedRows();
		});
	}, [selectedRows]);

	const handleDismiss = useCallback(() => {
		const selectedThreadIds = selectedRows.map((r) => r.id);
		DismissWithNotification(selectedThreadIds, LinkedInMessageStatus.Canceled).then(() => {
			Store.Dispatch(
				OutboxActions.deleteOutboxMessage(selectedThreadIds.map((id) => ({ id })))
			);
			resetSelectedRows();
		});
	}, [selectedRows]);

	//#endregion Failed Outgoing Table BatchActions Callbacks

	return (
		<Content
			style={{
				display: failedOutgoingTotal > 0 ? 'block' : 'none',
			}}
		>
			<StyledErrorTable
				orgId={props.orgId}
				columns={FailedOutboxColumns}
				data={displayedPendingMessages}
				pagination={{ total: failedOutgoingTotal, current: currentPage }}
				onChange={handleOutgoingTableChange}
				loading={failedOutgoingFetch.isFetching}
				rowSelection={rowSelection}
			/>
			{selectedRows.length > 0 && (
				<FailedOutgoingPageBatchAction
					selectedCount={selectedRows.length}
					onRetry={handleRetry}
					onDismiss={handleDismiss}
				/>
			)}
		</Content>
	);
};
export default FailedOutgoing;
