import { ReactNode, useCallback } from 'react';
import { ScheduledMessage } from '@copilot/common/store/models/redux';

import { SchedulingStatus, ThreadStatus } from '@copilot/common/store/models/const/enum';
import { ExclamationCircleTwoTone, EditOutlined } from '@ant-design/icons';
import { Popover, Button, Popconfirm, Tooltip, Row, Col } from 'antd';
import drawerManager from '@copilot/common/utils/drawerManager';
import { ContactDrawerTabsConstants } from '../../drawer/wrappers/contact/activity/constants';
import AntTheme from '@copilot/common/config/antd.theme';
import TableModule from '@copilot/common/components/tables/tableModule';
import { TableModuleTableProps } from '../tableModule/types';
import { TableModuleColumn, TableModuleHeader, TableModuleTitle } from '../tableModule/util';
import { TableModuleColumnSelector } from '../tableModule/columnSelector';
import { TableModuleTable } from '../tableModule/table';
import { checkHasAccess } from '@copilot/common/utils/access';
import {
	OUTBOX_SCHEDULED_CANCEL_TRACKING_ID,
	OUTBOX_SCHEDULED_EDIT_TRACKING_ID,
} from '@copilot/common/tracking/userpilotEventConsts';

interface IScheduledMessageTableProps extends TableModuleTableProps<ScheduledMessage> {
	orgId: string;
	isOrgAdmin: boolean;
	onDismiss: (record: ScheduledMessage) => void;
}

const renderStatusColumn = (_: any, record: ScheduledMessage) => {
	const { status } = record;
	let result: ReactNode = null;
	if (status === ThreadStatus.Failed) {
		result = (
			<Popover placement="top" content={record.error ?? ''} trigger="hover">
				<ExclamationCircleTwoTone twoToneColor={AntTheme['@error-color']} />
			</Popover>
		);
	}
	return result;
};

const renderDateColumn = (value: ScheduledMessage['timestamp']) =>
	new Date(value).toLocaleDateString();

const renderRescheduleColumn = (_: any, record: ScheduledMessage) => {
	const { contactId, orgMemberId } = record;
	if (!contactId || !orgMemberId) return null;
	return (
		<Button
			type="primary"
			block
			onClick={(event) => {
				event.stopPropagation();
				drawerManager.openContactDrawer({
					id: contactId,
					memberId: orgMemberId,
					tab: ContactDrawerTabsConstants.Schedules,
				});
			}}
			data-tracking-id={OUTBOX_SCHEDULED_EDIT_TRACKING_ID}
		>
			<EditOutlined /> Edit
		</Button>
	);
};

const generateOnCell = (record: ScheduledMessage) => ({
	onClick: () =>
		drawerManager.openContactDrawer({ id: record.contactId, memberId: record.orgMemberId }),
});

const generateRowClassName = (record: ScheduledMessage) =>
	record.status && record.error === SchedulingStatus.Failed ? 'flag' : '';

/**
 * [Presentational] Table specific for showing ScheduledMessage data
 * @param {IScheduledMessageTableProps} props
 */
const ScheduledMessageTable: React.FC<IScheduledMessageTableProps> = (props) => {
	const { isOrgAdmin, onDismiss, ...rest } = props;

	const renderDismissColumn = useCallback(
		(_: any, record: ScheduledMessage) => {
			const { id, orgMemberId, ownerOrgMemberId } = record;
			if (!id) return null;
			const isDeletable = checkHasAccess(orgMemberId, ownerOrgMemberId, isOrgAdmin);
			if (isDeletable) {
				return (
					<Button block data-tracking-id={OUTBOX_SCHEDULED_CANCEL_TRACKING_ID}>
						<Popconfirm
							title="Are you sure you want to cancel this scheduled message?"
							onConfirm={() => onDismiss(record)}
							okText="Yes"
							cancelText="No"
							placement="rightBottom"
						>
							Cancel
						</Popconfirm>
					</Button>
				);
			} else {
				return (
					<Tooltip
						placement="topRight"
						title="This message was not created by you. Please contact admin to cancel the message"
						trigger="hover"
						overlayStyle={{ width: 300 }}
					>
						<Button disabled block style={{ pointerEvents: 'all' }}>
							Cancel
						</Button>
					</Tooltip>
				);
			}
		},
		[isOrgAdmin, onDismiss]
	);

	return (
		<TableModule
			header={
				<TableModuleHeader>
					<Row justify="space-around">
						<Col style={{ flexGrow: 1 }}>
							<TableModuleTitle>Scheduled</TableModuleTitle>
						</Col>
						<Col>
							<TableModuleColumnSelector />
						</Col>
					</Row>
				</TableModuleHeader>
			}
			table={
				<TableModuleTable rowKey="id" rowClassName={generateRowClassName} {...rest}>
					<TableModuleColumn
						title=""
						columnKey="status"
						hideable={false}
						width={50}
						render={renderStatusColumn}
						className="column-status clickable"
					/>
					<TableModuleColumn
						title="Name"
						columnKey="name"
						dataIndex="name"
						onCell={generateOnCell}
						className="column-name clickable"
					/>
					<TableModuleColumn
						title="Message"
						columnKey="message"
						dataIndex="message"
						onCell={generateOnCell}
						className="column-message clickable"
					/>
					<TableModuleColumn
						columnKey="timestamp"
						title="Scheduled Send"
						dataIndex="timestamp"
						render={renderDateColumn}
						onCell={generateOnCell}
						className="column-timestamp clickable"
						width="10%"
					/>
					<TableModuleColumn
						columnKey="reschedule"
						render={renderRescheduleColumn}
						width={150}
						hideable={false}
						className="column-reschedule clickable"
					/>
					<TableModuleColumn
						columnKey="dismiss"
						width={150}
						hideable={false}
						className="column-cancel clickable"
						render={renderDismissColumn}
					/>
				</TableModuleTable>
			}
		/>
	);
};

export default ScheduledMessageTable;
