import { Badge, Flex, Spin, Tabs, Typography } from 'antd';
import styles from './meetingsBookedPage.module.less';
import OrganizationFilterSection from '@copilot/common/components/filters/organizationFilterSection/organizationFilterSection';
import DateRangeSelector from '@copilot/common/components/forms/common/generics/daterangeselector';
import {
	AI_DETECTED_TAB_KEY,
	CONFIRMED_MEETINGS_TAB_KEY,
	MAX_TAB_SECTION_HEIGHT,
	MIN_TAB_SECTION_HEIGHT,
	ROW_HEIGHT,
	TAB_SECTION_OFFSET,
} from './constants';
import useMeetingsBookedContext from './context/useMeetingsBookedContext';
import DetectedMeetingsTable from './tables/detectedMeetingsTable';
import ConfirmedMeetingsTable from './tables/confirmedMeetingsTable';
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { isNil } from 'lodash';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
/**
 * Presentational layer for the meetingsBooked page
 * @returns
 */
export default function MeetingsBookedPage() {
	const {
		activeFilter,
		campaignOptions,
		teamMemberOptions,
		onCampaignFilterChange,
		onTeamMemberFilterChange,
		onClearFilters,
		aiDetectedMeetings: { content: detectedContent, count: detectedCount },
		confirmedMeetings: { content: confirmedContent, count: confirmedCount },
		isLoadingConfirmed,
		isLoadingDetected,
		isClearFiltersEnabled,
		onUpdateDateRange,
		teamMemberFilterEnabled,
	} = useMeetingsBookedContext();

	const [activeTab, setActiveTab] = useState(AI_DETECTED_TAB_KEY);
	const [activeTabRowCount, setActiveTabRowCount] = useState(
		activeTab === AI_DETECTED_TAB_KEY ? detectedContent?.length : confirmedContent?.length
	);

	useEffect(() => {
		if (activeTab === AI_DETECTED_TAB_KEY && !isLoadingDetected) {
			setActiveTabRowCount(detectedContent.length);
		} else if (activeTab === CONFIRMED_MEETINGS_TAB_KEY && !isLoadingConfirmed) {
			setActiveTabRowCount(confirmedContent.length);
		}
	}, [activeTab, detectedContent, confirmedContent]);

	const minTabSectionHeight = useMemo(
		() =>
			Math.min(
				Math.max(
					activeTabRowCount * ROW_HEIGHT + TAB_SECTION_OFFSET,
					MIN_TAB_SECTION_HEIGHT
				),
				MAX_TAB_SECTION_HEIGHT
			),
		[activeTabRowCount]
	);

	const { start, end } = activeFilter.dateRange;
	const rangeValue: RangeValue<Moment> = [
		!isNil(start) ? moment(start) : null,
		!isNil(end) ? moment(end) : null,
	];
	return (
		<div className={styles.pageContentWrapper}>
			<div className={styles.pageHeaderSection}>
				<Typography.Title level={4} className={styles.pageTitle}>
					Meetings booked
				</Typography.Title>
				<Typography.Text className={styles.pageDescription}>
					All meetings that have been marked as “Meetings booked” on our platform.
				</Typography.Text>
				<OrganizationFilterSection
					activeFilter={activeFilter}
					campaignOptions={campaignOptions}
					enableTeamMemberFilter={teamMemberFilterEnabled}
					teamMemberOptions={teamMemberOptions}
					onCampaignFilterChange={onCampaignFilterChange}
					onTeamMemberFilterChange={onTeamMemberFilterChange}
					onClearFilters={onClearFilters}
					isClearFiltersEnabled={isClearFiltersEnabled}
				>
					<DateRangeSelector
						onChange={onUpdateDateRange}
						datePickerProps={{ value: rangeValue }}
					/>
				</OrganizationFilterSection>
			</div>

			<div className={styles.tabsSectionWrapper} style={{ maxHeight: minTabSectionHeight }}>
				<div className={styles.tabsSection}>
					<Tabs
						onChange={setActiveTab}
						className={styles.tabsSection}
						//prevents us from rendering non-visible tabs
						destroyInactiveTabPane
						items={[
							{
								key: AI_DETECTED_TAB_KEY,
								label: (
									<Flex gap={16} justify="space-between" align="center">
										AI-detected
										{isLoadingDetected ? (
											<Spin
												indicator={
													<LoadingOutlined
														className={styles.tabSpinner}
													/>
												}
											/>
										) : (
											<Badge count={detectedCount} />
										)}
									</Flex>
								),

								children: (
									<DetectedMeetingsTable viewPortHeight={minTabSectionHeight} />
								),
							},
							{
								key: CONFIRMED_MEETINGS_TAB_KEY,
								label: (
									<Flex gap={16} justify="space-between" align="center">
										Confirmed meetings
										{isLoadingConfirmed ? (
											<Spin
												indicator={
													<LoadingOutlined
														className={styles.tabSpinner}
													/>
												}
											/>
										) : (
											<Badge count={confirmedCount} />
										)}
									</Flex>
								),
								children: (
									<ConfirmedMeetingsTable viewPortHeight={minTabSectionHeight} />
								),
							},
						]}
					/>
				</div>
			</div>
		</div>
	);
}
