import { InboxMessage } from '@copilot/common/pages/inbox/ui/types';
import { InboxMessageType, ReminderStatus } from '@copilot/common/pages/inbox/data/types';

export type CardReminderProps = {
	reminderTimestamp: string;
	reminderType: ReminderStatus;
};

export type InboxV2CardProps = {
	/**
	 * Type of card
	 */
	cardType: InboxV2CardType;
	/**
	 * Inbox message item
	 */
	message: InboxMessage;
	/**
	 * Callback for viewing the full conversation
	 */
	onViewConversation: () => void;
	/**
	 * Handler for the remove action
	 */
	onRemove: () => Promise<void>;
	/**
	 * Reminder associated with the conversation
	 */
	reminder?: CardReminderProps;
};

/**
 * Props to inbox header
 */
export type InboxV2CardHeaderProps = {
	contactName: string;
	company: string;
	position: string;
};

// Inbox card type
export const REMINDER_CARD = 'REMINDER';
export const NEW_MESSAGE_CARD = 'NEW_MESSAGE';
export type InboxV2CardType = typeof REMINDER_CARD | typeof NEW_MESSAGE_CARD;

/**
 * Props for the smart text editor
 */
export type SmartTextEditorProps = {
	/**
	 * Handler for slicking send
	 * @param text The text to send
	 * @returns
	 */
	onSubmit: (text: string) => void;
	/**
	 * Whether we are in the loading / sending state
	 */
	isLoading: boolean;
	/**
	 *
	 */
	receivedMessage: InboxMessageType;
};
