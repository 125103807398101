import { Button, Card, Typography, message } from 'antd';
import styles from './templateCard.module.less';
import { CopyTwoTone } from '@ant-design/icons';

type TemplateCardProps = {
	template: string;
};
/**
 * Card for rendering a message template, provides syntax highlighting for sections marked with []
 * @param param0
 * @returns
 */
export function TemplateCard({ template }: TemplateCardProps) {
	function parseString(input: string) {
		const parts = input.split(/(\[[^\]]+\])/g);
		return parts.map((part, index) =>
			part.startsWith('[') && part.endsWith(']') ? (
				<span key={index} className={styles.highlight}>
					{part}
				</span>
			) : (
				part
			)
		);
	}

	/**
	 * Copies the template to the clipboard and displays notification
	 */
	async function handleButtonClick() {
		try {
			await navigator.clipboard.writeText(template);
			message.success('Template copied to clipboard');
		} catch (err) {
			message.error('Failed to copy template');
		}
	}

	return (
		<Card className={styles.templateCard}>
			<Typography.Paragraph>{parseString(template)}</Typography.Paragraph>
			<Button
				icon={<CopyTwoTone />}
				type="text"
				onClick={() => {
					handleButtonClick();
				}}
			/>
		</Card>
	);
}
