import { FC } from 'react';
import { Col, Row } from 'antd';
import { CampaignsFiltersProps } from '../types';
import FilterWithSelectedList from '@copilot/common/components/filters/filterWithSelectedList';

/**
 * Presentation Component for All Campaigns Page Filters
 * @param props
 */
const CampaignsPageFilters: FC<CampaignsFiltersProps> = (props) => {
	const { filters, filterUpdateCallbacks } = props;
	const { teamMembers } = filters;
	const { onTeamMemberFilterUpdate } = filterUpdateCallbacks;
	return (
		<>
			<Row align="middle" style={{ alignItems: 'baseline' }}>
				<Col>
					<FilterWithSelectedList
						label="Team Member"
						filters={teamMembers}
						onUpdate={onTeamMemberFilterUpdate}
					/>
				</Col>
			</Row>
		</>
	);
};
export default CampaignsPageFilters;
