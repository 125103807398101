import {
	InboxV2ListProps,
	InboxV2ListRenderProps,
} from '@copilot/common/pages/inboxV2/components/listing/types';
import isEmpty from 'lodash/isEmpty';
import { MESSAGE_LIST_TEST_ID } from '@copilot/common/pages/inboxV2/constants';
import InboxPageV2NoMessagePlaceholder from '@copilot/common/pages/inboxV2/components/listing/inboxV2NoMessagePlaceholder';
import { Button, Checkbox, Flex } from 'antd';
import styles from './inboxV2List.module.less';
import InboxV2SearchBar from '@copilot/common/pages/inboxV2/components/inboxPageV2SearchBar';
import { useMemo, useState } from 'react';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';
import { InboxMessageType } from '@copilot/common/pages/inbox/data/types';
import BatchActions from './inboxV2BatchActions';
import {
	LOAD_MORE_BUTTON,
	LOAD_MORE_BUTTON_TEST_ID,
	SELECT_ALL_CHECKBOX_TEST_ID,
	SELECT_CHECKBOX_TEST_ID,
} from './constants';
import { MESSAGE_LIST_CONTAINER_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';

export default function InboxV2List({
	messages,
	render,
	hasFilter,
	isLoading,
	canLoadMore,
	isLoadingMore,
	onLoadMore,
	extraHeaderContent,
	nurtureCampaigns = [],
	isSelectable,
}: InboxV2ListProps & InboxV2ListRenderProps) {
	const [selectedItems, setSelectedItems] = useState<InboxMessageType[]>([]);
	const [selectedOrgMembers, setSelectedOrgMembers] = useState<Set<string>>(new Set([]));

	const selectedIds = useMemo(() => selectedItems.map((item) => item.id), [selectedItems]);

	function onSelected(selectedItem: InboxMessageType, checked: boolean) {
		if (checked && !selectedItems.map((item) => item.id).includes(selectedItem.id)) {
			setSelectedItems(selectedItems.concat(selectedItem));
			setSelectedOrgMembers(new Set([...selectedOrgMembers, selectedItem.orgMemberId]));
		} else {
			const filteredItems = selectedItems.filter((item) => item.id !== selectedItem.id);
			setSelectedOrgMembers(new Set([...filteredItems.map((item) => item.orgMemberId)]));
			setSelectedItems(filteredItems);
		}
	}

	function onSelectAll(checked: boolean) {
		if (checked) {
			setSelectedItems(messages);
			setSelectedOrgMembers(new Set(messages.map((item) => item.orgMemberId)));
		} else {
			setSelectedItems([]);
			setSelectedOrgMembers(new Set([]));
		}
	}

	function onClearSelection() {
		setSelectedItems([]);
		setSelectedOrgMembers(new Set([]));
	}

	return (
		<LoadingIndicator isLoading={isLoading}>
			<div className={styles.headerSection}>
				{isSelectable && (
					<Checkbox
						data-testid={SELECT_ALL_CHECKBOX_TEST_ID}
						className={styles.checkbox}
						indeterminate={
							selectedItems.length > 0 && selectedItems.length < messages.length
						}
						onChange={(e) => onSelectAll(e.target.checked)}
						checked={
							selectedItems.length > 0 && messages.length === selectedItems.length
						}
					/>
				)}
				<InboxV2SearchBar
					extraContent={extraHeaderContent}
					searchPlaceholder={'Search By Name'}
				/>
			</div>
			{isEmpty(messages) ? (
				<InboxPageV2NoMessagePlaceholder hasFilter={hasFilter} />
			) : (
				<>
					<div
						data-testid={MESSAGE_LIST_TEST_ID}
						className={styles.messageList}
						data-tracking-id={MESSAGE_LIST_CONTAINER_TRACKING_ID}
					>
						{messages.map((item, idx) => (
							<Flex gap={'small'} className={styles.messageItem} key={item.id}>
								{isSelectable ? (
									<>
										<Checkbox
											data-testid={SELECT_CHECKBOX_TEST_ID}
											className={styles.checkbox}
											checked={selectedIds.includes(item.id)}
											onChange={(e) => onSelected(item, e.target.checked)}
										/>
										{render(item, idx, selectedIds.includes(item.id))}
									</>
								) : (
									render(item, idx)
								)}
							</Flex>
						))}
					</div>
					{canLoadMore && (
						<div className={styles.loadMoreContainer}>
							<Button
								block
								data-testid={LOAD_MORE_BUTTON_TEST_ID}
								className={styles.loadMoreButton}
								loading={isLoadingMore}
								disabled={isLoadingMore}
								onClick={() => onLoadMore(messages.length)}
							>
								{LOAD_MORE_BUTTON}
							</Button>
						</div>
					)}
				</>
			)}
			{isSelectable && (
				<BatchActions
					selectedOrgMembers={selectedOrgMembers}
					selected={selectedItems}
					onClearSelection={onClearSelection}
					nurtureCampaigns={nurtureCampaigns}
				/>
			)}
		</LoadingIndicator>
	);
}
