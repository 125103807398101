import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { getAuthRedirectRequestOptions } from '@copilot/common/utils/authenticationProvider';
import { B2CCustomPolicyRoutes } from '@copilot/data/config/routes';

type ImpersonationFunction = (userId: string, orgId: string, path?: string) => void;

/**
 * Hook to handle impersonation logic
 */
export function useImpersonation(): [ImpersonationFunction, () => void] {
	const { login } = useMsalAuthentication(InteractionType.Redirect);
	const { instance } = useMsal();

	const onImpersonation = async (userId: string, orgId: string, path = '/'): Promise<void> => {
		await instance.handleRedirectPromise();

		const authOptions = getAuthRedirectRequestOptions({
			authority: B2CCustomPolicyRoutes.Impersonation,
			scopes: [],
			extraQueryParameters: {
				orgId,
				userId,
			},
			redirectStartPage: path,
		});

		login(InteractionType.Redirect, authOptions);
	};

	const onExitImpersonation = (): void => {
		window.close();
	};

	return [
		(...params) => {
			onImpersonation(...params);
		},
		(...params) => onExitImpersonation(...params),
	];
}

/**
 * Handle redirect to impersonation in new tab
 */
export function useImpersonationWithNewTab(): ImpersonationFunction {
	return function (userId: string, orgId: string, path = '/') {
		window.mixpanel?.track('impersonate', { userId, orgId, path });
		const userOrgPath = `${userId}/${orgId}`;
		window.open(`/impersonate/${userOrgPath}?path=${encodeURIComponent(path)}`, userOrgPath);
	};
}
