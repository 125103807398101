import { IAccountManager, IAccountType } from '@copilot/data/graphql/_generated';

/**
 * Representation of an account used by the accounts dashboard
 */
export type Account = Readonly<{
	/**
	 * Id of the account
	 */
	id: string;
	/**
	 * Name of the account
	 */
	name: string;

	/**
	 * The logo URL
	 */
	logoUrl?: string;

	/**
	 * Number of active users in the account
	 */
	activeUsers: number;
	/**
	 * Number of inactive users in the account
	 */
	inactiveUsers: number;
	/**
	 * Number of attention items for the account
	 */
	linkedInConnection: number;
	/**
	 * Whether the account is active or inactive
	 */
	status: string;
	/**
	 * The manager of the account
	 */
	accountManager?: IAccountManager;
	/**
	 * The primary contact for the account
	 */
	primaryContact?: {
		/**
		 * Primary contact's user id
		 */
		id: string;
	};
	/**
	 * The organization Id of the account
	 * (if this account has prospecting)
	 */
	orgId?: string;
	/** the type of account */
	type: IAccountType;
}>;

export type AccountDashboardFilters = {
	/**
	 * Account manager id to filter
	 */
	accountManagerId: string | undefined;
	/**
	 * Account status to filter
	 */
	accountStatus: string | undefined;
	/**
	 * The current page, zero based
	 */
	pageIndex: number;
	/**
	 * The size of each page
	 */
	pageSize: number;
};

/**
 * Names of account statuses
 */
export const AccountStatusNames = {
	Active: 'Active',
	Deactivated: 'Deactivated',
} as const;
