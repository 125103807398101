import { Button, Col, Drawer, Row, Skeleton, Tabs } from 'antd';
import styles from './contactDrawerV2.module.less';
import { RightOutlined, ReadOutlined, CalendarOutlined, MessageOutlined } from '@ant-design/icons';
import { CONVERSATION_TAB_KEY, NOTES_TAB_KEY, SCHEDULE_MESSAGE_TAB_KEY } from './constants';
import ThreadNavigator from '../wrappers/contact/drawer/threadNavigator';
import useContactDrawerV2 from './context/useContactDrawerV2Context';
import InfoPanelV2 from './infoPanelV2/infoPanelV2Smart';
import ConversationTabSmart from './tabs/conversationTab/conversationTabSmart';
import NotesTabSmartComponent from './tabs/notesTab/notesTabSmartComponent';
import ScheduleMessageTabSmart from './tabs/scheduleMessageTab/scheduleMessageTabSmart';

type ContactDrawerV2Props = {
	isOpen?: boolean;
	onGoToThread: (idx: number) => void;
	onClose: () => void;
	currConversationIdx: number;
	totalConversations: number;
};

/**
 * Presentational component for the contact drawer, responsible for rendering the drawer layout
 * @param param0
 * @returns
 */
export default function ContactDrawerV2({
	onClose,
	isOpen = false,
	onGoToThread,
	currConversationIdx,
	totalConversations,
}: ContactDrawerV2Props) {
	const { isLoadingConversationDrawerData, isLoading } = useContactDrawerV2();
	return (
		<Drawer width="908px" open={isOpen} className={styles.drawer} closable={false}>
			<ThreadNavigator
				currThreadIdx={currConversationIdx}
				onGoToThread={onGoToThread}
				numThreads={totalConversations}
			/>
			<Button className={styles.closeDrawerButton} onClick={onClose}>
				<RightOutlined width={16} height={16} />
			</Button>
			<Row className={styles.contentRow}>
				<Col span={8} className={styles.infoPanelColumn}>
					{isLoading ? (
						<Skeleton
							loading
							active
							className={styles.skeletonLoader}
							paragraph={{ rows: 2 }}
						/>
					) : (
						<InfoPanelV2 />
					)}
				</Col>

				<Col span={16}>
					{isLoadingConversationDrawerData ? (
						<Skeleton loading active className={styles.skeletonLoader} />
					) : (
						<Tabs
							className={styles.contentTabs}
							//prevents us from rendering non-visible tabs
							destroyInactiveTabPane
							items={[
								{
									key: CONVERSATION_TAB_KEY,
									label: (
										<div>
											<MessageOutlined />
											Conversation
										</div>
									),

									children: <ConversationTabSmart />,
								},
								{
									key: NOTES_TAB_KEY,
									label: (
										<div>
											<ReadOutlined />
											Notes
										</div>
									),

									children: <NotesTabSmartComponent />,
								},
								{
									key: SCHEDULE_MESSAGE_TAB_KEY,
									label: (
										<div>
											<CalendarOutlined />
											Schedule Message
										</div>
									),

									children: <ScheduleMessageTabSmart />,
								},
							]}
						/>
					)}
				</Col>
			</Row>
		</Drawer>
	);
}
