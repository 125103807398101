import { FC } from 'react';
import { FilterDefinition } from '../../componentModels/filterDefinition';
import DataFilter from '../../tables/dataFilter';
import FilterList from '../list';

interface FilterWithSelectedListProps {
	/**
	 * title of the filter dropdown
	 */
	label: string;
	/**
	 * list of filter options
	 */
	filters: FilterDefinition[];
	/**
	 * Callback on filter update
	 */
	onUpdate: (value: FilterDefinition[]) => void;
}

/**
 * Display filter dropdown and a list of selected options as a tag
 */
const FilterWithSelectedList: FC<FilterWithSelectedListProps> = (props) => {
	const { label, filters, onUpdate } = props;

	return (
		<>
			<DataFilter title={label} filters={filters} updateFilters={onUpdate} />
			<FilterList filters={filters} updateFilterSelector={onUpdate} />
		</>
	);
};

export default FilterWithSelectedList;
