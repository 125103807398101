import { createContext } from 'react';
import { MeetingsBookedContextData } from './types';
import { DEFAULT_MEETINGS_BOOKED_CONTEXT } from './constants';

/**
 * Boiler plate for defining the InboxV2 context, supplies default data
 */
const MeetingsBookedContext = createContext<MeetingsBookedContextData>(
	DEFAULT_MEETINGS_BOOKED_CONTEXT
);

export default MeetingsBookedContext;
