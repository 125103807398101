import Store from '..';
import { IOutboxMessage, OutboxMessage } from '../models/redux';
import { ThreadStatus } from '../models/const/enum';
import { createSelector, SessionBoundModel, Selector, OrmState } from 'redux-orm';

export namespace OutboxMessageSelectors {
	export const getOutboxMessages = (state: { entities: OrmState<any> }) =>
		Store.ORM.session(state.entities)
			.OutboxMessage.all()
			.toRefArray()
			.map((m) => m as IOutboxMessage);

	export const getOutboxMessagesByThreadId = (
		state: { entities: OrmState<any> },
		threadId: string
	) =>
		Store.ORM.session(state.entities)
			.OutboxMessage.all()
			.toModelArray()
			.filter((m) => m.threadId == threadId);

	export const getOutboxMessagesByStatus = (
		status: ThreadStatus
	): Selector<any, IOutboxMessage[]> =>
		createSelector(
			Store.ORM,
			(session) =>
				session.OutboxMessage.all()
					.toRefArray()
					.filter((m) => m.status == status) as IOutboxMessage[]
		);

	export const getOutboxMessagesByIds =
		(ids: string[]) => (state: { entities: OrmState<any> }) => {
			const messages = Store.ORM.session(state.entities).OutboxMessage;
			return ids
				.map((id) => messages.withId(id))
				.filter((c): c is SessionBoundModel<OutboxMessage, {}> => c !== null);
		};
}
