import { createContext } from 'react';
import { BlocklistContextData } from './types';
import { MOCK_DEFAULT_BLOCKLIST_CONTEXT } from './__mocks__/defaultBlocklistContext.mock';

/**
 * Boiler plate for defining the InboxV2 context, supplies default data
 */
const BlocklistContext = createContext<BlocklistContextData>(
	// TODO: Replace with default blocklist context after implementation
	MOCK_DEFAULT_BLOCKLIST_CONTEXT
);

export default BlocklistContext;
