/**
 * Test ID for the item container
 */
export const EMAIL_ITEM_CONTAINER_TEST_ID = 'email-item-container';

/**
 * Test ID for the LinkedIn button
 */
export const EMAIL_ITEM_LINKEDIN_TEST_ID = 'email-item-linkedin';

/**
 * Test ID for the delete button
 */
export const EMAIL_ITEM_DELETE_TEST_ID = 'email-item-delete';

/**
 * Test ID for the reminder button
 */
export const EMAIL_ITEM_REMINDER_TEST_ID = 'email-item-reminder';
