import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Button, Flex } from 'antd';
import { OrganizationMemberManager, AuthenticationManager } from '@copilot/data';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import notificationManager from '@copilot/common/utils/notificationManager';
import { Config } from '@copilot/common/config';

import PasswordUpdateForm, {
	PasswordUpdateFormValues,
} from '@copilot/common/components/forms/user/passwordUpdate';
import EditableTextDisplay from '@copilot/common/components/forms/common/inputs/editableTextDisplay';
import { IOrganizationMember, IProxy } from '@copilot/common/store/models/redux';
import { ERROR_ICON, OK_ICON, SYNCING_ICON } from '@copilot/common/components/icon';
import styles from './userSettings.module.less';
import { clearSalesNavMissingError, disconnectMyLinkedIn } from './serviceCalls';
import { useRegionalProxies } from '../regions/useRegionalProxies';
import { LinkedInMetaActions } from '@copilot/common/store/actions/linkedInMeta';
import LinkedinLoginModal from '@copilot/common/components/modals/wrappers/linkedinSync/linkedinLoginModal';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';
import {
	ILinkedInAuthErrors,
	useGenerateLinkedInAuthMutation,
} from '@copilot/data/graphql/_generated';
import {
	HOSTED_AUTH_FAILED_HASH,
	HOSTED_AUTH_SUCCESS_HASH,
} from '@copilot/common/tracking/hostedAuthConsts';
import { USER_SETTINGS_LINKEDIN_CONNECT_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';

type UserSettingsProps = {
	orgMember: IOrganizationMember;
	hideLinkedIn?: boolean;
};

/**
 * Settings config for the user
 * @param param0
 * @returns
 */
export default function UserSettings({ orgMember, hideLinkedIn }: UserSettingsProps) {
	const storeDispatch = useDispatch();
	const { currentUserRegion, hasProxySet } = useRegionalProxies({
		userVpnPort: orgMember.vpnPort,
	});

	const isHostedAuthEnabled = useFeatureToggle(Features.UseHostedAuthFeature);

	const [getAuthUrl] = useGenerateLinkedInAuthMutation();

	const [isRestartingAutomation, setIsRestartingAutomation] = useState(false);
	const [isReconnecting, setIsReconnecting] = useState(false);
	const [isDisconnectingLinkedIn, setIsDisconnectingLinkedIn] = useState(false);
	const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);

	function onUpdatePhoneNumber(phoneNumber: string) {
		OrganizationMemberManager.updateSelf({ phoneNumber }).then((member) => {
			storeDispatch(OrganizationMemberActions.loadOrganizationMember(member));
		});
	}

	async function onRefreshSalesNav() {
		try {
			setIsRestartingAutomation(true);
			const updatedMember = await clearSalesNavMissingError();
			storeDispatch(OrganizationMemberActions.loadOrganizationMember(updatedMember));
			notificationManager.showSuccessNotification({
				message: 'Success',
				description: 'Automation has been resumed!',
			});
		} catch (e) {
			notificationManager.showErrorNotification({
				message: 'Error',
				description:
					'There was an issue resuming automation, please refresh and try again.',
			});
		}
		setIsRestartingAutomation(false);
	}

	async function onDisconnectLinkedIn() {
		try {
			setIsDisconnectingLinkedIn(true);
			const updatedMember = await disconnectMyLinkedIn();
			const isLoggedIn = await OrganizationMemberManager.checkIsLoggedIn(updatedMember.id);
			if (isLoggedIn === false) {
				if (orgMember.isLiSessionInRetryState) {
					storeDispatch(OrganizationMemberActions.loadOrganizationMember(updatedMember));
				}
				storeDispatch(
					LinkedInMetaActions.loadLinkedInMeta({
						orgMemberId: updatedMember.id,
						isLoggedIn,
					})
				);
				notificationManager.showSuccessNotification({
					message: 'Success',
					description: 'LinkedIn was successfully disconnected',
				});
			} else {
				throw new Error('Failed to disconnect LinkedIn');
			}
		} catch (e) {
			notificationManager.showErrorNotification({
				message: 'Error',
				description:
					'There was an issue disconnecting LinkedIn, please refresh and try again.',
			});
		}
		setIsDisconnectingLinkedIn(false);
	}

	async function onUpdateProxy(proxy: IProxy) {
		return OrganizationMemberManager.updateSelf({ vpnPort: proxy.value }).then((member) => {
			storeDispatch(OrganizationMemberActions.loadOrganizationMember(member));
		});
	}

	function onUpdatePassword(values: PasswordUpdateFormValues) {
		AuthenticationManager.setPassword(
			values.oldPassword,
			values.newPassword,
			values.confirmPassword
		)
			.then(() =>
				notificationManager.showSuccessNotification({
					message: 'Saved',
					description: 'Your password has been updated.',
				})
			)
			.catch(() => {
				notificationManager.showErrorNotification({
					message: 'Failed',
					description: 'Please refresh the page and try again',
				});
			});
	}

	async function onReconnect() {
		if (isHostedAuthEnabled) {
			try {
				setIsReconnecting(true);
				const isLoggedIn = await OrganizationMemberManager.checkIsLoggedIn(orgMember.id);
				if (isLoggedIn) {
					notificationManager.showInfoNotification({
						message: 'Already Connected',
						description: 'Your account is already connected to LinkedIn.',
					});
					storeDispatch(
						LinkedInMetaActions.loadLinkedInMeta({
							orgMemberId: orgMember.id,
							isLoggedIn,
						})
					);
					return;
				}
				const res = await getAuthUrl({
					variables: {
						input: {
							orgMemberId: orgMember.id,
							successRedirectUrl: `${window.location.href}${HOSTED_AUTH_SUCCESS_HASH}`,
							failureRedirectUrl: `${window.location.href}${HOSTED_AUTH_FAILED_HASH}`,
						},
					},
				});
				const data = res.data?.generateLinkedInAuth;
				if (data?.__typename === 'LinkedInAuthDTO') {
					window.location.replace(data.url);
				} else if (data?.__typename === 'LinkedInAuthError') {
					console.error(
						`Error retrieving authentication URL. TypeName: '${data.__typename}', Type: '${data.type}'`
					);
					if (data.type === ILinkedInAuthErrors.InProgress) {
						notificationManager.showErrorNotification({
							message:
								'A login attempt is currently being finalized. Please wait a few moments before trying again.',
							description: data.type,
						});
					} else {
						notificationManager.showErrorNotification({
							message:
								'Oops! Something went wrong. Please try again in a moment, or contact support if this problem persists.',
							description: data.type,
						});
					}
				} else {
					console.error('Non-string url returned');
					notificationManager.showErrorNotification({
						message:
							'Oops! Something went wrong. Please try again in a moment, or contact support if this problem persists.',
						description: 'Non-string url returned',
					});
				}
			} catch (e) {
				console.error("Couldn't generate LinkedIn auth URL", e);
				notificationManager.showErrorNotification({
					message:
						'Oops! Something went wrong. Please try again in a moment, or contact support if this problem persists.',
					description: "Couldn't generate LinkedIn auth URL",
				});
			} finally {
				// Add a brief delay to prevent the user from spamming the reconnect button after an error
				// Not relevant to the success case since the user is redirected to a new page
				await new Promise((resolve) => setTimeout(resolve, 1000));
				setIsReconnecting(false);
			}
		} else {
			setIsLoginModalOpen(true);
		}
	}

	return (
		<div className={styles.userSettingsWrapper}>
			<Flex gap="small">
				<label>First Name</label>
				<div>{orgMember.firstName}</div>
			</Flex>
			<Flex gap="small">
				<label>Last Name</label>
				<div>{orgMember.lastName}</div>
			</Flex>
			<Flex gap="small">
				<label>Email</label>
				<div>{orgMember.email}</div>
			</Flex>
			<Flex gap="small">
				<label>Phone number</label>
				<EditableTextDisplay
					value={orgMember.phoneNumber}
					confirmHandler={onUpdatePhoneNumber}
					confirmOnBlur
				>
					{orgMember.phoneNumber}
				</EditableTextDisplay>
			</Flex>
			<Flex gap="small">
				<label>Region</label>
				{hasProxySet ? currentUserRegion : 'No region set'}
			</Flex>
			{!hideLinkedIn && (
				<>
					<Flex gap="small">
						<label>LinkedIn session status</label>
						{orgMember.isLinkedInLoggedIn || orgMember.isLiSessionInRetryState ? (
							<div>
								<Flex gap="small" align="center">
									{orgMember.isLinkedInLoggedIn ? OK_ICON : SYNCING_ICON}
									{orgMember.isLinkedInLoggedIn ? 'Connected' : 'Syncing'}
									<Button
										size="small"
										onClick={() => void onDisconnectLinkedIn()}
										loading={isDisconnectingLinkedIn}
									>
										Disconnect
									</Button>
								</Flex>
								<div className={styles.statusDescription}>
									{orgMember.isLinkedInLoggedIn ? (
										<>
											Your LinkedIn is currently connected to our platform,
											automation will run if you have an active LinkedIn Sales
											Navigator subscription.
										</>
									) : (
										<>
											We are attempting to sync your LinkedIn account.
											Outgoing messages will be paused and uploading search
											lists will be temporarily unavailable until the
											connection is established.
										</>
									)}
								</div>
							</div>
						) : (
							<div>
								<Flex gap="small" align="center">
									{ERROR_ICON}
									Disconnected
									<Button
										size="small"
										onClick={() => void onReconnect()}
										loading={isRestartingAutomation || isReconnecting}
										data-tracking-id={
											USER_SETTINGS_LINKEDIN_CONNECT_TRACKING_ID
										}
									>
										Reconnect
									</Button>
								</Flex>
								<div className={styles.statusDescription}>
									Your LinkedIn is currently not connected to our platform,
									preventing automation. Click "Reconnect" to connect your
									LinkedIn account.
								</div>
							</div>
						)}
					</Flex>
					{orgMember.isLinkedInLoggedIn && (
						<Flex gap="small">
							<label>Sales Navigator status</label>
							{orgMember.hasInvalidSalesNavSubscription ? (
								<div>
									<Flex gap="small" align="center">
										{ERROR_ICON}
										Paused
										<Button
											size="small"
											onClick={() => void onRefreshSalesNav()}
											loading={isRestartingAutomation}
										>
											Resume automation
										</Button>
									</Flex>
									<div className={styles.statusDescription}>
										Automation has paused because we can't detect a valid
										LinkedIn Sales Navigator subscription for your account.
										Please ensure you have an active LinkedIn Sales Navigator
										subscription and then click "Resume automation"
									</div>
								</div>
							) : (
								<div>
									<Flex gap="small" align="center">
										{OK_ICON} Active
									</Flex>
									<div className={styles.statusDescription}>
										Your LinkedIn Sales Navigator subscription is marked as
										active and automation is running
									</div>
								</div>
							)}
						</Flex>
					)}
				</>
			)}
			{!Config.isB2CEnabled && (
				<>
					<Divider />
					<PasswordUpdateForm onSubmit={onUpdatePassword} />
				</>
			)}
			<LinkedinLoginModal
				orgMember={orgMember}
				onProxySet={onUpdateProxy}
				open={isLoginModalOpen}
				onCancel={() => setIsLoginModalOpen(false)}
			/>
		</div>
	);
}
