import { ICampaignStatsOwnerType } from '@copilot/data/graphql/_generated';
import { FunnelData } from './prospectingCampaignFunnel';
import isUndefined from 'lodash/isUndefined';
import isNil from 'lodash/isNil';
import { ABOVE_AVERAGE_COLOR } from './constants';

import Percentage from '@copilot/common/components/dataDisplay/percentage';
import { ReactNode } from 'react';
import { Chip } from '@copilot/common/components/dataDisplay/chip';
import { CampaignStatsType, getCampaignStatsColor } from '@copilot/common/utils/campaign';

type BaseCampaignStats = {
	ownerId: string;
	ownerType: ICampaignStatsOwnerType;
	start?: number | null;
	end?: number | null;
	numMeetingsBooked: number;
};

export type ProspectingCampaignStats = BaseCampaignStats & {
	__typename?: 'ProspectingCampaignStats';
	numUserReplies: number;
	numUsersConnected: number;
	numInvitesSent: number;
	numInterestedReplies: number;
	percentConnected?: number | null;
	percentReplied?: number | null;
	percentInterestedReplied?: number | null;
};

export type NurtureCampaignStats = BaseCampaignStats & {
	__typename?: 'NurtureCampaignStats';
	numSendMessages: number;
	numUserReplies: number;
	numInterestedReplies: number;
	percentReplied?: number | null;
	percentInterestedReplied?: number | null;
};

export type CampaignStats = ProspectingCampaignStats | NurtureCampaignStats;

/**
 * Typeguard for prospecting campaign stats
 * @param stats
 * @returns
 */
export function isProspectingCampaignStats(
	stats: CampaignStats
): stats is ProspectingCampaignStats {
	return stats.__typename === 'ProspectingCampaignStats';
}

/**
 * Display information about the number of connection invites sent, used in the prospecting campaign funnel chart
 * @param stats
 * @returns
 */
export function getConnectedData(stats: ProspectingCampaignStats): FunnelData {
	return { name: 'Connection invites sent', fill: '#C1F1EF', value: stats.numInvitesSent };
}

/**
 * Display information about the number of new connections, used in the prospecting campaign funnel chart
 * @param stats
 * @returns
 */
export function getNewConnections(stats: ProspectingCampaignStats): FunnelData {
	return {
		name: 'New connections',
		fill: '#71D5D1',
		value: stats.numUsersConnected,
	};
}

/**
 * Display information about the number of replies, used in the prospecting campaign funnel chart
 * @param stats
 * @returns
 */
export function getReplies(stats: ProspectingCampaignStats): FunnelData {
	return {
		name: 'Replies',
		fill: '#22BAB4',
		value: stats.numUserReplies,
	};
}

/**
 * Display information about the number of meetings booked, used in the prospecting campaign funnel chart
 * @param stats
 * @returns
 */
export function getMeetingsBooked(stats: ProspectingCampaignStats): FunnelData {
	return {
		name: 'Meetings booked',
		fill: '#1B8782',
		value: stats.numMeetingsBooked,
	};
}

/**
 * Convert organization stats data to data consumable by prospecting campaign funnel chart
 * @param orgStat Organization stats data
 * @returns Data to be used by the prospecting campaign funnel chart
 */
export function toProspectingCampaignFunnelData(orgStat: CampaignStats | undefined): FunnelData[] {
	if (isUndefined(orgStat)) return [];
	if (!isProspectingCampaignStats(orgStat))
		throw new Error('Only prospecting campaign stats supported');
	return [
		getConnectedData(orgStat),
		getNewConnections(orgStat),
		getReplies(orgStat),
		getMeetingsBooked(orgStat),
	];
}

/**
 * Get color for the given stats and its value
 * @param percentConnected
 * @returns
 */
export function getStatsColor(
	statsValue: number | undefined | null,
	statsType: CampaignStatsType
): string {
	if (isNil(statsValue)) {
		return ABOVE_AVERAGE_COLOR;
	}

	return getCampaignStatsColor(statsValue, statsType);
}

/**
 * Get color for connection rate based on percent connected
 * @param percentConnected
 * @returns
 */
export function getConnectionRateColor(percentConnected: number | undefined | null): string {
	return getStatsColor(percentConnected, CampaignStatsType.ConnectionRate);
}

/**
 * Get color for reply rate based on percent replied
 * @param percentReplied
 * @returns
 */
export function getReplyRateColor(percentReplied: number | undefined | null): string {
	return getStatsColor(percentReplied, CampaignStatsType.ReplyRate);
}

/**
 * Create funnel chart dividers based off of organization stats
 * @param data Organization stats data
 * @returns Dividers to use for prospecting campaign funnel chart
 */
export function toDividers(orgStat: CampaignStats | undefined): ReactNode[] {
	if (isUndefined(orgStat)) return [];
	if (!isProspectingCampaignStats(orgStat))
		throw new Error('Only prospecting campaign stats supported');
	const { percentConnected, percentReplied } = orgStat;
	const percentConnectedColor = getConnectionRateColor(percentConnected);
	const percentConnectedString = !isNil(percentConnected) ? (
		<Percentage value={percentConnected} precision={1} />
	) : (
		'-'
	);
	const percentRepliedColor = getReplyRateColor(percentReplied);
	const percentRepliedString = !isNil(percentReplied) ? (
		<Percentage value={percentReplied} precision={1} />
	) : (
		'-'
	);
	return [
		<>
			Connection rate{' '}
			<Chip style={{ marginLeft: '5px' }} color={percentConnectedColor}>
				{percentConnectedString}
			</Chip>
		</>,
		<>
			Reply rate{' '}
			<Chip style={{ marginLeft: '5px' }} color={percentRepliedColor}>
				{percentRepliedString}
			</Chip>
		</>,
	];
}
