import { List } from 'antd';
import { UseScheduleMessagesTabV2Data } from './hooks/types';
import ScheduledMessageItem from '@copilot/common/components/componentModels/activities/scheduledMessages/item';
import { ScheduledMessage } from '@copilot/common/store/models/redux';
import { checkHasAccess } from '@copilot/common/utils/access';
import MessageForm from '@copilot/common/components/forms/messageForm';
import styles from './scheduleMessageTab.module.less';
import isNil from 'lodash/isNil';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';
import { manualMessageDisabledMsg } from '@copilot/common/constant/strings';

export type ScheduleMessageTabProps = UseScheduleMessagesTabV2Data;
/**
 * Renders the schedule message tab, responsible for rendering the list of scheduled messages and the message form
 * @param param0
 * @returns
 */
export default function ScheduleMessageTab({
	scheduledMessages,
	onDeleteScheduledMessage,
	isOrgAdmin,
	smartReplyResult,
	writeSmartReply,
	regenerateSmartReply,
	editSmartReply,
	messageTemplates,
	createQuickReplyModal,
	campaignId,
	nextAutomatedStep,
	hasAcceptedAITermsOfUse,
	videoInAppValidationEnabled,
	onScheduleMessage,
	isSubmitting,
}: ScheduleMessageTabProps) {
	const isManualSendMessageFeatureEnabled = useFeatureToggle(Features.ManualSendMessageFeature);
	return (
		<div className={styles.scheduleMessageWrapper}>
			<div className={styles.scheduleMessageListWrapper}>
				<List
					dataSource={scheduledMessages}
					itemLayout="vertical"
					renderItem={(item: ScheduledMessage) => (
						<ScheduledMessageItem
							key={item.id}
							scheduledMessage={item}
							onDeleteScheduledMessage={onDeleteScheduledMessage}
							isDeletable={checkHasAccess(
								item.orgMemberId,
								item.ownerOrgMemberId,
								isOrgAdmin
							)}
						/>
					)}
				/>
			</div>

			<div className={styles.scheduleMessageFormWrapper}>
				<MessageForm
					messageTemplates={messageTemplates}
					isScheduled
					isDisabled={!isManualSendMessageFeatureEnabled}
					isUsingTemplateDropdown
					onSetupTemplate={(formMessage: string) => {
						createQuickReplyModal([campaignId], formMessage);
					}}
					hasAcceptedAITermsOfUse={hasAcceptedAITermsOfUse}
					videoInAppValidationEnabled={videoInAppValidationEnabled}
					onScheduledSubmit={onScheduleMessage}
					automatedSteps={!isNil(nextAutomatedStep) ? [nextAutomatedStep] : []}
					chatGPTWriteRetryCallback={regenerateSmartReply}
					chatGPTWriteCallback={writeSmartReply}
					chatGPTEditCallback={editSmartReply}
					chatGPTQueryResult={smartReplyResult}
					isSubmitting={isSubmitting}
					placeholder={
						!isManualSendMessageFeatureEnabled ? manualMessageDisabledMsg : undefined
					}
				/>
			</div>
		</div>
	);
}
