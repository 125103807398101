import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import PendingOutgoing from './pendingOutgoing';
import FailedOutgoing from './failedOutgoing';
import ScheduledOutgoing from './scheduledOutgoing';
import { OutboxActions } from '@copilot/common/store/actions/outbox';
import { ScheduledMessageActions } from '@copilot/common/store/actions/scheduledMessage';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import BasicContainer from '@copilot/common/components/containers/basic';
import { Row, Col } from 'antd';
//view types - campaign, sentiment, email

interface OutboxPageProps {}

const OutboxPage: React.FC<OutboxPageProps> = () => {
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const adminMember = useSelector(OrganizationMemberSelectors.getAdminMember);
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const organizationId = activeOrganization ? activeOrganization.id : '';

	if (!activeMember) return null;
	const storeDispatch = useDispatch();
	//clear store of outgoing and schedules
	useEffect(() => {
		storeDispatch(OutboxActions.resetOutboxMessages());
		storeDispatch(ScheduledMessageActions.resetScheduledMessages());
	}, []);

	return (
		<BasicContainer>
			<BasicContainer.Header>
				<Row justify="space-between" align="middle">
					<Col span={1}>
						<h2>Outbox</h2>
						<br />
					</Col>
				</Row>
			</BasicContainer.Header>
			<>
				<div>
					<FailedOutgoing
						orgMemberId={activeMember.id}
						orgId={organizationId}
						isOrgAdmin={activeMember.isOrgAdmin && !adminMember}
					/>
					<PendingOutgoing
						orgMemberId={activeMember.id}
						orgId={organizationId}
						isOrgAdmin={activeMember.isOrgAdmin && !adminMember}
					/>
					<ScheduledOutgoing
						orgMemberId={activeMember.id}
						orgId={organizationId}
						isOrgAdmin={activeMember.isOrgAdmin}
						isImpersonate={!!adminMember}
					/>
				</div>
			</>
		</BasicContainer>
	);
};
export default OutboxPage;
