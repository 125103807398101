// Copy for the header section in the "My inbox" configuration
import { OrgRoleTitles } from '../../config/roles';

export const MY_INBOX_TITLE = 'My Inbox';
export const MY_INBOX_DESCRIPTION = 'View and manage your messages';

// Copy for the header section in the "Team inbox" configuration
export const TEAMS_INBOX_TITLE = 'Team Inbox';
export const TEAMS_INBOX_DESCRIPTION = "View and manage your team's messages";

//Placeholder copy for upsale, should be replaced/added to as part of https://cassia.atlassian.net/browse/COPILOT-7698
export const UP_SALE_COPY = 'Unlock Teams Inbox';

//Low-level UI Test ids

export const TAB_LOADER_TEST_ID = 'tab-loader';

//Empty state test ids
export const NO_MESSAGES_TEST_ID = 'no-messages-placeholder';
export const NO_MESSAGES_FOR_FILTER_TEST_ID = 'no-message-for-filter-placeholder';

//Section test ids
export const MESSAGE_LIST_TEST_ID = 'message-list';
export const MAIN_PAGE_CONTENT_TEST_ID = 'main-page-content';

/**
 * Roles that have access to the team inbox page
 */
export const RolesWithTeamInboxAccess = [OrgRoleTitles.Admin, OrgRoleTitles.Owner];
