import {
	CommunicationStyleAnalysis,
	PersonalizedInsightsManager,
} from '@copilot/data/managers/personalizedInsights';
import { Insights, isCommunicationStyle } from './types';
import { isNil } from 'lodash';

/**
 * Convert a communication style analysis to insights
 * @param record
 * @returns
 */
export function toInsights(record: CommunicationStyleAnalysis): Insights {
	const { profileDetails, analysis } = record;
	const communicationStyle = analysis.communicationStyles[0];
	if (isNil(communicationStyle) || !isCommunicationStyle(communicationStyle)) {
		throw new Error('Invalid communication style');
	}
	return {
		firstName: profileDetails.name,
		lastName: '',
		title: profileDetails.title,
		commStyle: communicationStyle,
		alias: profileDetails.alias,
	};
}

/**
 * Possible failures when generating a communication style analysis
 */
export const INSIGHT_FAILURE_CODES = {
	ERROR: 'ERROR',
	INSUFFICIENT_DATA: 'INSUFFICIENT_DATA',
	PROFILE_MISSING: 'PROFILE_MISSING',
	CONFLICT: 'CONFLICT',
} as const;

export type InsightFailureCode = typeof INSIGHT_FAILURE_CODES[keyof typeof INSIGHT_FAILURE_CODES];

/**
 * Get the failure status of a communication style analysis
 * @param analysis The analysis we want to get the failure status for
 * @returns
 */
export function getFailureStatus(
	analysisStatus?: string,
	errorStatusCode?: number
): {
	hasFailed: boolean;
	failureCode: InsightFailureCode | null;
} {
	if (errorStatusCode === 400) {
		return { hasFailed: true, failureCode: INSIGHT_FAILURE_CODES.PROFILE_MISSING };
	} else if (errorStatusCode === 409) {
		return { hasFailed: true, failureCode: INSIGHT_FAILURE_CODES.CONFLICT };
	} else if (errorStatusCode === 500 || analysisStatus === 'ERROR') {
		return { hasFailed: true, failureCode: INSIGHT_FAILURE_CODES.ERROR };
	} else if (analysisStatus === 'INSUFFICIENT_DATA') {
		return { hasFailed: true, failureCode: INSIGHT_FAILURE_CODES.INSUFFICIENT_DATA };
	} else {
		return { hasFailed: false, failureCode: null };
	}
}

/**
 * Get or generate a communication style analysis for a given profile
 * @param profileId The profile we want to get the analysis for
 * @returns The analysis
 */
export async function getInsights(profileId: string) {
	const existingsAnalysis = await PersonalizedInsightsManager.fetchCommunicationStyle(profileId);
	if (!isNil(existingsAnalysis)) {
		return existingsAnalysis;
	} else {
		return PersonalizedInsightsManager.createCommunicationStyle(profileId);
	}
}

export async function getRemainingInsightsCredits() {
	return PersonalizedInsightsManager.getRemainingCredits();
}
