import * as React from 'react';
import ModelTableModule, { IModelTableModuleProps } from '../index';
import { IOutboxMessage } from '@copilot/common/store/models/redux';
import { ThreadStatus } from '@copilot/common/store/models/const/enum';

interface OutboxTableProps extends IModelTableModuleProps<IOutboxMessage> {
	orgId: string;
}

interface OutboxTableState {}

class OutboxTable extends React.Component<OutboxTableProps, OutboxTableState> {
	constructor(props: OutboxTableProps) {
		super(props);
	}

	retrieveOutgoingMessage = () =>
		// todo get outgoing messages

		[];

	render() {
		const { ...rest } = this.props;
		// let outgoingMessage = this.retrieveOutgoingMessage(this.props.userId);
		// only flag in error table
		return (
			<ModelTableModule<IOutboxMessage>
				{...rest}
				columns={this.props.columns}
				data={this.props.data}
				title="Outgoing"
				subtitle="Messages will be sent in batches every 10-20 minutes"
				rowClassName={(record: IOutboxMessage) =>
					record?.status && record.status == ThreadStatus.Failed ? 'flag' : ''
				}
			/>
		);
	}
}

export default OutboxTable;
