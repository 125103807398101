import useInboxV2 from '../useInboxV2';
import IncludeExcludeFilter from './includeExcludeFilter/includeExcludeFilter';
import OrganizationFilterSection from '@copilot/common/components/filters/organizationFilterSection/organizationFilterSection';

/**
 * Global filter section for InboxV2
 * TODO build out properly: https://cassia.atlassian.net/browse/COPILOT-7764
 */
export default function InboxPageV2FilterSection() {
	const {
		campaignOptions,
		activeFilter,
		onCampaignFilterChange,
		enableTeamMemberFilter,
		teamMemberOptions,
		tagOptions,
		onTeamMemberFilterChange,
		onTagFilterChange,
		onClearFilters,
	} = useInboxV2();

	return (
		<OrganizationFilterSection
			campaignOptions={campaignOptions}
			onCampaignFilterChange={onCampaignFilterChange}
			enableTeamMemberFilter={enableTeamMemberFilter}
			teamMemberOptions={teamMemberOptions}
			onTeamMemberFilterChange={onTeamMemberFilterChange}
			onClearFilters={onClearFilters}
			activeFilter={activeFilter}
		>
			<IncludeExcludeFilter
				onChange={onTagFilterChange}
				selections={activeFilter.tags}
				options={tagOptions}
				placeholder="Tags"
				menuHeader="Apply a tag filter"
			/>
		</OrganizationFilterSection>
	);
}
