/**
 * The properties of the EmailListItem component
 */
export type EmailListItemProps = Readonly<{
	contactName: string;
	message: string;
	profileUrl?: string;
	timestamp: string;
	reminder: ReminderProps;
	isSelected: boolean;
	isRead: boolean;
	isDeclined: boolean;
	onClick: () => void;
	onDelete: () => Promise<void>;
}>;

export type ReminderProps = {
	hasReminder: boolean;
	reminderTimestamp?: string;
	onCancelReminder: () => Promise<void>;
	onSetReminder: (date: moment.Moment, isNewReminder: boolean) => Promise<void>;
	onReminderClicked: () => void;
};

// Copy for card action tooltips
export const LIST_ITEM_TOOLTIPS = {
	VIEW_LINKEDIN: 'View LinkedIn',
	LINKED_IN_NOT_ACCESSIBLE: 'This LinkedIn profile is not accessible',
} as const;
