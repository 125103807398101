import { LockOutlined } from '@ant-design/icons';
import styles from './restricted.module.less';
import { Typography } from 'antd';

const { Text, Title } = Typography;

/**
 * Placeholder for when the current user does not have access
 */
export default function InboxPageV2RestrictedPlaceholder() {
	return (
		<div className={styles.inboxPageV2RestrictedContainer}>
			<div className={styles.inboxPageV2RestrictedContent}>
				<LockOutlined className={styles.inboxPageV2RestrictedIcon} />

				<div className={styles.inboxPageV2RestrictedMessage}>
					<Title level={3} className={styles.inboxPageV2RestrictedTitle}>
						Access Restricted
					</Title>
					<Text className={styles.inboxPageV2RestrictedBody}>
						Your current permissions do not allow access to this page. Please reach out
						to your account admin to request the necessary permission updates for
						accessing this area.
					</Text>
				</div>
			</div>
		</div>
	);
}
