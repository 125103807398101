import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Campaigns, { CampaignsConfig, CampaignsConfigProps } from './campaigns';
import { useDispatch, useSelector } from 'react-redux';
import { getPagedCampaigns } from '../data/selectors';
import {
	fetchCampaigns,
	toggleStatus,
	updateCampaignsFilters,
	updateCampaignsPage,
} from '../data/actionCreators';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { CampaignModel } from '@copilot/common/pages/campaigns/data/models';
import { CampaignManager } from '@copilot/data';
import { useHistory } from 'react-router';
import {
	useCreateCampaign,
	useCreateCampaignForIndividual,
	useCampaignRedirectToAllocate,
} from '@copilot/common/hooks/campaign';
import { NameValidator } from '@copilot/common/components/modals/wrappers/campaignCreation/nameEditor';
import {
	canCreateCampaignIndividual,
	canCreateCampaignTeam,
	canSeeTeamMembers,
	getCampaignStatusDisplayName,
	getCampaignTypeDisplayName,
	getSimpleCampaignNameValidator,
	isCampaignEnabled,
} from '@copilot/common/utils/campaign';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import {
	LoadingProps,
	withLoading,
} from '@copilot/common/components/errorAndLoading/errorAndLoading';
import Column from 'antd/lib/table/Column';
import { createCaseInsensitiveSorter, sorterByName } from '@copilot/common/utils/common/sort';
import {
	CampaignApprovalStatus,
	CampaignLaunchErrors,
	CampaignResponse,
	CampaignType,
} from '@copilot/data/responses/interface';
import { Link } from 'react-router-dom';
import { CampaignStatusEnum } from '@copilot/data/requests/models';
import CampaignStatusDisplay from '@copilot/common/components/campaignStatusDisplay';
import { OrganizationType } from '@copilot/common/store/models/const/enum';
import { useDebounce } from '@copilot/common/hooks/common';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import { CampaignsFilters, CampaignsFiltersCallbacksType } from '../types';
import { CampaignModelState } from '../data/reducer';
import { TablePaginationConfig } from 'antd/lib/table';
import modalManager from '@copilot/common/utils/modalManager';
import { getCampaignLaunchErrorElement } from '@copilot/common/components/modals/wrappers/campaignLaunchError/errorElement';

type UserInfo = Readonly<{
	organizationId: string;
	activeMember: IOrganizationMember;
}>;

type OnStatusChangeType = (obj: CampaignModel, checked: boolean, orgType: OrganizationType) => void;

const typeRenderer = (value: any) => getCampaignTypeDisplayName(value as CampaignType);

const createLinkToTeamMembersTab = (model: CampaignModel) =>
	`/campaign/${model.id}?tab=teammembers`;

const memberRenderer = (value: any, record: CampaignModel) => (
	<Link to={createLinkToTeamMembersTab(record)} onClick={(ev) => ev.stopPropagation()}>
		{record.members.length}
	</Link>
);

interface StatusDisplayProps {
	/**
	 * Status to display
	 */
	value: CampaignStatusEnum;
	/**
	 * Campaign Record
	 */
	record: CampaignModel;
	/**
	 * Configuration for the display campaign
	 */
	config: CampaignsConfig;
	/**
	 * Handler for status changes
	 */
	onStatusChange: OnStatusChangeType;
}

/**
 * Component to display campaign status
 */
const StatusDisplay: FC<StatusDisplayProps> = ({ value, record, config, onStatusChange }) => {
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember); // TODO: get active member passed in instead
	const onChangeHandler = (checked: boolean) => {
		// setting default orgType to Enterprise to hide redirect buttons when orgType is undefined
		onStatusChange(record, checked, activeMember?.orgType ?? OrganizationType.Enterprise);
		return Promise.resolve();
	};
	return config.editRight ? (
		<CampaignStatusDisplay
			approvalStatus={record.approvalStatus}
			isEnabled={isCampaignEnabled(value)}
			isPaused={!!activeMember?.unpauseDate}
			alignment="start"
			onToggleChange={onChangeHandler}
			onToggleClick={(_, event) => event.stopPropagation()} // to disable navigation
			campaignType={record.type}
		/>
	) : (
		<>{getCampaignStatusDisplayName(value)}</>
	);
};

/**
 * Create a campaign status renderer with properties injected
 * @param config
 * @param onStatusChange
 * @returns
 */
const createStatusRenderer =
	(config: CampaignsConfig, onStatusChange: OnStatusChangeType) =>
	(value: CampaignStatusEnum, record: CampaignModel) =>
		(
			<StatusDisplay
				value={value}
				record={record}
				config={config}
				onStatusChange={onStatusChange}
			/>
		);

const statusOrderMap: { [key in CampaignStatusEnum | CampaignApprovalStatus]: number } = {
	[CampaignStatusEnum.Disabled]: 0,
	[CampaignStatusEnum.Enabled]: 1,
	[CampaignStatusEnum.Running]: 2,
	[CampaignStatusEnum.Initializing]: 3,
	[CampaignStatusEnum.Unknown]: 4,
	[CampaignApprovalStatus.Approved]: 5,
	[CampaignApprovalStatus.Disconnected]: 6,
	[CampaignApprovalStatus.Incomplete]: 7,
	[CampaignApprovalStatus.Rejected]: 8,
	[CampaignApprovalStatus.Waiting]: 9,
};

const getOrder = (campaign: CampaignResponse) => {
	if (campaign.approvalStatus == CampaignApprovalStatus.Approved)
		return statusOrderMap[campaign.status];
	else return statusOrderMap[campaign.approvalStatus];
};

const statusSorter = (a: CampaignResponse, b: CampaignResponse) => getOrder(a) - getOrder(b);

/**
 * [Container] A container component for the campaigns page.
 * @constructor
 */
const CampaignsContainerInternal: React.FC<UserInfo & CampaignsConfigProps> = ({
	organizationId,
	config,
}) => {
	const history = useHistory();
	const redirectToAllocate = useCampaignRedirectToAllocate();
	const storeDispatch = useDispatch();

	const campaigns: undefined | CampaignModelState<CampaignsFilters> =
		useSelector(getPagedCampaigns);
	const orgMembers = useSelector((state) =>
		OrganizationMemberSelectors.getOrgMembersByOrgId(state, organizationId)
	);

	const createCampaign = config.showAlertOnCampaignCreation
		? useCreateCampaignForIndividual()
		: useCreateCampaign();

	const [searchTerm, setSearchTerm] = useState<string | undefined>();
	const [teamMemberFilters, setTeamMemberFilters] = useState<FilterDefinition[]>([]);

	useEffect(() => {
		storeDispatch(fetchCampaigns(organizationId, 0, 1000));
	}, [organizationId]);

	const statusRenderer = createStatusRenderer(
		config,
		(obj: CampaignModel, checked: boolean, orgType: OrganizationType) => {
			const status = CampaignManager.convertBooltoStatus(checked);
			storeDispatch(
				toggleStatus(
					obj.id,
					status,
					obj.type,
					orgType,
					() => redirectToAllocate(obj.id, obj.type),
					(errors: CampaignLaunchErrors[]) =>
						modalManager.openCampaignLaunchErrorModal({
							issues: errors.map(getCampaignLaunchErrorElement),
						})
				)
			);
		}
	);

	const campaignNameValidator: NameValidator = getSimpleCampaignNameValidator(campaigns.data);

	const handleOnRowClick = (obj: CampaignModel): void => {
		history.push(`/campaign/${obj.id}`);
	};

	const handleSearch = useCallback(
		(term: string | undefined) => {
			if (term === undefined) return;
			storeDispatch(
				updateCampaignsFilters(
					organizationId,
					{ teamMembers: teamMemberFilters },
					term ?? ''
				)
			);
		},
		[organizationId, teamMemberFilters]
	);

	//TODO: Need to implement proper sorting in order to fetch by dynamic page and pageSize
	const handlePageChange = useCallback(
		(pagination: TablePaginationConfig) => {
			storeDispatch(
				updateCampaignsPage(organizationId, pagination.current, pagination.pageSize)
			);
		},
		[organizationId]
	);

	const debouncedFetch = useDebounce(handleSearch);

	// a side effect for search term changes
	useEffect(() => {
		debouncedFetch(searchTerm);
	}, [searchTerm]);

	useEffect(() => {
		const filters = orgMembers.map(
			(f) =>
				new FilterDefinition({
					key: f.id,
					label: `${f.firstName} ${f.lastName}`,
				})
		);
		setTeamMemberFilters(filters);
	}, [orgMembers]);

	const campaignsFiltersCallbacks: CampaignsFiltersCallbacksType = useMemo(
		() => ({
			onTeamMemberFilterUpdate: (teamMembers: FilterDefinition[]) => {
				storeDispatch(
					updateCampaignsFilters(organizationId, { teamMembers }, searchTerm ?? '')
				);
				setTeamMemberFilters(teamMembers);
			},
		}),
		[organizationId, searchTerm]
	);

	return (
		<>
			<Campaigns
				config={config}
				data={campaigns}
				campaignNameValidator={campaignNameValidator}
				onRowClick={handleOnRowClick}
				onCreateCampaign={createCampaign}
				searchTerm={searchTerm}
				onSearchTermChange={setSearchTerm}
				onSearch={handleSearch}
				filters={{ teamMembers: teamMemberFilters }}
				onUpdateFilter={campaignsFiltersCallbacks}
				onChangeTable={handlePageChange}
			>
				<Column<CampaignModel>
					title="Campaign Name"
					dataIndex="name"
					key="name"
					sorter={sorterByName}
					defaultSortOrder={searchTerm ? null : 'ascend'}
				/>
				<Column<CampaignModel>
					title="Type"
					dataIndex="type"
					key="type"
					render={typeRenderer}
					sorter={createCaseInsensitiveSorter<{ type: string }>((item) => item.type)}
				/>
				{config.showMembers && (
					<Column<CampaignModel>
						title="Team Members"
						key="campaignMembersCount"
						width="300px"
						render={memberRenderer}
						sorter={(a, b) => a.members.length - b.members.length}
					/>
				)}
				<Column<CampaignModel>
					title="Status"
					dataIndex="status"
					key="status"
					render={statusRenderer}
					sorter={statusSorter}
				/>
			</Campaigns>
		</>
	);
};

/**
 * Attaches UserInfo to the component.
 * @param Component
 */
const withUserInfo =
	<T extends UserInfo & { isLoading: boolean }>(Component: React.FC<T>) =>
	(props: T) => {
		const organizationId = useSelector(OrganizationSelectors.getActiveOrganizationId);
		const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
		return (
			<Component
				{...props}
				organizationId={organizationId}
				activeMember={activeMember}
				isLoading={!organizationId || !activeMember}
			/>
		);
	};

type WithConfigType = <T extends CampaignsConfigProps & UserInfo>(
	Component: React.FC<T>
) => (props: T) => JSX.Element;

/**
 * Creates a campaigns container component with individual capability applied.
 * @param Component
 */
export const withIndividualCapability: WithConfigType =
	<T extends CampaignsConfigProps & UserInfo>(Component: React.FC<T>) =>
	(props: T) => {
		const { activeMember } = props;
		const config: CampaignsConfig = {
			editRight: canCreateCampaignIndividual(activeMember),
			showAlertOnCampaignCreation: true,
			showMembers: false,
			canCreateNurture: false,
		};
		return <Component {...props} config={config} />;
	};

/**
 * Creates a campaigns container component with team capability applied.
 * @param Component
 */
export const withTeamCapability: WithConfigType =
	<T extends CampaignsConfigProps & UserInfo>(Component: React.FC<T>) =>
	(props: T) => {
		const { activeMember } = props;
		const canCreate = canCreateCampaignTeam(activeMember);
		const canSeeMembers = canSeeTeamMembers(activeMember);
		const config: CampaignsConfig = {
			editRight: canCreate,
			showAlertOnCampaignCreation: false,
			showMembers: canSeeMembers,
			canCreateNurture: true,
		};
		return <Component {...props} config={config} />;
	};

/**
 * Creates a campaigns container with config.
 * @param withConfig
 */
export const createCampaignsContainerWithConfig = (
	withConfig: WithConfigType
): React.FC<CampaignsConfigProps & UserInfo & LoadingProps> =>
	withUserInfo(withLoading(withConfig(CampaignsContainerInternal)));
