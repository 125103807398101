import { CommunicationStyle } from '../../services/types';
import { getCommunicationClass } from '../body/util';
import styles from './commStyleWheel.module.less';

type WheelSize = 'small' | 'medium';

type CommStyleWheelProps = {
	size?: WheelSize;
	selectedCommStyle?: CommunicationStyle;
};
/**
 * Communication Style Wheel component used for insights page
 * @param param0
 * @returns
 */
export function CommStyleWheel({ size = 'small', selectedCommStyle }: CommStyleWheelProps) {
	function getSizeClass() {
		switch (size) {
			case 'small':
				return styles.small;
			case 'medium':
				return styles.medium;
			default:
				'';
		}
	}
	return (
		<div
			className={[
				styles.wrapper,
				getSizeClass(),
				getCommunicationClass(selectedCommStyle, styles),
			].join(' ')}
		>
			<div className={styles.row}>
				<div className={styles.director} />
				<div className={styles.socializer} />
			</div>
			<div className={styles.row}>
				<div className={styles.thinker} />
				<div className={styles.relator} />
			</div>
			<div className={styles.centerDot} />
		</div>
	);
}
