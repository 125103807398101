import moment, { Moment } from 'moment';
import { UseScheduleMessagesTabV2Data } from './types';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useSelector } from 'react-redux';
import { ScheduledMessage } from '@copilot/common/store/models/redux';
import { useContext, useEffect, useState } from 'react';
import ContactDrawerV2Context from '../../../context/contactDrawerV2Context';
import isNil from 'lodash/isNil';
import { AIFeatureManager, ActivityManager, OutboxManager } from '@copilot/data';
import { useSmartReply } from '@copilot/common/hooks/smartReply';
import { MessageTemplateSelectors } from '@copilot/common/store/selectors/messageTemplate';
import { useQuickResponseModal } from '@copilot/common/hooks/quickResponse';
import { useTermsOfUse } from '@copilot/common/hooks/termsOfUse';
import { Features, TermsOfUse } from '@copilot/data/responses/interface';
import { Config } from '@copilot/common/config';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import notificationManager from '@copilot/common/utils/notificationManager';
import { formatNameFromObject } from '@copilot/common/utils';
import { SchedulingStatus, ThreadStatus } from '@copilot/common/store/models/const/enum';
import { useProspectDrawerTracking } from '@copilot/common/components/drawer/wrappers/contact/tracking';

/**
 * Wraps business logic for the schedule message tab, providing a facade layer for redux tech debt
 * @returns
 */
export default function useScheduleMessageTabV2(): UseScheduleMessagesTabV2Data {
	const { campaignConnection, organizationId, orgMemberId, orgContact, nextAutomatedStep } =
		useContext(ContactDrawerV2Context);
	const activeOrgMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const [createQuickReplyModal] = useQuickResponseModal();
	const [scheduledMessages, setScheduledMessages] = useState<ScheduledMessage[]>([]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const onTracking = useProspectDrawerTracking('Prospect Drawer');

	const { hasAccepted: hasAcceptedAITermsOfUse } = useTermsOfUse(
		TermsOfUse.AIFeature,
		AIFeatureManager.acceptTermsOfUse
	);

	const videoInAppValidationEnabled =
		!Config.isAgency && useFeatureToggle(Features.VideoValidationFeature);

	const messageTemplates = useSelector((state) =>
		MessageTemplateSelectors.getTemplatesByCampaignId(
			state,
			campaignConnection?.campaignId ?? ''
		)
	);

	//smart reply callbacks/state
	const [smartReplyResult, { writeSmartReply, regenerateSmartReply, editSmartReply }] =
		useSmartReply(organizationId ?? '', orgMemberId ?? '', orgContact?.id ?? '');

	useEffect(() => {
		async function fetchScheduledMessages() {
			if (!isNil(campaignConnection) && !isNil(orgContact)) {
				try {
					const paginatedMessageResp = await OutboxManager.getScheduledMessagesForContact(
						campaignConnection.orgMemberId,
						orgContact.id
					);
					setScheduledMessages(paginatedMessageResp.results);
				} catch (e) {}
			}
		}
		fetchScheduledMessages();
	}, [campaignConnection, orgContact]);

	async function onScheduleMessage(
		msg: string,
		selectedDate: Moment,
		templateId?: string
	): Promise<void> {
		if (isNil(orgContact) || isNil(orgMemberId) || isNil(campaignConnection)) return;
		try {
			setIsSubmitting(true);
			if (msg.trim() != '') {
				const sendDate = selectedDate.toISOString();
				const response = await ActivityManager.submitScheduledMsgToLinkedInProfile(
					orgContact.id,
					orgMemberId,
					msg,
					sendDate,
					templateId,
					campaignConnection.campaignId
				);
				onTracking?.({ buttonClicked: 'Schedule Message' });

				const scheduledMessage = {
					name: formatNameFromObject(orgContact),
					status: ThreadStatus.Pending,
					threadId: '',
					message: response.message,
					timestamp: `${new Date(response.triggerDateTime)}`,
					dateCreated: `${new Date()}`,
					error: SchedulingStatus.OnSchedule,
					contactId: orgContact.id,
					id: response.id,
					orgMemberId,
					ownerOrgMemberId: orgMemberId,
				} as ScheduledMessage;
				setScheduledMessages((prev) =>
					[...prev, scheduledMessage].sort((a, b) =>
						moment(a.timestamp).isBefore(moment(b.timestamp)) ? -1 : 1
					)
				);
			}
		} catch (e) {
			notificationManager.showErrorNotification({
				message:
					'There was an issue scheduling your message. Please try again later or contact support.',
			});
		}
		setIsSubmitting(false);
	}

	async function onDeleteScheduledMessage(scheduledMessage: ScheduledMessage): Promise<void> {
		if (isNil(orgContact)) return;
		try {
			void (await ActivityManager.deleteScheduledMsgFromLinkedInProfile(
				orgContact.id,
				scheduledMessage.id
			));
			setScheduledMessages((prev) => prev.filter((msg) => msg.id !== scheduledMessage.id));
		} catch (err) {
			notificationManager.showErrorNotification({
				message: 'Message Error',
				description: 'Unable to delete message',
			});
		}
	}

	return {
		onScheduleMessage,
		onDeleteScheduledMessage,
		scheduledMessages,
		isOrgAdmin: activeOrgMember?.isOrgAdmin ?? false,
		smartReplyResult,
		writeSmartReply,
		regenerateSmartReply,
		editSmartReply,
		messageTemplates,
		createQuickReplyModal,
		campaignId: campaignConnection?.campaignId ?? '',
		nextAutomatedStep,
		hasAcceptedAITermsOfUse,
		videoInAppValidationEnabled,
		isSubmitting,
	};
}
