import { useContext } from 'react';
import MeetingsBookedContext from './meetingsBookedContext';

/**
 * Hook wrapper for meetingsBooked context
 * @returns
 */
export default function useMeetingsBookedContext() {
	const contextData = useContext(MeetingsBookedContext);

	return { ...contextData };
}
