import { Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from '../../meetingsBooked/tables/meetingTables.module.less';
import { BlocklistEntry } from './types';
import moment from 'moment';
import { useState } from 'react';
import { DEFAULT_BLOCKLIST_PAGE_SIZE } from './constants';
import { TablePaginationConfig } from 'antd/lib';
import isNumber from 'lodash/isNumber';

type BaseMeetingTableProps = Readonly<{
	data: ReadonlyArray<BlocklistEntry>;
	isLoading: boolean;
}>;

/**
 * Table containing blocklist data
 * @param data
 * @param isLoading
 * @param pageSize
 * @constructor
 */
export default function BlocklistTable({ data, isLoading }: BaseMeetingTableProps) {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(DEFAULT_BLOCKLIST_PAGE_SIZE);

	function onTableUpdate(pagination: TablePaginationConfig) {
		if (pageSize !== pagination.pageSize && typeof pagination.pageSize === 'number') {
			setPageSize(pagination.pageSize);
			setCurrentPage(1);
		} else {
			setCurrentPage(pagination.current ?? 1);
		}
	}

	return (
		<Table
			dataSource={data}
			loading={{
				spinning: isLoading,
				indicator: <LoadingOutlined className={styles.tableLoader} />,
			}}
			pagination={{
				pageSize,
				total: data.length,
				current: currentPage, // TODO: Implement proper pagination when more data mocks
			}}
			onChange={onTableUpdate}
			columns={[
				{
					title: 'Date Added',
					dataIndex: 'date',
					render: (date) => {
						if (!isNumber(date)) return undefined;
						return moment(date).format('MM/DD/YYYY');
					},
					width: '25%',
				},
				{
					title: 'Company Name',
					dataIndex: 'company',
					width: '25%',
				},
				{
					title: 'Apply to',
					dataIndex: 'teamMember',
					render: (teamMember) => teamMember.name,
					width: '25%',
				},
				{
					title: 'Condition',
					dataIndex: 'condition',
					width: '25%',
				},
			]}
		/>
	);
}
