import { ApolloError } from '@apollo/client';
import { groupBy, isNil, keyBy } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	toCampaignAttentionRequiredItem,
	toOrgMemberAttentionRequiredItem,
} from '@copilot/common/components/lists/attentionRequired/converters';
import { isProspectingCampaignStats } from '@copilot/common/pages/campaignDashboard/helpers';
import {
	ICampaignAttentionItem,
	ICampaignStatsOwnerType,
	IOrgMembersAndCampaignsQuery,
	useOrganizationStatsQuery,
	useOrgMembersAndCampaignsQuery,
} from '@copilot/data/graphql/_generated';
import { generateAllOrgMembers, LoadTeamMembersAction } from '../../orgTeamMembers/data/saga';
import { getAllOrganizationTeamMembers } from '../../orgTeamMembers/data/selectors';
import { ActiveMember } from '../organizationActiveMemberTable';
import {
	CampaignStats,
	ProspectingCampaignStats,
} from '@copilot/common/pages/organizationDashboard/summaryV2/util-functions';

type OrgMember = ArrayElement<IOrgMembersAndCampaignsQuery['orgMembers']>;

type OrgMemberWithStats = ActiveMember;
type CampaignAttentionItemWithCampaignInfo = Readonly<{
	attentionItem: ICampaignAttentionItem;
	campaignName: string;
	campaignId: string;
	orgMemberId: string;
}>;

const toOrgMemberWithStats = (
	name: string,
	orgMember: OrgMember,
	campaignItems: CampaignAttentionItemWithCampaignInfo[],
	linkedInReconnectCallback: (orgMemberId: string) => void,
	stats?: ProspectingCampaignStats
): ActiveMember => {
	const orgAttentionItems = orgMember.attentionItems.map((item) =>
		toOrgMemberAttentionRequiredItem(item, linkedInReconnectCallback)
	);
	const campaignAttentionItems = campaignItems.map((item) =>
		toCampaignAttentionRequiredItem(item.attentionItem, item.campaignName, item.campaignId)
	);
	const attentionItems = [...orgAttentionItems, ...campaignAttentionItems];
	return {
		id: orgMember.id,
		attentionRequiredItems: attentionItems,
		isLinkedInLoggedOut: orgMember.linkedInErrors.loggedOut,
		connectionRate: stats?.percentConnected ?? 0,
		replyRate: stats?.percentReplied ?? 0,
		name,
		meetingsBooked: stats?.numMeetingsBooked ?? 0,
	};
};

export type OrganizationStats = Readonly<{
	orgStats?: CampaignStats;
	orgMembers: OrgMemberWithStats[];
	error?: ApolloError;
	loading: boolean;
}>;

/**
 * Return all stats for an organization
 * @param organizationId The organization we want to get the stats for
 * @param startMS MS in epoch to start the stats range from
 * @param endMS MS in epoch to end the stats range at
 */
export const useOrganizationStats = (
	organizationId: string,
	linkedInReconnectCallback: (orgMemberId: string) => void,
	startMS?: number,
	endMS?: number
): OrganizationStats => {
	// Get the organization members from webapi
	const dispatch = useDispatch();
	useEffect(() => {
		if (organizationId) {
			dispatch(LoadTeamMembersAction(generateAllOrgMembers, organizationId));
		}
	}, [organizationId]);
	const orgMembersFromStore = useSelector(getAllOrganizationTeamMembers);

	// Generate a map of org member id -> org member
	const orgMembersMap = keyBy(orgMembersFromStore.data, (member) => member.id);

	// Get org members and campaign summaries
	const orgMembersAndCampaignsResult = useOrgMembersAndCampaignsQuery({
		variables: {
			organizationId,
		},
	});
	// Campaign summaries
	const campaignSummaryResults = orgMembersAndCampaignsResult.data?.campaignSummaries ?? [];
	// Org members
	const orgMemberResults = orgMembersAndCampaignsResult.data?.orgMembers ?? [];

	// Campaign members from summary
	const campaignMembers = campaignSummaryResults.flatMap((summary) => summary.members);
	// Map of campaign member id -> campaign member
	const campaignMemberByIdMap = keyBy(campaignMembers, (member) => member.id);

	// Get all of the campaign attention items with the relevant campaign info
	const campaignAttentionItemsWithCampaignInfo: CampaignAttentionItemWithCampaignInfo[] =
		campaignSummaryResults.flatMap<CampaignAttentionItemWithCampaignInfo>((summary) => {
			return summary.attentionItems.flatMap((attentionItem) => ({
				attentionItem,
				campaignId: summary.id ?? '',
				campaignName: summary.name ?? '',
				orgMemberId: campaignMemberByIdMap[attentionItem.memberId]?.orgMemberId,
			}));
		}) ?? [];

	// Map of org member id -> campaign attention items
	const campaignAttentionItemsByOrgMemberId: Record<
		string,
		CampaignAttentionItemWithCampaignInfo[]
	> = groupBy(campaignAttentionItemsWithCampaignInfo, (info) => info.orgMemberId);

	// Org members that are active
	const activeMembers = orgMemberResults.filter((orgMember) => orgMember.isActive);

	// Get stats for the organization and active organization members
	const { data, ...queryResults } = useOrganizationStatsQuery({
		variables: {
			organizationId,
			orgMemberIds: activeMembers?.map((m) => m.id) ?? [],
			start: startMS,
			end: endMS,
		},
		skip: isNil(activeMembers),
	});

	// Active org members with stats
	let orgMembers: OrgMemberWithStats[] = [];
	// Organization level stats
	let orgStats: CampaignStats | undefined;
	if (!isNil(data)) {
		// Group organization stats by ownertype (organization OR organization member)
		const statsByType = groupBy(data.campaignStats, (stat) => stat.ownerType);
		// Get the organization stats
		orgStats = statsByType[ICampaignStatsOwnerType.Organization]?.[0];
		// Map org member id -> org member stats
		const statsByOrgMembers = keyBy(
			statsByType[ICampaignStatsOwnerType.OrganizationMember],
			(stat) => stat.ownerId
		);
		// Set org members to be active members with stats and attention items
		orgMembers =
			activeMembers.map((orgMember): OrgMemberWithStats => {
				const name = orgMembersMap[orgMember.id]?.name;
				const foundStats = statsByOrgMembers[orgMember.id];
				const stats = isProspectingCampaignStats(foundStats) ? foundStats : undefined;
				return toOrgMemberWithStats(
					name,
					orgMember,
					campaignAttentionItemsByOrgMemberId[orgMember.id] ?? [],
					linkedInReconnectCallback,
					stats
				);
			}) ?? [];
	}

	return {
		...queryResults,
		orgStats,
		orgMembers,
		error: orgMembersAndCampaignsResult.error ?? queryResults.error,
		loading: orgMembersAndCampaignsResult.loading || queryResults.loading,
	};
};
