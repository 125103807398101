export const DEFAULT_MEETING_BOOKED_PAGE_SIZE = 10;

/**
 * Offset to compensate for the pagination section, white space, when determining table viewport size
 */
export const VIRTUAL_SCROLL_OFFSET = 114;

/**
 * Constant representing the smallest table width we will support (needed to maintain a semblance of readability on really small screens)
 */
export const MIN_TABLE_WIDTH = 600;
