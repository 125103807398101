import { Alert, Tag, Typography } from 'antd';
import { QuestionCircleOutlined, CheckCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { CSSProperties, FC, ReactElement, useEffect, useState } from 'react';
import { GeneralSuggestionBox } from './generalSuggestionBox';
import { PointedNotes } from './pointedNotes';
import { SpecificSuggestions } from './specificSuggestions';
import { UserBulbOutlined } from './userBulbOutlined';
import { useParams } from 'react-router';
import { URL } from '@copilot/common/data/urls';
import { PersonalityInsightsResponse } from '@copilot/data/responses/interface';
import { ReactComponent as DefaultProfilePicture } from '@copilot/common/assets/icon/linkedinDefaultProfilePicture.svg';
import { PersonalizedInsightsManager } from '@copilot/data/managers/personalizedInsights';
import { pluralString } from '@copilot/common/utils/stringFormat';
import { OceanAssessment } from './oceanAssessment';
import { PersonalizedInsightsLoadingScreen } from './loadingScreen/loadingScreen';
import { useFetch, usePollUntilTimeout } from '@copilot/common/hooks/common';
import { AxiosResponse } from 'axios';

const INSUFFICIENT_DATA_STATUS = 'INSUFFICIENT_DATA';
const NOT_FOUND_STATUS = 'NOT_FOUND';

/**
 * await this function to delay by some number of milliseconds
 * @param ms
 * @returns
 */
export const timedDelay = async (ms: number) => new Promise((res) => setTimeout(res, ms));

export const personalizedInsightsSubheadingFontSize: CSSProperties = {
	fontSize: '24px',
};

export const boldFontStyle: CSSProperties = {
	fontWeight: 'bold',
};

export const flexSpaceBetweenStyle: CSSProperties = {
	display: 'flex',
	justifyContent: 'space-between',
};

const insightsPageGreyBackground = 'rgba(232, 239, 248, 0.4)';

const InsightHeader = ({ remainingCredits }: { remainingCredits: number }) => (
	<div style={{ height: '40px', backgroundColor: '#22BAB4', textAlign: 'center' }}>
		<p style={{ color: 'white', padding: '6px' }}>
			⭐️ {remainingCredits} {pluralString('more insight', remainingCredits)} available this
			month
		</p>
	</div>
);

type InsightsPageStyledTagProps = {
	text: string;
	additionalStyle?: CSSProperties;
};

export const InsightsPageStyledTag: FC<InsightsPageStyledTagProps> = ({
	text,
	additionalStyle,
}) => (
	<Tag
		style={{
			color: '#0056C0',
			backgroundColor: '#B9D8FF',
			padding: '10px',
			borderRadius: '6px',
			fontSize: '14px',
			marginRight: '12px',
			fontWeight: 'bold',
			display: 'inline-block',
			border: 'none',
			...additionalStyle,
		}}
	>
		{text}
	</Tag>
);

type ProfileSectionProps = {
	profileImageURL: string;
	displayName: string;
	location: string;
	position: string;
	adjectives: string[];
	description: string;
};

const ProfileSection: FC<ProfileSectionProps> = ({
	profileImageURL,
	displayName,
	adjectives,
	description,
}) => {
	const imageStyling: CSSProperties = {
		width: '185px',
		height: '185px',
		border: '4px solid #C3CFE1',
		borderRadius: '300px',
		gridColumn: 2,
		marginTop: '6px',
	};

	return (
		<div style={{ backgroundColor: 'rgba(233, 243, 255, 0.5)', padding: '75px 0' }}>
			<div style={{ display: 'grid', gridTemplateColumns: 'auto 185px 34px 646px auto' }}>
				{profileImageURL ? (
					<img src={profileImageURL} style={imageStyling} />
				) : (
					<DefaultProfilePicture style={imageStyling} />
				)}

				<div style={{ gridColumn: 4 }}>
					<div>
						<span
							style={{ ...personalizedInsightsSubheadingFontSize, ...boldFontStyle }}
						>
							{displayName}
						</span>
						{/* Potentially to be reinserted in the future + {location,position} added to profileSelection Props*/}
						{/* <span style={{ color: '#515F74' }}>
                            <Icon style={{ fontSize: '18px', marginLeft: '12px' }} icon='mdi:map-marker'/>
                            <span style={{ verticalAlign: 'text-bottom' }}> {location}</span>
                        </span> */}
					</div>
					{/* Potentially to be reinserted in the future */}
					{/* <div> 
                        <p style={{ color: '#0056C0', margin: '0' }}>{position}</p>
                    </div> */}
					<div style={{ marginTop: '12px' }}>
						{adjectives.map((adjective) => (
							<InsightsPageStyledTag key={adjective} text={adjective} />
						))}
					</div>
					<div
						style={{
							backgroundColor: 'rgba(195, 207, 225, 0.25)',
							padding: '10px',
							borderRadius: '5px',
							fontSize: '14px',
							marginTop: '16px',
						}}
					>
						<p style={{ margin: '0' }}>{description}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const StyledAlert = () => (
	<Alert
		message={
			<Typography.Text style={{ display: 'flex' }}>
				<QuestionCircleOutlined
					style={{ marginRight: '40px', fontSize: '30px', alignSelf: 'center' }}
				/>
				This insight report is generated using an Ocean Assessment methodology, assessing
				the data points available from the prospect's LinkedIn profile and publicly
				available data.
			</Typography.Text>
		}
		style={{
			backgroundColor: insightsPageGreyBackground,
			borderRadius: '10px',
			gridColumn: 2,
			padding: '24px 40px',
			margin: '70px 0 50px',
			border: 'none',
		}}
	/>
);

type StyledRegionProps = {
	additionalStyle?: CSSProperties;
};

const StyledRegion: FC<StyledRegionProps> = ({ additionalStyle, children }) => (
	<div style={{ backgroundColor: insightsPageGreyBackground, ...additionalStyle }}>
		{children}
	</div>
);

type MidSectionWithHeadingProps = {
	headerText: string;
	additionalHeaderIcon?: ReactElement;
	bodyText?: string;
};

const MidSectionWithHeading: FC<MidSectionWithHeadingProps> = ({
	headerText,
	additionalHeaderIcon,
	children,
}) => (
	<div style={{ gridColumn: 2 }}>
		<h2 style={{ fontWeight: 'bold', marginBottom: '50px' }}>
			<span>{headerText}</span>
			<span style={{ marginLeft: '12px' }}>{additionalHeaderIcon}</span>
		</h2>
		<div style={{ marginBottom: '50px' }}>{children}</div>
	</div>
);

interface PersonalityInsightsPageRouteParams {
	linkedInAlias: string;
}

export const PersonalityInsightsPage: FC = () => {
	const [shouldCreateIfNotFound, setShouldCreateIfNotFound] = useState<boolean>(true);
	const [hasLoadingFailed, setHasLoadingFailed] = useState<boolean>(false);
	const [loadingFailureReason, setLoadingFailureReason] = useState<string>('');

	const params = useParams<PersonalityInsightsPageRouteParams>();
	const linkedInAlias = params.linkedInAlias;
	const profileId = `${URL.LINKEDIN_URL}/in/${linkedInAlias}`;
	const [{ data, error }, fetchInsights] = useFetch<
		AxiosResponse<PersonalityInsightsResponse>,
		unknown
	>(PersonalizedInsightsManager.fetchAnalysis);
	const [creationResponse, createInsights] = useFetch(PersonalizedInsightsManager.createAnalysis);

	const insights = data?.data;
	const insightPersona = insights?.analysis?.persona.sales;
	const communicationAdvice = insightPersona?.communication_advice;
	const emailPersonalization = insightPersona?.email_personalization;
	const oceanAssessment = insights?.analysis?.personality_analysis.ocean_assessment;

	const insufficientDataMessage =
		"Uh oh... It seems like this LinkedIn profile doesn't have enough information to generate insights.";
	const profileNotFoundMessage = "Hmm... It looks like this profile doesn't exist anymore.";
	const stillInProgressMessage =
		'We apologize for the inconvenience caused, but our server load is currently experiencing delays in processing your request. Please kindly refresh this page at a later time, thank you!';

	const cancelPolling = usePollUntilTimeout(
		() => {
			fetchInsights(profileId);
		},
		15000,
		90000,
		() => {
			setHasLoadingFailed(true);
			setLoadingFailureReason(stillInProgressMessage);
		}
	);

	useEffect(() => {
		if (insights?.analysis) {
			cancelPolling();
		} else if (insights?.analysisStatus === INSUFFICIENT_DATA_STATUS) {
			cancelPolling();
			setHasLoadingFailed(true);
			setLoadingFailureReason(insufficientDataMessage);
		} else if (insights?.analysisStatus === NOT_FOUND_STATUS) {
			cancelPolling();
			setHasLoadingFailed(true);
			setLoadingFailureReason(profileNotFoundMessage);
		} else if (error?.status === 404 && shouldCreateIfNotFound) {
			createInsights(profileId);
			setShouldCreateIfNotFound(false);
		}
	}, [insights, profileId, error?.status]);

	useEffect(() => {
		if (creationResponse.error) {
			setHasLoadingFailed(true);
			cancelPolling();
		}
	}, [creationResponse.error]);

	useEffect(() => {
		fetchInsights(profileId);
	}, []);

	const clearEmptyProperties = (object: { [key: string]: string }) => {
		for (const key of Object.keys(object)) {
			if (object[key] === '') {
				delete object[key];
			}
		}
		return object;
	};

	return (
		<div>
			{insights?.analysis ? (
				<div>
					<InsightHeader remainingCredits={insights.credits.available} />
					<ProfileSection
						profileImageURL={insights.analysis.user_profile_image}
						displayName={insights.analysis.display_name}
						location={insights.analysis.location}
						position={insights.analysis.latest_title}
						adjectives={communicationAdvice?.adjectives ?? []}
						description={(communicationAdvice?.description ?? []).join(' ')}
					/>
					<div style={{ display: 'grid', gridTemplateColumns: 'auto 865px auto' }}>
						<StyledAlert />
						<MidSectionWithHeading headerText="When writing messages">
							<div style={{ ...flexSpaceBetweenStyle, marginBottom: '45px' }}>
								<GeneralSuggestionBox
									suggestions={communicationAdvice?.what_to_say ?? []}
									icon={<CheckCircleFilled style={{ color: '#2EB88F' }} />}
									title="DO"
									titleStyles={{ color: '#006763' }}
									boxStyles={{ backgroundColor: '#EAF8F8', width: '47.5%' }}
								/>
								<GeneralSuggestionBox
									suggestions={communicationAdvice?.what_to_avoid ?? []}
									icon={<MinusCircleFilled style={{ color: '#CD4141' }} />}
									title={"DON'T"}
									titleStyles={{ color: '#4B0000' }}
									boxStyles={{ backgroundColor: '#FFE5E5', width: '47.5%' }}
								/>
							</div>
							<StyledRegion additionalStyle={{ padding: '36px 100px 20px' }}>
								<div
									style={{
										marginBottom: '10px',
										display: 'grid',
										gridTemplateColumns: 'auto 50px max-content auto ',
									}}
								>
									<span
										style={{
											transform: 'translateX(-25px)',
											gridColumn: 2,
											display: 'inherit',
										}}
									>
										<UserBulbOutlined />
									</span>
									<p
										style={{
											textAlign: 'center',
											...personalizedInsightsSubheadingFontSize,
											marginBottom: '15px',
											gridColumn: 3,
											...boldFontStyle,
										}}
									>
										{emailPersonalization?.advice['Overall Messaging']}
									</p>
								</div>
								<div>
									<PointedNotes
										notes={Object.values(communicationAdvice?.key_traits ?? {})}
										listStyles={{ ...flexSpaceBetweenStyle, flexWrap: 'wrap' }}
										noteStyles={{ width: '47%' }}
									/>
								</div>
							</StyledRegion>
						</MidSectionWithHeading>
						<MidSectionWithHeading headerText="Message Examples">
							<StyledRegion additionalStyle={{ padding: '12px 48px 36px' }}>
								<div style={{ ...flexSpaceBetweenStyle, flexWrap: 'wrap' }}>
									<SpecificSuggestions
										suggestions={clearEmptyProperties({
											...emailPersonalization?.examples,
										})}
									/>
								</div>
							</StyledRegion>
						</MidSectionWithHeading>
						<MidSectionWithHeading headerText="OCEAN Assessment">
							<StyledRegion additionalStyle={{ padding: '1px 160px' }}>
								<OceanAssessment oceanAssessment={oceanAssessment} />
							</StyledRegion>
						</MidSectionWithHeading>
					</div>
				</div>
			) : (
				<PersonalizedInsightsLoadingScreen
					hasLoadingFailed={hasLoadingFailed}
					loadingFailureReason={loadingFailureReason}
				/>
			)}
		</div>
	);
};
