import React from 'react';
import { useSelector } from 'react-redux';
import BasicContainer from '@copilot/common/components/containers/basic';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import Albato from '@copilot/common/components/snippets/albato';
import { RootState } from '@copilot/common/store/reducers';
import { Button, Col, Dropdown, MenuProps, Row } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react';
import { newWindowIcon } from '@copilot/common/constant/icons';
import Link from 'antd/lib/typography/Link';
import { Config } from '@copilot/common/config';

interface IOrganizationCrmSettingsProps {
	organizationId: string;
}

const items: MenuProps['items'] = [
	{
		label: (
			<Link href={Config.crmIntegrationHelpUrl} target="_blank">
				<Icon icon={newWindowIcon} />
				&nbsp;&nbsp;
				<span>How to set up an integration</span>
			</Link>
		),
		key: '0',
	},
	{
		label: (
			<Link href={Config.crmIntegrationSurveyUrl} target="_blank">
				<Icon icon={newWindowIcon} />
				&nbsp;&nbsp;
				<span>Request an integration</span>
			</Link>
		),

		key: '1',
	},
];

/**
 * Organization dashboard tab that displays an admin's CRM configuration for the account
 * @param props
 * @returns
 */
const OrganizationCrmConfiguration: React.FC<IOrganizationCrmSettingsProps> = (props) => {
	const { organizationId } = props;
	const organization = useSelector(OrganizationSelectors.getOrganization(organizationId));

	const currentUserTenantId = useSelector((state: RootState) => state.app.tenant) ?? '';
	if (!organization) return null;
	return (
		<BasicContainer>
			<BasicContainer.Header>
				<Row justify="space-between" align="middle">
					<Col style={{ fontSize: '16px' }}>Integrations</Col>
					<Col>
						<Dropdown menu={{ items }} trigger={['click']}>
							<Button>
								<QuestionCircleOutlined />
								Get help
							</Button>
						</Dropdown>
					</Col>
				</Row>
			</BasicContainer.Header>
			<BasicContainer.Content>
				<Albato tenantId={currentUserTenantId}></Albato>
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default OrganizationCrmConfiguration;
