import { useInfoPanelV2 } from './hooks/useInfoPanelV2';
import InfoPanelV2 from './infoPanelV2';

/**
 * Component responsible for fetching the data for managing the data for the information panel in the contact drawer
 * @returns
 */
export default function InfoPanelV2Smart() {
	const infoPanelData = useInfoPanelV2();
	return <InfoPanelV2 {...infoPanelData} />;
}
