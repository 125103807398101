import React, { useCallback, useState } from 'react';
import PasswordRecoveryForm from '@copilot/common/components/forms/user/passwordRecovery';
import { Alert, Button } from 'antd';
import { Config } from '@copilot/common/config';
import { OrganizationMemberManager } from '@copilot/data';
import BasicWizardContainer from '@copilot/common/components/wizard/basic/wizardContainer';
import { useFetch } from '@copilot/common/hooks/common';

enum RecoverPasswordWizardSteps {
	SendEmail,
	Done,
}

interface PasswordRecoveryPageProps {
	isIndividualUser: boolean;
}

/**
 * [Smart] Page for password recovery
 * @param isIndividualUser wheather user is an individual user or not
 */
const PasswordRecoveryPage: React.FC<PasswordRecoveryPageProps> = (props) => {
	const { isIndividualUser } = props;
	const [errorMessage, setErrorMessage] = React.useState<string>();
	const [wizardStep, setWizardStep] = useState<RecoverPasswordWizardSteps>(
		RecoverPasswordWizardSteps.SendEmail
	);
	const [resetPasswordFetch, fetchResetPassword] = useFetch(
		OrganizationMemberManager.resetPassword
	);

	const resetPassword = useCallback(
		async (email: string) => {
			try {
				await fetchResetPassword(email, isIndividualUser);
				setWizardStep(RecoverPasswordWizardSteps.Done);
			} catch (err) {
				setErrorMessage('Failed to submit. Please try again.');
			}
		},
		[isIndividualUser]
	);

	return (
		<BasicWizardContainer farthestNode={wizardStep}>
			<BasicWizardContainer.Step id={RecoverPasswordWizardSteps.SendEmail}>
				<BasicWizardContainer.Step.Content title="Recover Password">
					{errorMessage && (
						<Alert
							message={errorMessage}
							type="error"
							showIcon
							style={{ marginBottom: '20px', paddingLeft: '44px', textAlign: 'left' }}
						/>
					)}
					<PasswordRecoveryForm
						onSubmit={resetPassword}
						isSubmitting={resetPasswordFetch.isFetching}
					/>
				</BasicWizardContainer.Step.Content>
			</BasicWizardContainer.Step>
			<BasicWizardContainer.Step id={RecoverPasswordWizardSteps.Done}>
				<BasicWizardContainer.Step.Content title="Recover Password">
					<p style={{ textAlign: 'left', marginBottom: '30px' }}>
						We have sent an email to the address you have entered. If the address
						matches an account, there will be an email from us in your inbox
					</p>
				</BasicWizardContainer.Step.Content>
			</BasicWizardContainer.Step>
			<BasicWizardContainer.Footer>
				<Button type="link" href="/login">{`Return to ${Config.agencyName} login`}</Button>
			</BasicWizardContainer.Footer>
		</BasicWizardContainer>
	);
};
export default PasswordRecoveryPage;
