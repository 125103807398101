import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
	LinkedinOutlined,
	MailOutlined,
	PauseCircleOutlined,
	UserOutlined,
} from '@ant-design/icons';
import BasicContainer from '@copilot/common/components/containers/basic';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import Store from '@copilot/common/store';
import UserSettings from './user/userSettings';
import LinkedInLogIn from './linkedin/linkedInLogin';
import EmailSettings from './email';
import PauseSettings from './pause';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import isNil from 'lodash/isNil';
import { OrganizationMemberManager } from '@copilot/data';
import { IProxy } from '@copilot/common/store/models/redux';
import { Tabs } from 'antd';
import { useMemo } from 'react';
import styles from './settingsPage.module.less';
import { HostedAuthCompletionModal } from '@copilot/common/pages/settings/linkedInAuthResults';

type SettingsPageProps = RouteComponentProps<{ tab?: string }> & {
	hideEmails?: boolean;
	hideLinkedIn?: boolean;
	hidePause?: boolean;
	hideUser?: boolean;
};

const containerPadding = '20px';

function SettingsPage(props: SettingsPageProps) {
	const { tab = 'user' } = props.match.params;
	const { hideEmails, hideLinkedIn, hidePause, hideUser } = props;
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const dispatch = useDispatch();

	function onTabChange(key: string) {
		const path = key === 'user' ? '/settings' : `/settings/${key}`;
		Store.History.replace(path);
	}

	async function onUpdateProxy(proxy: IProxy) {
		if (isNil(activeMember)) return;
		return OrganizationMemberManager.updateSelf({ vpnPort: proxy.value }).then((member) => {
			dispatch(OrganizationMemberActions.loadOrganizationMember(member));
		});
	}

	const availableTabs = useMemo(() => {
		const hasLoadedOrgMember = !isNil(activeMember);
		if (!hasLoadedOrgMember) return [];

		const tabs = [];
		if (!hideUser) {
			tabs.push({
				key: 'user',
				label: (
					<>
						<UserOutlined /> User Settings
					</>
				),
				children: (
					<BasicContainer style={{ padding: containerPadding }}>
						<UserSettings orgMember={activeMember} hideLinkedIn={hideLinkedIn} />
					</BasicContainer>
				),
			});
		}

		if (!hideLinkedIn) {
			tabs.push({
				key: 'linkedin',
				label: (
					<>
						<LinkedinOutlined /> LinkedIn Settings
					</>
				),
				children: (
					<BasicContainer
						className={styles.linkedInTabContent}
						style={{
							padding: containerPadding,
						}}
					>
						<LinkedInLogIn
							member={activeMember}
							isLoggedIn={activeMember.isLinkedInLoggedIn}
							isRetrying={activeMember.isLiSessionInRetryState}
							onProxySet={onUpdateProxy}
						/>
					</BasicContainer>
				),
			});
		}

		if (!hideEmails) {
			tabs.push({
				key: 'email',
				label: (
					<>
						<MailOutlined /> Email Notification
					</>
				),
				children: (
					<BasicContainer
						className={styles.emailTabContent}
						style={{
							padding: containerPadding,
						}}
					>
						<EmailSettings user={activeMember} />
					</BasicContainer>
				),
			});
		}

		if (!hidePause) {
			tabs.push({
				key: 'pause',
				label: (
					<>
						<PauseCircleOutlined /> Pause All Campaigns
					</>
				),
				children: (
					<BasicContainer style={{ padding: containerPadding }}>
						<PauseSettings orgMemberId={activeMember.id} />
					</BasicContainer>
				),
			});
		}

		return tabs;
	}, [hideEmails, hideLinkedIn, hidePause, hideUser, activeMember]);

	if (isNil(activeMember)) return null;

	return (
		<div className={styles.settingsPageWrapper}>
			<div className={styles.pageHeader}>
				<h2>Settings</h2>
			</div>

			<Tabs
				className={styles.settingsTabs}
				defaultActiveKey={tab}
				onChange={onTabChange}
				//prevents us from rendering non-visible tabs
				destroyInactiveTabPane
				items={availableTabs}
			/>
			<HostedAuthCompletionModal />
		</div>
	);
}

export default SettingsPage;
