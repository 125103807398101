import { InboxV2ListHandlerProps, InboxV2ListProps } from './types';
import { EmailItem } from '../email/emailItem';
import { utc } from 'moment';
import InboxV2List from '@copilot/common/pages/inboxV2/components/listing/inboxV2List';
import useInboxV2 from '../../useInboxV2';
import partial from 'lodash/partial';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { InboxMessage } from '@copilot/common/pages/inbox/ui/types';
import { createLinkedInProfileUrl } from '@copilot/common/contacts/helpers';

type InboxV2EmailListProps = InboxV2ListProps;
/**
 * Lists the messages in the inbox in an email-style format, making it easier to skim through the messages.
 */
export default function InboxV2EmailList({
	onViewConversation: onClick,
	onRemove,
	...props
}: InboxV2EmailListProps & InboxV2ListHandlerProps) {
	const { setReminder, setReminderClick, cancelReminder, nurtureCampaigns } = useInboxV2();

	return (
		<InboxV2List
			nurtureCampaigns={nurtureCampaigns}
			render={(message: InboxMessage, idx: number, selected) => {
				// TODO -> Move to context if appropriate
				const profileUrl = !isEmpty(message.linkedInAlias)
					? createLinkedInProfileUrl(message.linkedInAlias as string)
					: undefined;
				const isLastMessageReceived = isEqual(message.lastReceived, message.lastMessage);
				const displayedMessage = isLastMessageReceived
					? message.lastMessage.message
					: `You replied: ${message.lastMessage.message}`;
				return (
					<EmailItem
						contactName={message.contactName}
						message={displayedMessage}
						timestamp={createDateCopy(message.lastMessage.timestamp)}
						isRead={message.isRead}
						profileUrl={profileUrl}
						onClick={() => onClick(message, idx)}
						reminder={{
							hasReminder: message.isSnoozed === true,
							onCancelReminder: partial(cancelReminder, message.threadId),
							onSetReminder: partial(setReminder, message.threadId),
							onReminderClicked: setReminderClick,
							reminderTimestamp: message.reminder?.reminderTimestamp,
						}}
						isSelected={selected ?? false}
						isDeclined={message.inMail?.status === 'INMAIL_DECLINED'}
						onDelete={() => onRemove([message])}
					/>
				);
			}}
			{...props}
		/>
	);
}

/**
 * Converts the given date string to the copy expected on the email list
 */
function createDateCopy(input: string): string {
	const now = utc().local();
	const date = utc(input).local();

	if (now.isSame(date, 'day')) return date.format('LT');
	if (now.isSame(date, 'isoWeek')) return date.format('ddd');
	if (now.isSame(date, 'year')) return date.format('MMM D');
	return date.format('MM/DD/YY');
}
