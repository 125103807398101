import { FC, ReactElement } from 'react';
import { flexSpaceBetweenStyle } from './personalityInsightsPage';

type DotProps = {
	isSelected: boolean;
	selectionHighlightColour: string;
	selectionDescription: string;
	selectionDescriptionColour: string;
};

const Dot: FC<DotProps> = (props) => (
	<div
		style={{
			fontSize: props.isSelected ? '60px' : '30px',
			transform: props.isSelected ? 'translateY(-2px)' : '',
		}}
	>
		•
		{props.isSelected && (
			<>
				<div
					style={{
						position: 'absolute',
						fontSize: '90px',
						color: props.selectionHighlightColour,
						left: '-6px',
						top: '-2px',
						zIndex: -1,
					}}
				>
					•
				</div>
				<div
					style={{
						position: 'absolute',
						top: '30px',
						left: '11px',
						fontSize: '16px',
						width: 'max-content',
						transform: 'translateX(-50%)',
						color: props.selectionDescriptionColour,
						fontWeight: 'bold',
					}}
				>
					{props.selectionDescription}
				</div>
			</>
		)}
	</div>
);

type DescribedRatingScaleProps = {
	maxRating: number;
	characteristic: string;
	barColour: string;
	selectionHighlightColour: string;
	selectionValue: number; //TODO: Depending on response from BE, could have all selection descriptions passed in at same time
	selectionDescription: string;
	selectionDescriptionColour: string;
};

/**
 * [Presentational]
 * Bar-shaped rating scale with description for the selected value/dot
 * @param props
 * @returns
 */
export const DescribedRatingScale: FC<DescribedRatingScaleProps> = (props) => {
	const dots: ReactElement[] = [];
	for (let i = 0; i < props.maxRating; i++) {
		dots.push(
			<Dot
				key={i}
				isSelected={props.selectionValue === i}
				selectionDescription={props.selectionDescription}
				selectionDescriptionColour={props.selectionDescriptionColour}
				selectionHighlightColour={props.selectionHighlightColour}
			/>
		);
	}

	return (
		<div style={{ margin: '50px 0 90px' }}>
			<h3
				style={{
					textAlign: 'center',
					fontSize: '22px',
					marginBottom: '20px',
					fontWeight: 'bold',
				}}
			>
				{props.characteristic}
			</h3>
			<div
				style={{
					fontSize: '50px',
					color: `${props.barColour}`,
					lineHeight: 0,
					...flexSpaceBetweenStyle,
				}}
			>
				{dots.map((dot) => dot)}
			</div>
			<hr
				style={{
					border: `1px solid ${props.barColour}`,
					backgroundColor: `${props.barColour}`,
					margin: '0 10px',
					transform: 'translateY(1px)',
				}}
			/>
		</div>
	);
};
