// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jj4DrtLVsRQiFzkOgYQT {\n  background-color: rgba(233, 243, 255, 0.5);\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n.k5h_RvrdorGK2HKdn9MC {\n  width: 400px;\n  background-color: #FFF;\n  padding: 72px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08);\n  border-radius: 13px;\n  text-align: center;\n}\n.UNqMzBF14o6m5puFpRmf {\n  font-size: 96px;\n  color: #f44336;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/personalityInsights/loadingScreen/loadingScreen.module.less"],"names":[],"mappings":"AAEA;EACE,0CAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AADF;AAIA;EACE,YAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,4HAAA;EACA,mBAAA;EACA,kBAAA;AAFF;AAKA;EACE,eAAA;EACA,cAAA;AAHF","sourcesContent":["@import '../../../../styles/antd-variables.less';\n\n.background {\n  background-color: rgba(233, 243, 255, 0.5);\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.container {\n  width: 400px;\n  background-color: #FFF;\n  padding: 72px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08);\n  border-radius: 13px;\n  text-align: center;\n}\n\n.errorIcon {\n  font-size: 96px;\n  color: @Red\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "jj4DrtLVsRQiFzkOgYQT",
	"container": "k5h_RvrdorGK2HKdn9MC",
	"errorIcon": "UNqMzBF14o6m5puFpRmf"
};
export default ___CSS_LOADER_EXPORT___;
