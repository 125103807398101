import { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
// Survicate has this name space and required for importing the right files
// eslint-disable-next-line import/no-duplicates
import { type Survicate, } from '@survicate/survicate-web-surveys-wrapper';
// eslint-disable-next-line import/no-duplicates
import { getSurvicateInstance, initSurvicate } from '@survicate/survicate-web-surveys-wrapper/widget_wrapper';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { RootState } from '@copilot/common/store/reducers';
import { SurvicateContext } from './hooks';
import moment from 'moment';

/**
 * Provider for Survicate
 * Used to initialize and prepare the Survicate widget
 */
export function SurvicateProvider({
    isEnabled,
    workspaceKey,
    children
}: PropsWithChildren<Readonly<{ isEnabled: boolean, workspaceKey: string }>>) {
    const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
    const activeImpersonator = useSelector((state: RootState) => state.app.impersonator);
    const enabled = isEnabled && !isEmpty(workspaceKey) && !isNil(activeMember) && !isNil(activeMember.userCreatedDate);
    const [survicate, setSurvicate] = useState<Survicate | undefined>(undefined);

    useEffect(() => {
        if (enabled) {
            initSurvicate({
                workspaceKey,
                traits: {
                    user_id: activeMember.id,
                    first_name: activeMember.firstName,
                    last_name: activeMember.lastName,
                    impersonator_name: activeImpersonator ?? '',
                    email: activeMember.email,
                    user_created_date: moment.unix(activeMember.userCreatedDate).toISOString()
                },
            }).then(() => {
                setSurvicate(getSurvicateInstance() ?? undefined);
            }).catch((error) => {
                console.error(`Failed loading survicate: ${error}`);
            });
        }
    }, [enabled, activeMember?.id]);

    return <SurvicateContext.Provider value={survicate}>{children}</SurvicateContext.Provider>;
}
