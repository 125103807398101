import { createContext, useContext } from 'react';
import { type Survicate } from '@survicate/survicate-web-surveys-wrapper';

/**
 * Context housing the Survicate instance
 */
export const SurvicateContext = createContext<Survicate | undefined>(undefined);

/**
 * Hook to interact with Survicate
 * @returns Survicate instance
 */
export function useSurvicate() {
	return useContext(SurvicateContext);
}
