import BasicContainer from '@copilot/common/components/containers/basic';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { TableProps } from '@copilot/common/components/tables/basicTable';
import { ThreadStatus } from '@copilot/common/store/models/const/enum';
import SentMessagesTable from './sentMessagesTable';

const Content = styled(BasicContainer.Content)`
	background-color: ${(props) => props.theme['@layout-body-background']};
`;

/**
 * Expected type of the sent messages
 */
type SentMessageType = {
	name: string;
	message: string;
	contactId: string;
	lastSent: string;
	orgMemberId: string;
	isContactDeleted: boolean;
	status: ThreadStatus;
};

/**
 * Presentation Component of the Sent Messages Page
 * @param props
 */
const SentMessagesPage = (
	props: Omit<TableProps<SentMessageType>, 'pagination'> & { total: number }
): JSX.Element => (
	<BasicContainer>
		<BasicContainer.Header>
			<Row justify="space-between" align="middle">
				<Col span={1}>
					<h2>Sent</h2>
					<br />
				</Col>
			</Row>
		</BasicContainer.Header>
		<Content>
			<SentMessagesTable {...props} />
		</Content>
	</BasicContainer>
);

export default SentMessagesPage;
