import { Alert, Button, Select, Typography } from 'antd';
import styles from './blocklist.module.less';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import isUndefined from 'lodash/isUndefined';
import BlocklistTable from './blocklistTable';
import { DEFAULT_MAXIMUM_BLOCKLIST_RESTRICTIONS } from './constants';
import useBlocklistContext from './context/useBlocklistContext';
import isNil from 'lodash/isNil';

/**
 * Presentational layer for the blocklist page
 * @constructor
 */
export default function Blocklist() {
	const {
		data,
		alert,
		isLoading, // switch for isInitializingData?
		teamMemberOptions,
		onUpdateTeamMember: onTeamMemberFilterChange,
		onAddToBlocklistClick,
		onEditBlocklistClick,
	} = useBlocklistContext();

	const lastUpdate = !isNil(data.lastUpdate) ? moment(data.lastUpdate) : undefined;

	return (
		<div className={styles.contentWrapper}>
			<div className={styles.titleSection}>
				<Typography.Title className={styles.title} level={3}>
					Blocklist
				</Typography.Title>
				<Typography.Text className={styles.secondary}>
					Setup a blocklist to prevent outreach to specific companies. Effective for new
					invites only.
				</Typography.Text>
			</div>

			<div>
				<Button
					icon={<PlusOutlined className={styles.buttonIcon} />}
					onClick={onAddToBlocklistClick}
				>
					Add to blocklist
				</Button>
			</div>

			<div className={styles.editSection}>
				<Select
					options={teamMemberOptions}
					onChange={onTeamMemberFilterChange}
					className={styles.teamMemberDropdown}
				/>

				<Button
					icon={<EditOutlined className={styles.buttonIcon} />}
					onClick={onEditBlocklistClick}
				>
					Edit blocklist
				</Button>

				{lastUpdate?.isValid() === true ? (
					<Typography.Text className={styles.secondary}>
						Last updated {moment(data.lastUpdate).format('MM/DD/YYYY HH:SS')}
					</Typography.Text>
				) : null}
			</div>

			<div className={styles.blocklistSummarySection}>
				<Typography.Text>Blocklist</Typography.Text>
				<Typography.Text className={styles.secondary}>•</Typography.Text>
				<Typography.Text className={styles.secondary}>
					{data.entries.length} / {DEFAULT_MAXIMUM_BLOCKLIST_RESTRICTIONS} restrictions
				</Typography.Text>
			</div>

			{!isUndefined(alert) ? (
				<Alert message={alert.message} type={alert.type} showIcon />
			) : null}

			<BlocklistTable data={data.entries} isLoading={isLoading} />
		</div>
	);
}
