import { useCallback, useContext, useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import { MIN_DATE } from '@copilot/common/utils/time';
import { URL } from '@copilot/common/data/urls';
import { LinkedInThreadType } from '@copilot/common/store/models/const/enum';
import {
	linkedInDisconnectedMsg,
	manualMessageDisabledMsg,
} from '@copilot/common/constant/strings';
import ContactDrawerV2Context from '../../../context/contactDrawerV2Context';
import { Features, MessageResponse, TermsOfUse } from '@copilot/data/responses/interface';
import { useProspectDrawerTracking } from '../../../../wrappers/contact/tracking';
import { useSendMessageWithReminderTrackingParamsTracking } from '@copilot/common/components/selector/reminder/tracking';
import { AIFeatureManager, InboxManager } from '@copilot/data';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useSmartReply } from '@copilot/common/hooks/smartReply';
import { getMillisecondsFromNow } from '@copilot/common/utils/dateUtil';
import { Moment } from 'moment';
import { getSubmissionFunction } from './conversationBusinessLogic';
import { getSmartReplyPromptCategory } from '@copilot/common/components/forms/messageForm/smartReply/smartReplyUtils';
import { useSelector } from 'react-redux';
import { MessageTemplateSelectors } from '@copilot/common/store/selectors/messageTemplate';
import { formatName } from '@copilot/common/utils';
import { useQuickResponseModal } from '@copilot/common/hooks/quickResponse';
import { Config } from '@copilot/common/config';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { useTermsOfUse } from '@copilot/common/hooks/termsOfUse';

/**
 * Hook wrapper for conversation tab core business logic
 * @returns
 */
export default function useConversationTabV2() {
	const {
		conversation,
		isLoading,
		activeMember,
		organizationId,
		orgMemberId,
		onUpdateCurrentConversation,
		contactData: { contact },
		campaignConnection,
		isConversationInvalid,
		sourceName,
		nextAutomatedStep,
	} = useContext(ContactDrawerV2Context);

	const videoInAppValidationEnabled =
		!Config.isAgency && useFeatureToggle(Features.VideoValidationFeature);
	const { hasAccepted: hasAcceptedAITermsOfUse } = useTermsOfUse(
		TermsOfUse.AIFeature,
		AIFeatureManager.acceptTermsOfUse
	);
	const isManualSendMessageFeatureEnabled = useFeatureToggle(Features.ManualSendMessageFeature);

	//tracking
	const handleConversationTabTracking = useProspectDrawerTracking('Prospect Drawer');
	const updateSendWithReminderTracking =
		useSendMessageWithReminderTrackingParamsTracking('Send With Reminder');

	const [createQuickReplyModal] = useQuickResponseModal();

	//message templates
	const messageTemplates = useSelector((state) =>
		MessageTemplateSelectors.getTemplatesByCampaignId(
			state,
			campaignConnection?.campaignId ?? ''
		)
	);

	//smart reply callbacks/state
	const [smartReplyResult, { writeSmartReply, regenerateSmartReply, editSmartReply }] =
		useSmartReply(organizationId ?? '', orgMemberId ?? '', contact?.id ?? '');

	//client side buffer of new messages sent in the drawer session
	const [tempOutgoing, setTempOutgoing] = useState<MessageResponse[]>([]);

	//clear tempOutgoing when campaignConnection changes (either due to switching org members via the dropdown or navigating to a new org contact entirely)
	useEffect(() => {
		setTempOutgoing([]);
	}, [campaignConnection]);

	//submission function for sending messages
	const onSubmitMessage = useCallback(
		getSubmissionFunction({
			orgMemberId: orgMemberId ?? '',
			linkedInThreadNum: conversation?.threadId ?? '',
			campaignId: campaignConnection?.campaignId ?? undefined,
			orgContactId: contact?.id ?? undefined,
			onTracking: handleConversationTabTracking,
			campaignMemberId: campaignConnection?.campaignMemberId ?? undefined,
			onSuccess: onAppendToTempOutgoing,
			onFailure: () => {
				onUpdateCurrentConversation({
					isConversationRestricted: true,
				});
			},
			updateConversation: onUpdateCurrentConversation,
		}),
		[conversation, campaignConnection, contact, orgMemberId, handleConversationTabTracking]
	);

	//append new message to tempOutgoing so we can render it immediately
	function onAppendToTempOutgoing(newOutgoing: MessageResponse) {
		setTempOutgoing([...tempOutgoing, newOutgoing]);
	}

	//ordered messages for rendering, ideally this would be handled by the BE
	function getOrderedMessages() {
		return !isNil(conversation)
			? [
					...conversation.sentMessages.map((m) => {
						return { ...m, isSelf: true };
					}),
					...conversation.receivedMessages,
			  ].sort((a, b) => {
					const aValue = a.timestamp ?? MIN_DATE;
					const bValue = b.timestamp ?? MIN_DATE;
					return aValue < bValue ? -1 : 1;
			  })
			: [];
	}

	//get thread link for the current conversation, allowing the contact to be viewed on LinkedIn
	function getThreadLink() {
		return !isNil(conversation)
			? getLinkedInThreadUrl(conversation.threadId, conversation.threadType)
			: undefined;
	}

	//get LinkedIn thread URL for the given thread number and type
	function getLinkedInThreadUrl(liThreadNumber: string, threadType: LinkedInThreadType) {
		if (!isNil(liThreadNumber)) {
			const route =
				threadType === LinkedInThreadType.SalesNav ? '/sales/inbox/' : '/messaging/thread/';
			return `${URL.LINKEDIN_URL}${route}${liThreadNumber}`;
		}
		return undefined;
	}

	//handler for snoozing thread when the user sets a reminder on no response in the message form
	async function onSetReminderOnNoResponse(date: Moment) {
		if (isNil(conversation)) return;
		const { id } = conversation;
		const reminderDate = date.toISOString();
		try {
			await InboxManager.snoozeThread(id, reminderDate);
			const interval = getMillisecondsFromNow(date);
			updateSendWithReminderTracking('Sent from Prospect Drawer', interval);
			notificationManager.showSuccessNotification({
				message: `Reminder set for ${date.format('ddd MMM Do')}`,
				description: '',
			});
			onUpdateCurrentConversation({
				isSnoozed: true,
				snoozedDateTime: reminderDate,
			});
		} catch {
			notificationManager.showErrorNotification({
				message: 'Failed to set Reminder',
				description: 'Please try again.',
			});
		}
	}

	function getPlaceholderMessage() {
		if (!isManualSendMessageFeatureEnabled) {
			return manualMessageDisabledMsg;
		} else if (!conversation?.isLoggedIn && !conversation?.isLiSessionInRetryState) {
			return linkedInDisconnectedMsg;
		} else {
			return undefined;
		}
	}

	return {
		//message thread rendering data
		messages: getOrderedMessages(),
		threadLink: getThreadLink(),
		isConversationRestricted: conversation?.isConversationRestricted,
		sourceName,
		targetName: isNil(contact) ? '' : formatName(contact.firstName, contact.lastName),
		tempOutgoing,
		isConversationInvalid,
		//message form data
		isMessageFormDisabled:
			isLoading ||
			isNil(activeMember) ||
			isNil(conversation) ||
			!isManualSendMessageFeatureEnabled ||
			conversation?.isConversationRestricted ||
			(!conversation.isLoggedIn && !conversation.isLiSessionInRetryState),
		campaignId: isNil(campaignConnection) ? '' : campaignConnection?.campaignId,
		messageFormPlaceholder: getPlaceholderMessage(),
		smartReplyPromptCategory: getSmartReplyPromptCategory({
			isSnoozed: conversation?.isSnoozed,
		}),
		onSubmitMessage,
		onSetReminderOnNoResponse,
		smartReplyResult,
		writeSmartReply,
		regenerateSmartReply,
		editSmartReply,
		nextAutomatedStep,
		messageTemplates,
		createQuickReplyModal,
		//tracking
		handleConversationTabTracking,
		//feature flags
		videoInAppValidationEnabled,
		hasAcceptedAITermsOfUse,
	};
}
