import { createContext } from 'react';
import { DEFAULT_INBOX_V2_CONTEXT } from './constants';
import { InboxV2ContextData } from './types';

/**
 * Boiler plate for defining the InboxV2 context, supplies default data
 */
const InboxV2Context = createContext<InboxV2ContextData>(DEFAULT_INBOX_V2_CONTEXT);

export default InboxV2Context;
