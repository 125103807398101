import React from 'react';
import BasicContainer from '@copilot/common/components/containers/basic';
import OutboxTable from '@copilot/common/components/tables/tableTypes/outbox';
import { ThreadStatus, NotificationType } from '@copilot/common/store/models/const/enum';
import { useFetch } from '@copilot/common/hooks/common';
import { OutboxManager } from '@copilot/data';
import { OutboxActions } from '@copilot/common/store/actions/outbox';
import { OutboxColumns } from '@copilot/common/components/componentModels/columnTypeDefinition';
import { QueryObject } from '@copilot/data/managers/base';
import { useSelector, useDispatch } from 'react-redux';
import { OutboxMessageSelectors } from '@copilot/common/store/selectors/outbox';
import { NotificationActions } from '@copilot/common/store/actions/notification';
import { addSorterToQuery } from '@copilot/common/utils/tables';
import styled from 'styled-components';
//view types - campaign, sentiment, email

interface PendingOutgoingProps {
	orgId: string;
	orgMemberId: string;
	isOrgAdmin: boolean;
}

const Content = styled(BasicContainer.Content)`
	background-color: ${(props) => props.theme['@layout-body-background']};
	padding: ${(props) => props.theme['@card-padding-base']};
`;

const PendingOutgoing: React.FC<PendingOutgoingProps> = (props) => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = React.useState<number | undefined>(1);
	const outgoingGetter = React.useCallback<typeof OutboxManager.getOutboxMessages>(
		(orgMemberId, query) => {
			if (props.isOrgAdmin) orgMemberId = null;
			return OutboxManager.getOutboxMessages(orgMemberId, query).then((r) => {
				dispatch(
					NotificationActions.updateNotification({
						id: NotificationType.Pending,
						body: `${r.totalCount}`,
					})
				);
				return r;
			});
			//TODO if we want to have a separate route for ADMIN and CS to retrieve outgoing then add here
		},
		[props.orgMemberId, props.isOrgAdmin]
	);
	const [outgoingFetch, fetchOutgoingMessages] = useFetch(
		outgoingGetter,
		OutboxActions.loadOutboxMessages,
		(r) => r.results
	);
	const outgoingTotal = outgoingFetch.data ? outgoingFetch.data.totalCount : 0;
	const outgoingResults = outgoingFetch.data ? outgoingFetch.data.results : [];

	React.useEffect(() => {
		const query = new QueryObject();
		query.addFilter(OutboxManager.KeyMap.status, '==', `${ThreadStatus.Pending}`);
		fetchOutgoingMessages(props.orgMemberId, query);
	}, [props.orgMemberId]);

	const handleOutgoingTableChange = React.useCallback<
		NonNullable<React.ComponentProps<typeof OutboxTable>['onChange']>
	>(
		(pagination, filters, sorter) => {
			const query = new QueryObject({
				page: (pagination.current ?? 1) - 1,
				pageSize: pagination.pageSize,
			});
			query.addFilter(OutboxManager.KeyMap.status, '==', `${ThreadStatus.Pending}`);
			setCurrentPage(pagination.current);
			Object.keys(filters).forEach((k) => {
				if (filters[k]) {
					const key = OutboxManager.KeyMap[k as keyof typeof OutboxManager.KeyMap] || k;
					const evaluator = k === OutboxManager.KeyMap.error ? '==' : '^*=';
					query.addFilter(key, evaluator, filters[k]!.join('|'));
				}
			});
			addSorterToQuery(sorter, query);
			fetchOutgoingMessages(props.orgMemberId, query);
		},
		[props.orgMemberId]
	);

	const displayedPendingMessages = useSelector(
		OutboxMessageSelectors.getOutboxMessagesByIds(outgoingResults.map((c) => c.id))
	);

	return (
		<Content>
			<OutboxTable
				orgId={props.orgId}
				columns={OutboxColumns}
				data={displayedPendingMessages}
				pagination={{ total: outgoingTotal, current: currentPage }}
				onChange={handleOutgoingTableChange}
				loading={outgoingFetch.isFetching}
			/>
		</Content>
	);
};
export default PendingOutgoing;
