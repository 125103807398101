import { isNil } from 'lodash';
import { OAuthConstants, OAuthPopupDimentions, OAuthResponseTypes } from './constants';
import { TMessageData, TOauth2Options as TOauth2Options } from './types';

/**
 * OAuth manager to manage the authorization code flow
 * Use 'startAuth' to kick off the authentication process
 */
export class OAuthCodeFlowManager {
	private popupRef: Window | null = null;
	private intervalRef: NodeJS.Timeout | undefined;
	private initialized = false;

	private authorizeUrl: string;
	private clientId: string;
	private redirectUri: string;
	private scope: string;
	private responseType: string;
	private extraQueryParams: Record<string, any> | undefined;
	private requestParams: Record<string, any> | undefined;
	private onSuccess:
		| ((payload: string, requestParams: Record<string, any> | undefined) => Promise<void>)
		| undefined;
	private onError: ((error: string) => void) | undefined;

	constructor(options: TOauth2Options) {
		const {
			authorizeUrl,
			clientId,
			redirectUri,
			scope = '',
			responseType,
			extraQueryParameters,
			onSuccess,
			onError,
		} = options;
		this.authorizeUrl = authorizeUrl;
		this.clientId = clientId;
		this.scope = scope;
		this.responseType = responseType;
		this.extraQueryParams = extraQueryParameters;
		this.redirectUri = redirectUri;
		this.onSuccess = onSuccess;
		this.onError = onError;
	}

	/**
	 * Starts the authentication process
	 * @param requestParams request items to get back upon callback
	 * @returns
	 */
	public startAuth(requestParams: Record<string, any> | undefined) {
		if (this.initialized) return;
		this.initialized = true;

		if (requestParams) this.requestParams = requestParams;

		const state = this.generateState();
		this.saveState(sessionStorage, state);

		// Open popup
		this.popupRef = this.openPopup(
			this.formatAuthorizeUrl(
				this.authorizeUrl,
				this.clientId,
				this.redirectUri,
				this.scope,
				state,
				this.responseType,
				this.extraQueryParams
			)
		);

		// Register message listener
		window.addEventListener('message', this.executeHandleMessageListener.bind(this));

		// Begin popup monitoring interval
		if (isNil(this.intervalRef)) {
			this.intervalRef = setInterval(() => {
				const popupClosed = !this.popupRef || this.popupRef?.closed;
				if (popupClosed) {
					// Popup was closed before completing auth...
					console.warn('Warning: Popup was closed before completing authentication.');
					this.cleanup();
				}
			}, 1000);
		}
	}

	/**
	 * Helper to register and run the message listner
	 * @param message
	 */
	private executeHandleMessageListener(message: MessageEvent<TMessageData>) {
		void (async () => this.handleMessageListener(message))();
	}

	/**
	 * Handle event emitted by the popup window when the auth response is set
	 * @param message
	 * @returns
	 */
	private async handleMessageListener(message: MessageEvent<TMessageData>) {
		const type = message?.data?.type;

		if (type !== OAuthConstants.OAuthResponse) {
			return;
		}
		try {
			if ('error' in message.data) {
				const errorMessage = message.data?.error || 'Unknown Error occured.';
				if (this.onError) this.onError(errorMessage);
			} else {
				const payload = message?.data?.payload;
				if (this.responseType === OAuthResponseTypes.Code) {
					const code: string = payload.code;
					if (this.onSuccess && code) {
						await this.onSuccess(code, this.requestParams);
					}
				}
			}
		} catch (genericError: any) {
			console.error(genericError);
		} finally {
			this.cleanup();
		}
	}

	/**
	 * Generates a random string of character for setting as the state variable
	 * @returns A randomly generated set of strings
	 */
	private generateState() {
		const arrayLen = 40;
		const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let array = new Uint8Array(arrayLen);
		window.crypto.getRandomValues(array);
		array = array.map((x: number) => validChars.codePointAt(x % validChars.length) ?? 0);
		const randomState = String.fromCharCode.apply(null, [...array]);
		return randomState;
	}

	/**
	 * Helper that generates the auth URL with the given params
	 * @param authorizeUrl
	 * @param clientId
	 * @param redirectUri
	 * @param scope
	 * @param state
	 * @param responseType
	 * @param extraQueryParameters
	 * @returns URL with all the URL params
	 */
	private formatAuthorizeUrl(
		authorizeUrl: string,
		clientId: string,
		redirectUri: string,
		scope: string,
		state: string,
		responseType: TOauth2Options['responseType'],
		extraQueryParameters: TOauth2Options['extraQueryParameters'] = {}
	): string {
		const query = new URLSearchParams({
			response_type: responseType,
			client_id: clientId,
			redirect_uri: redirectUri,
			scope,
			state,
			...extraQueryParameters,
		}).toString();

		return `${authorizeUrl}?${query}`;
	}

	/**
	 * Opens a popup window to initate the login prompt
	 * @param url
	 * @returns Window object
	 */
	private openPopup(url: string): Window | null {
		const top = window.outerHeight / 2 + window.screenY - OAuthPopupDimentions.Height / 2;
		const left = window.outerWidth / 2 + window.screenX - OAuthPopupDimentions.Width / 2;
		return window.open(
			url,
			'Verify your credentials',
			`height=${OAuthPopupDimentions.Height},width=${OAuthPopupDimentions.Width},top=${top},left=${left}`
		);
	}

	/**
	 * Saves the state string to local storage for later verification
	 * @param storage
	 * @param state
	 */
	private saveState(storage: Storage, state: string): void {
		storage.setItem(OAuthConstants.OAuthStateKey, state);
	}

	/**
	 * Removes the state from local storage
	 * @param storage
	 */
	private removeState(storage: Storage): void {
		storage.removeItem(OAuthConstants.OAuthStateKey);
	}

	/**
	 * Cleanup helper function
	 */
	private cleanup(): void {
		clearInterval(this.intervalRef);
		this.intervalRef = undefined;
		if (this.popupRef && typeof this.popupRef.close === 'function') this.popupRef.close();
		this.removeState(sessionStorage);
		window.removeEventListener('message', this.executeHandleMessageListener);
		this.requestParams = undefined;
	}
}

export default OAuthCodeFlowManager;
export * from './oauthpopup';
