import { InboxManager } from '@copilot/data';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import {
	ConversationResponse,
	LinkedInMessageStatus,
	MessageResponse,
} from '@copilot/data/responses/interface';
import { ProspectDrawerTrackingParams } from '../../../../wrappers/contact/tracking';
import notificationManager from '@copilot/common/utils/notificationManager';

type SubmissionArgs = {
	orgMemberId?: string;
	linkedInThreadNum?: string;
	campaignId?: string | undefined;
	orgContactId?: string;
	onTracking: ((params: ProspectDrawerTrackingParams) => void) | undefined;
	campaignMemberId?: string | undefined;
	onSuccess: (msg: MessageResponse) => void;
	onFailure: () => void;
	updateConversation(update: Partial<ConversationResponse>): void;
};
/**
 * Getter for the submission function for the conversation tab's message form
 * @param param0
 * @returns
 */
export function getSubmissionFunction({
	orgMemberId,
	linkedInThreadNum,
	campaignId,
	orgContactId,
	onTracking,
	campaignMemberId,
	onSuccess,
	onFailure,
	updateConversation,
}: SubmissionArgs) {
	if (
		isNil(orgMemberId) ||
		isNil(linkedInThreadNum) ||
		isNil(orgContactId) ||
		isNil(campaignId)
	) {
		return () => {};
	}

	// TODO: COPILOT-7983 move remove and send reminder logic when sending a message to backend
	return async function onSubmit(
		msg: string,
		manualReplyRemoveReminder: boolean,
		automatedStepId?: string,
		templateId?: string
	) {
		if (orgMemberId && linkedInThreadNum && msg.trim() !== '') {
			try {
				let response;
				if (isEmpty(automatedStepId)) {
					response = await InboxManager.sendManualReply(
						linkedInThreadNum,
						msg,
						orgMemberId,
						manualReplyRemoveReminder,
						templateId,
						campaignId
					);
				} else if (!isNil(campaignMemberId)) {
					// messageItem required to send automated reply
					response = await InboxManager.sendAutomationReply(
						campaignMemberId,
						orgContactId,
						msg,
						String(automatedStepId)
					);
				} else {
					throw new Error('Message item should be not null when resuming automation');
				}
				const tempOutgoingMessage = createTempOutgoingMessage(msg);
				if (response) {
					onTracking?.({ buttonClicked: 'Send to Outbox Button' });
					notificationManager.showSuccessNotification({
						description: 'Sent to Outbox',
						message: 'Message has been sent to Outbox',
					});

					onSuccess(tempOutgoingMessage);

					if (manualReplyRemoveReminder) {
						updateConversation({
							isSnoozed: false,
							snoozedDateTime: undefined,
						});
					}
				} else {
					notificationManager.showWarningNotification({
						description: 'Failed',
						message: 'Failed to send message.',
					});
					onFailure();
				}
			} catch {
				notificationManager.showWarningNotification({
					description: 'Failed',
					message: 'Failed to send message.',
				});
			}
		}
	};
}

function createTempOutgoingMessage(message: string): MessageResponse {
	return {
		data: message,
		isSelf: true,
		timestamp: new Date(),
		pending: true,
		status: LinkedInMessageStatus.Pending,
	};
}
