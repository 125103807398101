import isString from 'lodash/isString';
import { isPlainJsonObject } from '../../../utils/index';

/**
 * Type representing an automated step in a campaign
 */
export type AutomatedStep = Readonly<{
	id: string;
	name: string;
	message: string;
}>;

/**
 * Type guard for checking if an object is an automated step
 * @param obj
 */
export function isAutomatedStep(obj: unknown): obj is AutomatedStep {
	return (
		isPlainJsonObject(obj) && isString(obj.id) && isString(obj.name) && isString(obj.message)
	);
}
