import React from 'react';
import { Col, Row } from 'antd';
import { CampaignManager } from '@copilot/data';
import PauseSelector from '@copilot/common/components/pause';
import { useEffectAsync } from '@copilot/common/hooks/common';

interface PauseSettingsProps {
	orgMemberId: string;
}

const PauseSettings: React.FC<PauseSettingsProps> = (props) => {
	const [pausedTill, setPausedTill] = React.useState<string | null>(null);

	useEffectAsync(async () => {
		if (!props.orgMemberId || props.orgMemberId == '') return;
		const date = await CampaignManager.getPauseService(props.orgMemberId);
		if (date.unpauseDate != null) {
			setPausedTill(
				new Date(date.unpauseDate)
					.toLocaleDateString('en-US', {
						weekday: 'long',
						month: 'long',
						day: 'numeric',
					})
					.replace(',', '')
			);
		}
	}, [props.orgMemberId]);

	return (
		<>
			<Row>
				<Col>
					<h2>Pause All Campaigns</h2>
				</Col>
			</Row>
			<Row>
				<Col span={16}>
					<p>
						You can pause all your campaigns by hitting the button below and selecting a
						date for your campaigns to resume. Once the resume date is set, your
						campaigns will pause. Please note this can take up to 30-minutes.
					</p>
					<p>
						When paused, no invites or followup messages will be sent out from the
						dashboard, however, you can still get replies from your connections. Your
						billing will not be affected in any way by pausing your campaigns.
					</p>
				</Col>
			</Row>
			{pausedTill && (
				<>
					<Row>
						<Col>
							<h2>
								Campaigns are currently paused until{' '}
								<strong>
									<u>{pausedTill}</u>
								</strong>
							</h2>
						</Col>
					</Row>
				</>
			)}
			<PauseSelector
				orgMemberId={props.orgMemberId}
				setPausedTill={setPausedTill}
				pausedTill={pausedTill}
			/>
			<br />
			{!pausedTill && (
				<>
					<Row>
						<Col span={12}>
							<div style={{ border: '1px solid #eee', padding: '10px' }}>
								<strong>IMPORTANT</strong> - Please read below before pausing your
								campaign:
								<br />
								<ol
									style={{
										marginBottom: '0',
										paddingLeft: '15px',
										fontSize: '.9em',
										lineHeight: '1.75',
									}}
								>
									<li>Your account will still collect replies</li>
									<li>Invites will stop sending</li>
									<li>Follow ups will stop sending</li>
									<li>
										Your billing will not be adjusted by pausing your campaign
									</li>
									<li>
										Invites already sent out before pausing can still be
										accepted and when they are, the timestamp in LinkedIn will
										show when it was accepted, not when it was sent.
									</li>
								</ol>
							</div>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default PauseSettings;
