import { ReactNode } from 'react';
import { getChildByType } from '@copilot/common/utils/common';
import { Layout } from 'antd';
import DrawerContainerManager from '../../../components/drawer/index';
import ModalManager from '@copilot/common/components/modals/manager';
import { useTheme } from 'styled-components';

import styles from './headerSider.module.less';

type SubComponentProps = {
	children: ReactNode;
};

/**
 * Renders the content of a subcomponent.
 *
 * @param {Object} children - The content to be rendered.
 * @returns {Object} - The rendered content.
 */
function Content({ children }: SubComponentProps) {
	return <>{children}</>;
}

/**
 * Represents a header component.
 *
 * @param {React.ReactNode} children - The child elements of the header component.
 * @constructor
 * @returns {React.ReactElement} The rendered header component.
 */
function Header({ children }: SubComponentProps) {
	return <>{children}</>;
}

/**
 * Creates a Sider component.
 *
 * @param {object} children - The children of the Sider component.
 * @constructor
 */
function Sider({ children }: SubComponentProps) {
	return <>{children}</>;
}

/**
 * Renders the notification subcomponent.
 *
 * @param {Object} children - The content to be rendered.
 * @returns {Object} - The rendered content.
 */
function Notification({ children }: SubComponentProps) {
	return <>{children}</>;
}

type LayoutProps = {
	children: Array<ReactNode>;
};

/**
 * Renders a layout component with a content area and a footer area.
 * The content and footer components can be provided as children.
 *
 * @param {LayoutProps} props - The properties for the layout component.
 * @returns {JSX.Element} - The rendered layout component.
 */
function HeaderSiderLayout(props: LayoutProps): JSX.Element {
	const { children } = props;
	const HeaderComponent = getChildByType(children, Header);
	const SiderComponent = getChildByType(children, Sider);
	const ContentComponent = getChildByType(children, Content);
	const NotificationComponent = getChildByType(children, Notification);
	const theme = useTheme();
	return (
		<Layout className={styles.headerSiderWrapper}>
			{NotificationComponent}
			<Layout.Header>{HeaderComponent}</Layout.Header>
			<Layout>
				<Layout.Sider
					theme="light"
					width={theme['@layout-sider-width']}
					className={styles.siderWrapper}
				>
					{SiderComponent}
				</Layout.Sider>
				<Layout.Content className={styles.contentWrapper}>
					{ContentComponent}
					<ModalManager />
					<DrawerContainerManager />
				</Layout.Content>
			</Layout>
		</Layout>
	);
}

HeaderSiderLayout.Content = Content;
HeaderSiderLayout.Header = Header;
HeaderSiderLayout.Sider = Sider;
HeaderSiderLayout.Notification = Notification;
export default HeaderSiderLayout;
