import { AI_DETECTED_TAB_KEY } from '../constants';
import useMeetingsBookedContext from '../context/useMeetingsBookedContext';
import BaseMeetingsTable from './baseMeetingsTable';
import { Button, Flex } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './meetingTables.module.less';
import partial from 'lodash/partial';
import {
	MEETINGS_BOOKED_AI_CONFIRM_TRACKING_ID,
	MEETINGS_BOOKED_AI_REJECT_TRACKING_ID,
} from '@copilot/common/tracking/userpilotEventConsts';

type DetectedMeetingsTableProps = { viewPortHeight: number };
/**
 * Table of meetings detected by AI but not confirmed by the user
 * @returns
 */
export default function DetectedMeetingsTable({ viewPortHeight }: DetectedMeetingsTableProps) {
	const {
		aiDetectedMeetings,
		onLoadPage,
		onViewConversation,
		onConfirmMeeting,
		onDenyMeeting,
		detectedPageSize,
		onUpdatePageSize,
		isLoadingDetected,
	} = useMeetingsBookedContext();

	return (
		<BaseMeetingsTable
			viewPortHeight={viewPortHeight}
			isExternallyLoading={isLoadingDetected}
			data={aiDetectedMeetings}
			pageSize={detectedPageSize}
			onPageSizeChange={partial(onUpdatePageSize, AI_DETECTED_TAB_KEY)}
			onPageChange={partial(onLoadPage, AI_DETECTED_TAB_KEY)}
			onViewConversation={partial(onViewConversation, AI_DETECTED_TAB_KEY)}
			leadingColumn={{
				title: 'Did you book a meeting?',
				dataIndex: 'id',
				render: (id: string) => (
					<Flex gap={8} onClick={(e) => e.stopPropagation()}>
						<Button
							size="small"
							icon={<CheckOutlined className={styles.approveMeetingIcon} />}
							onClick={() => onConfirmMeeting(id)}
							data-tracking-id={MEETINGS_BOOKED_AI_CONFIRM_TRACKING_ID}
						>
							Yes
						</Button>
						<Button
							size="small"
							icon={<CloseOutlined className={styles.denyMeetingIcon} />}
							onClick={() => onDenyMeeting(id)}
							data-tracking-id={MEETINGS_BOOKED_AI_REJECT_TRACKING_ID}
						>
							No
						</Button>
					</Flex>
				),
				width: '20%',
			}}
		/>
	);
}
