import { useContext } from 'react';
import BlocklistContext from './blocklistContext';

/**
 * Hook wrapper for meetingsBooked context
 * @returns
 */
export default function useBlocklistContext() {
	const contextData = useContext(BlocklistContext);

	return { ...contextData };
}
