import { Flex, Tabs, Typography, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import styles from './blocklistEntryUpload.module.less';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';

const { Dragger } = Upload;

type BlocklistEntryUploadProps = Readonly<{
	// Function to call when a file is uploaded
	onUpload: (file: File) => void;
	// The upload is in progress
	isUploading: boolean;
}>;

/**
 * Blocklist entry upload component
 * @returns
 */
export function BlocklistEntryUpload({ onUpload, isUploading }: BlocklistEntryUploadProps) {
	return (
		<div className={styles.blocklistEntryUploadContainer}>
			<Typography.Title className={styles.blocklistEntryUploadTitle} level={3}>
				Upload a CSV file or manually enter a list of company names you'd like to exclude
			</Typography.Title>
			<Tabs
				defaultActiveKey="csv"
				items={[
					{
						key: 'csv',
						label: 'Upload a file',
						children: (
							<Flex justify="center" gap="large" vertical>
								<LoadingIndicator isLoading={isUploading}>
									<Dragger
										name="file"
										multiple={false}
										showUploadList={false}
										beforeUpload={(file) => {
											// Calling the onUpload function in beforeUpload since we are not using the
											// built-in functionality for uploading the file
											onUpload(file);
											// Returning false to prevent the file from being uploaded by the Dragger
											return false;
										}}
									>
										<InboxOutlined className={styles.dropzoneIcon} />
										<Typography.Title
											level={5}
											className={styles.dropzoneMainText}
										>
											Click or drag file to this area to upload
										</Typography.Title>
										<Typography.Text type="secondary">
											CSV files only. Maximum file size 100KB
										</Typography.Text>
									</Dragger>
								</LoadingIndicator>
								<Flex vertical align="center" gap="small">
									<Typography.Text type="secondary">
										Need help formatting your list?
									</Typography.Text>
									<Typography.Link href="/" target="_blank">
										Use this template
									</Typography.Link>
								</Flex>
							</Flex>
						),
					},
					{
						key: 'manual',
						label: 'Manual Entry',
						children: <div>Manual Entry</div>,
						// TODO Implement manual entry https://cassia.atlassian.net/browse/COPILOT-9160
						disabled: true,
					},
				]}
			/>
		</div>
	);
}
