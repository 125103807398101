import HidableBottomPanel from '@copilot/common/components/containers/hidableBottomPanel';
import Text from 'antd/lib/typography';
import { DownOutlined, LoadingOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';
import {
	ADD_TO_CAMPAIGN_BATCH,
	APPLY_TAGS_BATCH,
	BATCH_ACTION_TITLE,
	DELETE_BATCH,
	MARK_AS_READ_BATCH,
	MARK_AS_UNREAD_BATCH,
	SELECTED_ITEMS_COUNT,
} from '@copilot/common/pages/inboxV2/components/listing/constants';
import isEmpty from 'lodash/isEmpty';
import { Button, Dropdown, Flex, Popconfirm, Space, Spin, Tooltip } from 'antd';
import { useMemo, useState } from 'react';
import { InboxMessageType } from '@copilot/common/pages/inbox/data/types';
import styles from './inboxV2List.module.less';
import isNil from 'lodash/isNil';
import { CampaignModel } from '@copilot/common/store/selectors/campaign';
import useInboxV2 from '../../useInboxV2';

const WARNING_COLOR = '#ffc32e';
// TODO: Integrate callbacks for batch actions (COPILOT-7903)

type NurtureCampaignOption = { label: string; key: string; campaignMemberId?: string };

type BatchActionsProps = {
	selected: InboxMessageType[];
	onClearSelection: () => void;
	nurtureCampaigns: CampaignModel[];
	selectedOrgMembers: Set<string>;
};

/**
 * Batch actions drawers for the inbox
 * @param param0
 * @returns
 */
export default function BatchActions({
	selected,
	onClearSelection,
	nurtureCampaigns = [],
	selectedOrgMembers,
}: BatchActionsProps) {
	const [isLoading, setIsLoading] = useState(false);

	const {
		onDeleteMessages: handleDeleteMessages,
		onMarkAsRead: handleMarkAsRead,
		onMarkAsUnread: handleMarkAsUnread,
		onApplyTags: handleApplyTags,
		onMoveThreadsToNurtureCampaign: handleMoveThreadsToNurtureCampaign,
	} = useInboxV2();

	const campaignOptions: NurtureCampaignOption[] = useMemo(() => {
		if (selectedOrgMembers.size > 1) {
			return [];
		} else {
			return nurtureCampaigns
				.sort((a, b) => a.name.localeCompare(b.name))
				.reduce((acc, campaign) => {
					acc.push({
						label: campaign.name,
						key: campaign.id,
						campaignMemberId: campaign.members
							.toModelArray()
							.find((member) => selectedOrgMembers.has(member.orgMemberId))?.id,
					});
					return acc;
				}, [] as NurtureCampaignOption[])
				.sort(
					(a, b) =>
						Number(!isEmpty(b.campaignMemberId)) - Number(!isEmpty(a.campaignMemberId))
				);
		}
	}, [nurtureCampaigns, selectedOrgMembers]);

	async function onMarkAsRead() {
		setIsLoading(true);
		await handleMarkAsRead(selected.map((message) => message.threadId));
		setIsLoading(false);
		onClearSelection();
	}

	async function onMarkAsUnread() {
		setIsLoading(true);
		await handleMarkAsUnread(selected.map((message) => message.threadId));
		setIsLoading(false);
		onClearSelection();
	}

	async function onDeleteMessages() {
		setIsLoading(true);
		await handleDeleteMessages(selected.map((message) => message.threadId));
		setIsLoading(false);
		onClearSelection();
	}

	async function onApplyTags() {
		await handleApplyTags(
			selected.map((message) => {
				return { id: message?.connectionId ?? '', name: message?.contactName ?? '' };
			})
		);
		onClearSelection();
	}

	async function onAddToCampaign(campaignId: string, campaignMemberId: string) {
		setIsLoading(true);
		await handleMoveThreadsToNurtureCampaign(
			campaignId,
			campaignMemberId,
			selected
				.filter((thread) => thread.campaignId != campaignId)
				.map((thread) => thread.connectionId)
		);
		setIsLoading(false);
		onClearSelection();
	}

	const tooManyOrgMembersSelected = selectedOrgMembers.size > 1;
	const canAddToCampaign = !tooManyOrgMembersSelected;

	return (
		<HidableBottomPanel hidden={isEmpty(selected)}>
			<Flex className={styles.batchActionsContainer}>
				<Space wrap>
					{isLoading && <Spin indicator={<LoadingOutlined />} />}
					<Text>{BATCH_ACTION_TITLE}</Text>
					<Button onClick={() => void onApplyTags()} disabled={isLoading}>
						{APPLY_TAGS_BATCH}
					</Button>
					<Button onClick={() => void onMarkAsRead()} disabled={isLoading}>
						{MARK_AS_READ_BATCH}
					</Button>
					<Button onClick={() => void onMarkAsUnread()} disabled={isLoading}>
						{MARK_AS_UNREAD_BATCH}
					</Button>
					<Popconfirm
						title={
							<>
								<Text>
									Deleting this message will remove it from your inbox completely.
								</Text>
								<Text>You can still find it in Connections.</Text>
							</>
						}
						placement={'topRight'}
						okText="Delete"
						onConfirm={() => void onDeleteMessages()}
						disabled={isLoading}
					>
						<Button disabled={isLoading}>{DELETE_BATCH}</Button>
					</Popconfirm>

					<Dropdown
						disabled={!canAddToCampaign || isLoading}
						menu={{
							items: campaignOptions.map(({ key, label, campaignMemberId }) => {
								const selectable = !isEmpty(campaignMemberId);
								return {
									label: (
										<>
											{label}{' '}
											{!selectable ? (
												<Tooltip
													title={
														'The selected team member is not part of this campaign.'
													}
												>
													<ExclamationCircleTwoTone
														twoToneColor={WARNING_COLOR}
													/>
												</Tooltip>
											) : undefined}
										</>
									),
									key,
									disabled: !selectable,
									onClick: () => {
										!isNil(campaignMemberId)
											? onAddToCampaign(key, campaignMemberId)
											: undefined;
									},
								};
							}),
						}}
						trigger={['click']}
						overlayClassName={styles.campaignDropdown}
					>
						<Tooltip
							title={
								canAddToCampaign
									? ''
									: 'Can only move conversations belonging to one team member at a time.'
							}
							trigger="hover"
						>
							<Button disabled={!canAddToCampaign || isLoading}>
								<Space>
									{ADD_TO_CAMPAIGN_BATCH}
									<DownOutlined />
								</Space>
							</Button>
						</Tooltip>
					</Dropdown>
				</Space>
				<Text>{`${SELECTED_ITEMS_COUNT} ${selected.length}`}</Text>
			</Flex>
		</HidableBottomPanel>
	);
}
