import styled from 'styled-components';
import BasicContainer from '@copilot/common/components/containers/basic';
import { spacingLG, spacingMD } from '@copilot/common/constant/commonStyles';
import { Col, Row, Space, Typography } from 'antd';
import { ReactNode } from 'react';

const StyledBasicContainer = styled(BasicContainer)`
	&.pageHeader {
		padding: ${spacingMD} ${spacingLG};
	}
`;

type PageContainerPropsType = {
	/* Page header */
	header: ReactNode;
	/* Page content */
	content: ReactNode;
};

/**
 * [Presentational] Page Layout Component
 * @param props
 * @constructor
 */
export function PageContainer(props: PageContainerPropsType): JSX.Element {
	const { header, content } = props;
	return (
		<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
			<StyledBasicContainer className="pageHeader">
				<Row justify="space-between" align="middle">
					<Col>
						<Typography.Title level={4} style={{ fontWeight: 500, margin: '0px' }}>
							{header}
						</Typography.Title>
					</Col>
				</Row>
			</StyledBasicContainer>
			{content}
		</Space>
	);
}
