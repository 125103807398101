import React, { useCallback } from 'react';
import BasicContainer from '@copilot/common/components/containers/basic';
import { useFetch } from '@copilot/common/hooks/common';
import { ActivityManager, OutboxManager } from '@copilot/data';
import ScheduledMessageTable from '@copilot/common/components/tables/tableTypes/scheduledMessage';
import { ScheduledMessageActions } from '@copilot/common/store/actions/scheduledMessage';
import { QueryObject } from '@copilot/data/managers/base';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduledMessageSelectors } from '@copilot/common/store/selectors/scheduledMessage';
import { addSorterToQuery } from '@copilot/common/utils/tables';
import styled from 'styled-components';
import { ScheduledMessage } from '@copilot/common/store/models/redux';
import notificationManager from '@copilot/common/utils/notificationManager';
//view types - campaign, sentiment, email

interface ScheduledOutgoingProps {
	orgId: string;
	orgMemberId: string;
	isOrgAdmin: boolean;
	isImpersonate: boolean;
}

const Content = styled(BasicContainer.Content)`
	background-color: ${(props) => props.theme['@layout-body-background']};
	padding: 0 ${(props) => props.theme['@card-padding-base']};
`;

/**
 * Page for Scheudled Outgoing Page
 * @param orgId organization id
 * @param orgMemberId orgMemberId
 * @param isOrgAdmin check if user is an orgAdmin
 * @param isImpersonate check if CS or Admin is impersonating
 */

const ScheduledOutgoing: React.FC<ScheduledOutgoingProps> = (props) => {
	const { orgId, orgMemberId, isOrgAdmin, isImpersonate } = props;
	const dispatch = useDispatch();
	const scheduledGetter = React.useCallback<typeof OutboxManager.getScheduledMessages>(
		(memberId, query) => {
			if (isOrgAdmin && !isImpersonate) memberId = null;
			return OutboxManager.getScheduledMessages(memberId, query);
			//TODO if we want to have a separate route for ADMIN and CS to retrieve scheduled then
			//     add here
		},
		[orgMemberId, isOrgAdmin, isImpersonate]
	);
	const [currentPage, setCurrentPage] = React.useState<number | undefined>(1);
	const [scheduledFetch, fetchScheduledMessages] = useFetch(
		scheduledGetter,
		ScheduledMessageActions.loadScheduledMessages,
		(r) => r.results
	);
	const scheduledTotal = scheduledFetch.data ? scheduledFetch.data.totalCount : 0;
	const scheduledResults = scheduledFetch.data ? scheduledFetch.data.results : [];

	React.useEffect(() => {
		const query = new QueryObject();
		fetchScheduledMessages(orgMemberId, query);
	}, [orgMemberId]);

	const handleScheduledTableChange = React.useCallback<
		NonNullable<React.ComponentProps<typeof ScheduledMessageTable>['onChange']>
	>(
		(pagination, filters, sorter) => {
			const query = new QueryObject({
				page: (pagination.current ?? 1) - 1,
				pageSize: pagination.pageSize,
			});
			setCurrentPage(pagination.current);
			addSorterToQuery(sorter, query);
			fetchScheduledMessages(orgMemberId, query);
		},
		[orgMemberId]
	);

	const displayedScheduledMessages = useSelector(
		ScheduledMessageSelectors.getScheduledMessagesByIds(scheduledResults.map((c) => c.id))
	);

	const handleDismiss = useCallback(
		async (record: ScheduledMessage) => {
			try {
				const response = await ActivityManager.deleteScheduledMsgFromLinkedInProfile(
					record.contactId,
					record.id
				);
				if (response)
					dispatch(ScheduledMessageActions.deleteScheduledMessage({ id: record.id }));
			} catch (err) {
				notificationManager.showErrorNotification({
					message: 'Message Error',
					description: 'Unable to delete message',
				});
			}
		},
		[dispatch]
	);

	return (
		<Content>
			<ScheduledMessageTable
				orgId={orgId}
				isOrgAdmin={isOrgAdmin || isImpersonate}
				dataSource={displayedScheduledMessages}
				pagination={{ total: scheduledTotal, current: currentPage }}
				onChange={handleScheduledTableChange}
				loading={scheduledFetch.isFetching}
				onDismiss={(record) => {
					handleDismiss(record);
				}}
			/>
		</Content>
	);
};
export default ScheduledOutgoing;
