import { batch, useDispatch, useSelector } from 'react-redux';
import {
	applyTagsToInboxMessages,
	dismissInboxMessagesSuccess,
	loadActionsMessageCount,
	loadMemberActionsMessageCount,
	markInboxMessagesAsReadSuccess,
	migrateConnectionTags,
	migrateConnections,
	updateInboxMessagesSuccess,
} from '../inbox/data/actionCreators';
import { InboxMessageType } from '../inbox/data/types';
import { InboxManager } from '@copilot/data';
import InboxMessageSelectors from '../inbox/data/selector';
import { convertFilterToRequestModel, getInboxCount } from '../inbox/data/helper';
import isNil from 'lodash/isNil';
import notificationManager from '@copilot/common/utils/notificationManager';
import { NotificationContent } from '@copilot/common/constant/notificationContent';
import drawerManager from '@copilot/common/utils/drawerManager';
import { FiltersRequestModel } from '@copilot/data/requests/models';
import modalManager from '@copilot/common/utils/modalManager';
import { CampaignFields } from '@copilot/common/store/models/redux';

/**
 * Hook to get handlers for actions performed against inbox messages
 * @returns Actions performed against inbox messages
 */
export default function useMessageActions() {
	const dispatch = useDispatch();
	const { filter, viewType, csOrgId } = useSelector(InboxMessageSelectors);
	function applyTagsToMessages(connections: { id: string; name: string }[]): Promise<void> {
		return new Promise((resolve) => {
			drawerManager.openTagsApplyDrawerSaga({
				connections,
				onApplyTag: (
					connectionIds: string[],
					tags: string[],
					overrideTags: boolean,
					filterRequest?: Partial<FiltersRequestModel>
				) => {
					return dispatch(
						applyTagsToInboxMessages(
							connectionIds,
							tags,
							csOrgId!,
							overrideTags,
							filterRequest,
							() => {
								resolve();
							}
						)
					);
				},
			});
		});
	}

	/**
	 * delete (archive) an array of messages
	 * @param messages
	 */
	async function deleteMessages(messages: InboxMessageType[]) {
		if (isNil(viewType)) throw new Error('View type must be set to archive messages');
		const threadIds = messages.map((m) => m.threadId);
		await deleteMessagesByThreadId(threadIds);
	}

	/**
	 * delete (archive) an array of messages by the message's thread id
	 * @param threadIds
	 */
	async function deleteMessagesByThreadId(threadIds: string[]) {
		if (isNil(viewType)) throw new Error('View type must be set to archive messages');
		try {
			const calculatedFilter = convertFilterToRequestModel(filter, viewType);
			await InboxManager.archiveMessage(threadIds);
			const messageCounts: Record<string, number> = await getInboxCount(
				csOrgId,
				viewType,
				calculatedFilter
			);
			notificationManager.showSuccessNotification(NotificationContent.DeleteMessage.Success);
			dispatch(dismissInboxMessagesSuccess(threadIds, messageCounts));
		} catch {
			notificationManager.showErrorNotification(NotificationContent.DeleteMessage.Fail);
		}
	}

	/**
	 * Mark an array of messages as being read
	 * @param messages
	 */
	function markAsRead(messages: InboxMessageType[], isBatchAction = false) {
		markAsReadByThreadId(
			messages.map((m) => m.threadId),
			isBatchAction
		);
	}

	async function markAsReadByThreadId(threadIds: string[], showSuccessNotification = false) {
		try {
			await InboxManager.setRead(threadIds, true);
			batch(() => {
				dispatch(markInboxMessagesAsReadSuccess(threadIds, true));
				dispatch(loadActionsMessageCount());
				dispatch(loadMemberActionsMessageCount());
			});
			if (showSuccessNotification) {
				notificationManager.showSuccessNotification(NotificationContent.MarkAsRead.Success);
			}
		} catch (e) {
			console.error('Error marking as read', e);
		}
	}

	/**
	 * Mark as unread by thread id
	 * @param threadIds
	 */
	async function markAsUnReadByThreadId(threadIds: string[]) {
		try {
			await InboxManager.setRead(threadIds, false);
			batch(() => {
				dispatch(markInboxMessagesAsReadSuccess(threadIds, false));
				dispatch(loadActionsMessageCount());
				dispatch(loadMemberActionsMessageCount());
			});
			notificationManager.showSuccessNotification(NotificationContent.MarkAsRead.Success);
		} catch (e) {
			console.error('Error marking as unread', e);
		}
	}

	/**
	 * Mark a reminder as complete
	 * @param message
	 */
	async function markReminderAsComplete(messages: InboxMessageType[]) {
		if (messages.length !== 1 || isNil(viewType))
			throw new Error('Marking reminder does not support bulk updates.');
		const calculatedFilter = convertFilterToRequestModel(filter, viewType);
		const threadId = messages[0].threadId;
		try {
			await InboxManager.completeRemindedThread(threadId);
			const messageCounts: Record<string, number> = await getInboxCount(
				csOrgId,
				viewType,
				calculatedFilter
			);
			dispatch(
				updateInboxMessagesSuccess(
					threadId,
					{ isSnoozed: undefined, reminder: undefined },
					messageCounts
				)
			);
			notificationManager.showSuccessNotification(
				NotificationContent.CompleteRemindedThread.Success
			);
			dispatch(loadActionsMessageCount());
			dispatch(loadMemberActionsMessageCount());
		} catch (e) {
			console.error('Error marking reminder as complete', e);
		}
	}

	function onMigrateConnectionToNurtureCampaign(
		campaign: CampaignFields,
		_: string,
		connectionIds: string[]
	) {
		return new Promise((resolve, reject) => {
			dispatch(
				migrateConnections(connectionIds, campaign.id, {
					onSuccess: (migrationResponse) => resolve(migrationResponse),
					onError: () => reject(),
				})
			);
		});
	}

	function onTagMigratedConnections(
		createCampaignTag: boolean,
		selectedTags: string[],
		connectionIds: string[]
	) {
		return new Promise((resolve, reject) => {
			dispatch(
				migrateConnectionTags(
					createCampaignTag,
					selectedTags,
					connectionIds,
					isNil(csOrgId) ? null : csOrgId,
					{
						onSuccess: (migrationResponse) => resolve(migrationResponse),
						onError: () => reject(),
					}
				)
			);
		});
	}

	function moveThreadsToNurtureCampaign(
		targetNurtureCampaignId: string,
		campaignMemberId: string,
		connectionsToMoveIds: string[]
	): Promise<void> {
		return new Promise((resolve) => {
			modalManager.openProspectsMigrationModal({
				campaignId: targetNurtureCampaignId,
				campaignMemberId,
				connectionIds: connectionsToMoveIds,
				onMigrate: onMigrateConnectionToNurtureCampaign,
				onMigrateTag: onTagMigratedConnections,
				// connectionsFilter,
				onComplete: () => {
					resolve();
				},
			});
		});
	}

	return {
		applyTagsToMessages,
		deleteMessages,
		deleteMessagesByThreadId,
		markAsRead,
		markAsReadByThreadId,
		markReminderAsComplete,
		markAsUnReadByThreadId,
		moveThreadsToNurtureCampaign,
	};
}
