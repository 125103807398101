import React from 'react';
import EmailNotificationSettings from './notifications';
import EmailAddressSettings from './address';
import { Col, Row } from 'antd';

interface EmailSettingsProps {
	user: {
		id: string;
		alternateEmail: string;
		cc: string[];
		bcc: string[];
		isNotificationEnable: boolean;
	};
}

const EmailSettings: React.FC<EmailSettingsProps> = (props) => {
	const { user } = props;
	return (
		<>
			<Row>
				<Col span={12}>
					<EmailAddressSettings user={user} />
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<EmailNotificationSettings user={user} />
				</Col>
			</Row>
		</>
	);
};

export default EmailSettings;
