import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { RootState } from '@copilot/common/store/reducers';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';

type MixpanelProviderProps = {
	children: ReactNode;
	token?: string;
	isEnabled: boolean;
};

declare global {
	interface Window {
		mixpanel?: Mixpanel;
	}
}

/**
 * Provider for MixPanel
 * @param children
 * @param token
 * @param isEnabled
 * @constructor
 */
export default function MixpanelProvider({ children, token, isEnabled }: MixpanelProviderProps) {
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeImpersonatorId = useSelector((state: RootState) => state.app.impersonatorId);
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const canEnable = isEnabled && token;

	useEffect(() => {
		if (canEnable) {
			try {
				mixpanel.init(token, {
					ignore_dnt: true,
					debug: false,
					persistence: 'localStorage',
					// TODO COPILOT-7601: add forwarding service
					// api_host: `${window.origin}/mixpanel`,
				});
				window.mixpanel = mixpanel;
			} catch (e) {
				console.error('Failed to initialize mix panel');
			}
		}
	}, []);

	useEffect(() => {
		if (canEnable && activeMember && activeOrganization) {
			try {
				void window.mixpanel?.identify(activeImpersonatorId ?? activeMember.userId);
				if (isEmpty(activeImpersonatorId)) {
					void window.mixpanel?.people.set({
						organizationId: activeMember.organizationId,
						orgMemberId: activeMember.id,
						orgType: activeMember.orgType,
						orgRole: head(activeMember.orgRoles) ?? '',
						isFreeTrial: activeOrganization?.isFreeTrial ?? false,
						// Reserved profile properties for better lookup in mixpanel
						// https://docs.mixpanel.com/docs/data-structure/user-profiles#reserved-profile-properties
						$name: activeMember.name,
						$created: activeMember.userCreatedDate,
						$email: activeMember.email,
					});
				}
			} catch (e) {
				console.error('Failed to initialize user in mix panel');
			}
		}
	}, [activeMember?.userId, activeOrganization?.isFreeTrial, activeImpersonatorId]);

	return <>{children}</>;
}
