// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AJGqc_Wpp3wo7F95H13Q {\n  font-weight: normal;\n  user-select: none;\n  min-height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/layouts/headerSider/sidebar/sidebar.module.less"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,iBAAA;EACA,gBAAA;AACD","sourcesContent":[".sidebar {\n\tfont-weight: normal;\n\tuser-select: none;\n\tmin-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "AJGqc_Wpp3wo7F95H13Q"
};
export default ___CSS_LOADER_EXPORT___;
