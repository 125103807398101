import InsightsBody from './components/body';
import InsightsHeader from './components/header';
import {
	INSIGHT_FAILURE_CODES,
	InsightFailureCode,
	getFailureStatus,
	getInsights,
	getRemainingInsightsCredits,
	toInsights,
} from './services/insightsService';
import { useParams } from 'react-router';
import { useFetch } from '@copilot/common/hooks/common';
import { useEffect } from 'react';
import { PersonalizedInsightsLoadingScreen } from '../personalityInsights/loadingScreen/loadingScreen';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import { Config } from '@copilot/common/config';
import styles from './personalizedInsights.module.less';

const ERROR_MESSAGES: Record<InsightFailureCode, string> = {
	[INSIGHT_FAILURE_CODES.ERROR]:
		"It looks like we ran into an issue. Please refresh the page and we'll try again.",
	[INSIGHT_FAILURE_CODES.INSUFFICIENT_DATA]:
		"Uh oh... It seems like this LinkedIn profile doesn't have enough information to generate insights.",
	[INSIGHT_FAILURE_CODES.PROFILE_MISSING]:
		"Hmm... It looks like this profile doesn't exist anymore.",
	[INSIGHT_FAILURE_CODES.CONFLICT]:
		"It looks like we ran into an issue. Please refresh the page and we'll try again.",
} as const;

/**
 * Page showing the personalized insights (eg communication style) for a connection, using our in house model
 * @returns
 */
function PersonalizedInsightsPage() {
	const { profileId } = useParams<Record<'profileId', string>>();
	const [{ data, error }, fetchAnalysis] = useFetch(getInsights);
	const [{ data: creditData, error: creditError }, fetchRemainingCredits] = useFetch(
		getRemainingInsightsCredits
	);
	useEffect(() => {
		if (profileId) {
			fetchAnalysis(profileId).then(() => fetchRemainingCredits());
		}
	}, [profileId]);

	const insights = !isNil(data) && !isNil(data.analysis) ? toInsights(data) : null;
	const errorStatus: number | undefined = isNumber(error?.status) ? error.status : undefined;
	const { hasFailed, failureCode } = getFailureStatus(data?.analysisStatus, errorStatus);

	return (
		<div className={styles.insightsWrapper}>
			{insights ? (
				<>
					<InsightsHeader
						insights={insights}
						communicationStyleHelpArticleUrl={Config.communicationStyleHelpArticleUrl}
						remainingInsightsCredits={creditData?.data?.available}
						creditCountFailed={!isEmpty(creditError)}
					/>
					<InsightsBody insights={insights} />
				</>
			) : (
				<PersonalizedInsightsLoadingScreen
					hasLoadingFailed={hasFailed}
					loadingFailureReason={failureCode ? ERROR_MESSAGES[failureCode] : ''}
				/>
			)}
		</div>
	);
}

export default PersonalizedInsightsPage;
