import { Menu } from 'antd';
import { BarChartOutlined, ContactsOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import styles from './sidebar.module.less';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';
import { Link } from 'react-router-dom';
import { last, values } from 'lodash';
import isNil from 'lodash/isNil';

const Paths = {
	CustomerAccount: '/dashboard',
	CampaignsReport: '/dashboard/campaigns-report',
	CampaignsList: '/dashboard/campaigns-list',
	CampaignsProspecting: '/dashboard/campaigns-prospecting',
	CampaignsNurture: '/dashboard/campaigns-nurture',
} as const;

/**
 * Represents an Account Dashboard Sidebar component.
 * It is a functional component that renders a sidebar menu for an account dashboard.
 *
 * @return {JSX.Element} Returns the rendered sidebar menu component.
 */
export function AccountDashboardSidebar(): JSX.Element {
	const location = useLocation();
	const activeKey = last(values(Paths).filter((l) => location.pathname.includes(l)));
	const isAccountDataEnabled = useFeatureToggle(Features.AccountDataFeature);
	const customerAccount = {
		icon: <ContactsOutlined />,
		label: <Link to={Paths.CustomerAccount}>Customer Accounts</Link>,
		key: Paths.CustomerAccount,
	};
	const extraOptions = isAccountDataEnabled
		? [
				{
					icon: <BarChartOutlined />,
					label: <Link to={Paths.CampaignsReport}>Campaigns Report</Link>,
					key: Paths.CampaignsReport,
				},
				{
					icon: <UnorderedListOutlined />,
					label: 'Campaigns',
					key: Paths.CampaignsList,
					children: [
						{
							label: <Link to={Paths.CampaignsProspecting}>Prospecting</Link>,
							key: Paths.CampaignsProspecting,
						},
						{
							label: <Link to={Paths.CampaignsNurture}>Nurture</Link>,
							key: Paths.CampaignsNurture,
						},
					],
				},
		  ]
		: [];

	return (
		<Menu
			theme={'light'}
			mode={'inline'}
			className={styles.sidebar}
			defaultOpenKeys={[Paths.CampaignsList]}
			selectedKeys={isNil(activeKey) ? [] : [activeKey]}
			items={[customerAccount, ...extraOptions]}
		/>
	);
}
