import * as React from 'react';
import {
	ColumnDefinition,
	ColumnTypes,
	StatusWithPopoverColumnDefinition,
	LinkActionColumnDefinition,
} from '../../componentModels/columnDefinition';
import ModelTableModule, { IModelTableModuleProps } from '../index';
import { CustomFieldActions } from '../../componentModels/columnTypeDefinition';
import { IOutboxMessage } from '@copilot/common/store/models/redux';
import { ThreadStatus, LinkedInSentMessageError } from '@copilot/common/store/models/const/enum';
import { QuestionCircleTwoTone, RedoOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Popover, Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import { OutboxManager } from '@copilot/data';
import { LinkedInMessageStatus } from '@copilot/data/responses/interface';
import Store from '@copilot/common/store';
import { OutboxActions } from '@copilot/common/store/actions/outbox';
import { ISendError, SendErrorMap } from '@copilot/common/store/models/const/map';
import AntTheme from '@copilot/common/config/antd.theme';
import { FC } from 'react';
import {
	OUTBOX_DISMISS_TRACKING_ID,
	OUTBOX_RETRY_TRACKING_ID,
} from '@copilot/common/tracking/userpilotEventConsts';

interface IErrorProps {
	errorName: string;
	popupContents: React.ReactNode;
}

/**
 * [Presentational] An error row in the table
 * @param {string} errorName name that is displayed in the table
 * @param {React.Component} popupContents the component to be displayed in popup on hover
 */
const Error: FC<IErrorProps> = (props) => {
	const { errorName, popupContents } = props;
	return (
		<>
			<Text style={{ marginRight: '7px' }}>{errorName}</Text>
			<Popover
				placement="top"
				content={popupContents}
				trigger="hover"
				overlayStyle={{ width: '25em' }}
			>
				<QuestionCircleTwoTone
					style={{ fontSize: '18px' }}
					twoToneColor={`${AntTheme['@error-color']}`}
				/>
			</Popover>
		</>
	);
};

interface ErrorTableProps extends IModelTableModuleProps<IOutboxMessage> {
	orgId: string;
}

interface ErrorTableState {}

class ErrorTable extends React.Component<ErrorTableProps, ErrorTableState> {
	constructor(props: ErrorTableProps) {
		super(props);
	}

	determineStatusCol = (record: [IOutboxMessage['status'], IOutboxMessage['error']]) => {
		let html = <span />;
		const status = record[0] ? record[0] : ThreadStatus.Pending;
		const errorType = record[1] ? record[1] : '';
		if (status === ThreadStatus.Failed) {
			const error = ((SendErrorMap as any)[errorType] || SendErrorMap.Default) as ISendError;
			html = <Error errorName={error.name} popupContents={error.message} />;
		}
		return html;
	};

	determineLinkDisplay = (actionType: CustomFieldActions, record: any) => {
		const html = <span />;
		const id = record[0] as string;
		const status = record[1] as ThreadStatus;
		if (
			actionType == CustomFieldActions.Retry &&
			id &&
			id != '' &&
			status &&
			status == ThreadStatus.Failed
		) {
			return (
				<Button
					type="primary"
					block
					onClick={(event) => {
						event.stopPropagation();
						this.retrySend(id);
					}}
					data-tracking-id={OUTBOX_RETRY_TRACKING_ID}
				>
					<RedoOutlined />
					Retry
				</Button>
			);
		} else if (
			actionType == CustomFieldActions.Dismiss &&
			id &&
			id != '' &&
			status &&
			status == ThreadStatus.Failed
		) {
			return (
				<Button
					block
					onClick={(event) => {
						event.stopPropagation();
						this.dismissRecord(id);
					}}
					data-tracking-id={OUTBOX_DISMISS_TRACKING_ID}
				>
					<CloseCircleOutlined />
					Dismiss
				</Button>
			);
		}

		return html;
	};

	retrySend = (id: string) => {
		OutboxManager.updateOutboxMessageStatus([id], LinkedInMessageStatus.Pending).then(() => {
			this.updateItem(id);
		});
	};

	dismissRecord = (id: string) => {
		OutboxManager.updateOutboxMessageStatus([id], LinkedInMessageStatus.Canceled).then(() => {
			this.removeItem(id);
		});
	};

	updateItem = (id: string) => {
		const template = {
			id,
			status: ThreadStatus.Pending,
			error: LinkedInSentMessageError.NoError,
		} as Partial<IOutboxMessage>;
		Store.Dispatch(OutboxActions.updateOutboxMessage(template));
	};

	removeItem = (id: string) => {
		Store.Dispatch(OutboxActions.deleteOutboxMessage({ id } as Partial<IOutboxMessage>));
	};

	customizeColumns = (columns: any) =>
		columns.map((c: ColumnDefinition) => this.mapColumnActions({ ...c }));

	mapColumnActions = (column: any): ColumnDefinition => {
		if (column.type == ColumnTypes.StatusWithPopover) {
			(column as StatusWithPopoverColumnDefinition).determineStatusIcon =
				this.determineStatusCol;
		}

		if (column.type == ColumnTypes.LinkAction) {
			(column as LinkActionColumnDefinition).determineLinkDisplay = this.determineLinkDisplay;
		}

		return column;
	};

	retrieveOutgoingMessage = () =>
		// todo get outgoing messages

		[];

	render() {
		const { ...rest } = this.props;
		const customizedColumns = this.customizeColumns(this.props.columns);
		return (
			<ModelTableModule<IOutboxMessage>
				{...rest}
				columns={customizedColumns}
				data={this.props.data}
				title="Failed Outgoing Messages"
				rowClassName={(record: IOutboxMessage) =>
					record.status && record.status == ThreadStatus.Failed ? 'flag' : ''
				}
			/>
		);
	}
}

export default ErrorTable;
