import { AutoComplete, Input, InputRef, Spin } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useRef, useState } from 'react';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { NO_RESULT_MESSAGE } from '@copilot/common/pages/accountDashboard/const';
import { ZoomOutOutlined } from '@ant-design/icons';
import styles from './searchAutoComplete.module.less';

export interface SearchOptionType extends DefaultOptionType {
	key: string;
}

interface SearchAutoCompleteProps {
	/**
	 * Autocomplete options that shows in a dropdown
	 */
	options?: SearchOptionType[];
	/**
	 * The placeholder of input
	 */
	placeholder: string;
	/**
	 * Current value of the search
	 */
	value: string;
	/**
	 * Called when invoking search
	 */
	onSearch: (val: string, option: SearchOptionType) => void;
	/**
	 * Called when changing the input value
	 */
	onChange: (val: string) => void;
	/**
	 * Is fetching data for autocomplete options
	 */
	isLoadingOptions?: boolean;
}

export default function (props: SearchAutoCompleteProps) {
	const { placeholder, value, options, onSearch, onChange, isLoadingOptions } = props;
	const [dropDownOpen, setDropdownOpen] = useState(false);
	const input = useRef<InputRef | null>(null);
	const emptyMessage = (
		<div className={styles.searchBoxEmpty}>
			{isLoadingOptions ? (
				<div className={styles.searchBoxLoading}>
					<Spin />
				</div>
			) : (
				<div>
					<ZoomOutOutlined /> {NO_RESULT_MESSAGE}
				</div>
			)}
		</div>
	);

	return (
		<AutoComplete
			value={value}
			options={options}
			filterOption={true}
			open={dropDownOpen}
			notFoundContent={emptyMessage}
			onChange={(v, o) => {
				setDropdownOpen(true);
				if (isEmpty(o)) onChange(v);
			}}
			onSelect={(v, o) => {
				onSearch(v, o);
				input.current?.blur();
				setDropdownOpen(false);
			}}
			onFocus={() => setDropdownOpen(true)}
			onBlur={() => setDropdownOpen(false)}
			popupClassName={styles.searchBoxPopup}
		>
			<Input.Search
				ref={input}
				size="middle"
				placeholder={placeholder}
				loading={!isNil(isLoadingOptions) ? isLoadingOptions : false}
				onSearch={() => {
					setDropdownOpen(false);
				}}
			/>
		</AutoComplete>
	);
}
