import { IncludeExcludeFilterOption, IncludeExcludeFilterProps } from './types';
import { Button, Tag, Dropdown, Typography, Empty } from 'antd';
import { useEffect, useState } from 'react';
import {
	EXCLUDED_SECTION_HEADER,
	INCLUDED_SECTION_HEADER,
	IncludeExcludeFilterOptionState,
	IncludeExcludeFilterOptionStates,
	NO_FILTER_RESULTS,
	NO_FILTER_RESULTS_FOR_SEARCH_TERM,
	UNSELECTED_SECTION_HEADER,
	INCLUDE_EXCLUDE_FILTER_TEST_IDS,
} from './constants';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import partial from 'lodash/partial';
import styles from './includeExcludeFilter.module.less';
import { PlusOutlined, StopOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { FilterOption } from '@copilot/common/components/filters/types';
import { APPLY_FILTERS_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';

/**
 * Component for filtering by included/excluded options
 * @param param0
 * @returns
 */
function IncludeExcludeFilter({
	options,
	selections: externalSelections,
	onChange,
	disabled,
	placeholder,
	menuHeader,
}: IncludeExcludeFilterProps) {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [selections, setSelections] = useState(externalSelections);

	useEffect(() => {
		setSelections(externalSelections);
	}, [externalSelections]);

	function onDropdownVisibleChange(visible: boolean) {
		setIsDropdownOpen(visible);
	}

	function onSave(newSelections: IncludeExcludeFilterOption[]) {
		onChange(newSelections);
		setSelections(newSelections);
		setIsDropdownOpen(false);
	}

	function onClearAll() {
		onChange([]);
		setSelections([]);
		setIsDropdownOpen(false);
	}

	function onCancel() {
		setIsDropdownOpen(false);
	}
	const sortedSelections = selections.sort((a, b) => a.label.localeCompare(b.label));
	const displayedTag = sortedSelections.length > 0 ? selections[0] : null;
	const countTag = sortedSelections.length > 1 ? `+${selections.length - 1}` : null;
	return (
		<div className={styles.filterWrapper}>
			<Dropdown
				destroyPopupOnHide
				dropdownRender={() => (
					<IncludeExcludeMenu
						options={options}
						selections={sortedSelections}
						onCancel={onCancel}
						onSave={onSave}
						onClearAll={onClearAll}
						menuHeader={menuHeader}
					/>
				)}
				trigger={['click']}
				onOpenChange={onDropdownVisibleChange}
				open={isDropdownOpen}
				disabled={disabled}
			>
				<Button
					data-testid={INCLUDE_EXCLUDE_FILTER_TEST_IDS.DROP_DOWN_TAG}
					className={styles.inlineButton}
				>
					{isEmpty(selections) ? (
						<div className={styles.placeholderWrapper}>{placeholder}</div>
					) : (
						<div
							data-testid={INCLUDE_EXCLUDE_FILTER_TEST_IDS.INLINE_BOX}
							className={styles.tagWrapper}
						>
							{!isNil(displayedTag) && (
								<Tag
									className={[
										styles.filterTag,
										displayedTag.isIncluded ? styles.included : styles.excluded,
									].join(' ')}
									key={displayedTag.value}
									data-testid={
										displayedTag.isIncluded
											? INCLUDE_EXCLUDE_FILTER_TEST_IDS.INCLUDED_TAG
											: INCLUDE_EXCLUDE_FILTER_TEST_IDS.EXCLUDED_TAG
									}
									onClose={() =>
										onChange(
											selections.filter((s) => s.value !== displayedTag.value)
										)
									}
									closable
								>
									{displayedTag.label}
								</Tag>
							)}
							{!isNil(countTag) && (
								<Tag
									data-testid={INCLUDE_EXCLUDE_FILTER_TEST_IDS.COUNT_TAG}
									className={styles.filterTag}
								>
									{countTag}
								</Tag>
							)}
						</div>
					)}
					<DownOutlined />
				</Button>
			</Dropdown>
		</div>
	);
}

type IncludeExcludeMenuProps = {
	options: FilterOption[];
	selections: IncludeExcludeFilterOption[];
	onCancel(): void;
	onClearAll(): void;
	onSave(selections: IncludeExcludeFilterOption[]): void;
	menuHeader?: string;
};

/**
 * Menu renderer for IncludeExcludeFilter (eg what is shown in the popup)
 * @param param0
 * @returns
 */
function IncludeExcludeMenu({
	selections: initialSelections,
	options,
	onSave,
	onCancel,
	onClearAll,
	menuHeader,
}: IncludeExcludeMenuProps) {
	const [searchValue, setSearchValue] = useState('');
	const [selections, setSelections] = useState(initialSelections);
	const unselectedOptions = options
		.filter((option) => !selections.some((item) => item.value === option.value))
		.filter((option) => option.label.toLowerCase().includes(searchValue));

	function onSearch(term: string) {
		const newSearchTerm = term.toLowerCase();
		setSearchValue(newSearchTerm);
	}

	function onDeselect(option: IncludeExcludeFilterOption) {
		const updatedSelections = selections.filter((item) => item.value !== option.value);
		setSelections(updatedSelections);
	}

	function onSelectOption(isIncluded: boolean, option: FilterOption) {
		const isAlreadySelected = selections.some((item) => item.value === option.value);
		const updatedSelections = isAlreadySelected
			? [
					...selections.filter((selection) => selection.value != option.value),
					{ ...option, isIncluded },
			  ]
			: [...selections, { ...option, isIncluded }];
		setSelections(updatedSelections);
	}

	const itemHandlers = {
		onInclude: partial(onSelectOption, true),
		onExclude: partial(onSelectOption, false),
		onDeselect,
	};

	const includedOptions = selections.filter(
		(option) => option.isIncluded && option.label.toLowerCase().includes(searchValue)
	);
	const excludedOptions = selections.filter(
		(option) => !option.isIncluded && option.label.toLowerCase().includes(searchValue)
	);

	return (
		<div
			className={styles.filterMenu}
			data-testid={INCLUDE_EXCLUDE_FILTER_TEST_IDS.FILTER_MENU}
		>
			<div className={styles.menuHeader}>
				<Typography.Text>{menuHeader}</Typography.Text>
				<Button
					onClick={onClearAll}
					data-testid={INCLUDE_EXCLUDE_FILTER_TEST_IDS.CLEAR_ALL_BUTTON}
					type="link"
					size="small"
				>
					Clear All
				</Button>
			</div>

			<Search
				placeholder="Search"
				className={styles.searchInput}
				onSearch={onSearch}
				data-testid={INCLUDE_EXCLUDE_FILTER_TEST_IDS.SEARCH_INPUT}
			/>
			<div className={styles.menuBody}>
				{isEmpty(includedOptions) &&
					isEmpty(excludedOptions) &&
					isEmpty(unselectedOptions) && (
						<IncludeExcludeEmptyState hasFilter={!isEmpty(searchValue)} />
					)}
				{!isEmpty(includedOptions) && (
					<div key="included">
						<Typography.Text className={styles.subMenuHeader}>
							{INCLUDED_SECTION_HEADER}
						</Typography.Text>
						<div>
							{includedOptions.map((option) => (
								<IncludeExcludeMenuItem
									key={option.value}
									option={option}
									state={IncludeExcludeFilterOptionStates.INCLUDED}
									{...itemHandlers}
								/>
							))}
						</div>
					</div>
				)}

				{!isEmpty(excludedOptions) && (
					<div key="excluded" title={`Excluded (${excludedOptions.length})`}>
						<Typography.Text className={styles.subMenuHeader}>
							{EXCLUDED_SECTION_HEADER}
						</Typography.Text>
						<div>
							{excludedOptions.map((option) => (
								<IncludeExcludeMenuItem
									key={option.value}
									option={option}
									state={IncludeExcludeFilterOptionStates.EXCLUDED}
									{...itemHandlers}
								/>
							))}
						</div>
					</div>
				)}
				{(isEmpty(excludedOptions) && isEmpty(includedOptions)) ||
				isEmpty(unselectedOptions) ? (
					<>
						{unselectedOptions.map((option) => (
							<IncludeExcludeMenuItem
								key={option.value}
								option={option}
								{...itemHandlers}
							/>
						))}
					</>
				) : (
					<div key="unselected" title={`Unselected (${unselectedOptions.length})`}>
						<Typography.Text className={styles.subMenuHeader}>
							{UNSELECTED_SECTION_HEADER}
						</Typography.Text>
						<div>
							{unselectedOptions.map((option) => (
								<IncludeExcludeMenuItem
									key={option.value}
									option={option}
									{...itemHandlers}
								/>
							))}
						</div>
					</div>
				)}
			</div>

			<div className={styles.filterFooter}>
				<Button onClick={onCancel}>Cancel</Button>
				<Button
					onClick={() => onSave(selections)}
					data-testid={INCLUDE_EXCLUDE_FILTER_TEST_IDS.SAVE_BUTTON}
					type="primary"
					data-tracking-id={APPLY_FILTERS_TRACKING_ID}
				>
					Save
				</Button>
			</div>
		</div>
	);
}

type IncludeExcludeMenuItemProps = {
	option: FilterOption;
	state?: IncludeExcludeFilterOptionState;
	onInclude(option: FilterOption): void;
	onExclude(option: FilterOption): void;
	onDeselect(option: FilterOption): void;
};
/**
 * Individual item in the IncludeExcludeMenu, renders an unselected, included, or excluded state
 * @param param0
 * @returns
 */
function IncludeExcludeMenuItem({
	option,
	state = IncludeExcludeFilterOptionStates.UNSELECTED,
	onInclude,
	onExclude,
	onDeselect,
}: IncludeExcludeMenuItemProps) {
	const { INCLUDED, EXCLUDED, UNSELECTED } = IncludeExcludeFilterOptionStates;

	function getClassName() {
		if (state === EXCLUDED) {
			return styles.excluded;
		} else if (state === INCLUDED) {
			return styles.included;
		} else {
			return '';
		}
	}

	return (
		<div key={option.value} className={[styles.option, getClassName()].join(' ')}>
			<Button
				icon={<PlusOutlined />}
				size="small"
				disabled={state === INCLUDED}
				onClick={() => onInclude(option)}
				data-testid={`${INCLUDE_EXCLUDE_FILTER_TEST_IDS.INCLUDED_BUTTON_PREFIX}${option.value}`}
			/>
			<Button
				icon={<StopOutlined />}
				size="small"
				disabled={state === EXCLUDED}
				onClick={() => onExclude(option)}
				data-testid={`${INCLUDE_EXCLUDE_FILTER_TEST_IDS.EXCLUDED_BUTTON_PREFIX}${option.value}`}
			/>
			{option.label}
			{state !== UNSELECTED && (
				<Button
					className={styles.deselectButton}
					type="text"
					icon={<CloseOutlined />}
					size="small"
					onClick={() => onDeselect(option)}
					data-testid={`${INCLUDE_EXCLUDE_FILTER_TEST_IDS.DESELECT_BUTTON_PREFIX}${option.value}`}
				/>
			)}
		</div>
	);
}

type IncludeExcludeEmptyStateProps = { hasFilter: boolean };

/**
 * Empty state renderer for IncludeExcludeMenu, shows when there are no options due to filtering or lack of data
 * @param param0
 * @returns
 */
function IncludeExcludeEmptyState({ hasFilter }: IncludeExcludeEmptyStateProps) {
	return (
		<div className={styles.emptyState}>
			<Empty
				description={hasFilter ? NO_FILTER_RESULTS_FOR_SEARCH_TERM : NO_FILTER_RESULTS}
			/>
		</div>
	);
}

export default IncludeExcludeFilter;
