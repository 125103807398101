import { DEFAULT_DATE_FORMAT } from '@copilot/common/utils/dateFormat';
import notificationManager from '@copilot/common/utils/notificationManager';
import { getMillisecondsFromNow } from '@copilot/common/utils/dateUtil';
import { InboxManager } from '@copilot/data';
import { useDispatch } from 'react-redux';
import { UseRemindersHandlers } from './types';
import { InboxPageCardViewTrackingParams } from '@copilot/common/pages/inbox/tracking/cardView';
import { InboxPageListViewTrackingParams } from '@copilot/common/pages/inbox/tracking/emailView';
import isNil from 'lodash/isNil';
import { REMINDER_STATUSES } from '@copilot/common/pages/inbox/data/constant';
import { updateInboxMessagesSuccess } from '@copilot/common/pages/inbox/data/actionCreators';

/**
 * Hook for use reminders functionality in inboxV2, updated to work in a listing context
 * @param conversationId: the id of the conversation
 * @param handleTracking: tracking callback for analytics
 * @returns functions to set and cancel reminders
 */
export default function useRemindersV2(
	handleTracking?:
		| ((newParams: InboxPageCardViewTrackingParams) => void)
		| ((newParams: InboxPageListViewTrackingParams) => void),
	onSuccess?: (reminderDate: string | undefined) => void
): UseRemindersHandlers {
	const dispatch = useDispatch();

	function setReminderClick() {
		handleTracking?.({ buttonClicked: 'Set Reminder Clicked' });
	}

	async function setReminder(
		conversationId: string,
		date: moment.Moment,
		isNewReminder: boolean
	) {
		await updateReminder(conversationId, date);
		const interval = getMillisecondsFromNow(date);
		handleTracking?.({
			buttonClicked: isNewReminder ? 'Set Reminder Specified' : 'Edit Reminder',
			interval,
		});
	}

	async function cancelReminder(conversationId: string) {
		await updateReminder(conversationId, undefined);
	}

	async function updateReminder(conversationId: string, date: moment.Moment | undefined) {
		const remindOn = date?.toISOString();

		try {
			await InboxManager.snoozeThread(conversationId, remindOn);
			notificationManager.showSuccessNotification({
				message: date
					? `Reminder set for ${date.format(DEFAULT_DATE_FORMAT)}`
					: 'Reminder Cancelled',
				description: '',
			});
			if (isNil(onSuccess)) {
				dispatch(
					updateInboxMessagesSuccess(conversationId, {
						reminder: !isNil(remindOn)
							? {
									hasReminder: true,
									reminderTimestamp: remindOn,
									reminderType: REMINDER_STATUSES.Upcoming,
							  }
							: undefined,
					})
				);
			} else {
				onSuccess(remindOn);
			}
		} catch (e) {
			notificationManager.showErrorNotification({
				message: date ? 'Failed to set Reminder' : 'Failed to cancel Reminder',
				description: 'Please try again.',
			});
		}
	}

	return { setReminderClick, setReminder, cancelReminder };
}
