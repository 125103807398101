import { CSSProperties, FC } from 'react';

type PointedNotesProps = {
	notes: string[];
	listStyles?: CSSProperties;
	noteStyles?: CSSProperties;
};

/**
 * [Presentational]
 * Point form notes
 * @param props
 * @returns
 */
export const PointedNotes: FC<PointedNotesProps> = (props) => (
	<ul style={{ paddingLeft: '25px', ...props.listStyles }}>
		{props.notes.map((note) => (
			<li key={note} style={{ margin: '10px 0 14px', ...props.noteStyles }}>
				{note}
			</li>
		))}
	</ul>
);
