import { FilterOption } from '@copilot/common/components/filters/types';
import { useCallback, useEffect, useState } from 'react';
import { Meeting } from '../types';
import {
	AI_DETECTED_TAB_KEY,
	CONFIRMED_MEETINGS_TAB_KEY,
	MeetingsBookedTabKey,
} from '../constants';
import { ActiveInactiveFilterOptions } from '@copilot/common/components/filters/organizationFilterSection/types';
import { getDefaultActiveInactiveOptions } from '@copilot/common/components/filters/organizationFilterSection/constants';
import { DEFAULT_MEETING_BOOKED_PAGE_SIZE } from '../tables/constants';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import isNil from 'lodash/isNil';
import { CampaignResponse, PaginatedResponse } from '@copilot/data/responses/interface';
import { CampaignManager, OrganizationManager } from '@copilot/data';
import { CampaignStatusEnum } from '@copilot/data/requests/models';
import { getOrganizationMemberName } from '@copilot/common/utils/organizationMember';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { DateRange } from '../context/types';
import {
	IMeetingStatus,
	useConfirmDetectionMutation,
	useRejectDetectionMutation,
} from '@copilot/data/graphql/_generated';
import { handleOpenPersonalizedInsights } from '@copilot/common/components/drawer/wrappers/contact/informationPanel/util';
import useReplenishableMeetingData from './useBufferedMeetingData';
import notificationManager from '@copilot/common/utils/notificationManager';

/**
 * Data context hook for Meetings Booked page, wraps and provides an interface for all the fetching logic and server-driven data
 * @returns
 */
export default function useMeetingsBookedData() {
	const [selectedTeamMembers, setSelectedTeamMembers] = useState<string[]>([]);
	const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
	const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({});
	const [teamMemberOptions, setTeamMemberOptions] = useState<FilterOption[]>([]);
	const [campaignOptions, setCampaignOptions] = useState<ActiveInactiveFilterOptions>(
		getDefaultActiveInactiveOptions()
	);
	const [rejectDetection] = useRejectDetectionMutation();
	const [confirmDetection] = useConfirmDetectionMutation();

	const [meetingUpdatedLookup, setMeetingUpdatedLookup] = useState<Record<string, boolean>>({});

	const [isInitializingData, setIsInitializingData] = useState<boolean>(true);

	const [isLoadingDetected, setIsLoadingDetected] = useState<boolean>(false);
	const [isLoadingConfirmed, setIsLoadingConfirmed] = useState<boolean>(false);
	const [pageSizeLookUp, setPageSizeLookUp] = useState<Record<MeetingsBookedTabKey, number>>({
		[AI_DETECTED_TAB_KEY]: DEFAULT_MEETING_BOOKED_PAGE_SIZE,
		[CONFIRMED_MEETINGS_TAB_KEY]: DEFAULT_MEETING_BOOKED_PAGE_SIZE,
	});

	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const debouncedRefreshTabContent = useCallback(debounce(refreshTabContent, 1000), []);

	const [currentPageLookUp, setCurrentPageLookup] = useState<
		Record<MeetingsBookedTabKey, number>
	>({
		[AI_DETECTED_TAB_KEY]: 1,
		[CONFIRMED_MEETINGS_TAB_KEY]: 1,
	});

	const {
		meetingBuffer: confirmedMeetings,
		onBulkRemoveFromBuffer: onBulkRemoveFromConfirmedBuffer,
		isLoadingBuffer: isInitializingConfirmed,
		refetchBuffer: refetchConfirmedMeetings,
	} = useReplenishableMeetingData({
		selectedCampaigns,
		selectedTeamMembers,
		selectedDateRange,
		currentPage: currentPageLookUp[CONFIRMED_MEETINGS_TAB_KEY],
		pageSize: pageSizeLookUp[CONFIRMED_MEETINGS_TAB_KEY],
		status: IMeetingStatus.Booked,
	});

	const {
		meetingBuffer: aiDetectedMeetings,
		onRemoveFromBuffer: onRemoveFromDetectedBuffer,
		onBulkRemoveFromBuffer: onBulkRemoveFromDetectedBuffer,
		isLoadingBuffer: isInitializingDetected,
	} = useReplenishableMeetingData({
		selectedCampaigns,
		selectedTeamMembers,
		selectedDateRange,
		currentPage: currentPageLookUp[AI_DETECTED_TAB_KEY],
		pageSize: pageSizeLookUp[AI_DETECTED_TAB_KEY],
		status: IMeetingStatus.Detected,
	});

	/**
	 * Initialize all the filterOptions and initial pages for both meetingsBooked tabs
	 */
	useEffect(() => {
		async function initializeData(orgId: string) {
			try {
				setIsInitializingData(true);
				await Promise.all([onLoadCampaignOptions(orgId), onLoadTeamMemberOptions(orgId)]);
			} catch (_) {
				console.log('There was an issue loading your meetings booked data.');
			}
			setIsInitializingData(false);
		}
		if (!isNil(activeMember)) {
			initializeData(activeMember.organizationId);
		}
	}, [activeMember]);

	/**
	 * Load a new page for a given meetings booked tab
	 * @param tabKey
	 * @param targetPage
	 * @param pageSize
	 */
	function onLoadPage(
		tabKey: MeetingsBookedTabKey,
		targetPage: number,
		pageSize: number,
		params?: { campaigns: string[]; members: string[]; dateRange: DateRange }
	) {
		setCurrentPageLookup({ ...currentPageLookUp, [tabKey]: targetPage });
		setPageSizeLookUp({ ...pageSizeLookUp, [tabKey]: pageSize });
		if (!isNil(params)) {
			setSelectedCampaigns(params.campaigns);
			setSelectedTeamMembers(params.members);
			setSelectedDateRange(params.dateRange);
		}
	}

	/**
	 * Load the team member options for the filter dropdown
	 * @returns
	 */
	async function onLoadTeamMemberOptions(orgId: string) {
		const teamMembers = await OrganizationManager.getMembers(orgId);
		setTeamMemberOptions(
			teamMembers
				.map((m) => {
					return { label: getOrganizationMemberName(m), value: m.id };
				})
				.sort((a, b) => a.label.localeCompare(b.label))
		);
	}

	/**
	 * Load the campaign options for the filter dropdown
	 * @returns
	 */
	async function onLoadCampaignOptions(orgId: string) {
		//TODO-find more sensible way to fetch campaign options here and in inboxC2 flow, below implementation is pretty hacky
		const { results: campaigns }: PaginatedResponse<CampaignResponse> =
			await CampaignManager.getCampaignsByOrganization(orgId, 0, 1000);

		setCampaignOptions(
			campaigns
				.sort((a, b) => a.name.localeCompare(b.name))
				.reduce((acc, campaign) => {
					if (campaign.status === CampaignStatusEnum.Enabled) {
						acc.active.push({ label: campaign.name, value: campaign.id });
					} else {
						acc.inactive.push({ label: campaign.name, value: campaign.id });
					}
					return acc;
				}, getDefaultActiveInactiveOptions())
		);
	}

	/**
	 * Handler for updating teamMember filter state
	 * @param newSelection
	 */
	function onTeamMemberFilterChange(newSelection: string[]) {
		setSelectedTeamMembers(newSelection);
		debouncedRefreshTabContent(selectedCampaigns, newSelection, selectedDateRange);
	}

	/**
	 * Handler for updating campaign filter state
	 * @param newSelection
	 */
	function onCampaignFilterChange(newSelection: string[]) {
		setSelectedCampaigns(newSelection);
		debouncedRefreshTabContent(newSelection, selectedTeamMembers, selectedDateRange);
	}

	/**
	 * Handler for viewing the personalized insights for the contact associated with a meeting
	 * @param newSelection
	 */
	async function onViewPersonalizedInsights(meeting: Meeting) {
		return handleOpenPersonalizedInsights(meeting.linkedInProfileId);
	}

	/**
	 * Handler for adjusting and persisting the page size for a tab
	 * @param tabKey
	 * @param pageSize
	 */
	function onUpdatePageSize(tabKey: MeetingsBookedTabKey, pageSize: number) {
		setPageSizeLookUp({ ...pageSizeLookUp, [tabKey]: pageSize });
	}

	/**
	 * Handler for clearing the global meetingsBooked filters
	 */
	function onClearFilters() {
		setSelectedCampaigns([]);
		setSelectedTeamMembers([]);
		setSelectedDateRange({});
		refreshTabContent([], [], {});
	}

	function refreshTabContent(campaigns: string[], members: string[], dateRange: DateRange) {
		onLoadPage(AI_DETECTED_TAB_KEY, 1, DEFAULT_MEETING_BOOKED_PAGE_SIZE, {
			campaigns,
			members,
			dateRange,
		});
		onLoadPage(CONFIRMED_MEETINGS_TAB_KEY, 1, DEFAULT_MEETING_BOOKED_PAGE_SIZE, {
			campaigns,
			members,
			dateRange,
		});
	}

	function onUpdateDateRange(start: Date | undefined, end: Date | undefined) {
		setSelectedDateRange({ start, end });
		debouncedRefreshTabContent(selectedCampaigns, selectedTeamMembers, { start, end });
	}

	async function onUpdateDetectedMeeting(isBooked: boolean, campaignConnectionId: string) {
		onRemoveFromDetectedBuffer(campaignConnectionId);
		try {
			if (isBooked) {
				const response = await confirmDetection({
					variables: {
						campaignConnectionId,
						isBooked: true,
					},
				});
				if (!isNil(response.errors)) throw new Error('Failed to confirm detection');

				notificationManager.showSuccessNotification({
					message: 'Meeting Booked 🎉',
					description: 'The prospect has been marked as "Meeting Booked"',
				});
			} else {
				const response = await rejectDetection({ variables: { campaignConnectionId } });
				if (!isNil(response.errors)) throw new Error('Failed to confirm detection');
				notificationManager.showSuccessNotification({
					message: 'No meeting booked',
					description: 'The prospect has been removed from the list',
				});
			}
		} catch (e) {
			notificationManager.showErrorNotification({
				message: 'Three was an issue updating the meeting status',
			});
		}

		refetchConfirmedMeetings();
	}

	//when the meeting status is updated in the contact drawer, mark the meeting as updated so we can update the buffer on drawer close
	function onMarkMeetingUpdated(tabKey: string, connectionId: string, isBooked: boolean) {
		//if we are in the confirmed meeting tab, only consider the meeting updated if it was marked as not booked
		setMeetingUpdatedLookup({
			...meetingUpdatedLookup,
			[connectionId]: tabKey === CONFIRMED_MEETINGS_TAB_KEY ? !isBooked : true,
		});
	}

	//when the contact drawer is closed, update the buffer by removing updated meetings
	async function onUpdateOnContactDrawerClose(tabKey: MeetingsBookedTabKey) {
		if (tabKey === AI_DETECTED_TAB_KEY && !isEmpty(Object.keys(meetingUpdatedLookup))) {
			setIsLoadingDetected(true);
			await onBulkRemoveFromDetectedBuffer(meetingUpdatedLookup);
			setIsLoadingDetected(false);
			setIsLoadingConfirmed(true);
			await refetchConfirmedMeetings();
			setIsLoadingConfirmed(false);
		} else if (
			tabKey === CONFIRMED_MEETINGS_TAB_KEY &&
			!isEmpty(Object.keys(meetingUpdatedLookup))
		) {
			setIsLoadingConfirmed(true);
			await onBulkRemoveFromConfirmedBuffer(meetingUpdatedLookup);
			setIsLoadingConfirmed(false);
		}
		setMeetingUpdatedLookup({});
	}

	return {
		//data
		activeMember,
		selectedCampaigns,
		selectedTeamMembers,
		aiDetectedMeetings,
		confirmedMeetings,
		teamMemberOptions,
		campaignOptions,
		detectedPageSize: pageSizeLookUp[AI_DETECTED_TAB_KEY],
		confirmedPageSize: pageSizeLookUp[CONFIRMED_MEETINGS_TAB_KEY],
		//handlers
		onLoadPage,
		onClearFilters,
		onCampaignFilterChange,
		onTeamMemberFilterChange,
		onUpdateDateRange,
		onViewPersonalizedInsights,
		onUpdatePageSize,
		//ui state
		isInitializingData,
		isLoadingDetected: isLoadingDetected || isInitializingDetected,
		isLoadingConfirmed: isLoadingConfirmed || isInitializingConfirmed,
		onUpdateDetectedMeeting,
		selectedDateRange,
		isClearFiltersEnabled:
			!isEmpty(selectedCampaigns) ||
			!isEmpty(selectedTeamMembers) ||
			!isNil(selectedDateRange.end) ||
			!isNil(selectedDateRange.start),
		onMarkMeetingUpdated,
		onUpdateOnContactDrawerClose,
	};
}
