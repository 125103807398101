import { Tag, Typography, Button, Spin } from 'antd';
import { CommunicationStyle, Insights } from '../../services/types';
import { getHeaderContent } from './constants';
import styles from './header.module.less';
import { getCommunicationClass } from '../body/util';
import isEmpty from 'lodash/isEmpty';
import {
	BulbOutlined,
	CompassOutlined,
	EnvironmentOutlined,
	SmileOutlined,
	TeamOutlined,
	BookOutlined,
	HeartOutlined,
	SearchOutlined,
	InfoCircleOutlined,
	LinkedinFilled,
	ExportOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { UtilityFunctions } from '@copilot/common/utils/common';
import CommStyleCard from './commStyleCard';
import { generateLinkedInProfileUrl } from '@copilot/common/utils/linkedinProfile/constant';
import isNil from 'lodash/isNil';
import { Icon } from '@iconify/react';
import { aiIcon } from '@copilot/common/constant/icons';

/**
 * Get the communication style tags for the header based on the communication style
 * @param commStyle
 * @returns
 */
function getCommTags(commStyle: CommunicationStyle) {
	switch (commStyle) {
		case 'director':
			return (
				<>
					<Tag className={styles.headerTag}>
						<BulbOutlined /> Straightforward and Decisive
					</Tag>
					<Tag className={styles.headerTag}>
						<CompassOutlined /> Goal-Oriented
					</Tag>
					<Tag className={styles.headerTag}>
						<EnvironmentOutlined /> Results-driven
					</Tag>
				</>
			);
		case 'relator':
			return (
				<>
					<Tag className={styles.headerTag}>
						<TeamOutlined /> Trust-Driven
					</Tag>
					<Tag className={styles.headerTag}>
						<HeartOutlined /> Empathetic Collaborator
					</Tag>
					<Tag className={styles.headerTag}>
						<SmileOutlined /> Harmony Seeker
					</Tag>
				</>
			);
		case 'thinker':
			return (
				<>
					<Tag className={styles.headerTag}>
						<SearchOutlined /> Data-Backed
					</Tag>
					<Tag className={styles.headerTag}>
						<InfoCircleOutlined /> Detail-Oriented
					</Tag>
					<Tag className={styles.headerTag}>
						<BulbOutlined /> Logic-Driven
					</Tag>
				</>
			);
		case 'socializer':
			return (
				<>
					<Tag className={styles.headerTag}>
						<SmileOutlined /> Energetic and Friendly
					</Tag>
					<Tag className={styles.headerTag}>
						<TeamOutlined /> Relationship-Builder
					</Tag>
					<Tag className={styles.headerTag}>
						<BookOutlined /> Storytelling Focus
					</Tag>
				</>
			);
		default:
			return UtilityFunctions.assertUnreachable(commStyle);
	}
}

type InsightsHeaderProps = {
	insights: Insights;
	communicationStyleHelpArticleUrl?: string;
	remainingInsightsCredits?: number;
	creditCountFailed: boolean;
};

/**
 * Header section for the personalized insights page
 * @param param0
 * @returns
 */
function InsightsHeader({
	insights,
	communicationStyleHelpArticleUrl,
	remainingInsightsCredits,
	creditCountFailed,
}: InsightsHeaderProps) {
	const { commTypeTitle } = getHeaderContent(insights);
	const { commStyle, liAvatarUrl, title, firstName, lastName, alias } = insights;

	const hasAvatar = !isEmpty(liAvatarUrl);
	const hasTitle = !isEmpty(title);

	function onExportClick() {
		window.print();
	}

	function openCommStyleHelpArticle() {
		window.open(communicationStyleHelpArticleUrl);
	}

	const onCommStyleCTAClick = isEmpty(communicationStyleHelpArticleUrl)
		? undefined
		: openCommStyleHelpArticle;

	return (
		<>
			{!creditCountFailed && (
				<div
					className={[styles.tokenBanner, getCommunicationClass(commStyle, styles)].join(
						' '
					)}
				>
					<Typography.Text className={styles.tokenBannerText}>
						<Typography.Text className={styles.tokenCount}>
							<Icon icon={aiIcon} className={styles.tokenIcon} />
							{isNil(remainingInsightsCredits) ? (
								<Spin
									indicator={
										<LoadingOutlined
											className={styles.tokenIcon}
											style={{ fontSize: 16 }}
											spin
										/>
									}
								/>
							) : (
								remainingInsightsCredits
							)}{' '}
							more insights
							<Icon icon={aiIcon} className={styles.tokenIcon} />
						</Typography.Text>
						available this month
					</Typography.Text>
				</div>
			)}
			<div
				className={[styles.headerWrapper, getCommunicationClass(commStyle, styles)].join(
					' '
				)}
			>
				<div className={styles.headerCircle}></div>
				<div className={styles.header}>
					{hasAvatar && (
						<div className={styles.headerAvatar}>
							<img src={liAvatarUrl} alt="profile portrait" />
						</div>
					)}
					<div className={!hasAvatar ? styles.noAvatar : ''}>
						<div className={styles.headerTitleWrapper}>
							<div>
								<div className={styles.headerName}>
									<Typography.Title level={3}>
										{firstName} {lastName}
									</Typography.Title>
									{alias ? (
										<Button
											icon={<LinkedinFilled />}
											href={generateLinkedInProfileUrl(alias)}
											target="_blank"
										/>
									) : null}
								</div>
								{hasTitle ? (
									<Typography.Paragraph className={styles.headerSubtitle}>
										{title}
									</Typography.Paragraph>
								) : null}
							</div>
						</div>

						<Typography.Paragraph className={styles.commStyleLeadIn}>
							Communication style is a:
						</Typography.Paragraph>
						<Typography.Title level={1} className={styles.headerTitle}>
							{commTypeTitle}
						</Typography.Title>
						<div className={styles.headerTagWrapper}>{getCommTags(commStyle)}</div>
					</div>
					<div className={styles.commStyleSection}>
						<CommStyleCard onCTAClick={onCommStyleCTAClick} />
					</div>
				</div>
			</div>
			<div className={styles.mobileCommStyleSection}>
				<CommStyleCard onCTAClick={onCommStyleCTAClick} />
			</div>
			<div className={styles.exportWrapper}>
				<Button className={styles.exportButton} onClick={onExportClick}>
					<ExportOutlined />
					Export
				</Button>
			</div>
		</>
	);
}

export default InsightsHeader;
