import { createContext } from 'react';
import { ContactDrawerContextData } from './types';
import { DEFAULT_CONTACT_DRAWER_CONTEXT } from './constants';

/**
 * Boiler plate for defining the ContactDrawerV2 context, supplies default data
 */
const ContactDrawerV2Context = createContext<ContactDrawerContextData>(
	DEFAULT_CONTACT_DRAWER_CONTEXT
);

export default ContactDrawerV2Context;
