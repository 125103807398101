import { ICampaignConnectionsByMeetingsQuery } from '@copilot/data/graphql/_generated';
import { PaginatedMeetingResponse } from '../types';
import { EMPTY_PAGINATION_RESPONSE } from '@copilot/common/types/pagination';
import isNil from 'lodash/isNil';

/**
 * Converts query meeting entities into client meeting entity list
 * @param connectionData
 * @returns
 */
export function convertToMeetingsList(
	connectionData: ICampaignConnectionsByMeetingsQuery['campaignConnectionsByMeetings']['content']
) {
	return connectionData.map(
		({
			id,
			name,
			contactInfo,
			title,
			company,
			meeting: { bookedDate },
			campaignMember,
			profileId,
		}) => ({
			id,
			name,
			title: title ?? '',
			company: company ?? '',
			contactId: contactInfo?.id ?? '',
			orgMemberId: campaignMember.orgMemberId,
			meetingConfirmationDate: bookedDate ?? undefined,
			linkedInProfileId: profileId,
		})
	);
}

/**
 * Turns query results into paginated meeting response
 * @param data
 * @returns
 */
export function toPaginatedMeetings(
	data: ICampaignConnectionsByMeetingsQuery | undefined
): PaginatedMeetingResponse {
	if (isNil(data)) {
		return EMPTY_PAGINATION_RESPONSE;
	}

	return {
		count: data.campaignConnectionsByMeetings.count,
		pages: data.campaignConnectionsByMeetings.pages,
		content: convertToMeetingsList(data.campaignConnectionsByMeetings.content),
	};
}
