import { Button, Space, Table, Typography } from 'antd';
import { ComponentProps, FC, useState } from 'react';
import { get, isEmpty, keys } from 'lodash';
import { useTheme } from 'styled-components';
import {
	CheckSquareTwoTone,
	CloseSquareTwoTone,
	PlusSquareOutlined,
	MinusSquareOutlined,
} from '@ant-design/icons';
import AttentionRequiredItemList from '@copilot/common/components/lists/attentionRequired';
import Column, { ColumnProps } from 'antd/lib/table/Column';
import { createCaseInsensitiveSorter } from '@copilot/common/utils/common/sort';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { Chip } from '@copilot/common/components/dataDisplay/chip';
import { DefaultAboveBelowAverageLegend } from '@copilot/common/pages/organizationDashboard/summaryV2/dotLegend';
import { fontWeightMedium, spacingXS } from '@copilot/common/constant/commonStyles';
import Title from '@copilot/common/typography/title';

const getHeaderProps: ColumnProps<ActiveMember>['onHeaderCell'] = () => {
	return {
		style: {
			background: 'white',
		},
	};
};

/**
 * The names of the fields in an active member model
 */
const ActiveMemberFieldNames = {
	id: 'id',
	attentionRequiredItems: 'attentionRequiredItems',
	name: 'name',
	isLinkedInLoggedOut: 'isLinkedInLoggedOut',
	connectionRate: 'connectionRate',
	replyRate: 'replyRate',
	meetingsBooked: 'meetingsBooked',
} as const;

/**
 * The labels of the fields in an active member model
 */
const ActiveMemberLabels = {
	[ActiveMemberFieldNames.attentionRequiredItems]: 'Campaign issues',
	[ActiveMemberFieldNames.name]: 'Name',
	[ActiveMemberFieldNames.connectionRate]: 'Connection rate',
	[ActiveMemberFieldNames.replyRate]: 'Reply rate',
	[ActiveMemberFieldNames.isLinkedInLoggedOut]: 'LinkedIn sync',
	[ActiveMemberFieldNames.meetingsBooked]: 'Meetings booked',
} as const;

/**
 * The model of an active member
 */
export type ActiveMember = {
	/**
	 * Active member's id
	 */
	[ActiveMemberFieldNames.id]: string;
	/**
	 * Active member's attention required items
	 */
	[ActiveMemberFieldNames.attentionRequiredItems]: ComponentProps<
		typeof AttentionRequiredItemList
	>['items'];
	/**
	 * Active member's name
	 */
	[ActiveMemberFieldNames.name]: string;
	/**
	 * Active member's linkedIn logged out status
	 */
	[ActiveMemberFieldNames.isLinkedInLoggedOut]: boolean;
	/**
	 * Active member's connection rate
	 */
	[ActiveMemberFieldNames.connectionRate]: number;
	/**
	 * Active member's reply rate
	 */
	[ActiveMemberFieldNames.replyRate]: number;
	/**
	 * Active member's number of meetings booked
	 */
	[ActiveMemberFieldNames.meetingsBooked]: number;
};

type OrganizationActiveMemberTableProps = {
	/**
	 * Active members
	 */
	activeMembers: ActiveMember[];
	/**
	 * Callback when a user clicks to reconnect LinkedIn
	 * @param id
	 */
	onLinkedInReconnect: (id: string) => void;
	/**
	 * Colors
	 */
	colors: {
		/**
		 * Colors that indicate a rate is below average
		 */
		belowAverageColor: string;
		/**
		 * Colors that indicate a rate is above average
		 */
		aboveAverageColor: string;
		/**
		 * Color that represents the average range
		 */
		averageColor: string;
		/**
		 * function that gets color for connection rate
		 */
		getConnectionRateColor: (rate: number | undefined) => string;
		/**
		 * function that gets color for reply rate
		 */
		getReplyRateColor: (rate: number | undefined) => string;
	};
};

/**
 * Helper method to convert an Record<string, boolean> to an array with keys that the values are true
 * @param map
 */
const toArray = (map: Record<string, boolean>) => keys(map).filter((key) => get(map, key, false));

/**
 * Helper to create a number sorter for a given key
 * @param key
 */
const numberSorter =
	<K extends string>(key: K) =>
	<T extends Record<K, number>>(a: T, b: T): number => {
		return a[key] - b[key];
	};

/**
 * [Presentational] Table to display all active member within an organization
 * @param props
 * @constructor
 */
const OrganizationActiveMemberTable: FC<OrganizationActiveMemberTableProps> = (props) => {
	const { activeMembers, onLinkedInReconnect, colors } = props;
	const {
		belowAverageColor,
		aboveAverageColor,
		averageColor,
		getConnectionRateColor,
		getReplyRateColor,
	} = colors;
	const [expandedRowKeys, setExpandedRowKeys] = useState<Record<string, boolean>>({});
	const theme = useTheme();
	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<Title level={5}>Active team members</Title>
			<Typography.Text
				type="secondary"
				style={{ fontWeight: fontWeightMedium, marginTop: spacingXS }}
			>
				See how your team has performed and action on issues.
			</Typography.Text>
			<DefaultAboveBelowAverageLegend
				aboveAverageColor={aboveAverageColor}
				averageColor={averageColor}
				belowAverageColor={belowAverageColor}
			/>
			<Table<ActiveMember>
				rowKey={ActiveMemberFieldNames.id}
				style={{ userSelect: 'none' }}
				expandable={{
					expandedRowRender: (record) => (
						<AttentionRequiredItemList items={record.attentionRequiredItems} />
					),
					expandedRowKeys: toArray(expandedRowKeys),
					showExpandColumn: false,
				}}
				dataSource={activeMembers}
				data-testid="active-member-table-rows"
			>
				<Column<ActiveMember>
					title={ActiveMemberLabels.name}
					dataIndex={ActiveMemberFieldNames.name}
					sorter={createCaseInsensitiveSorter<ActiveMember>((x) => x.name)}
					onHeaderCell={getHeaderProps}
				/>
				<Column<ActiveMember>
					title={ActiveMemberLabels.isLinkedInLoggedOut}
					dataIndex={ActiveMemberFieldNames.isLinkedInLoggedOut}
					render={(_, record) => {
						return record.isLinkedInLoggedOut ? (
							<>
								<CloseSquareTwoTone twoToneColor={theme['@error-color']} />
								<Button type={'link'} size={'small'}>
									Reconnect
								</Button>
							</>
						) : (
							<CheckSquareTwoTone twoToneColor={theme['@success-color']} />
						);
					}}
					sorter={(a, b) => Number(a.isLinkedInLoggedOut) - Number(b.isLinkedInLoggedOut)}
					onCell={(record) => ({
						onClick: () => {
							if (!record.isLinkedInLoggedOut) return;
							onLinkedInReconnect(record.id);
						},
					})}
					width={170}
					onHeaderCell={getHeaderProps}
				/>
				<Column<ActiveMember>
					title={ActiveMemberLabels.attentionRequiredItems}
					dataIndex={ActiveMemberFieldNames.attentionRequiredItems}
					width={200}
					render={(_, record) => {
						if (isEmpty(record.attentionRequiredItems)) return null;
						return (
							<Space className={'issue-counts'}>
								{get(expandedRowKeys, record.id, false) ? (
									<MinusSquareOutlined />
								) : (
									<PlusSquareOutlined />
								)}
								<Typography>{record.attentionRequiredItems.length}</Typography>
							</Space>
						);
					}}
					onCell={(record) => ({
						onClick: () =>
							setExpandedRowKeys((map) => ({ ...map, [record.id]: !map[record.id] })),
					})}
					sorter={(a, b) =>
						a.attentionRequiredItems.length - b.attentionRequiredItems.length
					}
					onHeaderCell={getHeaderProps}
				/>
				<Column<ActiveMember>
					title={ActiveMemberLabels.connectionRate}
					dataIndex={ActiveMemberFieldNames.connectionRate}
					sorter={numberSorter(ActiveMemberFieldNames.connectionRate)}
					render={(value: number) => (
						<Chip color={getConnectionRateColor(value)}>
							{UtilityFunctions.convertDecimalToPercentage(value / 100)}
						</Chip>
					)}
					onHeaderCell={getHeaderProps}
				/>
				<Column<ActiveMember>
					title={ActiveMemberLabels.replyRate}
					dataIndex={ActiveMemberFieldNames.replyRate}
					sorter={numberSorter(ActiveMemberFieldNames.replyRate)}
					render={(value: number) => (
						<Chip color={getReplyRateColor(value)}>
							{UtilityFunctions.convertDecimalToPercentage(value / 100)}
						</Chip>
					)}
					onHeaderCell={getHeaderProps}
				/>
				<Column<ActiveMember>
					title={ActiveMemberLabels.meetingsBooked}
					dataIndex={ActiveMemberFieldNames.meetingsBooked}
					sorter={numberSorter(ActiveMemberFieldNames.meetingsBooked)}
					onHeaderCell={getHeaderProps}
				/>
			</Table>
		</Space>
	);
};

export default OrganizationActiveMemberTable;
