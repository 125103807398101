import { AccountDto } from '../../../../core/data/responses/models/account';
import { useFetchV2 } from '../common/index';
import { AccountManager } from '../../../../core/data/index';
import { useEffect } from 'react';

/**
 * Fetches the active account
 */
export function useActiveAccount(): AccountDto | undefined {
	const [{ data }, getCurrentAccount] = useFetchV2(AccountManager.getCurrentAccount);
	useEffect(() => {
		getCurrentAccount();
	}, []);

	return data;
}
