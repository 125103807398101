import { Space } from 'antd';
import moment from 'moment';
import { PropsWithChildren, ReactNode } from 'react';
import {
	CUSTOM_OPTION,
	CustomDropdownOption,
	DateRangeDropdownOption,
	DateRangeDropdownValue,
} from './types';

/**
 * Helper to get a date range since daysPrior
 * @param daysPrior Number of days prior to today to start the range.
 * @returns
 */
export function getDateRangeFromValue(daysPrior: number) {
	return {
		start: moment().utc().subtract(daysPrior, 'd').startOf('d'),
		end: moment().utc(),
	};
}

/**
 * Label for the selected option shown
 */
export function TimeRangeSelectLabel({
	children,
	icon,
}: PropsWithChildren<{
	icon: ReactNode;
}>): JSX.Element {
	return (
		<Space>
			{icon}
			{children}
		</Space>
	);
}

/**
 * Type guard for custom option
 * @param option
 * @returns
 */
export function isCustomOption(option: DateRangeDropdownOption): option is CustomDropdownOption {
	return option.key === CUSTOM_OPTION.key;
}

/**
 * Type guard for custom value
 * @param value
 * @returns
 */
export function isCustomValue(
	value: DateRangeDropdownValue['value']
): value is typeof CUSTOM_OPTION['value'] {
	return value === CUSTOM_OPTION.value;
}
