import { useContext } from 'react';
import ContactDrawerV2Context from './contactDrawerV2Context';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

/**
 * Hook wrapper for useContactDrawerV2 context
 * @returns
 */
export default function useContactDrawerV2() {
	const {
		conversation,
		isLoading,
		orgMemberId,
		isConversationInvalid,
		contactData: { loading: contactDataLoading },
		sourceName,
	} = useContext(ContactDrawerV2Context);

	return {
		isLoadingConversationDrawerData:
			isLoading ||
			contactDataLoading ||
			isNil(orgMemberId) ||
			isEmpty(sourceName) ||
			(isNil(conversation) && !isConversationInvalid), //if the conversation is invalid, we don't want to show the loading spinner
		isLoading,
		isConversationInvalid,
	};
}
