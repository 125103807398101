import { OrganizationMemberManager } from '@copilot/data';

/**
 * Clear the sales nav errors for the current user
 * @returns
 */
export function clearSalesNavMissingError() {
	return OrganizationMemberManager.resetMySalesNavErrorStatus();
}

/**
 * Clear the linkedin cookies to prevent automation from running
 * @returns
 */
export function disconnectMyLinkedIn() {
	return OrganizationMemberManager.disconnectMyLinkedIn();
}

/**
 * Check if a given orgMember is logged in
 * @param id
 * @returns
 */
export function checkIsLoggedIn(id: string) {
	return OrganizationMemberManager.checkIsLoggedIn(id);
}
