import { useState } from 'react';
import { isEmpty } from 'lodash';
import { parse } from 'papaparse';

type CSVFileUploadHookOptions = Readonly<{
	// The maximum size of the CSV file in bytes
	maxSizeInBytes?: number;
}>;

type CSVFileUploadResult<T> = Readonly<{
	// The data parsed from the CSV file
	data: T[] | undefined;
	// The error message encountered if parsing the CSV file failed
	error: string | undefined;
	// True if the file is currently being uploaded
	isUploading: boolean;
	// The callback to upload a file
	onUpload: (file: File) => void;
}>;

const BYTES_PER_KILOBYTE = 1024;

/**
 * Helper function to get the validation error message for a file
 * @param type
 * @param size
 * @param maxSizeInBytes
 */
function getValidationErrorMessage(
	{ type, size }: File,
	maxSizeInBytes: number
): string | undefined {
	if (type !== 'text/csv') {
		return 'File must be a CSV';
	}

	if (size > maxSizeInBytes) {
		return `File must be less than ${maxSizeInBytes / BYTES_PER_KILOBYTE}KB`;
	}

	return undefined;
}

/**
 * A hook for parsing CSV files as an array
 * @param options - The hook options
 * @returns The hook result
 */
export function useCSVFileUpload<T = string[]>({
	maxSizeInBytes = 100 * BYTES_PER_KILOBYTE,
}: CSVFileUploadHookOptions = {}): CSVFileUploadResult<T> {
	const [result, setResult] = useState<{
		data: T[] | undefined;
		error: string | undefined;
		isLoading: boolean;
	}>({
		data: undefined,
		error: undefined,
		isLoading: false,
	});

	function onUpload(file: File) {
		setResult({ data: undefined, error: undefined, isLoading: true });
		const error = getValidationErrorMessage(file, maxSizeInBytes);
		if (error) {
			setResult({ data: undefined, error, isLoading: false });
		} else {
			parse<T, File>(file, {
				worker: true,
				skipEmptyLines: true,
				complete: ({ data, errors }) => {
					if (isEmpty(errors)) {
						setResult({ data, error: undefined, isLoading: false });
					} else {
						setResult({
							data: undefined,
							error: errors.join('\n'),
							isLoading: false,
						});
					}
				},
			});
		}
	}

	return {
		data: result.data,
		error: result.error,
		isUploading: result.isLoading,
		onUpload,
	};
}
