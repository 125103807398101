import { Card, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import styles from './polarityCard.module.less';

type PolarityCardProps = {
	isPositive?: boolean;
	title: string;
	content: JSX.Element | JSX.Element[];
};
/**
 * Card with positive or negative connotation (eg do vs don't)
 * @param param0
 * @returns
 */
export function PolarityCard({ isPositive = false, title, content }: PolarityCardProps) {
	const contentArray = Array.isArray(content) ? content : [content];
	return (
		<Card
			className={[styles.polarityCard, isPositive ? styles.positive : styles.negative].join(
				' '
			)}
			title={
				<Typography.Title level={4} className={styles.polarityCardTitle}>
					{isPositive ? <CheckCircleOutlined /> : <CloseCircleOutlined />} {title}
				</Typography.Title>
			}
		>
			{contentArray.map((c) => c)}
		</Card>
	);
}
