import { Alert, Col, Row, Select, Typography } from 'antd';
import { MessageOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { getTemplateOptions } from '../constants';
import { PolarityCard } from '../polarityCard';
import { SectionCard } from '../sectionCard';
import { CommunicationStyle } from '../../../services/types';
import { TemplateCard } from '../templateCard';
import { useState } from 'react';
import { MessagingSectionData, TemplateCategory } from '../types';

type MessagingApproachSectionProps = {
	data: MessagingSectionData;
	communicationStyle: CommunicationStyle;
};

/**
 * Section for effective messaging content
 * @param param0
 * @returns
 */
export function MessagingEffectivelySection({
	data: { approachCopy, dos, donts, templates },
	communicationStyle,
}: MessagingApproachSectionProps) {
	const [selectedTemplateCategory, setSelectedTemplateCategory] =
		useState<TemplateCategory>('postConnection');
	function getTemplates() {
		return templates[selectedTemplateCategory];
	}
	return (
		<div id="#message-effectively">
			<SectionCard
				title={
					<>
						<MessageOutlined /> Message Effectively
					</>
				}
				communicationStyle={communicationStyle}
			>
				<Typography.Paragraph>{approachCopy}</Typography.Paragraph>
				<Row gutter={[24, 24]}>
					<Col span={24} sm={12}>
						<PolarityCard
							title={'Do'}
							isPositive
							content={dos.map((doItem) => {
								return (
									<Typography.Paragraph key={doItem}>
										{doItem}
									</Typography.Paragraph>
								);
							})}
						/>
					</Col>

					<Col span={24} sm={12}>
						<PolarityCard
							title={"Don't"}
							content={donts.map((dontItem) => {
								return (
									<Typography.Paragraph key={dontItem}>
										{dontItem}
									</Typography.Paragraph>
								);
							})}
						/>
					</Col>
				</Row>
				<SectionCard
					title={
						<>
							<DoubleRightOutlined /> Message Template
						</>
					}
					communicationStyle={communicationStyle}
					isSubSection
				>
					<Typography.Paragraph>
						Our message templates are designed as a starting point to help you
						communicate effectively with prospects of different communication styles.
						Tailored to align with each style’s preferences, these templates provide a
						framework for engaging interactions, whether you're making initial contact,
						nurturing a prospect, requesting a meeting, or responding to objections.
					</Typography.Paragraph>
					<Alert
						message="You are required to manually replace these tokens with relevant information specific to each prospect"
						type="warning"
						showIcon
					/>
					<Typography.Paragraph>
						Message purpose:{' '}
						<Select<TemplateCategory>
							defaultValue="postConnection"
							options={getTemplateOptions()}
							onSelect={setSelectedTemplateCategory}
						/>
					</Typography.Paragraph>
					<Row gutter={[24, 24]}>
						<Col span={24} sm={12}>
							<TemplateCard template={getTemplates()[0]} />
						</Col>
						<Col span={24} sm={12}>
							<TemplateCard template={getTemplates()[2]} />
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col span={24} sm={12}>
							<TemplateCard template={getTemplates()[1]} />
						</Col>
						<Col span={24} sm={12}>
							<TemplateCard template={getTemplates()[3]} />
						</Col>
					</Row>
				</SectionCard>
			</SectionCard>
		</div>
	);
}
