import { Card, Typography } from 'antd';
import styles from './takeAwaysCard.module.less';
import { CommStyleWheel } from '../../commStyleWheel';
import { CommunicationStyle } from '../../../services/types';
import { getCommunicationClass } from '../util';
import { getTakeAwaysContent } from './constants';

type TakeAwaysCardProps = {
	communicationStyle: CommunicationStyle;
};
/**
 * Card describing the takeaways for a communication style
 * @param param0
 * @returns
 */
export default function TakeAwaysCard({ communicationStyle }: TakeAwaysCardProps) {
	const { title, takeAways } = getTakeAwaysContent(communicationStyle);
	return (
		<Card
			className={[
				styles.takeAwaysCard,
				getCommunicationClass(communicationStyle, styles),
			].join(' ')}
		>
			<div className={styles.commStyleSection}>
				<Typography.Title level={4} className={styles.title}>
					{title}
				</Typography.Title>
				<div className={styles.commStyleVisual}>
					<Typography.Paragraph
						className={[styles.communicationCardinality, styles.top].join(' ')}
					>
						Faster & Direct
					</Typography.Paragraph>
					<div className={styles.horizontalCardinalities}>
						<Typography.Paragraph
							className={[styles.communicationCardinality, styles.left].join(' ')}
						>
							Guarded & Task Focused
						</Typography.Paragraph>
						<CommStyleWheel size="medium" selectedCommStyle={communicationStyle} />
						<Typography.Paragraph
							className={[styles.communicationCardinality, styles.right].join(' ')}
						>
							Open & People Focused
						</Typography.Paragraph>
					</div>
					<Typography.Paragraph
						className={[styles.communicationCardinality, styles.bottom].join(' ')}
					>
						Even Keeled & Indirect
					</Typography.Paragraph>
				</div>
			</div>

			<div className={styles.takeAwaysSection}>
				<Typography.Title level={5} className={styles.takeAwaysTitle}>
					Key Takeaways
				</Typography.Title>
				{takeAways.map((takeAway) => (
					<Typography.Paragraph className={styles.takeAwaysContent} key={takeAway}>
						{takeAway}
					</Typography.Paragraph>
				))}
			</div>
		</Card>
	);
}
