import moment from 'moment';
import { Divider, List } from 'antd';
import { useMemo } from 'react';
import { ProspectDrawerTrackingParams } from '@copilot/common/components/drawer/wrappers/contact/tracking';
import ConversationItemV2 from './conversationItemV2';
import { MessageResponse } from '@copilot/data/responses/interface';
import isNil from 'lodash/isNil';
import styles from './conversationTab.module.less';

type ConversationListProps = {
	targetName: string;
	sourceName: string;
	tempOutgoing: MessageResponse[];
	messages: MessageResponse[];
	threadLink: string | undefined;
	onTracking: ((params: ProspectDrawerTrackingParams) => void) | undefined;
};

/**
 * Renderer for the conversation between orgMember and contact displayed in the contact drawer
 * @param param0
 * @returns
 */
export default function ConversationListV2({
	messages,
	tempOutgoing,
	threadLink,
	onTracking,
	targetName,
	sourceName,
}: ConversationListProps) {
	const { nonPendingMessages, pendingMessages } = useMemo(() => {
		const internalNonPendingMessages: (MessageResponse | string)[] = [];
		const internalPendingMessagesQueued: MessageResponse[] = [];

		if (!isNil(messages)) {
			let lastMessageDay = undefined;
			for (let i = 0; i < messages.length; i++) {
				const newMessage: MessageResponse = messages[i];
				if (newMessage?.pending) {
					internalPendingMessagesQueued.push(newMessage);
				} else if (newMessage) {
					const formattedDate = moment(newMessage.timestamp).format('dddd MMM Do YYYY');
					if (lastMessageDay != formattedDate) {
						lastMessageDay = formattedDate;
						internalNonPendingMessages.push(lastMessageDay);
					}
					internalNonPendingMessages.push(newMessage);
				}
			}
		}
		return {
			nonPendingMessages: internalNonPendingMessages,
			pendingMessages: internalPendingMessagesQueued.concat(tempOutgoing),
		};
	}, [tempOutgoing]);

	return (
		<>
			<List
				dataSource={nonPendingMessages}
				renderItem={(item: MessageResponse | string, idx: number) => {
					return typeof item === 'string' ? (
						<Divider orientation="left" className={styles.dateDivider}>
							{item}
						</Divider>
					) : (
						<ConversationItemV2
							key={`${idx}`}
							message={item}
							sourceName={sourceName}
							targetName={targetName}
							threadUrl={threadLink}
							onTracking={onTracking}
						/>
					);
				}}
			/>
			{pendingMessages.length > 0 && (
				<List
					dataSource={pendingMessages}
					renderItem={(item: MessageResponse, idx: number) => (
						<ConversationItemV2
							key={`${idx}`}
							message={item}
							sourceName={sourceName}
							targetName={targetName}
							threadUrl={threadLink}
							onTracking={onTracking}
						/>
					)}
				/>
			)}
		</>
	);
}
