// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PAojxmhMIAAObWB0MX_d {\n  background: var(--layout-header-base-background, #3fa3d2);\n  display: flex;\n  padding: 16px 8px;\n  align-items: center;\n  width: 100%;\n  position: relative;\n}\n.SsFgFwnt1vPJ4TfHOXe5 {\n  font-size: 20px;\n  font-weight: 500;\n  color: white;\n  flex-grow: 1;\n  text-align: center;\n}\n.tYxS0DtIv57fSduZMxS2 {\n  position: absolute;\n  left: 8px;\n}\n.zEgeBjoVhpUFxUtvw2NC {\n  padding: 36px 100px;\n}\n.pm6dfzk2hXajxHSHitw_ {\n  padding: 0 24px;\n  max-width: 896px;\n  margin: 0 auto;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/wizard/steps/steps.module.less"],"names":[],"mappings":"AAIA;EACE,yDAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;AAHF;AAMA;EACE,eAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AAJF;AAOA;EACE,kBAAA;EACA,SAAA;AALF;AAQA;EACE,mBAAA;AANF;AASA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAPF","sourcesContent":["@import '../../../../styles/antd-variables.less';\n@import '../../../../styles/common-variables.less';\n@import './stepsVariables.less';\n\n.stepsHeaderSection {\n  background: @layout-header-base-background;\n  display: flex;\n  padding: @spacer-size-sm @spacer-size-xs;\n  align-items: center;\n  width: 100%;\n  position: relative;\n}\n\n.stepsHeaderTitle {\n  font-size: @fontSizeLg;\n  font-weight: @fontWeightMedium;\n  color: white;\n  flex-grow: 1;\n  text-align: center;\n}\n\n.exitButton {\n  position: absolute;\n  left: @spacer-size-xs;\n}\n\n.progressWrapper {\n  padding: 36px 100px;\n}\n\n.contentWrapper {\n  padding: 0 @spacer-size-md;\n  max-width: @page-max-width;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepsHeaderSection": "PAojxmhMIAAObWB0MX_d",
	"stepsHeaderTitle": "SsFgFwnt1vPJ4TfHOXe5",
	"exitButton": "tYxS0DtIv57fSduZMxS2",
	"progressWrapper": "zEgeBjoVhpUFxUtvw2NC",
	"contentWrapper": "pm6dfzk2hXajxHSHitw_"
};
export default ___CSS_LOADER_EXPORT___;
