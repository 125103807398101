// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VDODqV21dESLQNQBFk91 {\n  display: flex;\n  margin: 0 16px;\n  height: 100%;\n}\n.lYUWig84RYMjMfUbYwia {\n  margin: auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 24px;\n  max-width: 720px;\n}\n.IYhG7P2dnLFXXfDfHBT6 {\n  font-size: 72px;\n  color: rgba(0, 0, 0, 0.45);\n}\n.vQP_qXxR8aWIfli6NnFj {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  text-align: center;\n}\n.JBpNU9S_xEqcfDPi_dWZ {\n  margin: 0;\n}\n.i1oHj1ixoFzo8_c_WIma {\n  color: rgba(0, 0, 0, 0.45);\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/inboxV2/components/restricted/restricted.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,cAAA;EACA,YAAA;AADF;AAIA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;AAFF;AAKA;EACE,eAAA;EACA,0BAAA;AAHF;AAMA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;AAJF;AAOA;EACE,SAAA;AALF;AAQA;EACE,0BAAA;AANF","sourcesContent":["@import '../../../../../styles/common-variables';\n\n.inboxPageV2RestrictedContainer {\n  display: flex;\n  margin: 0 @spacingMD;\n  height: 100%;\n}\n\n.inboxPageV2RestrictedContent {\n  margin: auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: @spacingLG;\n  max-width: 720px;\n}\n\n.inboxPageV2RestrictedIcon {\n  font-size: 72px;\n  color: @textColorSecondary;\n}\n\n.inboxPageV2RestrictedMessage {\n  display: flex;\n  flex-direction: column;\n  gap: @spacingXSS;\n  text-align: center;\n}\n\n.inboxPageV2RestrictedTitle {\n  margin: 0;\n}\n\n.inboxPageV2RestrictedBody {\n  color: @textColorSecondary;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inboxPageV2RestrictedContainer": "VDODqV21dESLQNQBFk91",
	"inboxPageV2RestrictedContent": "lYUWig84RYMjMfUbYwia",
	"inboxPageV2RestrictedIcon": "IYhG7P2dnLFXXfDfHBT6",
	"inboxPageV2RestrictedMessage": "vQP_qXxR8aWIfli6NnFj",
	"inboxPageV2RestrictedTitle": "JBpNU9S_xEqcfDPi_dWZ",
	"inboxPageV2RestrictedBody": "i1oHj1ixoFzo8_c_WIma"
};
export default ___CSS_LOADER_EXPORT___;
