import React from 'react';
import EmailForm from '../../../components/forms/email';
import { OrganizationMemberManager } from '@copilot/data';
import { useFetch } from '@copilot/common/hooks/common';
import notificationManager from '@copilot/common/utils/notificationManager';

interface EmailAddressSettingsProps
	extends Omit<React.ComponentProps<typeof EmailForm>, 'onSubmit'> {
	user: {
		id: string;
		alternateEmail: string;
		cc: string[];
		bcc: string[];
		isNotificationEnable: boolean;
	};
	onSubmit?: React.ComponentProps<typeof EmailForm>['onSubmit'];
}

const EmailAddressSettings: React.FC<EmailAddressSettingsProps> = (props) => {
	const { user, onSubmit, ...rest } = props;
	const initialValues = React.useMemo(
		() => ({
			alternateEmail: user.alternateEmail,
			cc: user.cc ? user.cc.join(';') : '',
			bcc: user.bcc ? user.bcc.join(';') : '',
			isNotificationEnable: user.isNotificationEnable,
		}),
		[user]
	);
	const [, postSubmit] = useFetch(OrganizationMemberManager.updateMember);
	const handleSubmit: React.ComponentProps<typeof EmailForm>['onSubmit'] = (values) => {
		postSubmit({
			id: user.id,
			alternateEmail: values.alternateEmail,
			emailCC: values.cc.split(';'),
			emailBCC: values.bcc.split(';'),
			isNotificationEnable: values.isNotificationEnable,
		})
			.then(() => {
				notificationManager.showSuccessNotification({
					message: 'Saved',
					description: 'Your setting has been saved.',
				});
			})
			.catch(() => {
				notificationManager.showErrorNotification({
					message: 'Failed',
					description: 'Please refresh the page and try again',
				});
			});
		onSubmit?.(values);
	};
	return (
		<>
			<h2>Email Notification Settings</h2>
			<br />
			<EmailForm onSubmit={handleSubmit} initialValues={initialValues} {...rest} />
		</>
	);
};

export default EmailAddressSettings;
