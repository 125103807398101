import { AntdTheme, Theme } from '@copilot/common/config';
import { ConfigProvider } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { createContext, useContext, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

const ThemeContext = createContext<typeof Theme>(Theme);

/**
 * Get the theme currently applied to the application
 * @returns Current theme the application is using
 */
export function useCopilotTheme() {
	return useContext(ThemeContext);
}

type CopilotThemeProviderProps = {
	children: React.ReactNode;
};
/**
 * Provides theme related information to the copilot application (antd and styled-components as well as css vars)
 * @param param0
 * @returns
 */
export default function CopilotThemeProvider({ children }: CopilotThemeProviderProps) {
	useEffect(() => {
		if (!isEmpty(Theme)) {
			appendThemeOverridesToDocument(Theme as Record<string, string>);
		}
	}, [Theme]);

	function appendThemeOverridesToDocument(overridesObject: Record<string, string>) {
		Object.entries(overridesObject).forEach(([key, value]) => {
			const formattedKey = key.replace(/@/g, '--');
			document.documentElement.style.setProperty(formattedKey, value);
		});
	}

	return (
		<ConfigProvider prefixCls="copilot" theme={AntdTheme}>
			<ThemeProvider theme={Theme}>
				<ThemeContext.Provider value={Theme}>{children}</ThemeContext.Provider>
			</ThemeProvider>
		</ConfigProvider>
	);
}
