import { DEFAULT_BLOCKLIST_CONTEXT } from '../constants';
import { BlocklistContextData } from '../types';
import moment from 'moment';
import { range } from 'lodash';

export const MOCK_DEFAULT_BLOCKLIST_CONTEXT: BlocklistContextData = {
	...DEFAULT_BLOCKLIST_CONTEXT,
	onAddToBlocklistClick: () => console.log('Add to blocklist clicked'),
	onEditBlocklistClick: () => console.log('Edit blocklist clicked'),
	teamMemberOptions: [
		{
			label: 'Thing 1',
			value: 'c007c475',
		},
		{
			label: 'Thing 2',
			value: 'd34db33f',
		},
	],
	data: {
		entries: range(150).map((i) => ({
			teamMember: {
				id: '123456789',
				name: 'Thing 1',
			},
			company: 'Enron',
			condition: 'Exact Match',
			date: moment().subtract(i, 'week').valueOf(),
		})),
		lastUpdate: moment().date(),
	},
	alert: {
		type: 'success',
		message: 'This is a default message of some sort',
	},
};
