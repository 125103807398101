import {
	ContactResponse,
	ConversationResponse,
	MemberLinkedInProfileResponse,
	OrganizationMemberResponse,
} from '@copilot/data/responses/interface';
import { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useOrganizationContact } from '../wrappers/contact/data/hooks';
import { InboxManager, OrganizationMemberManager, TemplateManager } from '@copilot/data';
import notificationManager from '@copilot/common/utils/notificationManager';
import { MessageTemplateModel } from '@copilot/data/responses/models/messageTemplate';
import { formatName } from '@copilot/common/utils';
import { useCampaignConnectionsQuery } from '../../../../../core/data/graphql/_generated';
import { isAutomatedStep } from './types';
import isString from 'lodash/isString';

type UseContactDrawV2DataProps = {
	conversation?: ConversationResponse;
	contactId?: string;
	orgMemberId?: string;
	targetProfile?: MemberLinkedInProfileResponse;
	orgContact?: ContactResponse;
	onMeetingBookedUpdated?: (connectionId: string, isBooked: boolean) => void;
};

/**
 * Hook for grabbing all the common data needed between conversation tab views and
 * managing client-side state changes that impact the entire drawer (eg switching conversations)
 * @param param0
 * @returns
 */
export default function useContactDrawerV2Data({
	conversation: conversationData,
	contactId = '',
	orgMemberId: orgMemberIdData,
	targetProfile: targetProfileData,
	orgContact,
	onMeetingBookedUpdated = () => {},
}: UseContactDrawV2DataProps) {
	// Fetch the contact info for the selected org contact (eg editable title, workplace, contactInfo, etc.)
	const contactInfoData = useOrganizationContact(contactId, () => {});
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	// The currently selected org member id for the conversation we are viewing (eg who is talking to the contact)
	const [selectedOrgMemberId, setSelectedOrgMemberId] =
		useState<string | undefined>(orgMemberIdData);
	// Loading flag when fetching a new conversation for the same contact, etc.
	const [isRefetching, setIsRefetching] = useState(false);

	// Quick response templates for the active member's organization
	const [messageTemplates, setMessageTemplates] = useState<MessageTemplateModel[]>([]);

	const campaignConnection = contactInfoData?.campaignConnections?.find(
		(c) => c.orgMemberId === selectedOrgMemberId
	);

	const [selectedOrgMember, setSelectedOrgMember] =
		useState<OrganizationMemberResponse | undefined>(undefined);

	const [currOrgContact, setCurrOrgContact] = useState<ContactResponse | undefined>(orgContact);

	useEffect(() => {
		setCurrOrgContact(orgContact);
	}, [orgContact]);

	useEffect(() => {
		if (selectedOrgMember?.id != selectedOrgMemberId) {
			OrganizationMemberManager.getMember(selectedOrgMemberId)
				.then((member) => setSelectedOrgMember(member))
				.catch(() => {
					notificationManager.showErrorNotification({
						message: 'There was an issue fetching the org member information.',
					});
				});
		}
	}, [selectedOrgMemberId]);

	// Fetch message templates for the active member's organization
	useEffect(() => {
		if (!isNil(activeMember)) {
			TemplateManager.getTemplateMessagesByOrg(activeMember.organizationId)
				.then((templates) => {
					setMessageTemplates(templates);
				})
				.catch(() => {
					notificationManager.showErrorNotification({
						message: 'There was an issue fetching your message templates.',
					});
				});
		}
	}, [activeMember]);

	// The current conversation we are rendering in the drawer
	const [conversation, setConversation] =
		useState<ConversationResponse | undefined>(conversationData);
	useEffect(() => {
		setConversation(conversationData);
	}, [conversationData]);

	// Update the selected org member id when the prop changes or the contact id changes
	useEffect(() => {
		setSelectedOrgMemberId(orgMemberIdData);
	}, [orgMemberIdData, contactId]);

	// The linkedInProfile of the orgContact whose conversation we are viewing
	const [targetProfile, setTargetProfile] =
		useState<MemberLinkedInProfileResponse | undefined>(targetProfileData);
	useEffect(() => {
		setTargetProfile(targetProfileData);
	}, [targetProfileData]);

	/**
	 * Update client-side conversation state so we don't need to refetch when making edits
	 * @param update
	 * @returns
	 */
	function onUpdateCurrentConversation(update: Partial<ConversationResponse>) {
		if (isNil(conversation)) return;
		setConversation({ ...conversation, ...update });
	}

	/**
	 * Change which conversation is being viewed in the drawer (only happens when multiple org members have a shared connection to a contact)
	 * @param newOrgMemberId
	 * @returns
	 */
	function onChangeSelectedOrgMember(newOrgMemberId: string): void {
		const selectedContactId = contactInfoData?.contact?.id;
		if (isNil(selectedContactId)) return;
		setIsRefetching(true);
		setSelectedOrgMemberId(newOrgMemberId);
		changeConversation(selectedContactId, newOrgMemberId);
	}

	/**
	 * Fetch the new conversation and target profile for the selected contact and org member
	 * @param newContactId
	 * @param newOrgMemberId
	 */
	async function changeConversation(newContactId: string, newOrgMemberId: string) {
		try {
			const { thread: newConversation, targetProfile: newTargetProfile } =
				await InboxManager.getConversationAndTargetProfileForOrgContactAndMember(
					newContactId,
					newOrgMemberId
				);

			setConversation(newConversation);
			setTargetProfile(newTargetProfile);
		} catch (error) {
			notificationManager.showErrorNotification({
				message: 'There was an issue fetching the conversation.',
			});
		}
		setIsRefetching(false);
	}

	function onUpdateOrgContact(updatedContact: ContactResponse) {
		setCurrOrgContact(updatedContact);
	}

	// Fetch the next automation node for the current contact
	const nextAutomatedStep = useCampaignConnectionsQuery({
		variables: { input: { contactId } },
		skip: !isString(contactId) || contactId === '',
	}).data?.campaignConnections.find(
		(connection) => connection.id === campaignConnection?.id
	)?.nextAutomationNode;

	return {
		targetProfileId: targetProfile?.id,
		isTargetOpenProfile: targetProfile?.isOpenProfile,
		targetAlias: targetProfile?.alias,
		orgMemberId: selectedOrgMemberId,
		messageTemplates,
		organizationId: activeMember?.organizationId,
		conversation,
		isConversationInvalid: isNull(conversation),
		orgContact: currOrgContact,
		contactData: contactInfoData,
		activeMember: isNil(activeMember) ? undefined : activeMember,
		activeAdmin: isNil(activeAdmin) ? undefined : activeAdmin,
		onUpdateCurrentConversation,
		onChangeSelectedOrgMember,
		campaignConnection,
		//provides sourceName progressive fallbacks so we can display something if the contact is not a connection (eg inMail case)
		sourceName:
			campaignConnection?.orgMemberName ??
			formatName(selectedOrgMember?.firstName, selectedOrgMember?.lastName),
		isRefetching,
		onMeetingBookedUpdated,
		onUpdateOrgContact,
		nextAutomatedStep: isAutomatedStep(nextAutomatedStep) ? nextAutomatedStep : undefined,
	};
}
