import { VIRTUAL_SCROLL_OFFSET } from './tables/constants';
import { OrgRoleTitles } from '../../config/roles';

export const AI_DETECTED_TAB_KEY = 'ai-detected';
export const CONFIRMED_MEETINGS_TAB_KEY = 'confirmed-meetings';

/**
 * Tab-key type for meetings booked page
 */
export type MeetingsBookedTabKey = typeof AI_DETECTED_TAB_KEY | typeof CONFIRMED_MEETINGS_TAB_KEY;

export const TAB_SECTION_OFFSET = VIRTUAL_SCROLL_OFFSET + 62;
export const ROW_HEIGHT = 77;
export const MIN_TAB_SECTION_HEIGHT = 340;
export const MAX_TAB_SECTION_HEIGHT = 720;

/**
 * Roles that have the access permissions to view other members within a campaign
 */
export const RolesWithTeamMemberAccess = [OrgRoleTitles.Admin, OrgRoleTitles.Owner];
