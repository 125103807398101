import { Button, Divider, Input, Select, Skeleton, Space, Table, Tooltip, Typography } from 'antd';
import { isNil, isUndefined } from 'lodash';
import { throwError } from '@copilot/common/utils';
import Column from 'antd/lib/table/Column';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';
import BasicContainer from '@copilot/common/components/containers/basic';
import { spacingMD } from '@copilot/common/constant/commonStyles';

type Organization = {
	/* organization's id */
	id: string;
	/* organization's name */
	name: string;
	/* organization admin's user id */
	adminUserId: string | undefined;
	/* customer success owner */
	customerSuccessOwnerName: string;
};

type Tenant = {
	/* tenant's id */
	id: string;
	/* tenant's name */
	name: string;
	/* child tenants */
	childTenants: { id: string }[];
	/* child organizations */
	childOrganizations: Organization[];
};

type AccountOwner = {
	id: string;
	name: string;
};

type TenantPanelPropsType = {
	/* tenant information to display */
	tenant: Tenant | undefined;
	/* is fetching the data */
	isLoading: boolean;
	/* Callback to handle impersonation */
	onImpersonate: (userId: string, orgId: string) => void;
	/* Callback to handle tenant search term change */
	onTenantNameSearchTermChanged: (searchTerm: string) => void;
	/* Callback to handle organization search term change */
	onOrganizationNameSearchTermChanged: (searchTerm: string) => void;
	/* Callback to handle filtering by account owner */
	onAccountOwnerSelected: (id?: string) => void;
	/* account owners */
	accountOwners: AccountOwner[];
	/* account owner id that is selected */
	selectedAccountOwnerId: string | undefined;
} ;

const clickableRowClassname = 'clickable';

const ClickableTable = styled(Table<{ id: string }>) `
	.clickableRowClassname:hover {
	  cursor: pointer;
	}
`;

const StrMasterAccounts = 'Master Accounts';
const StrAccounts = 'Accounts';
const SELECT_ALL_OWNER = 'all';

/**
 * [Presentational] Component for displaying a Tenant
 * @param props
 * @constructor
 */
export function TenantPanel(props: TenantPanelPropsType): JSX.Element {
	const { 
		isLoading, 
		tenant, 
		onImpersonate, 
		onTenantNameSearchTermChanged, 
		onOrganizationNameSearchTermChanged, 
		accountOwners, 
		onAccountOwnerSelected,
		selectedAccountOwnerId,
	} = props;
	const history = useHistory();
	
	if (isLoading) return <Skeleton active/>;
	if (isNil(tenant)) return throwError('Unable to load tenant data');
	
	return (
		<Space direction={'vertical'} style={{ width: '100%' }}>
			<Typography.Text>ID: {tenant.id}</Typography.Text>
			<Typography.Text>Name: {tenant.name}</Typography.Text>
			<Divider />
			<BasicContainer.Content style={{ paddingBottom: spacingMD }}>
				<Space>
					<Input.Search
						placeholder={`Search ${StrMasterAccounts}`}
						onChange={(event) => {onTenantNameSearchTermChanged(event.target.value);}}
						minLength={400}
					/>
				</Space>
			</BasicContainer.Content>
			<ClickableTable
				bordered
				dataSource={tenant.childTenants}
				onRow={(record) => {
					return {
						onClick: () => {
							history.push(`/adminhub/${record.id}`);
							onTenantNameSearchTermChanged('');
						},
					};
				}}
				rowClassName={clickableRowClassname}
			>
				<Column<Tenant>
					title={`${StrMasterAccounts}`}
					dataIndex="name"
					key="name"
					sorter={(a, b) => a.name.localeCompare(b.name)}
				/>
			</ClickableTable>
			<Divider />
			<BasicContainer.Content style={{ paddingBottom: spacingMD }}>
				<Space>
					<Input.Search
						placeholder={`Search ${StrAccounts}`}
						onChange={(event) => {onOrganizationNameSearchTermChanged(event.target.value);}}
						minLength={400}
					/>
					<Select
						placeholder="Select an account owner"
						onChange={(id: string) => onAccountOwnerSelected(id == SELECT_ALL_OWNER ? undefined : id)}
						style={{ width: 200 }}
						options={[
							{ label: 'All Account Owners', value: SELECT_ALL_OWNER },
							...accountOwners.map(owner => ({ value: owner.id, label: owner.name })),
						]}
						value={selectedAccountOwnerId ?? SELECT_ALL_OWNER}
					/>
				</Space>
			</BasicContainer.Content>
			<ErrorBoundary>
				<Table
					bordered
					dataSource={tenant.childOrganizations}
				>
					<Column<Organization>
						title={`${StrAccounts}`}
						key='name'
						dataIndex='name'
						sorter={(a, b) => a.name.localeCompare(b.name)}
					/>
					<Column<Organization>
						title={'Account Owner'}
						key='customerSuccessOwnerName'
						dataIndex='customerSuccessOwnerName'
						align={'left'}
						width={300}
					/>
					<Column<Organization>
						key="action"
						align={'right'}
						width={150}
						render={
							(_, record) => {
								const canImpersonate = !isUndefined(record.adminUserId);
								return (
									<Tooltip
										title={'Unable to impersonate an inactive account'}
										open={canImpersonate ? false : undefined}
									>
										<Button
											disabled={!canImpersonate}
											onClick={() => {
												onImpersonate(record.adminUserId ?? throwError('user id not specified'), record.id);
											}}
										>
											Impersonate
										</Button>
									</Tooltip>
								);
							}
						}
					/>
				</Table>
			</ErrorBoundary>
		</Space>
	);
}
