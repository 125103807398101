import { useDispatch, useSelector } from 'react-redux';
import {
	loadInbox,
	sendAutomatedMessage,
	sendManualMessage,
	updateDisplayType,
	updateInboxMessagesFilter,
	updateInboxMessagesPagination,
	updateInboxMessagesSearchTerm,
} from '../inbox/data/actionCreators';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import InboxMessageSelectors from '../inbox/data/selector';
import {
	DUE_REMINDER,
	InboxV2GlobalFilter,
	isSearchContext,
	ReminderFilter,
	SEARCH_CONTEXTS,
	SearchContext,
	SentimentFilter,
	UPCOMING_REMINDER,
} from './context/types';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import { IncludeExcludeFilterOption } from './components/includeExcludeFilter/types';
import { InboxDisplayType } from '@copilot/data/requests/models';
import {
	LinkedInMessageType,
	MemberLinkedInProfileResponse,
	CampaignType,
} from '@copilot/data/responses/interface';
import { LinkedInManager } from '@copilot/data';
import { InboxMessage } from '../inbox/ui/types';
import isUndefined from 'lodash/isUndefined';
import { INBOX_VERSIONS, REMINDER_STATUSES } from '@copilot/common/pages/inbox/data/constant';
import { ReminderStatus } from '@copilot/common/pages/inbox/data/types';
import { InboxMessageSubmissionData } from './types';
import { RootState } from '@copilot/common/store/reducers';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { useSearchParams } from '@copilot/common/hooks/common';

type UseInboxV2DataProps = {
	isActiveMemberOnly: boolean;
};

type InboxPageV2SearchParams = {
	tab: string;
};

/**
 * Hook responsible for handling the data for the inbox page
 * @param param0
 * @returns
 */
export default function useInboxV2Data({ isActiveMemberOnly }: UseInboxV2DataProps) {
	const dispatch = useDispatch();
	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeImpersonator = useSelector((state: RootState) => state.app.impersonator);
	const [linkedInLookUp, setLinkedInLookUp] = useState<
		Record<string, MemberLinkedInProfileResponse>
	>({});
	const [searchParams, updateSearchParams] = useSearchParams<InboxPageV2SearchParams>();

	const {
		data = [],
		loading,
		filter: { campaigns, tags, teamMembers, sentiment },
		counts,
		hasMore,
	} = useSelector(InboxMessageSelectors);
	const nurtureCampaigns = useSelector(
		CampaignSelectors.getCampaignsByType([CampaignType.Nurture])
	);

	//currently we don't have linkedIn aliases in the returned inboxMessage model. In this case we can give a facade of the more ideal model for now by applying the alias via lookup
	const inboxV2Data: InboxMessage[] = useMemo(() => {
		return data.map((message) => {
			const linkedInProfile = linkedInLookUp[message.contactId];
			return {
				...message,
				linkedInAlias: linkedInProfile?.alias,
			};
		});
	}, [data, linkedInLookUp]);

	//we have a separate state for loading because we want to show the loading spinner while we are fetching the initial data
	//and the redux store defaults this value to false during the initial load
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!isNil(activeMember)) {
			initInboxPageData();
		}
	}, [activeMember, activeAdmin]);

	useEffect(() => {
		if (!isNil(data)) {
			setIsLoading(loading);
		}
	}, [loading, inboxV2Data]);

	useEffect(() => {
		const newMessages = data.filter((message) => isNil(linkedInLookUp[message.contactId]));
		fetchLinkedInProfiles(newMessages.map((message) => message.contactId));
	}, [activeMember, data]);

	/**
	 * Checks if we have any new contactIds and fetches their linkedInProfile information, adding it to the lookup on success
	 * @param contactIds
	 */
	async function fetchLinkedInProfiles(contactIds: string[]) {
		if (contactIds.length > 0 && !isNil(activeMember)) {
			try {
				const res = await LinkedInManager.getContactsLinkedInProfiles(
					activeMember.organizationId,
					contactIds
				);
				const newLinkedInLookUp = res.reduce((acc, resp) => {
					acc[resp.contactId] = resp.linkedInProfile;
					return acc;
				}, {} as Record<string, MemberLinkedInProfileResponse>);
				setLinkedInLookUp({ ...linkedInLookUp, ...newLinkedInLookUp });
			} catch (e) {
				console.error('Error fetching linkedin profiles', e);
			}
		}
	}

	function onLoadMore(offset?: number, pageSize?: number) {
		const dataLength = isNil(data) ? 0 : data.length; // Default to 0 if no data available
		const newOffset = isUndefined(offset) ? dataLength : offset; // Use offset if defined
		dispatch(updateInboxMessagesPagination(newOffset, pageSize ?? 10));
	}

	/**
	 * Load inbox page data (including first page and options for filters)
	 */
	function initInboxPageData() {
		// Load inbox page data
		if (!isNil(activeMember)) {
			const searchContext = isSearchContext(searchParams.tab)
				? searchParams.tab
				: SEARCH_CONTEXTS.ALL_MESSAGES_CONTEXT;
			dispatch(
				loadInbox(
					activeMember.organizationId,
					!isNil(activeAdmin),
					// If we are not in team inbox mode, we want to load messages for the active team member by default
					isActiveMemberOnly ? activeMember.id : undefined,
					InboxDisplayType.Email,
					INBOX_VERSIONS.V4,
					getDefaultFiltersByContext(searchContext)
				)
			);
		}
	}

	/**
	 * Handler for when the search context changes (eg we change tabs)
	 * Should set the display type and filter options based on the new context
	 * @param searchContext
	 */
	function onSearchContextChange(searchContext: SearchContext) {
		setIsLoading(true);
		updateSearchParams({ tab: searchContext });
		dispatch(
			updateDisplayType(
				searchContext === SEARCH_CONTEXTS.ALL_MESSAGES_CONTEXT
					? InboxDisplayType.Email
					: InboxDisplayType.Cards,
				!isNil(activeAdmin),
				getDefaultFiltersByContext(searchContext)
			)
		);
	}

	/**
	 * Handler for updating the search term
	 * @param term
	 */
	function onSubmitSearch(term: string) {
		dispatch(updateInboxMessagesSearchTerm(term));
	}

	/**
	 *  Handler for updating the sentiment filter
	 * @param newSentiment
	 */
	function onUpdateSentimentFilter(newSentiment: SentimentFilter) {
		dispatch(
			updateInboxMessagesFilter({
				sentiment: sentiment.map((sent) => {
					if (sent.key === newSentiment) {
						sent.isVisible = true;
					} else {
						sent.isVisible = false;
					}
					return sent;
				}),
			})
		);
	}

	/**
	 * Handler for updating the reminder filter
	 */
	function onUpdateReminderFilter(reminderFilter: ReminderFilter) {
		let reminderStatuses: ReminderStatus[] = [];
		switch (reminderFilter) {
			case UPCOMING_REMINDER:
				reminderStatuses = [REMINDER_STATUSES.Upcoming];
				break;
			case DUE_REMINDER:
				reminderStatuses = [REMINDER_STATUSES.Due];
				break;
			default:
				throw new Error('Invalid reminder filter');
		}
		dispatch(updateInboxMessagesFilter({ reminderStatuses }));
	}

	/**
	 * Handler for updating the all messages filter
	 */
	function onUpdateAllMessagesFilter(showUnRepliedOnly: boolean) {
		dispatch(
			updateInboxMessagesFilter({
				lastMessageTypes: showUnRepliedOnly ? [LinkedInMessageType.Received] : [],
			})
		);
	}

	/**
	 * Handler for updating the active/global filter
	 * @param param0
	 * @returns
	 */
	function onActiveFilterChange({
		campaigns: selectedCampaigns,
		tags: selectedTags,
		teamMembers: selectedTeamMembers,
	}: InboxV2GlobalFilter) {
		const tagsLookup = selectedTags.reduce((acc, tag) => {
			acc[tag.value] = tag;
			return acc;
		}, {} as Record<string, IncludeExcludeFilterOption>);

		if (isNil(activeMember)) return;

		selectedTeamMembers = isActiveMemberOnly ? [activeMember.id] : selectedTeamMembers;

		dispatch(
			updateInboxMessagesFilter({
				tags: tags.map((tag) => {
					if (!isNil(tagsLookup[tag.key])) {
						tag.isVisible = true;
						tag.isExclude = !tagsLookup[tag.key].isIncluded;
					} else {
						tag.isVisible = false;
						tag.isExclude = undefined;
					}
					return tag;
				}),
				campaigns: campaigns.map((campaign) => {
					if (selectedCampaigns.includes(campaign.key)) {
						campaign.isVisible = true;
					} else {
						campaign.isVisible = false;
					}
					return campaign;
				}),
				teamMembers: teamMembers.map((member) => {
					if (selectedTeamMembers.includes(member.key)) {
						member.isVisible = true;
					} else {
						member.isVisible = false;
					}
					return member;
				}),
			})
		);
	}

	function onSubmitInboxMessage({
		threadId,
		msgText,
		templateId,
		campaignId,
		nodeId,
	}: InboxMessageSubmissionData) {
		nodeId
			? dispatch(sendAutomatedMessage(threadId, msgText, nodeId))
			: dispatch(sendManualMessage(threadId, msgText, true, templateId, campaignId));
	}

	/**
	 * Get filter applied by default based on search context
	 * @param context
	 */
	function getDefaultFiltersByContext(context: SearchContext) {
		return {
			showReminderOnly: context === SEARCH_CONTEXTS.REMINDERS_CONTEXT,
			newMessagesOnly: context === SEARCH_CONTEXTS.UNREAD_MESSAGES_CONTEXT,
			sentiment: sentiment.map((sent) => {
				sent.isVisible = false;
				return sent;
			}),
			lastMessageTypes: [],
			reminderStatuses:
				context === SEARCH_CONTEXTS.REMINDERS_CONTEXT ? [REMINDER_STATUSES.Due] : [],
		};
	}

	return {
		initInboxPageData,
		activeMember,
		activeImpersonator,
		messages: inboxV2Data,
		isLoading,
		searchParams,
		onSearchContextChange,
		campaignOptions: {
			active: campaigns.filter((c) => c.properties[0]).map(filterDefToOption),
			inactive: campaigns.filter((c) => !c.properties[0]).map(filterDefToOption),
		},
		nurtureCampaigns,
		teamMemberOptions: teamMembers.map(filterDefToOption),
		tagOptions: tags.map(filterDefToOption),
		onActiveFilterChange,
		remindersCount: counts?.reminder ?? 0,
		unreadMessagesCount: counts?.unread ?? 0,
		sentimentOptions: sentiment?.map(filterDefToOption),
		onSubmitSearch,
		onUpdateSentimentFilter,
		onUpdateReminderFilter,
		hasMore,
		onLoadMore,
		onSubmitInboxMessage,
		onUpdateAllMessagesFilter,
	};
}

/**
 * Wrapper that takes a filter definition and converts it to a filter option
 * @param filter
 * @returns
 */
function filterDefToOption(filter: FilterDefinition) {
	return { label: filter.label, value: filter.key };
}
