import {
	AccountDashboardFilters,
	AccountStatusNames,
} from '@copilot/common/pages/accountDashboard/types';

/**
 * Default account filters that is set initially
 */
export const DEFAULT_ACCOUNT_DASHBOARD_FILTERS: AccountDashboardFilters = {
	accountStatus: AccountStatusNames.Active,
	accountManagerId: undefined,
	pageIndex: 0,
	pageSize: 10,
};

export const EMPTY_DATA_MESSAGE = "You haven't added accounts to the dashboard.";
export const NO_RESULT_MESSAGE = 'No results found';
export const SEARCH_INPUT_PLACEHOLDER = 'Search by Account';
