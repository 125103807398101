// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d0OEjI1vRobLp8qmj3ij {\n  height: 100vh;\n}\n.q5NMfvo2rNutstR8WQVi {\n  border-right: 1px solid #f5f5f5;\n  min-height: 100%;\n  font-weight: 700;\n  overflow-y: auto;\n}\n.RILs4ay2E968YVLezHi7 {\n  overflow-y: auto;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/layouts/headerSider/headerSider.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAIA;EACE,+BAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AAFF;AAKA;EACE,gBAAA;AAHF","sourcesContent":["@import '../../../../styles/common-variables';\n\n.headerSiderWrapper {\n  height: 100vh;\n}\n\n.siderWrapper {\n  border-right: 1px solid @neutral3;\n  min-height: 100%;\n  font-weight: 700;\n  overflow-y: auto;\n}\n\n.contentWrapper {\n  overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerSiderWrapper": "d0OEjI1vRobLp8qmj3ij",
	"siderWrapper": "q5NMfvo2rNutstR8WQVi",
	"contentWrapper": "RILs4ay2E968YVLezHi7"
};
export default ___CSS_LOADER_EXPORT___;
