import { SpinningStars } from './spinningStars';
import { Typography } from 'antd';
import styles from './loadingScreen.module.less';
import { CloseCircleFilled } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';

type PersonalizedInsightsLoadingScreenProps = {
	hasLoadingFailed: boolean;
	loadingFailureReason: string;
};

/**
 * [Presentational]
 * Loading/error screen for personalized insights
 * @param hasLoadingFailed
 * @param loadingFailureReason
 * @returns
 */
export function PersonalizedInsightsLoadingScreen({
	hasLoadingFailed,
	loadingFailureReason,
}: PersonalizedInsightsLoadingScreenProps) {
	return (
		<div className={styles.background}>
			<div className={styles.container}>
				{hasLoadingFailed ? (
					<CloseCircleFilled className={styles.errorIcon} />
				) : (
					<SpinningStars />
				)}
				{hasLoadingFailed ? (
					<>
						<Typography.Title level={2}>Generating insights failed</Typography.Title>
						<Typography.Text>
							{isEmpty(loadingFailureReason) ? (
								<>
									It looks like we ran into an issue. <br /> Please refresh the
									page and we'll try again.
								</>
							) : (
								loadingFailureReason
							)}
						</Typography.Text>
					</>
				) : (
					<>
						<Typography.Title level={2}>Analyzing profile data...</Typography.Title>
						<Typography.Text>
							We are generating insights for this profile,
							<br /> please keep this tab open.
						</Typography.Text>
					</>
				)}
			</div>
		</div>
	);
}
