import React from 'react';
import { Col, Row, Switch, Spin } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { useFetch } from '@copilot/common/hooks/common';
import { NotificationManager } from '@copilot/data';
import { NotificationOptions, PushMethods } from '@copilot/data/responses/interface';

interface EmailNotificationSettingOption {
	title: React.ReactNode;
	description: React.ReactNode;
	type: string;
}

const options: EmailNotificationSettingOption[] = [
	{
		title: 'Interested Leads',
		description:
			'Receive notification emails whenever you get an interested lead from LinkedIn.',
		type: NotificationOptions.INSTANT,
	},
	{
		title: 'Weekly LinkedIn Prospecting Summary',
		description: 'Receive a weekly summary of your LinkedIn prospecting.',
		type: NotificationOptions.WEEKLY,
	},
	{
		title: 'Monthly LinkedIn Prospecting Summary',
		description: 'Receive a monthly summary of your LinkedIn prospecting.',
		type: NotificationOptions.MONTHLY,
	},
	{
		title: 'Prospecting status alerts from your account',
		description: 'Receive an email when there is an issue with your account.',
		type: NotificationOptions.ACCOUNTALERTS,
	},
	{
		title: 'Scheduled message receipts',
		description:
			'Receive an email to notify that your scheduled message was sent successfully.',
		type: NotificationOptions.SENDRECEIPT,
	},
];

interface EmailNotificationSettingsProps {
	user: { id: string };
}

const EmailNotificationSettings: React.FC<EmailNotificationSettingsProps> = (props) => {
	const { user } = props;
	const [checkedState, setCheckedState] = React.useState<
		{ [k in EmailNotificationSettingOption['type']]: boolean }
	>({});
	const [settingsFetch, fetchSettings] = useFetch(NotificationManager.getNotificationOptions);
	const [, postUpdate] = useFetch(NotificationManager.updateNotificationSubscriptions);
	React.useEffect(() => {
		if (!user.id) return;
		fetchSettings().then((s) =>
			setCheckedState(
				s.reduce<typeof checkedState>((acc, r) => {
					acc[r.notificationCode] =
						r.subscriptions[user.id] === undefined
							? true
							: r.subscriptions[user.id] === PushMethods.Email;
					return acc;
				}, {})
			)
		);
	}, [user.id]);
	const handleChange: SwitchChangeEventHandler = function (
		this: EmailNotificationSettingOption,
		checked
	) {
		const { type } = this;
		// AJAX to set it
		postUpdate(type, {
			ownerIds: [user.id],
			pushMethods: [checked ? PushMethods.Email : PushMethods.None],
		}).then(() => setCheckedState((state) => ({ ...state, [type]: checked })));
	};

	return (
		<>
			<Row>
				<Col>
					<h2>Email Notifications</h2>
					<p>Select the types of notification that you want to receive through Email.</p>
					<br />
				</Col>
			</Row>
			{options.map((o) => (
				<Row align="middle" key={o.type} style={{ marginBottom: '1.5em' }}>
					<Col span={11} style={{ fontWeight: 500 }}>
						<h3>{o.title}</h3>
						<p>{o.description}</p>
					</Col>
					<Col span={2} offset={2}>
						{settingsFetch.isFetching && <Spin />}
						{!settingsFetch.isFetching && (
							<Switch
								checked={checkedState[o.type]}
								onChange={handleChange.bind(o)}
								checkedChildren="ON"
								unCheckedChildren="OFF"
							/>
						)}
					</Col>
				</Row>
			))}
		</>
	);
};

export default EmailNotificationSettings;
