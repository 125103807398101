import { FC } from 'react';
import { InsightsPageStyledTag } from './personalityInsightsPage';

type SpecificSuggestionsProps = {
	suggestions: {
		[attribute: string]: string;
	};
};

/**
 * [Presentational]
 * A property tag followed by description text
 * @param props
 * @returns
 */
export const SpecificSuggestions: FC<SpecificSuggestionsProps> = (props) => (
	<>
		{Object.entries(props.suggestions).map((attributeSuggestion) => (
			<div key={attributeSuggestion[0]} style={{ width: '47%' }}>
				<InsightsPageStyledTag
					text={attributeSuggestion[0]}
					additionalStyle={{ margin: '24px 0 16px' }}
				/>
				<p style={{ margin: '0' }}>{attributeSuggestion[1]}</p>
			</div>
		))}
	</>
);
