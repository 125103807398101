import { Button, Card, Typography } from 'antd';
import { CommStyleWheel } from '../../commStyleWheel';
import styles from './commStyleCard.module.less';

type CommStyleCardProps = {
	onCTAClick?: () => void;
};

/**
 * Card for describing what communication styles are, links out to help article
 * @param param0
 * @returns
 */
export default function CommStyleCard({ onCTAClick }: CommStyleCardProps) {
	return (
		<Card title={'What are Communication Styles?'} className={styles.card}>
			<div className={styles.contentWrapper}>
				<CommStyleWheel />
				<Typography.Paragraph className={styles.cardCopy}>
					Communication styles define how we interact with others. Unlock new insights to
					boost your prospecting efforts.
				</Typography.Paragraph>
			</div>

			{onCTAClick ? (
				<Button className={styles.ctaButton} onClick={onCTAClick}>
					Learn More About the Framework
				</Button>
			) : null}
		</Card>
	);
}
