import { Button, Select, Tag } from 'antd';
import {
	CAMPAIGN_FILTER_TEST_ID,
	FILTER_SECTION_TEST_ID,
	TEAM_MEMBER_FILTER_TEST_ID,
} from './constants';
import styles from './organizationFilterSection.module.less';
import { FilterOption } from '../types';
import {
	ActiveOrganizationFilter,
	OrganizationFilterConfig,
	OrganizationFilterHandlers,
	OrganizationFilterOptions,
} from './types';
import { ReactNode } from 'react';

type OrganizationFilterSectionProps = {
	activeFilter: ActiveOrganizationFilter;
	children?: ReactNode;
	isClearFiltersEnabled?: boolean;
} & OrganizationFilterOptions &
	OrganizationFilterHandlers &
	OrganizationFilterConfig;

/**
 * Global filter section for organization-wide properties
 */
export default function OrganizationFilterSection({
	campaignOptions,
	activeFilter,
	onCampaignFilterChange,
	enableTeamMemberFilter,
	teamMemberOptions,
	onTeamMemberFilterChange,
	onClearFilters,
	children,
	isClearFiltersEnabled = true,
}: OrganizationFilterSectionProps) {
	return (
		<div className={styles.filterWrapper} data-testid={FILTER_SECTION_TEST_ID}>
			{enableTeamMemberFilter && (
				<Select
					className={styles.filterDropdown}
					optionFilterProp="label"
					data-testid={TEAM_MEMBER_FILTER_TEST_ID}
					mode="multiple"
					placeholder="Team member"
					onChange={onTeamMemberFilterChange}
					tokenSeparators={[',']}
					options={teamMemberOptions}
					value={activeFilter.teamMembers}
					tagRender={TagRenderer}
				/>
			)}
			<Select
				className={styles.filterDropdown}
				optionFilterProp="label"
				data-testid={CAMPAIGN_FILTER_TEST_ID}
				mode="multiple"
				placeholder="Campaign"
				onChange={onCampaignFilterChange}
				optionRender={({ data: { label, active } }) => (
					<div className={styles.statusWrapper}>
						<div
							className={[styles.statusCircle, active ? styles.active : ''].join(' ')}
						/>{' '}
						{label}
					</div>
				)}
				popupClassName={styles.statusFilterPopup}
				options={calcCampaignOptions(campaignOptions.active, campaignOptions.inactive)}
				value={activeFilter.campaigns}
				tagRender={TagRenderer}
			/>
			{children}
			<Button type="text" onClick={onClearFilters} disabled={!isClearFiltersEnabled}>
				Clear Filters
			</Button>
		</div>
	);
}

type TagRendererProps = {
	label: React.ReactNode;
	onClose: () => void;
	value: { label: string };
};
/**
 * Custom tag renderer for antd Select, allowing for aligned styling between the different filter dropdown types
 * @param param0
 * @returns
 */
function TagRenderer({ label, onClose, value }: TagRendererProps) {
	return (
		<Tag
			className={[styles.filterTag, styles.included].join(' ')}
			key={value.label}
			closable
			onClose={onClose}
		>
			{label}
		</Tag>
	);
}

/**
 * Splits campaign options into active and inactive options
 * @param active
 * @param inactive
 * @returns
 */
function calcCampaignOptions(active: FilterOption[], inactive: FilterOption[]) {
	return [
		{
			label: `Active (${active.length})`,
			options: active.map((opt) => {
				return {
					...opt,
					active: true,
				};
			}),
			value: undefined,
		},
		{
			label: `Inactive (${inactive.length})`,
			options: inactive.map((opt) => {
				return {
					...opt,
					active: false,
				};
			}),

			value: undefined,
		},
	];
}
