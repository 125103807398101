// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XAsY1gcF6CoLrSSjWT_o .copilot-select-item-option {\n  padding-left: 4px;\n}\n.R_06cwmlbLH4rnN8Mrgq {\n  color: rgba(0, 0, 0, 0.45);\n  padding: 8px;\n}\n.H91Lx8X3_04Yh_eKR2wX {\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/search/autocomplete/searchAutoComplete.module.less"],"names":[],"mappings":"AAGA;EAEE,iBAAA;AAHF;AAOA;EACC,0BAAA;EACA,YAAA;AALD;AAQA;EACC,aAAA;EACA,uBAAA;AAND","sourcesContent":["@import '../../../../styles/common-variables';\n@import '../../../../styles/antd-variables';\n\n.searchBoxPopup {\n\t:global(.copilot-select-item-option) {\n\t\tpadding-left: @spacer-num-xxs;\n\t}\n}\n\n.searchBoxEmpty {\n\tcolor: @textColorSecondary;\n\tpadding: @spacer-num-xs;\n}\n\n.searchBoxLoading {\n\tdisplay: flex;\n\tjustify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBoxPopup": "XAsY1gcF6CoLrSSjWT_o",
	"searchBoxEmpty": "R_06cwmlbLH4rnN8Mrgq",
	"searchBoxLoading": "H91Lx8X3_04Yh_eKR2wX"
};
export default ___CSS_LOADER_EXPORT___;
