import { useEffect, useState } from 'react';
import { PaginatedMeetingResponse } from '../types';
import { EMPTY_PAGINATION_RESPONSE } from '@copilot/common/types/pagination';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { DateRange } from '../context/types';
import {
	IMeetingStatus,
	useCampaignConnectionsByMeetingsQuery,
} from '@copilot/data/graphql/_generated';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { toPaginatedMeetings } from './helpers';
import { set } from 'lodash';

type UseBufferedDetectedMeetingDataProps = {
	selectedTeamMembers: string[];
	selectedCampaigns: string[];
	selectedDateRange: DateRange;
	status: IMeetingStatus;
	currentPage: number;
	pageSize: number;
};

/**
 * Replenishing/buffering data hook that allows us to a full page of meetings by refetching data
 * @returns
 */
export default function useReplenishableMeetingData({
	selectedTeamMembers,
	selectedCampaigns,
	selectedDateRange,
	currentPage,
	pageSize,
	status,
}: UseBufferedDetectedMeetingDataProps) {
	const [meetingBuffer, setMeetingBuffer] =
		useState<PaginatedMeetingResponse>(EMPTY_PAGINATION_RESPONSE);
	//needed to prune duped data from the buffered page
	const [removedLookup, setRemovedLookup] = useState<Record<string, boolean>>({});

	const {
		data: dataBuffer,
		loading: isLoadingDataBuffer,
		refetch: refetchDataBuffer,
	} = useCampaignConnectionsByMeetingsQuery({
		variables: {
			input: {
				...calcInput(),
				pagination: {
					page: currentPage - 1,
					pageSize: pageSize,
				},
			},
		},
	});

	const debouncedRefetchDetectedBuffer = debounce(refetchDataBuffer, 500);

	useEffect(() => {
		const newBuffer = toPaginatedMeetings(dataBuffer);
		setMeetingBuffer({
			...newBuffer,
			content: newBuffer.content.filter((meeting) => !removedLookup[meeting.id]),
		});
	}, [dataBuffer]);

	//helper function to calculate the input for the query based on props
	function calcInput() {
		return {
			members: selectedTeamMembers,
			campaigns: selectedCampaigns,
			meeting: {
				status,
				start: isNil(selectedDateRange.start)
					? undefined
					: moment(selectedDateRange.start).valueOf(),
				end: isNil(selectedDateRange.end)
					? undefined
					: moment(selectedDateRange.end).valueOf(),
			},
		};
	}

	//helper function to remove multiple meetings from the buffer
	function onBulkRemoveFromBuffer(bulkRemoveLookup: Record<string, boolean>) {
		setRemovedLookup({ ...removedLookup, ...bulkRemoveLookup });
		const newContent = meetingBuffer.content.filter((meeting) => !bulkRemoveLookup[meeting.id]);
		const newBuffer = {
			...meetingBuffer,
			content: newContent,
			count: meetingBuffer.count - (meetingBuffer.content.length - newContent.length),
		};
		setMeetingBuffer(newBuffer);
		return replenishBuffer(newBuffer, false);
	}

	//helper function to remove a meeting from the buffer, fetching more data if needed or going back a page
	function onRemoveFromBuffer(campaignConnectionId: string) {
		setRemovedLookup((prev) => set(prev, campaignConnectionId, true));
		const {
			content,
			count,
			pages: { current, total },
		} = meetingBuffer;
		const newContent = content.filter((meeting) => meeting.id !== campaignConnectionId);
		const newBuffer = {
			content: newContent,
			count: count - 1,
			pages: {
				current,
				total,
			},
		};
		setMeetingBuffer(newBuffer);
		replenishBuffer(newBuffer, true);
	}

	//helper function to replenish the buffer with more data if needed
	async function replenishBuffer(
		{ content, pages: { current, total } }: PaginatedMeetingResponse,
		useDebounce: boolean
	) {
		const isOnLastPage = current === total - 1;
		if (!isOnLastPage) {
			useDebounce ? debouncedRefetchDetectedBuffer() : await refetchDataBuffer();
			return Promise.resolve();
		} else if (isEmpty(content) && isOnLastPage) {
			await refetchDataBuffer({
				input: {
					...calcInput(),
					pagination: {
						page: current > 0 ? current - 1 : 0,
						pageSize: pageSize,
					},
				},
			});
			return Promise.resolve();
		}
	}

	return {
		//data
		meetingBuffer,
		onRemoveFromBuffer,
		isLoadingBuffer: isLoadingDataBuffer,
		onBulkRemoveFromBuffer,
		refetchBuffer: refetchDataBuffer,
	};
}
