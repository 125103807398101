import { BlocklistContextData, BlocklistFilter } from './types';

export const DEFAULT_BLOCKLIST_FILTER: BlocklistFilter = {
	teamMember: undefined,
};

export const DEFAULT_BLOCKLIST_CONTEXT: BlocklistContextData = {
	//OPTIONS
	teamMemberOptions: [],
	//STATE
	activeFilter: DEFAULT_BLOCKLIST_FILTER,
	isLoading: false,
	isInitializingData: true,
	//HANDLERS
	onUpdateTeamMember: () => {},
	onAddToBlocklistClick: () => {},
	onEditBlocklistClick: () => {},
	//DATA
	data: {
		entries: [],
	},
};
