import Select from 'antd/lib/select';
import styles from '../../inboxPageV2.module.less';
import { Typography } from 'antd';
import { FilterOption } from '@copilot/common/components/filters/types';

type InboxV2ListSelectBoxProps<T = string> = {
	options: FilterOption<T>[];
	defaultValue: T;
	onChange?: (value: T) => void;
};

/**
 * Context/tab specific drop down filter for inboxV2
 */
export default function InboxV2ListSelectBox<T>({
	options,
	defaultValue,
	onChange,
	...rest
}: InboxV2ListSelectBoxProps<T>) {
	return (
		<Typography.Text className={styles.listSelectBox}>
			Show:{' '}
			<Select options={options} defaultValue={defaultValue} onChange={onChange} {...rest} />
		</Typography.Text>
	);
}
