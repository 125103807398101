import useConversationTabV2 from './hooks/useConversationTabV2';
import ConversationTab from './conversationTab';

/**
 * Component responsible for rendering the conversation tab in the contact drawer and coordinating its state, CRUD flows, etc
 * @returns
 */
export default function ConversationTabSmart() {
	const data = useConversationTabV2();

	return <ConversationTab {...data} />;
}
