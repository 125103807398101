import { SmartReplyManager } from '@copilot/data';
import { IPromptsQuery, usePromptsLazyQuery } from '@copilot/data/graphql/_generated';
import { PromptsResponse } from '@copilot/data/managers/smartReply';
import { useFetch } from '../common';
import { isUndefined } from 'lodash';
import { SmartReplyPromptCategory } from '../../components/forms/messageForm/smartReply/promptMenu/promptMenuTypes';

/**
 * A Smart Reply prompt's DTO
 */
export type Prompt = {
	id: string;
	label: string;
	value: string;
	action: string;
	useCases?: ReadonlyArray<SmartReplyPromptCategory> | null;
};

/** Hook used to return smart reply default prompts
 * @param isTeamUser Whether the user is a team user or not
 * @returns prompts and getPrompts (a function to retrieve the prompts)
 */
export function usePrompts(isTeamUser: boolean): {
	prompts: Prompt[];
	getPrompts: () => Promise<any>;
} {
	let data: PromptsResponse | IPromptsQuery | undefined;
	let formattedData: Prompt[] | undefined;
	let getPrompts;

	//GraphQL vs Rest Api
	if (isTeamUser) {
		[getPrompts, { data }] = usePromptsLazyQuery();
		formattedData = data?.prompts.map((prompt) => ({
			id: prompt.id,
			label: prompt.label,
			value: prompt.id,
			action: prompt.action,
			useCases: prompt.useCases,
		}));
	} else {
		[{ data }, getPrompts] = useFetch(SmartReplyManager.getPrompts);
		// all rest api prompts are WRITE
		formattedData = data?.general.map((prompt) => ({
			id: prompt.key,
			label: prompt.label,
			value: prompt.value,
			action: 'WRITE',
		}));
	}

	const prompts: Prompt[] = isUndefined(formattedData) ? [] : formattedData;

	return { prompts, getPrompts };
}
