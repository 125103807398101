import { Space } from 'antd';
import { useState } from 'react';
import { isUndefined } from 'lodash';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import BasicContainer from '@copilot/common/components/containers/basic';
import ProspectingCampaignFunnel from './prospectingCampaignFunnel';
import { useOrganizationStats } from './data';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';
import OrganizationActiveMemberTable from './organizationActiveMemberTable';
import DateRangeDropdown from '@copilot/common/components/dataInput/dateRangeDropdown';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import moment from 'moment';
import {
	ABOVE_AVERAGE_COLOR,
	AVERAGE_COLOR,
	BELOW_AVERAGE_COLOR,
	DATE_RANGE_OPTIONS,
	DEFAULT_DATE_RANGE_OPTION,
} from './constants';
import {
	getConnectionRateColor,
	getReplyRateColor,
	toDividers,
	toProspectingCampaignFunnelData,
} from './util-functions';
import { DateRange, DateRangeDropdownValue } from '@copilot/common/components/dataInput/types';
import { getDateRangeFromValue } from '@copilot/common/components/dataInput/util-functions';

type Props = {
	organizationId: string; // The organization id we are rendering the summary for
};

/**
 * Version 2 of organization summary
 * @param param0
 * @returns
 */
const OrganizationSummaryV2 = ({ organizationId }: Props): JSX.Element => {
	const history = useHistory();
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const isOrgAdmin: boolean = activeMember?.isOrgAdmin ?? false;
	const [dateState, setDateState] = useState<DateRangeDropdownValue>(() => {
		const value = DEFAULT_DATE_RANGE_OPTION.value;
		const { start, end } = getDateRangeFromValue(value);
		return {
			value,
			start,
			end,
		};
	});
	function onDateChange({ option, startDate, endDate }: DateRange): void {
		setDateState(({ start, end }) => ({
			value: option.value,
			start: startDate ?? start,
			end: endDate ?? end,
		}));
	}

	function linkedInReconnectHandler(orgMemberId: string): void {
		const pathState = isOrgAdmin ? { teamMembersRedirectMemberId: orgMemberId } : {};
		history.push(isOrgAdmin ? '/?tab=teammembers' : '/settings/linkedin', pathState);
	}

	const { orgStats, orgMembers, error, loading } = useOrganizationStats(
		organizationId,
		linkedInReconnectHandler,
		dateState.start ? moment(dateState.start).utc(true).valueOf() : undefined,
		dateState.end ? moment(dateState.end).utc(true).valueOf() : undefined
	);

	return (
		<Space direction="vertical" size="small" style={{ width: '100%' }}>
			<DateRangeDropdown
				onChange={onDateChange}
				defaultValue={DEFAULT_DATE_RANGE_OPTION.value}
				options={DATE_RANGE_OPTIONS}
				value={dateState}
			/>
			<BasicContainer>
				<BasicContainer.Content>
					<LoadingIndicator isLoading={loading || !isUndefined(error)}>
						<ProspectingCampaignFunnel
							aboveAverageColor={ABOVE_AVERAGE_COLOR}
							belowAverageColor={BELOW_AVERAGE_COLOR}
							averageColor={AVERAGE_COLOR}
							height={400}
							data={toProspectingCampaignFunnelData(orgStats)}
							dividers={toDividers(orgStats)}
						/>
					</LoadingIndicator>
				</BasicContainer.Content>
			</BasicContainer>
			<BasicContainer>
				<BasicContainer.Content>
					<LoadingIndicator isLoading={loading}>
						<OrganizationActiveMemberTable
							activeMembers={orgMembers}
							onLinkedInReconnect={linkedInReconnectHandler}
							colors={{
								belowAverageColor: BELOW_AVERAGE_COLOR,
								aboveAverageColor: ABOVE_AVERAGE_COLOR,
								averageColor: AVERAGE_COLOR,
								getConnectionRateColor: getConnectionRateColor,
								getReplyRateColor: getReplyRateColor,
							}}
						/>
					</LoadingIndicator>
				</BasicContainer.Content>
			</BasicContainer>
		</Space>
	);
};

export default OrganizationSummaryV2;
